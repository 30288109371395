import actions from './actions';
import likeDislikeSkipService from '../../services/likeDislikeSkipService';

const {
	likeDislikeSkipBegin,
	likeDislikeSkipSuccess,
	likeDislikeSkipErr
	
} = actions;


const sliderImages = (imagesdata) => {
	return async dispatch => {
		try {
			dispatch(likeDislikeSkipBegin());
			//console.log("getWork")
			const data = await likeDislikeSkipService.sliderImages(imagesdata);
			//console.log("Images data",data)
			dispatch(likeDislikeSkipSuccess(data));

			
			
		} catch (err) {
			console.log(err)
			dispatch(likeDislikeSkipErr(err));
		}
	};
};
const sliderImages1 = (imagesdata) => {
	return async dispatch => {
		try {
			dispatch(likeDislikeSkipBegin());
			
			const data = await likeDislikeSkipService.sliderImages1(imagesdata);
			
			dispatch(likeDislikeSkipSuccess(data))
			
			
		} catch (err) {
			console.log(err)
			dispatch(likeDislikeSkipErr(err));
		}
	};
};



const updateCartItems = (cartData) => {
	return async dispatch => {
		try {
			dispatch(likeDislikeSkipBegin());
			//console.log("getWork")
			const data = await likeDislikeSkipService.updateCartItems(cartData);
			//console.log("Images data",data)
			dispatch(likeDislikeSkipSuccess(data));

			
			
		} catch (err) {
			console.log(err)
			dispatch(likeDislikeSkipErr(err));
		}
	};
};



export { sliderImages,sliderImages1,updateCartItems};