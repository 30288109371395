const actions = {
    GET_WORK_FILTER_DATA_BEGIN: 'GET_WORK_FILTER_DATA_BEGIN',
    GET_WORK_FILTER_DATA_SUCCESS: 'GET_WORK_FILTER_DATA_SUCCESS',
    GET_WORK_FILTER_DATA_ERR: 'GET_WORK_FILTER_DATA_ERR',
  
    getWorkFilterDataBegin: () => {
      return {
        type: actions.GET_WORK_FILTER_DATA_BEGIN,
      };
    },
  
    getWorkFilterDataSuccess: data => {
      return {
        type: actions.GET_WORK_FILTER_DATA_SUCCESS,
        data,
      };
    },
  
    getWorkFilterDataListErr: err => {
      return {
        type: actions.GET_WORK_FILTER_DATA_ERR,
        err,
      };
    }
  };
  
  export default actions;