import actions from './actions';
import userServices from '../../../services/userServices';

const {
  fbLoginBegin,
  fbLoginSuccess,
  fbLoginErr,

  fbLogOutBegin,
  fbLogOutSuccess,
  fbLogOutErr,

  fbSignUpBegin,
  fbSignUpSuccess,
  fbSignUpErr,

  fbResetPasswordBegin,
  fbResetPasswordSuccess,
  fbResetPasswordErr,

  fbDeleteBegin,
  fbDeleteSuccess,
  fbDeleteErr,

  fbOTPBegin,
  fbOTPSuccess,
  fbOTPErr

} = actions;

const fbAuthLogin = data => {
  return async (dispatch, getState, { getFirebase }) => {
    const fb = getFirebase();
    try {
      await dispatch(fbLoginBegin());
      await fb.auth().signInWithEmailAndPassword(data.email, data.password);

      await dispatch(fbLoginSuccess(data));
    } catch (err) {
      await dispatch(fbLoginErr(err));
    }
  };
};

const fbAuthLogout = () => {
  return async (dispatch, getState, { getFirebase }) => {
    const fb = getFirebase();
    try {
      await dispatch(fbLogOutBegin());
      await fb.auth().signOut();
      await dispatch(fbLogOutSuccess());
    } catch (err) {
      await dispatch(fbLogOutErr(err));
    }
  };
};

const fbAuthSignUp = newUser => {

  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    const fb = getFirebase();
    try {
      newUser.country = 'usa';
      console.log("newUser", newUser)

      await dispatch(fbSignUpBegin());

      const resp = await fb.auth().createUserWithEmailAndPassword(newUser.email, newUser.password);
      console.log("resp", resp)

      const uid = resp.user.uid
      sessionStorage.setItem('UID', uid);

      const data = {
        i_am: newUser.i_am,
        uid: uid,
        name: newUser.name,
        email: newUser.email,
        phone: newUser.phone,
        city: newUser.city,
        country: newUser.country,
        password: newUser.password,
        bio: newUser.bio,
        address: newUser.address,
        coordinates: newUser.coordinates,
        joined_date: new Date()
      }

      console.log("data", data);
      /* commented Ramesh sep -15 -2022 Cloud Firestore functionlity
      await db
        .collection('users')
        .doc(resp.user.uid)
        .set({
          ...newUser, uid
      });
      */


      await userServices.SignUp(data);

      console.log("registered user")
      console.log(resp)


      await dispatch(fbSignUpSuccess());

    } catch (err) {
      await dispatch(fbSignUpErr(err));
    }
  };
};

const fbAuthResetUserPassword = forgotEmail => {

  return async (dispatch, getState, { getFirebase }) => {

    const fb = getFirebase();
    try {

      await dispatch(fbResetPasswordBegin());

      var actionCodeSettings = {
        // After password reset, the user will be give the ability to go back
        // to this page.
        url: 'http://3.84.163.202/',
        handleCodeInApp: false
      };

      const resp = await fb.auth().sendPasswordResetEmail(forgotEmail, actionCodeSettings);


      await dispatch(fbResetPasswordSuccess());
    } catch (err) {
      await dispatch(fbResetPasswordErr(err));
    }
  };
};

const fbAuthLoginWithGoogle = (role) => {


  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const fb = getFirebase();
    const db = getFirestore();
    const provider = new fb.auth.GoogleAuthProvider();
    try {
      await dispatch(fbLoginBegin());
      const result = await fb.auth().signInWithPopup(provider);
      //console.log("users", result);

      const users = {
        uid: result?.user.uid,
        email: result?.user.email,
        name: result?.user.displayName,
        i_am: role
      }

      await userServices.SignUp(users);

      const uid = users.uid
      /* commented Ramesh sep -15 -2022 Cloud Firestore functionlity
      await db
        .collection('users')
        .doc(result.user.uid)
        .set({
          ...users, uid
        });
      */

      await dispatch(fbLoginSuccess(result));
    } catch (err) {
      await dispatch(fbLoginErr(err));
    }
  };
};

const fbAuthLoginWithFacebook = () => {
  return async (dispatch, getState, { getFirebase }) => {
    const fb = getFirebase();
    const provider = new fb.auth.FacebookAuthProvider();
    try {
      await dispatch(fbLoginBegin());
      const result = await fb.auth().signInWithPopup(provider);
      console.log("facebook login", result)
      await dispatch(fbLoginSuccess(result));
    } catch (err) {
      await dispatch(fbLoginErr(err));
    }
  };
};

const fbDataDelete = id => {
  console.log("id", id)
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    const data = [];
    try {
      await dispatch(fbDeleteBegin());
      await db
        .collection('users')
        .doc(`${id}`)
        .delete();
      const query = await db.collection('users').get();
      await query.forEach(doc => {
        data.push(doc.data());
      });
      await dispatch(fbDeleteSuccess(data));
      await fbDataRead();
    } catch (err) {
      await dispatch(fbDeleteErr(err));
    }
  };
};


const fbOTPLogin = data => {
  /*
  return async (dispatch, getState, { getFirebase }) => {
    const fb = getFirebase();
    try {
      await dispatch(fbOTPBegin());
      await fb.auth().sendSignInLinkToEmail(data.email, {
        url: "http://localhost:3000/", 
        handleCodeInApp: true,
      });

      await dispatch(fbOTPSuccess(data));
    } catch (err) {
      await dispatch(fbOTPErr(err));
    }
  };
  */
};



export { fbAuthLogin, fbAuthLogout, fbAuthSignUp, fbAuthLoginWithGoogle, fbAuthLoginWithFacebook, fbAuthResetUserPassword, fbDataDelete, fbOTPLogin };
