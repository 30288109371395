import actions from './actions';

const {
  ADD_WORK_BEGIN,
  ADD_WORK_SUCCESS,
  ADD_WORK_ERR,

  ADD_TEST_QUIZ_WORK_BEGIN,
  ADD_TEST_QUIZ_WORK_SUCCESS,
  ADD_TEST_QUIZ_WORK_ERR,

  GET_WORK_BEGIN,
  GET_WORK_SUCCESS,
  GET_WORK_ERR,

  GET_SINGLE_WORK_DETAILS_BEGIN,
  GET_SINGLE_WORK_DETAILS_SUCCESS,
  GET_SINGLE_WORK_DETAILS_ERR,

  UPDATE_WORK_BEGIN,
  UPDATE_WORK_SUCCESS,
  UPDATE_WORK_ERR,

  ADMIN_UPDATE_WORK_BEGIN,
  ADMIN_UPDATE_WORK_SUCCESS,
  ADMIN_UPDATE_WORK_ERR,

  DELETE_WORK_BEGIN,
  DELETE_WORK_SUCCESS,
  DELETE_WORK_ERR,

  GET_IMAGES_WORK_BEGIN,
  GET_IMAGES_WORK_SUCCESS,
  GET_IMAGES_WORK_ERR,

  GET_WORK_DETAILS_BEGIN,
  GET_WORK_DETAILS_SUCCESS,
  GET_WORK_DETAILS_ERR,

  ADD_WORK_DETAILS_INTO_CART_BEGIN,
  ADD_WORK_DETAILS_INTO_CART_SUCCESS,
  ADD_WORK_DETAILS_INTO_CART_ERR,

  GET_CART_DETAILS_BEGIN,
  GET_CART_DETAILS_SUCCESS,
  GET_CART_DETAILS_ERR,

  REMOVE_CART_ITEM_BEGIN,
  REMOVE_CART_ITEM_SUCCESS,
  REMOVE_CART_ITEM_ERR,

  GET_ALL_ART_WORKS_BEGIN,
  GET_ALL_ART_WORKS_SUCCESS,
  GET_ALL_ART_WORKS_ERR,

} = actions;

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const testRecord = {
  categories: [1, 3, 4],
  created_at: null,
  height: 50,
  id: 1,
  image_urls: null,
  length: 50,
  materials: null,
  part_of_series: 1,
  price: 500,
  unique_copy: 0,
  updated_at: null,
  user_id: null,
  width: 50,
  work_description: "This is hardcoded data in grid",
  work_end_date: "2021-05-30T18:30:00.000Z",
  work_start_date: "2021-05-17T18:30:00.000Z",
  work_title: "My test work add",
}

const workReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case ADD_WORK_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADD_WORK_SUCCESS:
      console.log('ADD_WORK_SUCCESS')
      console.log(data)
      return {
        ...state,
        data: [
          ...state.data,
          // add new obj
          data
        ],
        loading: false,
      };
    case ADD_WORK_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };


    case ADD_TEST_QUIZ_WORK_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADD_TEST_QUIZ_WORK_SUCCESS:
      console.log('ADD_TEST_QUIZ_WORK_SUCCESS')
      console.log(data)
      return {
        ...state,
        data: [
          ...state.data,
          // add new obj
          data
        ],
        loading: false,
      };
    case ADD_TEST_QUIZ_WORK_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };


    case GET_WORK_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_WORK_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case GET_WORK_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case UPDATE_WORK_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_WORK_SUCCESS:
      var temp = state.data.map((work) => {
        if (work.id === data.id) {
          return {
            ...work,
            ...data,
          };
        } else {
          return work;
        }
      });
      return {
        ...state,
        data: temp,
        loading: false,
      };
    case UPDATE_WORK_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case ADMIN_UPDATE_WORK_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADMIN_UPDATE_WORK_SUCCESS:
      var temp = state.data.map((work) => {
        if (work.id === data.id) {
          return {
            ...work,
            ...data,
          };
        } else {
          return work;
        }
      });
      return {
        ...state,
        data: temp,
        loading: false,
      };
    case ADMIN_UPDATE_WORK_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case DELETE_WORK_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_WORK_SUCCESS:
      var temp = state.data.filter((work) => {
        console.log(work)
        if (work.id !== data) {
          return {
            ...work,
          };
        }
      });
      return {
        ...state,
        data: temp,
        loading: false,
      };
    case DELETE_WORK_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case GET_IMAGES_WORK_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_IMAGES_WORK_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case GET_IMAGES_WORK_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case GET_WORK_DETAILS_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_WORK_DETAILS_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case GET_WORK_DETAILS_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case ADD_WORK_DETAILS_INTO_CART_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADD_WORK_DETAILS_INTO_CART_SUCCESS:
      console.log('ADD_WORK_SUCCESS')
      console.log(data)
      return {
        ...state,
        data: [
          ...state.data,
          // add new obj
          data
        ],
        loading: false,
      };
    case ADD_WORK_DETAILS_INTO_CART_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };



    case GET_CART_DETAILS_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_CART_DETAILS_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case GET_CART_DETAILS_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };


    case REMOVE_CART_ITEM_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case REMOVE_CART_ITEM_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case REMOVE_CART_ITEM_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case GET_ALL_ART_WORKS_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_ART_WORKS_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case GET_ALL_ART_WORKS_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const initialStateSingle = {
  data: [],
  loading: false,
  error: null,
};

const singleWorkReducer = (state = initialStateSingle, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_SINGLE_WORK_DETAILS_BEGIN:
      return {
        ...initialStateSingle,
        loading: true,
      };
    case GET_SINGLE_WORK_DETAILS_SUCCESS:
      //console.log("I am in GET_SINGLE_WORK_DETAILS_SUCCESS reducer")
      //console.log(initialStateSingle)
      //console.log(data)
      return {
        ...initialStateSingle,
        data,
        loading: false,
      };
    case GET_SINGLE_WORK_DETAILS_ERR:
      return {
        ...initialStateSingle,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};

export { workReducer, singleWorkReducer };