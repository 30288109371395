import config from '../config/config';
import axios from 'axios';

const SignUp = async (data) => {
	//console.log("axios data : ", data);
	//return data;
	// return await axios.post(`${config.apiUrl}/signup`, JSON.stringify(data), {
	// 	headers: {
	// 		'Content-Type': 'multipart/form-data'
	// 	}
	// }).then(response => {

	// 	console.log('signUp response',response)
	// }).catch(error => {
	// 	// handle your error
	// 	console.log(error)
	// });


	return await axios.post(`${config.apiUrl}/signup`, data)
		.then(function (response) {
			return response;
		})
		.catch(function (error) {
			// handle your error
			//console.log(error);
			return error;
		});
}
const StoreCalculation = async (email) => {

	/*


	return await axios.post(`${config.apiUrl}/signup`, data)
	  .then(function (response) {
		console.log(response);
	  })
	  .catch(function (error) {
		  // handle your error
		console.log(error);
	  });

	  */
	

	let BrowserData = {
		tasteProfile: JSON.parse(sessionStorage.getItem('taste_profile')),
		email: email,
		affinityScore: JSON.parse(sessionStorage.getItem('affinity_data')),
		tasteTestStatus: sessionStorage.getItem('taste_test_status'),
		like_data: JSON.parse(sessionStorage.getItem('gallery_likdislike_data'))
	}
	
	console.log("BrowserData",BrowserData);
	if (sessionStorage.getItem('taste_profile') && sessionStorage.getItem('affinity_data') && sessionStorage.getItem('taste_test_status')) {


		/*
		axios.post(`${config.apiUrl}/store-calculation`, BrowserData)
			.then(()=> {
				
				sessionStorage.removeItem('taste_profile');
				sessionStorage.removeItem('taste_test_status');
				sessionStorage.removeItem('gallery_likdislike_data');
				sessionStorage.setItem('taste_view_visible',false);
			})
			.catch(function (error) {
				console.log(error);
			});
			*/
	}

}



const userServices = {
	SignUp,
	StoreCalculation
};

export default userServices;