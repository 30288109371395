import React, { useState, useEffect, useCallback } from 'react';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { Form, Input, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import { FacebookOutlined, TwitterOutlined, GoogleOutlined } from '@ant-design/icons';
import { AuthWrapper } from './style';
import { login } from '../../../../redux/authentication/actionCreator';
import {
  fbAuthLogin,
  fbAuthLoginWithGoogle,
  fbAuthLoginWithFacebook,
  fbAuthResetUserPassword
} from '../../../../redux/firebase/auth/actionCreator';
import { Checkbox } from '../../../../components/checkbox/checkbox';
import Heading from '../../../../components/heading/heading';
import { Modal } from '../../../../components/modals/antd-modals';
import FbSignup from './FbSignup';
import FbSignInWithOTP from './FbSignInWithOTP'
import { Alert } from 'antd';
import ReCAPTCHA from "react-google-recaptcha";
import config from '../../../../config/config';
import axios from 'axios';
import messages from '../../../../config/constants/messages';

const SignIn = ({ visible, onCancel }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { isLoading, error, isFbAuthenticate } = useSelector(state => {
    // console.log(0)
    return {
      error: state.firebaseAuth.error,
      isLoading: state.firebaseAuth.loading,
      isFbAuthenticate: state.fb.auth.uid,
    };
  });
  const [form] = Form.useForm();
  const [state, setState] = useState({
    checked: null,
    visible,
    signupVisible: false,
    OtpVisiable: false
  });

  const { signupVisible,OtpVisiable } = state;

  const showSignupModal = () => {
    handleCancel()
    setState({
      ...state,
      signupVisible: true
    });
  };

  const handleFbLogin = useCallback(() => {
    dispatch(login());
    // history.push('/admin');
    history.push('/gallery');  // redirect to home page after login
  }, [dispatch, history]);

  useEffect(() => {
    if (isFbAuthenticate) {
      handleFbLogin();
    }

    let unmounted = false;
    if (!unmounted) {

      setState({
        visible,
        signupVisible
      });
    }
    return () => {
      unmounted = true;
    };

  }, [isFbAuthenticate, handleFbLogin, visible, signupVisible]);

  const handleSubmit = values => {

    let verify_token = { token: recaptcha };
    axios.post(`${config.apiUrl}/send-recaptcha-token`, verify_token)
      .then(function (response) {
        if (response.data.google_response.success == true) {
          dispatch(fbAuthLogin(values));
          setRecaptcha('');
          window.grecaptcha.reset()
        } else {
          setRecaptcha('');
          setVerified(false);
          window.grecaptcha.reset()
          alert(messages['recaptcha_failed_error'])
          return false;
        }
      })
      .catch(function (error) {
        window.grecaptcha.reset()
        console.log(error);
      });
  };

  const onChange = checked => {
    setState({ ...state, checked });
  };

  const handleCancel = () => {
    onCancel();
  };

  const onSignupCancel = () => {
    setState({
      ...state,
      signupVisible: false,
    });
  };

  const onOTPCancel = () => {
    setState({
      ...state,
      OtpVisiable: false,
    });
  };

  const openWithoutPasswordModal = () => {
    setState({
      ...state,
      OtpVisiable: true,
    });
  }

  const [isForgotPasswordModalVisible, setIsForgotPasswordModalVisible] = useState(false);

  const showForgotPasswordModal = () => {
    setIsForgotPasswordModalVisible(true);
  };

  const handleForgotPasswordOk = () => {
    setIsForgotPasswordModalVisible(false);
  };

  const [forgotEmail, setForgotEmail] = useState('')
  const [recaptcha, setRecaptcha] = useState('')
  const [verified, setVerified] = useState(false);
  const [message, setMessage] = useState(false);

  const handleForgotPasswordCancel = () => {
    setIsForgotPasswordModalVisible(false);
    setMessage(false)
  };

  const handleForgotSubmit = () => {
    console.log("forgotEmail", forgotEmail);
    dispatch(fbAuthResetUserPassword(forgotEmail));
    setMessage(true)
  }

  /*
  custom forgot password mail
  const handleForgotSubmit = () =>{
    console.log("forgotEmail",forgotEmail);
    let verify_email = { email: forgotEmail };
    axios.post(`${config.apiUrl}/forgot-password`, verify_email)
            .then(function (response) {
              if (response.data[0].status == true) 
                {
                  setMessage(true)
                } else {
                  alert("That email is  invalid.")
                }
            })
            .catch(function (error) {
                console.log("error",error.message);
            });
    ////dispatch(fbAuthResetUserPassword(forgotEmail));
    
  }
  */
  function onChange1(value) {

    setRecaptcha(value);
    setVerified(true);
  }



  return (
    <React.Fragment>

      <Modal
        title="Forgot Password"
        visible={isForgotPasswordModalVisible}
        onOk={handleForgotPasswordOk}
        onCancel={handleForgotPasswordCancel}
        footer={null}>

        <AuthWrapper>

          <div className="auth-contents">
            <Form style={{ marginTop: 10 }}>
              {
                message ?
                  <Alert message="Password reset link has been sent to your email address." type="success" showIcon closable />
                  :
                  ""
              }

              <Form.Item>
                <Form.Item
                  name="forgot_email"
                  label="Email Address"
                  style={{ display: 'inline-block', width: 'calc(100% - 8px)' }}
                >
                  <Input placeholder="Enter Your Email" style={{ height: '45px' }} name="forgot_email" onChange={(e) => { setForgotEmail(e.target.value) }} />
                </Form.Item>

                <Form.Item>

                  <Button type="primary" disabled={!forgotEmail} onClick={handleForgotSubmit}>
                    Submit
                  </Button>


                </Form.Item>
              </Form.Item>
            </Form>
          </div>
        </AuthWrapper>
      </Modal>

      <Modal
        type={state.modalType}
        title="Sign In"
        visible={state.visible}
        footer={[]}
        onCancel={handleCancel}
      >
        <AuthWrapper>
          <p className="auth-notice">
            Don't have an account? <Link to="#" onClick={showSignupModal}>Sign up now</Link>
          </p>
          <div className="auth-contents">
            <Form name="login" form={form} onFinish={handleSubmit} layout="vertical">
              <Heading as="h3">
                Sign in
              </Heading>
              <Form.Item
                name="email"
                rules={[{ message: 'Please input your Email!', required: true }]}
                label="Email Address"
              >
                <Input />
              </Form.Item>

              <Form.Item
                rules={[{ message: 'Please input your password!', required: true }]}
                name="password"
                label="Password"
              >
                <Input.Password placeholder="Password" />
              </Form.Item>

              <Form.Item>
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                  onChange={onChange1}
                />
              </Form.Item>

              {/* <div className="auth-form-action">
                <Checkbox onChange={onChange}>Keep me logged in</Checkbox>
                <NavLink className="forgot-pass-link" to="/forgotPassword">
                  Forgot password?
                </NavLink>
              </div> */}
              {error ? <p>{error.message}</p> : null}
              <Form.Item style={{textAlign:'left'}}>
                <Button className="btn-signin" style={{margin:'20px 20px 0px 0px'}} htmlType="submit" type="primary" size="large" disabled={!verified}>
                  {isLoading ? 'Loading...' : 'Sign In'}
                </Button>

                <Button className="btn-signin" type="dark" size="large" style={{ margin: '20px 20px 0px 0px',backgroundColor:'#888888',borderColor:'#888888',color:'white' }} onClick={openWithoutPasswordModal}>
                  Login without password
                </Button>
              </Form.Item>
              <label style={{ fontWeight: 600 }} onClick={showForgotPasswordModal}>Forgot Password?</label>
              {/* <p className="form-divider">
                <span>Or</span>
              </p>
              <p>Sign in with the following apps below</p> */}
              <ul className="social-login">
                {/*
                <li>
                  <Link onClick={() => dispatch(fbAuthLoginWithGoogle("Collector"))} className="google-signup" to="#">
                    <img src={require('../../../../static/img/google.png')} alt="" />
                  </Link>
                </li>
                
                <li>
                  <Link onClick={() => dispatch(fbAuthLoginWithFacebook())} className="facebook-sign" to="#">
                    <FacebookOutlined />
                  </Link>
                </li>
                <li>
                  <Link className="twitter-sign" to="#">
                    <TwitterOutlined />
                  </Link>
                </li>
                */}
              </ul>
            </Form>
          </div>
        </AuthWrapper>
      </Modal>
      <FbSignup onCancel={onSignupCancel} visible={signupVisible} />
      <FbSignInWithOTP onCancel={onOTPCancel} visible={OtpVisiable} />
      
    </React.Fragment>
  );
};

export default SignIn;
