import React from 'react'

const ContentTasteProfile = (previousContentTasteProfile,previousContentTasteProfileLenght,newContentTasteProfile) => {

    var newContentTasteProfileGenerates = []

    for (var a = 0; a < newContentTasteProfile.length; a++) {
        for (var b = 0; b < previousContentTasteProfileLenght; b++) {
            if (newContentTasteProfile[a]) {
                var oldname = previousContentTasteProfile[b].name
                var newname = newContentTasteProfile[a].name
                if (oldname == newname) {
                    newContentTasteProfileGenerates.push({
                        id: newContentTasteProfile[a].id,
                        name: newContentTasteProfile[a].name,
                        tpScore: previousContentTasteProfile[b].tpScore + newContentTasteProfile[a].tpScore
                    })
                }
            }
        }

    }

    return { newContentTasteProfileGenerates }
}

export default ContentTasteProfile