import { Button, Col, Row } from 'antd'

import './css/style.css'
import { Link, NavLink, useHistory } from 'react-router-dom';
import CommonFooter from "../CommonFooter";
import React, { useEffect } from 'react';
import getdata from '../../../../../redux/contentChange/actions';
import ChicagoBoothLogo from '../../../../../static/img/ChicagoBooth.svg';
import expoChicagologo from '../../../../../static/img/expoChicagologo.svg';
import gertiLogo from '../../../../../static/img/gertie_logo.gif';
import Wdot from '../../../../../static/img/Wdot1.png';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import CommonSEO from '../../../../meta/CommonSEO';
import GetSnippetsData from '../Snippets/GetSnippetsData';

const AboutUs = () => {
    const role = "AboutUs"
    const snippetData = GetSnippetsData(role)

    const history = useHistory();


    const dispatch = useDispatch();

    const handleCartCount = (count) => {
        dispatch(getdata.getContentDetailsSuccess({
            count
        }));
    }

    useEffect(() => {
        console.assert("July 21 veriosn 1")
        sessionStorage.removeItem('cartItemCount')
        handleCartCount(0)

        /*if(sessionStorage.getItem('payment_success') != 1)
        {
            sessionStorage.removeItem('payment_success')
            history.push('/')
        }else{
            sessionStorage.removeItem('payment_success')
        }*/
    }, [])



    const [visible, setVisible] = React.useState(false);
    const [confirmLoading, setConfirmLoading] = React.useState(false);




    return (
        <div style={{
            width: '100%',
        }}>
            <CommonSEO URL="about-us" />
            <div>

                <div style={{ marginTop: 140, paddingBottom: 60 }}>
                    <Row style={{ marginRight: 10, marginLeft: 10 }} className='set-homepage-content'>
                        <Col xs={12} sm={6} md={6} lg={3}></Col>
                        <Col xs={24} sm={24} md={12} lg={18}>
                            <p style={{ fontFamily: "Melodrama", fontSize: 45 }} className='home-content-title' align="center">About Us</p>
                            <p dangerouslySetInnerHTML={{ __html: snippetData != "" ? snippetData.AboutUsContent : "" }} />
                        </Col>
                        <Col xs={0} sm={0} md={2} lg={3}></Col>
                    </Row>
                </div>

                <div className="adjust-home-page-footer" style={
                    Object.assign({}
                        , {
                            width: '-webkit-fill-available',
                            position: 'fixed',
                            left: 0,
                            bottom: 0,
                            right: 0,
                            background: '#E7F6FA'
                        }
                    )}
                >
                    <CommonFooter />
                </div>
            </div>
        </div>
    )
}

export default AboutUs