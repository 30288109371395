import { Button, Col, Row, Modal, notification, Form, Input, Tooltip, Checkbox } from 'antd'

import './css/style.css'
import { Link, NavLink, useHistory, useParams } from 'react-router-dom';
import CommonFooter from "../CommonFooter";
import React, { useEffect, useState } from 'react';
import MaterialOneCategoryData from '../AllCategories/MaterialOne';
import MaterialTwoCategoryData from '../AllCategories/MaterialTwo';
import axios from 'axios';
import Magnifier from 'react-magnifier';
import config from '../../../../../config/config';
import { useDispatch, useSelector } from 'react-redux';
import ArtWorkDetailsModal from './ArtWorkDetailsModal';
import ArtistProfile from '../ArtistProfile';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import notes_icon from '../../../../../static/img/leading-icon.svg';
import messages from '../../../../../config/constants/messages';
import { Helmet } from 'react-helmet';
import default_user_icon from '../../../../../static/img/user.png';
import Logo from '../../../../../static/img/stoodiologo.svg';
import EarlyWorkLogo from '../../../../../static/img/EarlyWorkLogo.svg';
import GetSnippetsData from '../../../authentication/overview/Snippets/GetSnippetsData';
import gtag from 'ga-gtag';

const EarlyAccessDetail = () => {


    const history = useHistory();
    const [artistData, setArtistData] = useState([]);
    const [artWorkData, setArtWorkData] = useState([]);
    const [form] = Form.useForm();

    const role = "EarlyWork"
    const snippetData = GetSnippetsData(role)
    console.log("snippetData : ", snippetData);


    const [exhibitionImage, setexhibitionImage] = useState('');
    const [earlyTitle, setTitle] = useState('');
    const [earlyDesc, setDesc] = useState('');
    const [exhibitionDate, setexhibitionDate] = useState('');
    const [exhibitionUrl, setexhibitionUrl] = useState('');
    const [exhibitionDateRange, setDateRange] = useState('');
    const [isEarlyActive, setisEarlyActive] = useState(false);
    const { early_access_id } = useParams();
    const [passcodeModalVisible, setPasscodeModalVisible] = useState(false);
    const [privateMemberName, setPrivateMemberName] = useState('gertie');
    const [privateMemberPasscode, setPrivateMemberPasscode] = useState('gertie');
    const [discountPercentage, setDiscountPercentage] = useState(0);

    const [ArtistId, setDetailsArtistId] = useState(null);
    const [ArtistBio, setArtistBio] = useState(null);
    const [ArtistName, setArtistName] = useState(null);
    const [ArtistLogo, setArtistLogo] = useState(null);
    const [ArtistExhibitionHistory, setArtistExhibitionHistory] = useState(null);
    const [ArtistEducation, setArtistEducation] = useState(null);
    const [metaTitle, setMetaTitle] = useState('');
    const [metaDescription, setMetaDescription] = useState('');
    const [metaKeywords, setMetaKeywords] = useState('');
    const [EarlyAccessId, setEarlyAccessId] = useState(null);

    const [artworkDetailsModalVisible, setArtworkDetailsModalVisible] = useState(false);
    const [detailsModalVisible, setDetailsModalVisible] = useState(false);
    const [ArtworkDetailsWorkId, setArtworkDetailsWorkId] = useState(null);
    const [detailsWorkId, setDetailsWorkId] = useState(null);
    const [detailsModalDiscountPrice, setDetailsModalDiscountPrice] = useState(null);

    const [artistDetailsModalVisible, setArtistDetailsModalVisible] = useState(false);
    const dispatch = useDispatch();

    const UID = useSelector((state) => {
        return state.fb.auth.uid;
    });


    useEffect(() => {
        var memberId = 1
        axios.get(`${config.apiUrl}/get-all-partner/${memberId}`)
            .then((response) => {
                console.log("response.data", response.data)
                var api_respo = response.data[0]
                // setEarlyAccessId(JSON.stringify(response.data[0].id));
                setPrivateMemberName(api_respo.name);
                setPrivateMemberPasscode('EarlyWork24!');
                //setDiscountPercentage(Number(api_respo.discount));
                setPasscodeModalVisible(true);
                getallartworks();
            }).catch((error) => {
                console.log("Error", error)
                notification.warning({
                    message: "Something went wrong"
                })
            })

    }, []);


    const getallartworks = () => {
        //console.log("discount : ", discount);

        //axios.get(`${config.apiUrl}/get-early/${early_access_id}`)
        axios.get(`${config.apiUrl}/get-early`)
            .then(function (response) {

                if (response.data.length > 0) {
                    setEarlyAccessId(response.data[0].early_access_id);
                    setisEarlyActive(response.data[0].status)
                    setArtistData(response.data[0].artist_ids)
                    let disc = Number(response.data[0].discount);
                    setDiscountPercentage(disc);
                    calculateDiscountAllArtWork(response.data[0].artwork_ids, disc);
                    setDateRange(response.data[0].early_date)
                    setTitle(response.data[0].title)
                    setDesc(response.data[0].description)
                    const imagesURL = 'https://stoodio.art/static/media/stoodiologo.175eeac4.svg';
                    setexhibitionImage(imagesURL)
                } else {
                    setisEarlyActive(false)
                }
                //console.log("response", response.data[0])

            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })

    }
    const calculateDiscountAllArtWork = (artworks, discount) => {
        let gallery_likdislike_data = JSON.parse(sessionStorage.getItem('gallery_likdislike_data'))
        artworks.forEach(element => {
            /*
            if (gallery_likdislike_data === null) {
                gallery_array.push({
                    id: element.id,
                    likeDislike: 0
                });
            }
            */

            if (element.materials1) {
                element.materials1 = MaterialOneCategoryData(element.materials1).appliedMaterialsXAttributes.join(', ').toLowerCase()
            }
            if (element.materials2) {
                element.materials2 = MaterialTwoCategoryData(element.materials2).appliedMaterialsYAttributes.join(', ').toLowerCase()
            }
            element.name_url = element.work_title.replaceAll(' ', '-') + '-' + element.id
            element.discounted_price = calculateDiscountedPrice(element.price, discount)
            if (UID == undefined && gallery_likdislike_data != null) {
                gallery_likdislike_data.forEach(value => {
                    if (element.id == value.id) {
                        if (value.likeDislike == 1)
                            element.isLiked = 1
                        else if (value.likeDislike == 2)
                            element.isLiked = 2;
                        else
                            element.isLiked = 0;

                        return
                    }
                });
            } else {
                element.isLiked = -2;
            }
        });
        console.log("cal artworks : ", artworks);

        setArtWorkData(artworks)
    }



    const [visible, setVisible] = React.useState(false);
    const [confirmLoading, setConfirmLoading] = React.useState(false);
    const showArtworkDetailsModal = (workId) => {
        setArtworkDetailsWorkId(workId)
        setArtworkDetailsModalVisible(true)
    };
    const handleArtworkDetailsCancel = () => {
        setArtworkDetailsModalVisible(false)
        setArtworkDetailsWorkId(null)
    };

    const showArtistDetailsModal = (ArtistId, artistName, bio, logo, exhibition_history, education) => {
        setDetailsArtistId(ArtistId)
        setArtistBio(bio)
        setArtistName(artistName)
        setArtistLogo(logo)
        setArtistExhibitionHistory(exhibition_history)
        setArtistEducation(education)
        setArtistDetailsModalVisible(true)
    };
    const handleArtistDetailsCancel = () => {
        setArtistBio('')
        setArtistName('')
        setArtistLogo('')
        setArtistExhibitionHistory('')
        setArtistEducation('')
        setDetailsArtistId(null)
        setArtistDetailsModalVisible(false)
    };

    const GAEvent = (event, title) => {
        gtag('event', event, {
            poll_title: event + ' ' + title,
            artwork_name: title,
        })
    };

    const submitPasscode = (values) => {
        console.log(values.passcode)
        if (values.passcode == privateMemberPasscode) {
            setPasscodeModalVisible(false);
            notification.success({
                message: "Passcode verified successfully"
            })
        } else {
            notification.warning({
                message: "Please enter valid passcode"
            })
        }
    }
    const handleClearPasscode = () => {
        form.resetFields();
    }

    const shareLink = () => {
        notification.success({
            message: messages['copied_to_clipboard']
        });
    };

    const showDetailsModal = (workId, work_title, discounted_price) => {
        setDetailsWorkId(workId)
        setDetailsModalDiscountPrice(discounted_price)
        // alert(discounted_price)
        setDetailsModalVisible(true)

        GAEvent('aw_artwork_details', work_title)
        var name = sessionStorage.getItem('name') != null ? sessionStorage.getItem('name') : 'Anonymous';
        // GAEvent('aw_'+work_title,name)

    };
    const handleDetailsCancel = () => {
        setDetailsModalVisible(false)
        setDetailsModalDiscountPrice(null)
        setDetailsWorkId(null)
    };


    const decryptData = (encryptedData) => {
        if (encryptedData) {
            try {
                const decryptedString = atob(encryptedData);
                const decryptedJson = decryptedString.replace(process.env.REACT_APP_FILTER_ENCRYPTION_KEY, '');
                const decryptedObject = JSON.parse(decryptedJson);
                return decryptedObject;
            } catch (error) {
                console.error('Error decrypting data:', error);
            }
        }
    };

    const calculateDiscountedPrice = (price, discount) => {
        console.log("price :", price, discount);
        //const discountPercentage = 13; // 13 percent discount;
        const discountAmount = (price * discount) / 100;
        const discountedPrice = price - discountAmount;
        return discountedPrice;
    }


    return (
        <div style={{
            width: '100%',
        }}>
            <div>
                <Helmet>
                    <title>{earlyTitle}</title>
                    <meta name="description" content={metaDescription} />
                    <meta name="keywords" content={metaKeywords} />
                    <meta name="author" content="stoodio" />
                    <meta name="robots" content="" />
                    <meta property="og:type" content="website" />
                    <meta property="og:site_name" content="https://stoodio.art/" />
                    <meta property="og:title" content={metaTitle} />
                    <meta property="og:description" content={metaDescription} />
                    <meta property="og:image" content={exhibitionImage} />
                    <meta property="og:robots" content="" />
                    <meta property="og:image:secure_url" content={exhibitionImage} />
                    <meta property="og:url" content={`https://stoodio.art/early-access`} />
                </Helmet>
                <div style={{ marginTop: 140, paddingBottom: 60 }}>
                    <Row style={{ marginRight: 10, marginLeft: 10 }} className='set-homepage-content'>
                        <Col xs={0} sm={0} md={2} lg={2}></Col>
                        <Col xs={24} sm={24} md={20} lg={20} className={passcodeModalVisible ? 'blur-background' : ''}>
                            {
                                isEarlyActive == 1 ?
                                    <>
                                        <Row style={{ justifyContent: 'center' }}>
                                            <p style={{ fontFamily: "Melodrama", fontSize: 45, marginBottom: 0 }} align="center">{earlyTitle}</p>
                                        </Row>
                                        <Row style={{ justifyContent: 'center' }}>
                                            <p style={{ fontFamily: "Arial", fontSize: 20, fontStyle: 'italic', marginBottom: 0 }} align="center">{exhibitionDateRange}<br />
                                                {/* <Tooltip placement="bottomRight" title="Click to share">
                                                    
                                                    <CopyToClipboard text={`${config.shareUrl}/early-access`}>
                                                        <img src={notes_icon} onClick={() => { shareLink() }} style={{ width: '40px', height: '40px', margin: '10px' }} />
                                                    </CopyToClipboard>
                                                </Tooltip> */}
                                            </p>
                                        </Row><br /><br />
                                        <Row style={{ justifyContent: 'center' }}>

                                            <p style={{ fontFamily: "Arial", fontSize: 15, textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: earlyDesc != "" ? earlyDesc : "" }}></p>

                                        </Row>

                                        {
                                            [...artistData].length > 0 &&
                                            <div> <br /><hr style={{ width: '80%' }} /><br />
                                                <Row style={{ justifyContent: 'center' }}>
                                                    <p style={{ fontFamily: "Melodrama", fontSize: 45 }} align="center">Featured Artist</p>
                                                </Row>
                                                <Row>
                                                    {
                                                        [...artistData].map((item) => {
                                                            if (item) {
                                                                if (item.logo) {
                                                                    const imagesURLs = JSON.parse(item.logo);
                                                                    const imagesURL = imagesURLs[0].url;
                                                                    return (

                                                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} id="artist-bio-div">

                                                                            <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ display: 'flex', justifyContent: 'left' }}>
                                                                                <span style={{ display: 'flex' }}>
                                                                                    {
                                                                                        imagesURL != null ?
                                                                                            <img src={imagesURL} alt='user-profile' className='artwork-logo' />
                                                                                            :
                                                                                            <img src={default_user_icon} alt='user-profile' className='artwork-logo' />
                                                                                    }
                                                                                    {/* <div style={{ backgroundColor: '#5CC7F0', width: 24, height: 24, borderRadius: 20, top: 0, marginLeft: '-25px', opacity: 0.5 }}>&nbsp;</div> */}
                                                                                    <h4 style={{
                                                                                        fontFamily: 'Melodrama',
                                                                                        fontSize: '32px',
                                                                                        fontWeight: '600',
                                                                                        lineHeight: '40px',
                                                                                        letterSpacing: '0em',
                                                                                        textAlign: 'left',
                                                                                        color: '#006C94',
                                                                                        paddingLeft: '20px'
                                                                                    }}
                                                                                    >
                                                                                        {item.name}
                                                                                    </h4>
                                                                                </span>

                                                                            </Col>
                                                                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                                                <p style={{
                                                                                    color: '#181A1B',
                                                                                    fontFamily: 'Arial',
                                                                                    fontSize: '16px',
                                                                                    fontStyle: 'normal',
                                                                                    fontWeight: 400,
                                                                                    lineHeight: '24px',
                                                                                    marginTop: 20
                                                                                }}
                                                                                    dangerouslySetInnerHTML={{ __html: item.bio != "" ? item.bio : "" }}
                                                                                >
                                                                                </p>
                                                                            </Col>
                                                                            {
                                                                                item.education_history &&
                                                                                <Col style={{ marginTop: 20 }} xs={24} sm={24} md={24} lg={24} xl={24}>
                                                                                    <span className='other-artwork' style={{ fontFamily: 'Melodrama', color: '#006C94' }}>Education History</span>
                                                                                    <p style={{
                                                                                        color: '#181A1B',
                                                                                        fontFamily: 'Arial',
                                                                                        fontSize: '16px',
                                                                                        fontStyle: 'normal',
                                                                                        fontWeight: 400,
                                                                                        lineHeight: '24px',
                                                                                        marginTop: 20
                                                                                    }}
                                                                                        dangerouslySetInnerHTML={{ __html: item.education_history != "" ? item.education_history : "" }}
                                                                                    >
                                                                                    </p>
                                                                                </Col>
                                                                            }
                                                                            {
                                                                                item.exhibition_history &&
                                                                                <Col style={{ marginTop: 20 }} xs={24} sm={24} md={24} lg={24} xl={24}>
                                                                                    <span className='other-artwork' style={{ fontFamily: 'Melodrama', color: '#006C94' }}>Exhibition History</span>
                                                                                    <p style={{
                                                                                        color: '#181A1B',
                                                                                        fontFamily: 'Arial',
                                                                                        fontSize: '16px',
                                                                                        fontStyle: 'normal',
                                                                                        fontWeight: 400,
                                                                                        lineHeight: '24px',
                                                                                        marginTop: 20
                                                                                    }}
                                                                                        dangerouslySetInnerHTML={{ __html: item.exhibition_history != "" ? item.exhibition_history : "" }}
                                                                                    >
                                                                                    </p>
                                                                                </Col>
                                                                            }


                                                                        </Col>
                                                                    )
                                                                }

                                                            }
                                                        })
                                                    }
                                                </Row>
                                            </div>
                                        }
                                        <>
                                            {
                                                [...artWorkData].length > 0 ?
                                                    <div> <br /><hr style={{ width: '80%' }} /><br />
                                                        <Row style={{ justifyContent: 'center' }}>
                                                            <p style={{ fontFamily: "Melodrama", fontSize: 45 }} align="center">Featured Artwork</p>
                                                        </Row>
                                                        <Row>
                                                            {
                                                                [...artWorkData].map((item) => {
                                                                    if (item.image_urls) {
                                                                        const imagesURLs = JSON.parse(item.image_urls);
                                                                        if (imagesURLs.length > 0) {
                                                                            const imagesURL = imagesURLs[0].url;
                                                                            return (
                                                                                imagesURL &&
                                                                                <Col className={passcodeModalVisible ? 'blur-background' : ''} xs={24} sm={24} md={8} lg={6} xl={6} style={{ textAlign: 'center', padding: 2 }}>
                                                                                    <>
                                                                                        <div style={{ background: 'rgb(240, 237, 235)', padding: '20px 20px 0px 20px', height: 330, display: 'flex', alignItems: 'center', justifyContent: 'center' }} className={item.sold === '1' ? 'product' : ''} >
                                                                                            {
                                                                                                item.sold == '1' && <Tooltip placement="bottomLeft" title="Artwork sold"><div className="sold-icon"></div></Tooltip>
                                                                                            }
                                                                                            {
                                                                                                item.sold == '2' && <Tooltip placement="bottomLeft" title="Artwork put on hold by collector for purchase"><div className="hold-icon"></div></Tooltip>
                                                                                            }
                                                                                            <div style={{ maxWidth: '280px', maxHeight: '280px' }}>
                                                                                                <Magnifier
                                                                                                    src={imagesURL}
                                                                                                    mgShape='square'
                                                                                                    alt="Art-Work"
                                                                                                    style={{
                                                                                                        borderRadius: 8,
                                                                                                        cursor: 'pointer',
                                                                                                        marginBottom: 10,
                                                                                                        marginTop: '-5px',
                                                                                                        maxHeight: '280px',
                                                                                                    }}
                                                                                                    onClick={() => { showDetailsModal(item.id, item.work_title, item.discounted_price) }}
                                                                                                />
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className='gallery-details-div'>
                                                                                            <a
                                                                                                style={{
                                                                                                    fontFamily: '"Maison Neue Demi", Arial, sans-serif',
                                                                                                    fontSize: '0.9em',
                                                                                                    lineHeight: '1.2em',
                                                                                                    fontWeight: 'bolder',
                                                                                                    color: '#272B41',
                                                                                                    cursor: 'pointer'
                                                                                                }}
                                                                                                target='_blank'
                                                                                                onClick={() => { showDetailsModal(item.id, item.work_title, item.discounted_price) }}
                                                                                            >{item.work_title}</a>

                                                                                            <br />
                                                                                            <small
                                                                                                style={{
                                                                                                    fontFamily: '"Mrs Eaves XL",Times,serif',
                                                                                                    fontSize: '1.1em',
                                                                                                    color: 'black',
                                                                                                    textDecoration: 'line-through',
                                                                                                    textDecorationThickness: '2px'
                                                                                                }}
                                                                                            >${(item.price != null && item.price != '') ? parseFloat(item.price).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : '0.00'}</small>
                                                                                            <small
                                                                                                style={{
                                                                                                    fontFamily: '"Mrs Eaves XL",Times,serif',
                                                                                                    fontSize: '1.3em',
                                                                                                    color: 'red'
                                                                                                }}
                                                                                            > ${(item.discounted_price != null && item.discounted_price != '') ? parseFloat(item.discounted_price).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : '0.00'}</small>

                                                                                            <br />
                                                                                            <div className='gallery-image-dimention'>
                                                                                                {item && item.width} x {item && item.height} {item && item.materials1}
                                                                                                {
                                                                                                    item && item.materials2 && item.materials2.length > 0 &&
                                                                                                    <span> on {item && item.materials2}</span>


                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                </Col>
                                                                            )
                                                                        }
                                                                    }
                                                                })
                                                            }
                                                        </Row>
                                                    </div>
                                                    :
                                                    <p style={{
                                                        fontWeight: '400',
                                                        fontFamily: 'PT Serif, sans-serif',
                                                        fontSize: 16,
                                                        // marginLeft: 8,
                                                        marginBottom: 25,
                                                        marginTop: 15
                                                    }}>No data found.</p>
                                            }
                                        </>
                                    </>
                                    :
                                    <Row style={{ justifyContent: 'center' }}>
                                        {/* <p style={{ fontFamily: "Melodrama", fontSize: 45 }} align="center">Not Found</p> */}
                                    </Row>
                            }

                        </Col>
                        <Col xs={0} sm={0} md={2} lg={2}></Col>
                    </Row>
                </div>
                <div className="adjust-home-page-footer" style={
                    Object.assign({}
                        , {
                            width: '-webkit-fill-available',
                            position: 'fixed',
                            left: 0,
                            bottom: 0,
                            right: 0,
                            background: '#E7F6FA'
                        }
                    )}
                >
                    <CommonFooter />
                </div>
                {
                    detailsWorkId && <Modal
                        className='custom-modal'
                        visible={detailsModalVisible}
                        // onOk={handleOk}
                        //confirmLoading={confirmLoading}
                        onCancel={handleDetailsCancel}
                        // width={1250}
                        // bodyStyle={{ height: 720 }}
                        footer={null}
                    >
                        {detailsWorkId != null && <ArtWorkDetailsModal is_disable_cart={true} work_id={detailsWorkId} discounted_price={detailsModalDiscountPrice} early_access_id={EarlyAccessId} />}
                    </Modal>
                }

                {
                    ArtistId && <Modal
                        className='custom-modal'
                        visible={artistDetailsModalVisible}
                        // onOk={handleOk}
                        //confirmLoading={confirmLoading}
                        onCancel={handleArtistDetailsCancel}
                        // width={1250}
                        // bodyStyle={{ height: 720 }}
                        footer={null}
                    >
                        {ArtistId != null &&
                            <Row>
                                <ArtistProfile
                                    UID={ArtistId && ArtistId}
                                    artistName={ArtistName && ArtistName}
                                    bio={ArtistBio && ArtistBio}
                                    currentArtWorkId={null}
                                    artistImage={ArtistLogo && ArtistLogo}
                                    isModal={false}
                                    exhibition_history={ArtistExhibitionHistory && ArtistExhibitionHistory}
                                    education_history={ArtistEducation && ArtistEducation}
                                    onlyProfile={35}
                                />

                            </Row>}
                    </Modal>
                }

                {/* Passcode Modal Start */}
                <Modal
                    visible={passcodeModalVisible}
                    // onCancel={handlePasscodeCancel}
                    width={600}
                    footer={null}
                >
                    <Row>
                        <div style={{ textAlign: 'center', width: '100%' }}>
                            <img src={snippetData && snippetData.image1 != null ? snippetData.image1 : Logo} alt="logo" width="130" style={{ padding: 10 }} />
                            <img src={snippetData && snippetData.image2 != null ? snippetData.image2 : EarlyWorkLogo} alt="logo" width="100" style={{ padding: 10 }} />
                            <p style={{ padding: 5, marginTop: 20, fontWeight: 600 }} className='header-menu-font' dangerouslySetInnerHTML={{ __html: snippetData != "" ? snippetData.modalTitle : "Welcome to Early Works!" }} />

                        </div>
                        <div>
                            <p style={{ padding: 5, marginTop: 10, fontWeight: 600 }} className='header-menu-font' dangerouslySetInnerHTML={{ __html: snippetData != "" ? snippetData.modalDesc : "" }} />
                        </div>

                        <Form onFinish={submitPasscode} form={form} style={{ marginTop: 10, width: '100%' }}>
                            <Form.Item>
                                <Form.Item
                                    name="passcode"
                                    label="Password"
                                    rules={[{ required: true, message: 'Please input password' }]}
                                    style={{ display: 'inline-block', width: '100%' }}
                                >
                                    <Input placeholder="Enter the password" name="passcode" />
                                </Form.Item>

                                <Form.Item>

                                    <Button type="dark" size='small' htmlType="submit">
                                        Submit
                                    </Button>
                                    {' '}
                                    <Button type="dark" size='small' onClick={handleClearPasscode}>
                                        Clear
                                    </Button>
                                </Form.Item>
                            </Form.Item>
                        </Form>
                    </Row>

                </Modal>
                {/* Passcode Modal End */}

            </div>
        </div>
    )
}

export default EarlyAccessDetail