import React, { useEffect, useState } from 'react'
import config from '../../config/config';
import axios from 'axios';

import { Helmet } from 'react-helmet';

function CommonSEO({ URL }) {

  const [title, setTitle] = useState("")
  const [description, setDescription] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [robots, setRobots] = useState(null);
  const [pageName, setPageName] = useState(null);


  useEffect(() => {

    sessionStorage.removeItem('tab-index');
    var temp_data = [];
    getSEO();

  }, [])

  const getSEO = async () => {
    const response = await fetch(`${config.apiUrl}/get-all-seo/${URL}`, {
      method: "GET", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      }
    });
    const data = await response.json();
    if (data && data.length > 0) {
      let meta = data[0];
      setPageName(meta.page_name)
      setTitle(meta.page_meta_title);
      setDescription(meta.page_meta_description);
      setRobots(meta.page_meta_robot);
      setIsVisible(true);
    }

    //setSEOnata(data)
  }


  return (
    <>
      {isVisible == true &&
        <>
          <Helmet>
            <title>{pageName} </title>
            <meta name="description" content={description} />
            <meta name="keywords" content={description} />
            <meta name="author" content="stoodio" />
            <meta name="robots" content={robots} />
            <meta property="og:type" content="website" />
            <meta property="og:site_name" content="https://stoodio.art/" />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content="" />
            <meta property="og:robots" content={robots} />
            <meta property="og:image:secure_url" content="" />
            <meta property="og:url" content={`https://stoodio.art/${URL}`} />
          </Helmet>
        </>
      }
    </>
  )
}

export default CommonSEO
