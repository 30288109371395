import { Form, Modal, Button, Input, Select, Row } from 'antd';
import React, { useEffect, useState, useRef } from 'react'
import propTypes from 'prop-types';
import axios from 'axios';
import config from '../../../../config/config';
import TempUpload from '../../authentication/overview/UploadImage/TempUpload';


const CreateCropImage = ({ visible, onCancel,Ratio }) => {
    const [state, setState] = useState({
        visible,
        modalType: 'primary',
    });

    const handleCancel = () => {
        onCancel();
    };


    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            setState({
                visible,
            });
        }
        return () => {
            unmounted = true;
        };
    }, [visible]);    


    return (
        <div>
            <Modal
                type={state.modalType}
                visible={state.visible}
                footer={null}
                width={600}
                height={600}
                title="Upload Image"
                onCancel={handleCancel}
            >
                <div className="project-modal">
                    <Row gutter={15}>
                        <TempUpload  Ratio={Ratio} handleCancel={handleCancel}/>
                    </Row>
                </div>
            </Modal>
        </div>
    )
}


CreateCropImage.propTypes = {
    visible: propTypes.bool.isRequired,
    onCancel: propTypes.func.isRequired,
};


export default CreateCropImage;
