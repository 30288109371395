import React, { useState } from "react";
import Cropper from "react-easy-crop";
import { Form, Modal, Button, Input } from 'antd';

function ImageCropper({ image, onCropDone, onCropCancel,ExhibitionId,Ratio }) {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedArea, setCroppedArea] = useState(null);
  const [aspectRatio, setAspectRatio] = useState(Ratio);

  const [buttonDisable, setButton] = useState(false);
  const [loading, setLoading] = useState(false);

  const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  };

  const onAspectRatioChange = (event) => {
    setAspectRatio(event.target.value);
  };

  return (
    <>
    
      <div md={24} sm={24} xs={24} style={{marginTop:40}}>
        <Cropper
          image={image}
          aspect={aspectRatio}
          crop={crop}
          zoom={zoom}
          onCropChange={setCrop}
          onZoomChange={setZoom}
          onCropComplete={onCropComplete}
          style={{
            containerStyle: {
              width: "100%",
              height: "60%",
              backgroundColor: "#fff",
            },
          }}
        />
      </div>

      <div  style={{marginTop:280,textAlign:'center'}}> 
        <div className="aspect-ratios" onChange={onAspectRatioChange} style={{display:"none"}}>
          <input type="radio" value={1 / 1} name="ratio" /> 1:1
        </div>

        <Button
        style={{margin:'0px 15px'}}
           type="primary" size="large"
          onClick={() => {
            onCropDone(croppedArea);
            setButton(true);
            setLoading(true);
          }}
          disabled={buttonDisable}
        >
          Upload {loading && (<i className='fa fa-spinner fa-spin' style={{ fontSize: 18, marginLeft: 5 }} />)}
        </Button>
        <Button style={{margin:'0px 15px'}} onClick={onCropCancel}  type="danger" size="large">
          Cancel
        </Button>
      </div>
    </>
  );
}

export default ImageCropper;
