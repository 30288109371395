import React, { useState } from 'react';
import { Jodit } from 'jodit';
import JoditReact from 'jodit-react';


const JoditReactEditor =({ SetEditorData, initialValue }) => {
    const [editorContent, setEditorContent] = useState(initialValue);

    const editorConfig = {
        readonly: false,
        autofocus: false,
        //tabIndex: 1,                        

        placeholder: 'Write something awesome ...',
        beautyHTML: true,
        toolbarButtonSize: 'large',
        buttons: ['bold','strikethrough','underline','italic','|',
            'ul','ol','|','outdent','indent','|','brush','|','align','|',
            'hr','eraser','|','table','link','image','|',
            'preview','undo','redo','print','source'
        ],
        //askBeforePasteHTML: false,
        //askBeforePasteFromWord: false,
        //defaultActionOnPaste: 'insert_clear_html',
        events: {
            // Add a custom paste event handler to remove formatting
            paste: function (e) {
                e.preventDefault();
                const text = e.clipboardData.getData('text/plain');
                document.execCommand('insertText', false, text);
            }
        }
    };
    
    
    
    const onChange = value => {
        console.log("Test value",value);
    };

    const setContent = newContent => {
        setEditorContent(newContent);
        SetEditorData(newContent);
    };

    return (
        <React.Fragment>
            <JoditReact
                value={editorContent}
                config={editorConfig}
                // onChange={onChange}
                onBlur={newContent => setContent(newContent)}
            />
        </React.Fragment>
    );
};


export default JoditReactEditor;