import { Modal, Row, Tabs, Col, Avatar } from 'antd'
import './css/style.css'
import { Link, NavLink, useHistory, useParams } from 'react-router-dom';

import React, { useState, useEffect } from 'react'
import Magnifier from 'react-magnifier'
import { useDispatch, useSelector } from 'react-redux'
import ContentCategoryData from '../AllCategories/Content'
import MaterialOneCategoryData from '../AllCategories/MaterialOne'
import MaterialTwoCategoryData from '../AllCategories/MaterialTwo'
import ArtistProfile from '../ArtistProfile'
import { likeStyle, disLikeStyle, skipStyle, defaultStyle, skipDefaultStyle, modalStyle } from '../../../../../container/profile/authentication/overview/GalleryComponents/css/RatingStyle'
import { UserOutlined } from '@ant-design/icons';
import Questions from '../Questions'
import { Button } from '../../../../../components/buttons/buttons';
import getdata from '../../../../../redux/contentChange/actions'
import getTabKey from '../../../../../redux/TabKeyChange/actions'
import CommonFooter from "../CommonFooter";
import axios from 'axios';
import config from '../../../../../../src/config/config';
import ArtWorkDetailsModal from './ArtWorkDetailsModal';
import count from '../../../../../redux/count/actionCreator';

const ArtWorkDetails = (match) => {

    const history = useHistory();
    const { artworkname } = useParams();
    const dispatch = useDispatch();
    const [workDetailsId, setworkDetailsId] = useState(null)

    const { UID } = useSelector(state => {
		return {			
			isFbAuthenticate: state.fb.auth.uid,
		};
	});

    // onclick to Image change in model

    useEffect(() => {
        if (artworkname) {
            const result = artworkname.split('-').pop();
            setworkDetailsId(result);
        }
        dispatch(count(UID));

    }, [])





    return (
        <div style={{
            width: '100%',
        }}>
            <div>
                {
                    workDetailsId &&
                    <Row style={{ minHeight: 900, margin: '5%', }}>
                        <Col lg={24} md={24} xs={24}>
                                {
                                    workDetailsId && <ArtWorkDetailsModal  work_id={workDetailsId} metaData={true} />
                                }
                        </Col>
                    </Row>
                }
                <div className="adjust-home-page-footer" style={
                    Object.assign({}
                        , {
                            width: '-webkit-fill-available',
                            position: 'fixed',
                            left: 0,
                            bottom: 0,
                            right: 0,
                            background: '#E7F6FA'
                        }
                    )}
                >
                    <CommonFooter />
                </div>
            </div>
        </div>
    )
}

export default ArtWorkDetails