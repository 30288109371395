import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Select, Col, Row, DatePicker, Avatar, Upload, TimePicker, notification, Button } from 'antd';
import propTypes from 'prop-types';
import { Modal } from '../../../components/modals/antd-modals';
import { BasicFormWrapper } from '../../styled';
import { FileImageOutlined } from '@ant-design/icons';
import config from '../../../config/config';
import moment from 'moment';
import messages from '../../../config/constants/messages';
import axios from 'axios';
//import CreateCropImage from '../../profile/settings/overview/CreateCropImage';
import JoditReactEditor from '../../profile/settings/overview/JoditReactEditor';

const { Option } = Select;
const dateFormat = 'MM/DD/YYYY';
const { Dragger } = Upload;
const { TextArea } = Input;

const CreateEarlyAccess = ({ visible, onCancel }) => {
  const dispatch = useDispatch();

  const UID = useSelector((state) => {
    return state.fb.auth.uid;
  });

  const Base64Data = useSelector((state) => {
    return state.Base64Reducer.data
  })
  console.log("Base64Data :", Base64Data);



  const [form] = Form.useForm();
  const formData = new FormData();

  //const [is_framed, setIsFramed] = useState(false);

  const [state, setState] = useState({
    visible,
    modalType: 'primary',
    checked: [],
    title: '',
    description: '',
    artist_ids: '',
    artwork_ids: '',
    early_date: '',
    status: '',
    images: [],
    isLoading: false
  });


  const [description_data, SetDescription] = useState("")

  const [preview, setPreview] = useState([]);

  const [images, setImages] = useState([]);
  const [document, setDocument] = useState('');
  const [buttonDisable, setButton] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imagesModalVisiable, setImagesModalVisiable] = useState(false);



  const [titlelength, setTitleLength] = useState(0);
  const [descriptionlength, SetDescriptionLength] = useState(0);


  const [ArtistList, setArtistList] = useState([]);
  const [ArtworkList, setArtworkList] = useState([]);

  const [t1, setT1] = useState('');
  const [t2, setT2] = useState('');


  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      setState({
        visible,
      });
    }
    return () => {
      unmounted = true;
    };
  }, [visible]);

  useEffect(() => {

    sessionStorage.removeItem('tab-index');
    const getAllOrders = async () => {
      const order_type = 1;
      const response = await fetch(`${config.apiUrl}/get-artist-artwork-list/null`, {
        method: "GET", // or 'PUT'
        headers: {
          "Content-Type": "application/json",
        }
      });
      const data = await response.json();
      if (data && data.status == true) {
        setArtistList(data.ArtistList);
        setArtworkList(data.ArtworkList);
      }
      //setGetExhibitionData(data)
    }
    getAllOrders();

  }, [])


  const handleStartDateChange = (date, dateString) => {
    setState({
      ...state,
      early_date: moment(date).format('YYYY-MM-DD'),
    });
  }



  const handleDropdownChange = async (value, name) => {
    console.log(`Dropdown changed: ${name}, Value:`, value); // Added for debugging

    if (name === "artist_ids") {
      form.resetFields(['artwork_ids']);
      setArtworkList([]);
      await fetchArtworks(value);
    }

    setState({
      ...state,
      [name]: value,
    });
  };


  const fetchArtworks = async (artistIds) => {
    console.log("Fetching artworks for artists:", artistIds); // Added for debugging

    try {
      let data = { artist_ids: artistIds };
      axios.post(`${config.apiUrl}/get-artworks-by-select-artists`, data)
        .then(function (response) {
          //alert(JSON.stringify(response.data));
          setArtworkList(response.data);
        })
        .catch(function (error) {
          // handle your error
          console.log("select artist :",error)
          return false;
        });

    } catch (error) {
      console.error("Error fetching artworks:", error);
    }
  };




  const fileobj = [];
  function removeHtmlTags(str) {
    if (str) {
      str = str && str.replace(/<[^>]*>/g, '');
      return str.replace(/&nbsp;/g, '');
    }
  }


  const handleOk = (values) => {

    var temp_data = removeHtmlTags(description_data);

    if (temp_data == "") {
      notification.warning({
        message: 'Please input early access description'
      });
      return false;
    }

    let post_data = { ...values, early_date: state.early_date, description: description_data, uid: UID };


    // console.log(state)
    setButton(true);
    setLoading(true);

    setState({
      ...state,
      isLoading: true,
    });

    if (state.images) {
      for (let i = 0; i < state.images.length; i++) {
        formData.append('file', state.images[i])
      }
    }

    formData.append('data', JSON.stringify(post_data))

    axios.post(`${config.apiUrl}/create-early-access`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then((response) => {
      if (response.data.affectedRows == 1) {
        alert("The early access created successfully.");
        location.reload();
      } else {
        setButton(false);
        setLoading(false);
        alert("Uanble to create the early access");
      }
    })
      .catch((error) => {
        console.log("Error :", error)
      })

  };

  const handleImageChange = (e) => {
    console.log("e.target.files :", e.target.files);
    if (e.target.files.length) {
      for (var i = 0; i < e.target.files.length; i++) {
        if (!e.target.files[i].name.match(/.(jpg|jpeg|png|bmp)$/i)) {
          alert(messages['invalid_image_format_error'])
          return false;
        }
      }
      setState({
        ...state,
        [e.target.name]: e.target.files,
      });
    } else {
      setState({
        ...state,
        [e.target.name]: [],
      });
    }
  }

  const validateDiscount = (_, value) => {
    if (value < 0) {
      return Promise.reject(new Error('Discount cannot be negative'));
    }
    if (value > 100) {
      return Promise.reject(new Error('Discount cannot be more than 100'));
    }
    return Promise.resolve();
  };


  const handleCancel = () => {
    onCancel();
  };

  const showModal = () => {
    setImagesModalVisiable(true);
  }
  const onImageCancel = () => {
    setImagesModalVisiable(false);
  }


  const SetEditorData = value => {
    //alert(value);
    //const cleanedString = removeHtmlTags(value);
    //SetDescriptionLength(cleanedString.length)
    SetDescription(value);
  }

  return (

    <>

      <Modal
        type={state.modalType}
        title="Create Early Work"
        visible={state.visible}
        width={1000}
        backdrop="static"
        footer={[null]}
        onCancel={handleCancel}
      >
        <div className="project-modal">
          <BasicFormWrapper>
            <Form id="createWorkForm" form={form} name="createProject" onFinish={handleOk} encType="multipart/form-data">
              <Form.Item name="title" label="Early Access Title"
                rules={[{ required: true, message: 'Please input early access title' },
                  //{ max: 200, message: "The maximum length for the exhibition name is 200 characters." }
                ]}
              >
                <Input name="title" />
              </Form.Item>



              <Form.Item name="description" label="Early Access Description">
                {/* <TextArea rows={3} type='text' name='description' /> */}
                <JoditReactEditor
                  SetEditorData={SetEditorData}
                />
              </Form.Item>
              {/* <div style={{ marginTop: '-25px', float: 'right' }} className={5000 < descriptionlength ? 'danger-text' : ''}>{descriptionlength}/5000 char used</div> */}


              <Row gutter={15}>
                <Col md={12} className="mb-25">
                  <Form.Item name="early_date" label="Date to show on site"
                    //rules={[{ required: true, message: 'Please input early work date' }]}
                  >
                    <DatePicker placeholder="mm/dd/yyyy" format={dateFormat} name="early_date" value={state.early_date} onChange={handleStartDateChange} />
                  </Form.Item>
                </Col>
              </Row>


              <Form.Item name="artist_ids" initialValue={[]} label="Select Artist"
                rules={[{ required: true, message: 'Please select artists' }]}
              >
                <Select size="large" name="artist_ids" className="sDash_fullwidth-select" placeholder="Select from dropdown"
                  onChange={(value) => handleDropdownChange(value, 'artist_ids')}
                  optionFilterProp='children'
                >
                  {
                    ArtistList.map((data) =>
                      <Option key={data.uid} value={data.uid}>{data.name}</Option>
                    )
                  }
                </Select>
              </Form.Item>

              <Form.Item name="artwork_ids" initialValue={[]} label="Select Artwork"
                rules={[{ required: true, message: 'Please select artworks' }]}
              >
                <Select size="large" mode="multiple" name="artwork_ids" className="sDash_fullwidth-select" placeholder="Select from dropdown"
                  onChange={(value) => handleDropdownChange(value, 'artwork_ids')}
                  optionFilterProp='children'
                >
                  {
                    ArtworkList.map((data) =>
                      <Option key={data.id} value={data.id}>{data.work_title}</Option>
                    )
                  }
                </Select>
              </Form.Item>

              <Form.Item name="discount" label="Early Work Discount (%)"
                rules={[
                  { required: true, message: 'Please enter a discount value' },
                    { validator: validateDiscount },
                ]}
              >
                <Input name="discount" />
              </Form.Item>


              <Form.Item name="status" label="Status"
                rules={[{ required: true, message: 'Please input status' }]}
              >
                <Select size="large" className="sDash_fullwidth-select" name="status" placeholder="Select from dropdown"
                  onChange={(value) => handleDropdownChange(value, 'status')}
                >
                  <Option value="1">Active</Option>
                  <Option value="0">Inactive</Option>
                </Select>
              </Form.Item>

              <Form.Item>

                <Button type="primary" htmlType="submit" disabled={buttonDisable}>
                  Submit {loading && (<i className='fa fa-spinner fa-spin' style={{ fontSize: 18, marginLeft: 5 }} />)}
                </Button>
                {' '}
                <Button size="default" type="white" key="back" outlined onClick={handleCancel}>
                  Cancel
                </Button>
              </Form.Item>
            </Form>
          </BasicFormWrapper>
        </div>
      </Modal>
    </>
  );
};

CreateEarlyAccess.propTypes = {
  visible: propTypes.bool.isRequired,
  onCancel: propTypes.func.isRequired,
};

export default CreateEarlyAccess;
