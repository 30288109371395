const actions = {

	GET_SINGLE_WORK_DETAILS_BEGIN: 'GET_SINGLE_WORK_DETAILS_BEGIN',
	GET_SINGLE_WORK_DETAILS_SUCCESS: 'GET_SINGLE_WORK_DETAILS_SUCCESS',
	GET_SINGLE_WORK_DETAILS_ERR: 'GET_SINGLE_WORK_DETAILS_ERR',

	UPDATE_ART_WORK_STATUS_BEGIN: 'UPDATE_ART_WORK_STATUS_BEGIN',
	UPDATE_ART_WORK_STATUS_SUCCESS: 'UPDATE_ART_WORK_STATUS_SUCCESS',
	UPDATE_ART_WORK_STATUS_ERR: 'UPDATE_ART_WORK_STATUS_ERR',
   

	getSingleWorkDetailsBegin: () => {
		return {
			type: actions.GET_SINGLE_WORK_DETAILS_BEGIN,
		};
	},

	getSingleWorkDetailsSuccess: data => {
		//console.log('in getSingleWorkDetailsSuccess actions')
		//console.log(data)
		return {
			type: actions.GET_SINGLE_WORK_DETAILS_SUCCESS,
			data,
		};
	},

	getSingleWorkDetailsErr: err => {
		return {
			type: actions.GET_SINGLE_WORK_DETAILS_ERR,
			err,
		};
	},

	updateArtWorkStatusBegin: () => {
		return {
			type: actions.UPDATE_ART_WORK_STATUS_BEGIN,
		};
	},

	updateArtWorkStatusSuccess: data => {
		//console.log('in getSingleWorkDetailsSuccess actions')
		//console.log(data)
		return {
			type: actions.UPDATE_ART_WORK_STATUS_SUCCESS,
			data,
		};
	},

	updateArtWorkStatusErr: err => {
		return {
			type: actions.UPDATE_ART_WORK_STATUS_ERR,
			err,
		};
	},

	



};

export default actions;