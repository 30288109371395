import React, { useRef, useState, useEffect } from 'react';
import './css/style.css'
import { Row, Col, Space, Spin } from 'antd';
import leftArrowCircle from '../../../../../static/img/icon/arrow-left-circle.svg';
import rightArrowCircle from '../../../../../static/img/icon/arrow-right-circle.svg';
import axios from 'axios';
import config from '../../../../../config/config';
const ImageSwiper = ({ images }) => {
    const containerRef = useRef(null);
    const [currentImage, setCurrentImage] = useState(2);
    const [imageUrls, setimageUrls] = useState(2);
    const [startIndex, setStartIndex] = useState(0);
    const [page_loader, setPageLoader] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);

    const handleImageLoad = () => {
        setIsLoaded(true);
    };

    useEffect(() => {
        axios.get(`${config.apiUrl}/get-home-images-slider`)
            .then(function (response) {
                var response_data = response.data;
                var images = []
                console.log("response_data", response_data)
                response_data.forEach(element => {
                    images.push(element.image_urls)
                });
                const generatedArrays = generateArrays(images);
                setimageUrls(generatedArrays)
                // add_loader();
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })

    }, [])
    const generateArrays = (originalArray) => {
        const arrays = [];
        for (let i = 0; i < 4; i++) {
            const startIndex = i * originalArray.length / 4;
            const newArray = [];
            for (let j = 0; j < originalArray.length; j++) {
                newArray.push(originalArray[(j + startIndex) % originalArray.length]);
            }
            arrays.push(newArray);
        }
        //console.log("array",arrays)
        return arrays;
    };

    const add_loader = () => {
        const timer = setTimeout(() => {
            setPageLoader(false);
        }, 5);

    }

    return (
        <>
            {page_loader == true ?
                <>
                    <Space
                        direction="vertical"
                        style={{
                            width: '100%',
                            textAlign: 'center'

                        }}
                    >
                        <Spin tip="Loading..." size="large">
                            <div className="content" />
                        </Spin>
                    </Space>
                </>
                :
                <>
                    <div class="marquee-wrapper">
                        <div>
                            <div style={{ width: 50 }} class="marquee-block">
                                <div class="marquee-inner to-left">
                                    <span>
                                    </span>
                                </div>
                            </div>
                            <div class="marquee-block">
                                <div class="marquee-inner to-left">
                                    <span>
                                        {imageUrls.length > 0 && imageUrls[0].map((image, index) => (
                                            <div class="marquee-item">
                                                {/* <img
                                                key={index}
                                                src={image}
                                                alt={`Image ${index + 1}`}
                                                className="homepage-carousel-image"
                                            /> */}
                                                <div>
                                                    {isLoaded ? (
                                                        <img key={index} src={image} alt={`Image ${index + 1}`} className="homepage-carousel-image" />
                                                    ) : (
                                                        <div>
                                                            {/* <Space
                                                                direction="vertical"
                                                                style={{
                                                                    width: '100%',
                                                                    textAlign: 'center'

                                                                }}
                                                            >
                                                                <Spin size="large">
                                                                    <div className="content" />
                                                                </Spin>
                                                            </Space> */}
                                                        </div>
                                                    )}
                                                    <img
                                                        src={image}
                                                        alt="Preload"
                                                        key={index}
                                                        onLoad={handleImageLoad}
                                                        style={{ display: 'none' }}
                                                        className="homepage-carousel-image"
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                    </span>
                                </div>
                            </div>
                            <div class="marquee-block">
                                <div class="marquee-inner to-left">
                                    <span>
                                        {imageUrls.length > 0 && imageUrls[1].map((image, index) => (
                                            <div class="marquee-item">
                                                {/* <img
                                                    key={index}
                                                    src={image}
                                                    alt={`Image ${index + 1}`}
                                                    className="homepage-carousel-image"
                                                /> */}
                                                <div>
                                                    {isLoaded ? (
                                                        <img key={index} src={image} alt={`Image ${index + 1}`} className="homepage-carousel-image" />
                                                    ) : (
                                                        <div>
                                                            <Space
                                                                direction="vertical"
                                                                style={{
                                                                    width: '100%',
                                                                    textAlign: 'center'

                                                                }}
                                                            >
                                                                <Spin size="large">
                                                                    <div className="content" />
                                                                </Spin>
                                                            </Space>
                                                        </div>
                                                    )}
                                                    <img
                                                        src={image}
                                                        alt="Preload"
                                                        key={index}
                                                        onLoad={handleImageLoad}
                                                        style={{ display: 'none' }}
                                                        className="homepage-carousel-image"
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                    </span>
                                </div>
                            </div>
                            <div class="marquee-block  hide-mobile">
                                <div class="marquee-inner to-left">
                                    <span>
                                        {imageUrls.length > 0 && imageUrls[2].map((image, index) => (
                                            <div class="marquee-item">
                                                {/* <img
                                                    key={index}
                                                    src={image}
                                                    alt={`Image ${index + 1}`}
                                                    className="homepage-carousel-image"
                                                /> */}
                                                <div>
                                                    {isLoaded ? (
                                                        <img  key={index} src={image} alt={`Image ${index + 1}`} className="homepage-carousel-image" />
                                                    ) : (
                                                        <div>
                                                            {/* <Space
                                                                direction="vertical"
                                                                style={{
                                                                    width: '100%',
                                                                    textAlign: 'center'

                                                                }}
                                                            >
                                                                <Spin size="large">
                                                                    <div className="content" />
                                                                </Spin>
                                                            </Space> */}
                                                        </div>
                                                    )}
                                                    <img
                                                        src={image}
                                                        alt="Preload"
                                                        key={index}
                                                        onLoad={handleImageLoad}
                                                        style={{ display: 'none' }}
                                                        className="homepage-carousel-image"
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                    </span>
                                </div>
                            </div>
                            <div class="marquee-block  hide-mobile hide-middle">
                                <div class="marquee-inner to-left">
                                    <span>
                                        {imageUrls.length > 0 && imageUrls[3].map((image, index) => (
                                            <div class="marquee-item">
                                                {/* <img
                                                    key={index}
                                                    src={image}
                                                    alt={`Image ${index + 1}`}
                                                    className="homepage-carousel-image"
                                                /> */}
                                                <div>
                                                    {isLoaded ? (
                                                        <img  key={index} src={image} alt={`Image ${index + 1}`} className="homepage-carousel-image" />
                                                    ) : (
                                                        <div>
                                                            {/* <Space
                                                                direction="vertical"
                                                                style={{
                                                                    width: '100%',
                                                                    textAlign: 'center'

                                                                }}
                                                            >
                                                                <Spin size="large">
                                                                    <div className="content" />
                                                                </Spin>
                                                            </Space> */}
                                                        </div>
                                                    )}
                                                    <img
                                                        src={image}
                                                        alt="Preload"
                                                        key={index}
                                                        onLoad={handleImageLoad}
                                                        style={{ display: 'none' }}
                                                        className="homepage-carousel-image"
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </>}

        </>
    );

};

export default ImageSwiper;
