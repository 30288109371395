import TasteQuizMaterialJson from '../../../../../config/constants/materials/tastequizmaterials';


const MaterialsTasteProfile = (globalData) => {

    let materialArray = TasteQuizMaterialJson;
    var material_Array_Data = []
    var tempDataMaterialSum = 0
    var tempDataMaterialCount = 0
    var MaterialName = ''
    var materialStep2 = []
    for (var i = 0; i < materialArray.length; i++) {
        MaterialName = materialArray[i].label
        var id = materialArray[i].value

        for (var j = 0; j < globalData.length; j++) {

            materialStep2 = globalData[j].materialTasteProfile
            for (let k = 0; k < materialStep2.length; k++) {
                if (materialStep2[k].name == MaterialName) {
                    tempDataMaterialCount++
                    tempDataMaterialSum += materialStep2[k].userRating

                }
            }

        }

        if (tempDataMaterialCount > 0) {
            material_Array_Data.push({
                id: materialArray[i].value,
                name: MaterialName,
                // cUserRating:tempDataMaterialSum,
                tpScore: tempDataMaterialCount == 0 ? tempDataMaterialCount : (tempDataMaterialSum) / (tempDataMaterialCount)
            })
        }


        tempDataMaterialCount = 0
        tempDataMaterialSum = 0
    }
    return {material_Array_Data}
}

export default MaterialsTasteProfile