import React, { lazy, useState, Suspense, useEffect } from 'react';
import { Layout, Row, Col, Badge } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link, NavLink } from 'react-router-dom';
import FbSignIn from './overview/FbSignIn';
import { Button } from '../../../components/buttons/buttons';
import getdata from '../../../redux/contentChange/actions'
import './overview/Payment.css'
import { Menu, Dropdown } from 'antd';
import { MailOutlined, AppstoreOutlined, SettingOutlined, DownOutlined } from '@ant-design/icons';

import SubMenu from 'antd/lib/menu/SubMenu';
import './overview/Navbar.css'
import './overview/Navbar1.css'
import { ReactComponent as CloseMenu } from "../../../static/img/icon/x.svg";
import { ReactComponent as MenuIcon } from "../../../static/img/icon/menu.svg";
//import Logo from '../../../static/img/logo.svg';
import Logo from '../../../static/img/stoodiologo.svg';
//import Cart from '../../../static/img/icon/shopping-cart.svg'
import Cart from '../../../static/img/shop-cart.svg'
import CountCart from '../../../static/img/cart-icon.svg'
import Hamburger from '../../../static/img/hamburger-Icon.svg'
import CloseIcon from '../../../static/img/close-Icon.svg'

const { Header, Footer, Sider } = Layout;


const AuthLayout = WraperContent => {
	return () => {
		const footerStyle = {
			padding: '20px 30px 18px',
			color: 'rgba(0, 0, 0, 0.65)',
			fontSize: '14px',
			background: 'rgba(255, 255, 255, .90)',
			width: '100%',
			boxShadow: '0 -5px 10px rgba(146,153,184, 0.05)',
		};

		// console.log("getdata", getdata.getContentDetailsSuccess)

		const dispatch = useDispatch();
		const history = useHistory();

		const [state, setState] = useState({
			signInVisible: false,
		});

		const { signInVisible } = state;

		const showModal = () => {
			setState({
				...state,
				signInVisible: true,
			});
		};

		const checkPage = () => {
			history.push('/check-out')
		}

		const onSignInCancel = () => {
			setState({
				...state,
				signInVisible: false,
			});
		};



		const handleCollector = () => {
			// const obj ={
			// 	showArtistsTab:false,
			// 	showCollectorsTab:true
			// }
			dispatch(getdata.getContentDetailsSuccess({
				showArtistsTab: false,
				showCollectorsTab: true,
				scrollToElement: 'box'
			}));
		}

		const handleArtist = () => {
			// const obj ={
			// 	showArtistsTab:true,
			// 	showCollectorsTab:false
			// }
			dispatch(getdata.getContentDetailsSuccess({
				showArtistsTab: false,
				showCollectorsTab: false,
				scrollToElement: 'box'
			}));
		}

		const [click, setClick] = React.useState(false);




		const handleClick = () => setClick(!click);
		const Close = () => setClick(false);

		//const cartItemCount = JSON.parse(sessionStorage.getItem('cartItemCount'))

		const handleCartCount = (count) => {
			dispatch(getdata.getContentDetailsSuccess({
				count
			}));
		}

		useEffect(() => {
			let cart = sessionStorage.getItem('cart')
			if (cart) {
				let parseCartData = JSON.parse(cart)
				let cartLength = parseCartData.length
				handleCartCount(cartLength)
			}

		}, [])

		const cartItemCount = useSelector((state) => {
			return state.contentData.count;
		});
		const redirecturl = () => {
			window.location = '/';
		}

		const currentURL = window.location.pathname;

		return (
			<Row>
				{/* <Layout className="layout"> */}
				<Header
					style={{
						height: 72,
						position: 'fixed',
						width: '100%',
						top: 0,
						zIndex: 2,
						//boxShadow: "0 5px 10px -10px rgba(0, 0, 0, 0.6)"
						boxShadow: "none"

					}}
				>
					<Row>
						<Col span={24}>
							<div>
								<div className={click ? "main-container" : ""} onClick={() => Close()} />
								<nav className="navbar" onClick={(e) => e.stopPropagation()}>

									<div className="nav-container">
										<NavLink exact to="#" className="nav-logo" style={{ cursor: 'auto', width: '10%' }}>
											<img src={Logo} alt="logo" width="130" style={{ cursor: 'pointer' }} onClick={redirecturl} />
										</NavLink>

										<div style={{ width: '75%' }}>
											<ul className={click ? "nav-menu active" : "nav-menu"}>
												<li className="nav-item">
													<Link
														exact
														to="/all-artworks"
														activeClassName="active"
														className="nav-links header-menu-name hamburger-menu-color header-menu-font"
														style={{ fontSize: 13, fontWeight: 600 }}
														// onClick={() => { handleCollector(),click ? handleClick() : null }}
														onClick={() => { click ? handleClick() : null }}
													>
														All Artworks
													</Link>
												</li>

												<li className="nav-item">
													<Link
														exact
														to="/view-gallery"
														activeClassName="active"
														className="nav-links header-menu-name hamburger-menu-color header-menu-font"
														//onClick={click ? handleClick : null}
														onClick={() => { click ? handleClick : null; sessionStorage.setItem('temp_taste_test', false); }}
														style={{ fontSize: 13, fontWeight: 600 }}
													>
														Gallery
													</Link>
												</li>

												<li className="nav-item">
													<Link
														exact
														to="/artists"
														activeClassName="active"
														className="nav-links header-menu-name hamburger-menu-color header-menu-font"
														style={{ fontSize: 13, fontWeight: 600 }}
														// onClick={() => { handleCollector(),click ? handleClick() : null }}
														onClick={() => { click ? handleClick() : null }}
													>
														Artists
													</Link>
												</li>


												<li
													className="nav-item"
													style={{ fontWeight: 600 }}
												>
													<Dropdown
														overlay={
															<Menu>
																<Menu.Item key="about-us">
																	<Link style={{ fontSize: 13, fontWeight: 600 }} className="nav-links hamburger-menu-color header-menu-font header-menu-font" to="/about-us">About Us</Link>
																</Menu.Item>
																<Menu.Item key="for-collectors">
																	<Link style={{ fontSize: 13, fontWeight: 600 }} className="nav-links hamburger-menu-color header-menu-font header-menu-font" to="/for-collectors">For Collectors</Link>
																</Menu.Item>
																<Menu.Item key="for-artists">
																	<Link style={{ fontSize: 13, fontWeight: 600 }} className="nav-links hamburger-menu-color header-menu-font header-menu-font" to="/for-artists">For Artists</Link>
																</Menu.Item>
															</Menu>
														}
													>
														<a
															style={{ fontSize: 13, fontWeight: 600 }}
															className="nav-links hamburger-menu-color header-menu-font "
															onClick={(e) => e.preventDefault()}
														>
															About <DownOutlined />
														</a>
													</Dropdown>
												</li>

												<li className="nav-item">
													<Link
														exact
														to="/exhibitions"
														activeClassName="active"
														className="nav-links header-menu-name hamburger-menu-color header-menu-font"
														style={{ fontSize: 13, fontWeight: 600 }}
														// onClick={() => { handleCollector(),click ? handleClick() : null }}
														onClick={() => { click ? handleClick() : null }}
													>
														Exhibitions
													</Link>
												</li>

												<li
													className="nav-item"
													style={{ fontWeight: 600 }}
												>
													<Dropdown
														overlay={
															<Menu>
																{/* <Menu.Item key="blogs">
																	<Link to="/blogs">Stoodio Blog</Link>
																</Menu.Item> */}
																<Menu.Item key="education">
																	<Link className='header-menu-name header-menu-font' style={{ fontSize: 13, fontWeight: 600 }} to="/education">Stoodio Education</Link>
																</Menu.Item>
																<Menu.Item key="news-events">
																	<Link className='header-menu-name header-menu-font' style={{ fontSize: 13, fontWeight: 600 }} to="/news-events">Stoodio News and Events</Link>
																</Menu.Item>
															</Menu>
														}
													>
														<a
															style={{ fontSize: 13, fontWeight: 600 }}
															className="nav-links header-menu-name hamburger-menu-color header-menu-font"
															onClick={(e) => e.preventDefault()}
														>
															Explore More <DownOutlined />
														</a>
													</Dropdown>
												</li>


												<li className="nav-item mobile-login-button">
													<Button
														className="menu-btn login-circle-btn"
														id="signinbutton"
														size="large"
														shape
														onClick={showModal}
														style={{ fontWeight: 600, height: 35, fontSize: 11, textTransform: 'uppercase', marginBottom: 10 }}
													>
														LOG IN/SIGN UP
													</Button>
												</li>
											</ul>
										</div>
										<div style={{ width: '15%', textAlign: 'right', marginRight: 25, display: 'flex', justifyContent: 'flex-end', marginTop: 5 }}>
											{/* <Link
												exact
												to="/check-out"
												activeclassname="active"
												className="menu-cart cart-button"
												id="mobileview"
												style={{ fontSize: 13, fontWeight: 600, textTransform: 'uppercase', marginRight: '75px' }}
											>
												<Badge count={cartItemCount ? cartItemCount : 0} style={{ marginTop: 10 }}>
													<img src={cartItemCount ? CountCart : Cart } alt='Cart' style={{ height: 35, marginTop: 10 }} />
												</Badge>
											</Link> */}

											{/* <button
												//exact
												to="/check-out"
												activeclassname="active"
												className="cart-button"
												id="deskview"
												//style={{ fontSize: 13, fontWeight: 600, textTransform: 'uppercase',textAlign:'center' }}
											>
												<Badge count={cartItemCount ? cartItemCount : 0} >
													<img src={cartItemCount ? CountCart : Cart } alt='Cart'  style={{ height: 20,marginTop:5 }}/>
												</Badge>
											</button> */}

											<button
												className={cartItemCount ? "login-blue-button" : "cart-zero"}
												onClick={checkPage}
											// style={{ fontWeight: 600, height: 35, fontSize: 11, textTransform: 'uppercase', marginBottom: 10 }}
											>
												<Badge count={cartItemCount ? cartItemCount : 0} >
													<img src={cartItemCount ? CountCart : Cart} alt='Cart' className={cartItemCount ? "" : "add-cart-image"} />
													{/* style={{ height: 20,marginTop:5 }} */}
												</Badge>
											</button>

											<button
												className="menu-btn login-circle-btn desktop-login-button"
												id="signinbutton"
												onClick={showModal}
												style={{ fontWeight: 600, height: 35, fontSize: 11, textTransform: 'uppercase', marginBottom: 10, marginLeft: 10 }}
											>
												LOG IN/SIGN UP
											</button>
											<FbSignIn onCancel={onSignInCancel} visible={signInVisible} />
										</div>

										<div className="nav-icon" onClick={handleClick}>
											{click ?
												<img src={CloseIcon} alt='close' />
												:
												<img src={Hamburger} alt='Cart' />
											}

										</div>
									</div>
								</nav>
							</ div>
						</Col>
					</Row>

				</Header>


				<WraperContent />
			</Row>
		);
	};
};

export default AuthLayout;
