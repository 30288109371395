import React from 'react'

const FormalCategories = (parsedFormalCategoriesArr, CalculationFormalCategoriesObject) => {

    var appliedFormalCategoriesToArtWork = []
    
    for (var i = 0; i < CalculationFormalCategoriesObject.length; i++) {
        for (var j = 0; j < parsedFormalCategoriesArr.length; j++) {
            if (CalculationFormalCategoriesObject[i].id == parsedFormalCategoriesArr[j].id) {
                appliedFormalCategoriesToArtWork.push({
                    id: CalculationFormalCategoriesObject[i].id,
                    name: CalculationFormalCategoriesObject[i].name,
                    tasteProfile: CalculationFormalCategoriesObject[i].tpScore,
                    rated: parsedFormalCategoriesArr[j].value,
                    affinitystep1: Math.abs(CalculationFormalCategoriesObject[i].tpScore - parsedFormalCategoriesArr[j].value)

                })
            }
        }
    }

    return {appliedFormalCategoriesToArtWork}
}

export default FormalCategories