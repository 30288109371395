import { Button, Col, Row, Modal, Tooltip, notification } from 'antd'

import '../../container/profile/authentication/overview/GalleryComponents/css/style.css';
import { Link, NavLink, useHistory, useParams } from 'react-router-dom';

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from '../../config/config';
import { useDispatch, useSelector } from 'react-redux';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import notes_icon from '../../static/img/leading-icon.svg';
import messages from '../../config/constants/messages';
import CommonFooter from '../profile/authentication/overview/CommonFooter';
import leftArrow from '../../static/img/icon/left-arrow.svg';


const NewsDetails = () => {
    const role = "Home"

    const history = useHistory();

    const [Image, setImage] = useState('');

    const [Name, setName] = useState('');
    const [PublishedName, setPublishedName] = useState('');
    const [Desc, setDesc] = useState('');
    const [Url, setUrl] = useState('');
    const [DateRange, setDateRange] = useState('');
    const [isActive, setisActive] = useState(false);
    const { url } = useParams();


    const [artistDetailsModalVisible, setArtistDetailsModalVisible] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {

        axios.get(`${config.apiUrl}/get-news-events/${url}`)
            .then(function (response) {
                //alert(JSON.stringify(response));
                if (response.data.length > 0) {
                    setisActive(response.data[0].is_approved)
                    setName(response.data[0].news_title)
                    setDateRange(response.data[0].created_at)
                    setDesc(response.data[0].news_description)
                    setUrl(response.data[0].news_url)
                    setPublishedName(response.data[0].name);

                    if (response.data[0].image_url.length > 3) {
                        const imagesURLs = response.data[0].image_url && JSON.parse(response.data[0].image_url);
                        const imagesURL = imagesURLs[0].url;
                        setImage(imagesURL)
                    } else {
                        const imagesURL = 'https://stoodio.art/static/media/stoodiologo.175eeac4.svg';
                        setImage(imagesURL)
                    }
                } else {
                    setisActive(false)
                }


            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })

    }, [])

    const [visible, setVisible] = React.useState(false);
    const [confirmLoading, setConfirmLoading] = React.useState(false);






    const shareLink = () => {
        notification.success({
            message: messages['copied_to_clipboard']
        });
    };
    const gotoback =()=>{
        history.push('/news-events');
    }



    return (
        <div style={{
            width: '100%',
        }}>
            <div>
                <div style={{ marginTop: 140, paddingBottom: 60 }}>
                    <Row style={{ marginRight: 10, marginLeft: 10 }} className='set-homepage-content'>                        
                        <Col xs={24} sm={24} md={2} lg={2}>
                        {/* <Button style={{ cursor: 'pointer', border: '1px solid #006C94', color: '#006C94', background: 'rgb(255, 255, 255)', borderRadius: 4 }}  size='default' onClick={gotoback}>
                            <img className="like-dislike" src={leftArrow} style={{ width: '14px', height: '14px' }} /> Go back
                        </Button> */}
                        </Col>
                        <Col xs={24} sm={24} md={20} lg={20}>
                            {
                                isActive == 1 ?
                                    <>
                                        <Row style={{ justifyContent: 'center' }}>
                                            <p style={{ fontFamily: "Melodrama", fontSize: 45, marginBottom: 0 }} align="center">{Name}</p>
                                        </Row>
                                        <Row style={{ justifyContent: 'center' }}>
                                            <p style={{ fontFamily: "Arial", fontSize: 20, fontStyle: 'italic', marginBottom: 0 }} align="center">
                                                <div class="entry-meta">
                                                    {/* Published by <span class="entry-author">{PublishedName} </span>
                                                    • */}
                                                     <span class="entry-date">{DateRange} </span>
                                                </div>
                                                <br />
                                                <Tooltip placement="bottomRight" title="Click to share">
                                                    <CopyToClipboard text={`https://stoodio.art/news-events/` + Url}>
                                                        <img src={notes_icon} onClick={() => { shareLink() }} style={{ width: '40px', height: '40px', margin: '10px' }} />
                                                    </CopyToClipboard>
                                                </Tooltip>
                                            </p>
                                        </Row>

                                        <Row style={{ justifyContent: 'center' }}>
                                            {
                                                Image != '' ?
                                                    <img
                                                        src={Image}
                                                        alt="Art-Work"
                                                        style={{
                                                            borderRadius: 8,
                                                            minHeight: '250px',
                                                            width: '100%',
                                                            maxHeight: 550,
                                                            maxWidth: 800
                                                        }}
                                                    />
                                                    :
                                                    <img
                                                        src='https://stoodio.art/static/media/stoodiologo.175eeac4.svg'
                                                        alt="Art-Work"
                                                        style={{
                                                            borderRadius: 8,
                                                            height: '450px',
                                                            maxWidth: 700
                                                        }}

                                                    />
                                            }
                                        </Row>
                                        <br /><br />
                                        <Row style={{ justifyContent: 'center' }}>
                                            <p style={{ fontFamily: "Arial", fontSize: 15, textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: Desc != "" ? Desc : "" }}></p>
                                        </Row>


                                    </>
                                    :
                                    <Row style={{ justifyContent: 'center' }}>
                                        {/* <p style={{ fontFamily: "Melodrama", fontSize: 45 }} align="center">Not Found</p> */}
                                    </Row>
                            }

                        </Col>
                        <Col xs={0} sm={0} md={2} lg={2}></Col>
                    </Row>
                </div>
                <div className="adjust-home-page-footer" style={
                    Object.assign({}
                        , {
                            width: '-webkit-fill-available',
                            position: 'fixed',
                            left: 0,
                            bottom: 0,
                            right: 0,
                            background: '#E7F6FA'
                        }
                    )}
                >
                    <CommonFooter />
                </div>

            </div>
        </div >
    )
}

export default NewsDetails