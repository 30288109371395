import React, { useEffect, useState } from 'react'
import { Col, Row, Skeleton, Avatar, Card, List, Badge, TextArea, Modal,Tooltip } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { getWorkDetails } from '../../../../redux/SingleWork/actionCreator';
import getTabKey from '../../../../redux/TabKeyChange/actions'
import axios from 'axios';
import config from '../../../../config/config';
import { useDispatch } from 'react-redux';
import default_user_icon from '../../../../static/img/user.png';
import ArtWorkDetailsModal from '../../authentication/overview/GalleryComponents/ArtWorkDetailsModal';


const ArtistProfile = ({ UID, artistName, bio, currentArtWorkId, artistImage, isModal,exhibition_history,education_history,onlyProfile }) => {

    const [artistArtWork, setArtistArtWork] = useState([])
    const dispatch = useDispatch();
    const { Meta } = Card;

    const [work_id, setArtWorkId] = useState(null);
    const [visible, setVisible] = useState(false);


    useEffect(() => {
        (async () => {
            await axios.get(`${config.apiUrl}/get-artist-artworks/${UID}/${currentArtWorkId}`)
                .then((response) => {
                    console.log("Response : ", response)
                    setArtistArtWork(response.data)
                }).catch((error) => {
                    console.log("Error : ", error)
                })

        })();
    }, []);

    /*
    const [userLogo, setUserLogo] = useState([])

    useEffect(() => {
        axios.get(`${config.apiUrl}/get-user-logo/${UID}`)
            .then((response) => {
                console.log("response", response)
                setUserLogo(JSON.parse(response.data[0].logo))
            }).catch((error) => {
                console.log("Error", error)
            })
    }, []);
    */

    const getArtWorkId = (id) => {
        setVisible(false);
        setTimeout(() => {
            setVisible(true);
        }, 500);
        setArtWorkId(id);

    }

    const onCancelArtpModal = () => {
        setTimeout(() => {
            setArtWorkId(null);
            setVisible(false);
        }, 300);
       
    };

    const imageDetailsGet = (workId) => {
        dispatch(getWorkDetails(workId));
        dispatch(getTabKey.getTabKeySuccess({
            defaultActiveTabKey: "1"
        }));
    }
    const shareLink = () => {
        notification.success({
            message: messages['copied_to_clipboard']
        });
    };
    return (
        <>

            <Row style={{width:'100%',paddingTop:onlyProfile ?  onlyProfile : 20 }}>
                <Col xs={24} sm={24} md={20} lg={20} xl={20} className='artist-bio' id="artist-bio-div">

                    <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ display: 'flex', justifyContent: 'left' }}>
                        <span style={{ display: 'flex' }}>
                            {
                                artistImage != null ?
                                    <img src={artistImage} alt='user-profile' className='artwork-logo' />
                                    :
                                    <img src={default_user_icon} alt='user-profile' className='artwork-logo' />
                            }
                            {/* <div style={{ backgroundColor: '#5CC7F0', width: 24, height: 24, borderRadius: 20, top: 0, marginLeft: '-25px', opacity: 0.5 }}>&nbsp;</div> */}
                            <h4 style={{
                                fontFamily: 'Melodrama',
                                fontSize: '32px',
                                fontWeight: '600',
                                lineHeight: '40px',
                                letterSpacing: '0em',
                                textAlign: 'left',
                                color: '#006C94',
                                paddingLeft: '20px'
                            }}
                            >
                                {artistName}
                            </h4>
                        </span>

                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <p style={{
                            color: '#181A1B',
                            fontFamily: 'Arial',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            lineHeight: '24px',
                            marginTop: 20
                        }}
                            dangerouslySetInnerHTML={{ __html: bio != "" ? bio : "" }}
                        >
                        </p>
                    </Col>
                    {
                        education_history &&
                        <Col style={{ marginTop: 20 }} xs={24} sm={24} md={24} lg={24} xl={24}>
                            <span className='other-artwork' style={{ fontFamily: 'Melodrama', color: '#006C94' }}>Education History</span>
                            <p style={{
                                color: '#181A1B',
                                fontFamily: 'Arial',
                                fontSize: '16px',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                lineHeight: '24px',
                                marginTop: 20
                            }}
                                dangerouslySetInnerHTML={{ __html: education_history != "" ? education_history : "" }}
                            >
                            </p>
                        </Col>
                    }
                    {
                        exhibition_history &&
                        <Col style={{ marginTop: 20 }} xs={24} sm={24} md={24} lg={24} xl={24}>
                            <span className='other-artwork' style={{ fontFamily: 'Melodrama', color: '#006C94' }}>Exhibition History</span>
                            <p style={{
                                color: '#181A1B',
                                fontFamily: 'Arial',
                                fontSize: '16px',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                lineHeight: '24px',
                                marginTop: 20
                            }}
                                dangerouslySetInnerHTML={{ __html: exhibition_history != "" ? exhibition_history : "" }}
                            >
                            </p>
                        </Col>
                    }


                </Col>

                <Col xs={24} sm={24} md={24} lg={24} xl={24} id="other-artwork-div" style={{ margin: '0px' }}>
                    <span className='other-artwork' style={{ fontFamily: 'Melodrama', marginTop: 10 }}>Other works by <br class="mobile-break" /><span style={{ color: '#006C94' }}>{artistName}</span></span>
                    {
                        artistArtWork.length > 0 ?


                            <Row style={{ marginTop: "45px", width: '100%' }}>
                                {

                                    artistArtWork.map((item) => {
                                        if (item.image_urls) {
                                            const imagesURLs = JSON.parse(item.image_urls);
                                            if (imagesURLs.length > 0) {
                                                const imagesURL = imagesURLs[0].url;
                                                return (
                                                    imagesURL &&

                                                    <Col xs={24} sm={24} md={8} lg={6} xl={6} style={{ textAlign: 'center', padding: 20 }}>
                                                        <>
                                                            <div >
                                                                {
                                                                    item.sold == '1' && <Tooltip placement="bottomLeft" title="Artwork sold"><div className="sold-icon"></div></Tooltip>
                                                                }
                                                                {
                                                                    item.sold == '2' && <Tooltip placement="bottomLeft" title="Artwork put on hold by collector for purchase"><div className="hold-icon"></div></Tooltip>
                                                                }
                                                                {isModal == true ?
                                                                    <img
                                                                        src={imagesURL}
                                                                        alt="Art-Work"
                                                                        style={{
                                                                            //width: '100%',
                                                                            borderRadius: 8,
                                                                            cursor: 'pointer',
                                                                            marginBottom: 10,
                                                                            marginTop: '-5px',
                                                                            maxWidth: '200px',
                                                                            maxHeight:'200px'
                                                                            //height: '190px',
                                                                            //gap: '8px'
                                                                        }}
                                                                        onClick={() => { getArtWorkId(item.id); }}
                                                                    //onClick={() => { imageDetailsGet(item.id) }} 
                                                                    />
                                                                    :
                                                                    <img
                                                                        src={imagesURL}
                                                                        alt="Art-Work"
                                                                        style={{
                                                                            //width: '100%',
                                                                            borderRadius: 8,
                                                                            cursor: 'pointer',
                                                                            marginBottom: 10,
                                                                            marginTop: '-5px',
                                                                            maxWidth: '200px',
                                                                            maxHeight:'200px'
                                                                            //height: '190px',
                                                                            //gap: '8px'
                                                                        }}
                                                                        onClick={() => { getArtWorkId(item.id); }}
                                                                    />
                                                                }
                                                            </div>
                                                            <strong
                                                                style={{
                                                                    marginTop: 8,
                                                                    //fontFamily: 'Source Sans Pro',
                                                                    fontFamily: 'Arial',
                                                                    fontSize: '16px',
                                                                    fontWeight: 600,
                                                                    lineHeight: '24px',
                                                                    letterSpacing: '0em',
                                                                    textAlign: 'left',
                                                                }}
                                                            >{item.work_title}</strong>
                                                            <br />
                                                            <span
                                                                style={{
                                                                    //styleName: OVERLINE;
                                                                    //fontFamily: 'Source Sans Pro',
                                                                    fontFamily: 'Arial',
                                                                    fontSize: '12px',
                                                                    fontWeight: 600,
                                                                    lineHeight: '16px',
                                                                    letterSpacing: '0.16em',
                                                                    textAlign: 'center'
                                                                }}
                                                            >{item.year_created}</span>

                                                        </>

                                                    </Col>
                                                )
                                            }
                                        }
                                    })
                                }

                                <Modal
                                    className='custom-modal'
                                    visible={visible}
                                    // onOk={handleOk}
                                    //confirmLoading={confirmLoading}
                                    onCancel={onCancelArtpModal}
                                    // width={1250}
                                    // bodyStyle={{ height: 720 }}
                                    footer={null}
                                >
                                    {work_id != null && <ArtWorkDetailsModal is_disable_cart={true} work_id={work_id} />}
                                </Modal>
                            </Row>


                            :
                            <p style={{
                                color: '#181A1B',
                                fontFamily: 'Arial',
                                fontSize: '16px',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                lineHeight: '24px',
                                marginTop: 20
                            }}>No other artworks available for this artist.</p>
                    }
                </Col>

            </Row>

        </>
    )
}

export default ArtistProfile