import TasteQuizMaterialJson from '../../../../../config/constants/materials/tastequizmaterials';


const MaterialsAverage = (parsedMaterialArr,userRateValue,colorNotSelectedValue) => {

    let materialArray = TasteQuizMaterialJson;
			var appliedMaterialToArtWork = [];

			for (var i = 0; i < materialArray.length; i++) {
				for (var j = 0; j < parsedMaterialArr.length; j++) {
					if (materialArray[i].value == parsedMaterialArr[j]) {
						appliedMaterialToArtWork.push({
							name: materialArray[i].label,
							userRating: userRateValue
						});
					}
				}
			}

			var materialTasteProfile = [...appliedMaterialToArtWork]

			var materialResult = materialArray.filter(function (o1) {
				return !appliedMaterialToArtWork.some(function (o2) {
					if (o1.label === o2.name) {
						return o1.id === o2.id
					}
				});
			});

			materialResult.map((itm) => {
				materialTasteProfile.push({
					name: itm.label,
					userRating: colorNotSelectedValue
				})
			})

  return {materialTasteProfile}
}

export default MaterialsAverage