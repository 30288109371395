import { Form, Modal, Button, Input, Select,notification } from 'antd';
import React, { useEffect, useState } from 'react'
import propTypes from 'prop-types';
import axios from 'axios';
import config from '../../../../config/config';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import ReCAPTCHA from "react-google-recaptcha";

const Questions = ({ visible, onCancel, artWorkId,offer }) => {

    const [state, setState] = useState({
        visible,
        modalType: 'primary',
    });

    const handleCancel = () => {
        
        onCancel();
    };

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            setState({
                visible,
            });
        }
        return () => {
            unmounted = true;
        };
    }, [visible]);

    const [phone, setPhone] = useState('')
    const [recaptcha, setRecaptcha] = useState('')
    const [verified, setVerified] = useState(false);
    const handleCountryCode = (value, data, event, formattedValue) => {
        // console.log("value",value)
        // console.log("data",data)
        // console.log("event",event)
        // console.log("formattedValue",formattedValue)
        setPhone(formattedValue)
    }

    function onChange1(value) {
        setRecaptcha(value);
        setVerified(true);
    }

    const handleSubmit = values => {

        const promise = new Promise((resolve, reject) => {            
            let verify_token = { token: recaptcha };
            axios.post(`${config.apiUrl}/send-recaptcha-token`, verify_token)
                .then(function (response) {
                    
                    if (response.data.google_response.success == true) {
                        resolve(true);
                    } else {
                        window.grecaptcha.reset();
                        setRecaptcha('');
                        setVerified(false);
                        notification.error({
                            message: '"recaptcha invalid please try again"',
                          });                        
                        return false;
                    }
                })
                .catch(function (error) {
                    window.grecaptcha.reset();
                    console.log(error);
                });


        })
        promise.then((d) => {
            axios.post(`${config.apiUrl}/create-questions`, { ...values, phone: phone, artWorkId: artWorkId,is_offer:offer })
                .then((response) => {
                    if (response.status === 200) {
                        notification.success({
                            message: "Message sent successfully",
                          });
                        //form.resetFields();
                        window.grecaptcha.reset();
                        onCancel();
                    } else {
                        window.grecaptcha.reset();
                        notification.error({
                            message: 'Something went wrong...!',
                          });
                        onCancel();
                    }
                }).catch((error) => {
                    console.log("Error", error)
                })
        })




    };

    return (
        <div>
            <Modal
                type={state.modalType}
                visible={state.visible}
                footer={null}
                // title=""
                onCancel={handleCancel}
            >
                {offer==true ?
                
                <p className='header-menu-font' style={{ padding: 5,marginTop:20,fontWeight:600}}>
                    Please make a reasonable offer in the message section of this form and if successful, someone from our team will get back to you.
                </p>
                :
                <p className='header-menu-font' style={{ padding: 5,marginTop:20,fontWeight:600}}>
                    Please call us at +1 (312) 900-7323 or send a message below and we will get back to you!
                </p>
                }
                <Form name="questions" onFinish={handleSubmit} layout="vertical">

                    <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Please input your full name' }]}>
                        <Input placeholder="Full name" />
                    </Form.Item>

                    <Form.Item
                        name="email"
                        label="Email Address"
                        rules={[{ required: true, message: 'Please input your email', type: 'email' }]}
                    >
                        <Input placeholder="name@example.com" />
                    </Form.Item>

                    <Form.Item
                        name="confirmemail"
                        label="Confirm Email Address"
                        rules={[{ required: true, message: 'Please input your confirm email', type: 'email' },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue('email') === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(new Error('The two email that you entered do not match'));
                          },
                        }),
                      ]}
                    >
                        <Input placeholder="name@example.com" />
                    </Form.Item>


                    <Form.Item
                        name="phone"
                        label="Phone"
                        rules={[{ required: true, message: 'Please input your Phone' }]}
                    >
                        <PhoneInput
                            country={'us'}
                            inputProps={{
                                name: 'phone'
                            }}
                            inputStyle={{
                                height: '48px',
                                width: '100%'
                            }}
                            enableSearch={true}
                            copyNumbersOnly={false}
                            searchPlaceholder='search'
                            searchNotFound='No entries to show'
                            countryCodeEditable={false}
                            onChange={handleCountryCode}
                        />
                    </Form.Item>

                    <Form.Item
                        name="confirmphone"
                        label="Confirm Phone"
                        rules={[{ required: true, message: 'Please input your Phone' },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue('phone') === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(new Error('The two phone number that you entered do not match'));
                          },
                        }),
                      ]}
                    >
                        <PhoneInput
                            country={'us'}
                            inputProps={{
                                name: 'phone'
                            }}
                            inputStyle={{
                                height: '48px',
                                width: '100%'
                            }}
                            enableSearch={true}
                            copyNumbersOnly={false}
                            searchPlaceholder='search'
                            searchNotFound='No entries to show'
                            countryCodeEditable={false}
                            onChange={handleCountryCode}
                        />
                    </Form.Item>


                    <Form.Item
                        label="Message"
                        name="message"
                        rules={[{ required: true, message: 'Please input your message!' }]}
                    >
                        <Input.TextArea rows={3} placeholder="Message" />
                    </Form.Item>

                    <Form.Item>
                        <ReCAPTCHA
                            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                            onChange={onChange1}
                        />
                    </Form.Item>

                    <Form.Item>
                        <Button className="btn-create" htmlType="submit" type="primary" size="large" disabled={!verified}>
                            Submit
                        </Button>
                    </Form.Item>

                </Form>
            </Modal>
        </div>
    )
}


Questions.propTypes = {
    visible: propTypes.bool.isRequired,
    onCancel: propTypes.func.isRequired,
};

export default Questions