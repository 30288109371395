import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useHistory, NavLink, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { Form, Col, Row, Tabs, Layout, Input, Select, Space, Badge, Card, Modal, Switch, Alert, Spin, Skeleton, Tooltip, Avatar, notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../../../redux/authentication/actionCreator';
import {
	fbAuthLogin,
} from '../../../../redux/firebase/auth/actionCreator';
import { Button } from '../../../../components/buttons/buttons';
import Image1 from '../../../../static/img/image1.jpg';
import Image2 from '../../../../static/img/image2.jpg';
import filter_image from '../../../../static/img/EmptyQueue.png'
import Filter from '../../../../static/img/icon/filter.svg'
import FeatherIcon from 'feather-icons-react';
import Slider from "react-slick";
import config from '../../../../config/config';
import axios from 'axios';
import { getImages, removeCartItem } from '../../../../redux/work/actionCreator';
import { getTasteQuizImages } from '../../../../redux/TasteQuizWork/actionCreator';
import { getWorkDetails } from '../../../../redux/SingleWork/actionCreator';
import getWorkFilterDataList from '../../../../redux/filterWorkData/actionCreator'
import { sliderImages } from '../../../../redux/userChoices/actionCreator'
import count from '../../../../redux/count/actionCreator';
import workService from '../../../../services/workService';
import Categories from '../../../../config/constants/materials/tastequizcontent'
import Color_Taste from '../../../../config/constants/materials/color_taste';
import TasteQuizContent from '../../../../config/constants/materials/tastequizcontent';
import TasteQuizMaterial from '../../../../config/constants/materials/tastequizmaterials.json'
import './Payment.css'
import './Ribbon.css'
import './SliderComponent/css/arrows.css'
import TasteQuiz from './TasteQuiz';
import Calculation from './Calculation';
import { InfoCircleOutlined, UserOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import ColorAverage from './AttributeAverageCalculation/ColorAverage';
import ContentAverage from './AttributeAverageCalculation/ContentAverage';
import MaterialsAverage from './AttributeAverageCalculation/MaterialsAverage';
import ColorTasteProfile from './TasteProfileCalculation/ColorTasteProfile';
import ContentTasteProfile from './TasteProfileCalculation/ContentTasteProfile';
import MaterialsTasteProfile from './TasteProfileCalculation/MaterialsTasteProfile';
import ArtWorkDetailsModal from '../../../../container/profile/authentication/overview/GalleryComponents/ArtWorkDetailsModal';

import cart_icon from '../../../../static/img/cart-icon.svg';
import cart_icon_hover from '../../../../static/img/cart-icon-hover.svg';
import like_icon from '../../../../static/img/icon/like-button.svg';
import leftArrow from '../../../../static/img/icon/left-arrow.svg';

import like_icon_hover from '../../../../static/img/icon/like-hover-button.svg';
import dislike_icon from '../../../../static/img/icon/dislike-button.svg';
import scroll_icon from '../../../../static/img/icon/scroll-icon.svg';
import dislike_icon_hover from '../../../../static/img/icon/dislike-hover-button.svg';
import notes_icon from '../../../../static/img/leading-icon.svg';


import LoginGallery from '../../../../container/profile/authentication/overview/GalleryComponents/LoginGallery'
import HomePageContent from './GalleryComponents/HomePageContent';
import CommonFooter from "./CommonFooter";
import ContentCategoryData from './AllCategories/Content'//src/container/profile/authentication/overview/AllCategories/Content
import MaterialOneCategoryData from './AllCategories/MaterialOne'
import MaterialTwoCategoryData from './AllCategories/MaterialTwo'
import { likeStyle, disLikeStyle, skipStyle, defaultStyle, skipDefaultStyle, modalStyle } from '../../../../container/profile/authentication/overview/GalleryComponents/css/RatingStyle'
import getTabKey from '../../../../redux/TabKeyChange/actions'
import Help from "../../../../container/profile/authentication/overview/Help";
import GetSnippetsData from '../../authentication/overview/Snippets/GetSnippetsData';
import messages from '../../../../config/constants/messages';
var base64Img = require('base64-img');


const { Header, Footer, Sider, Content } = Layout;
const delay = 3;
var unrated_queue_length;
const settings = {
	dots: false,
	fade: true,
	infinite: false,
	speed: 500,
	slidesToShow: 1,
	slidesToScroll: 1,
	adaptiveHeight: true,
	arrows: false,
};

const colorArray = Color_Taste;
const categoriesArray = TasteQuizContent


const Home = (props) => {

	const slider = useRef();

	const next = () => {
		slider.current.slickNext();
	};
	const previous = () => {
		slider.current.slickPrev();
	};
	const history = useHistory();
	const dispatch = useDispatch();
	const { TabPane } = Tabs;

	const role = "Queue"
	const snippetData = GetSnippetsData(role)

	const collector_Welcome_Signup_Message = "Collector_Welcome_Signup_Message"
	const collectorWelcomeSignupMessageSnippetData = GetSnippetsData(collector_Welcome_Signup_Message)
	const artist_welcome_Signup_Message = "Artist_Welcome_Signup_Message"
	const artistWelcomeSignupMessageSnippetData = GetSnippetsData(artist_welcome_Signup_Message)


	const [likeBgColour, setLikeBgColour] = useState("#fff")
	const [dislikeBgColour, setDislikeBgColour] = useState("#fff")
	const [skipBgColour, setSkipBgColour] = useState("#000")

	const [likeratingColour, setLikeRatingColour] = useState("#000")
	const [dislikeratingColour, setDislikeRatingColour] = useState("#000")
	const [skipratingColour, setSkipratingColour] = useState("100")
	const [base64, setBase64] = useState("")

	const [likeHoverIcon, setLikeHoverIcon] = useState(false)
	const [dislikeHoverIcon, setDisLikeHoverIcon] = useState(false)
	const [cartHoverIcon, setCartHoverIcon] = useState(false)

	const targetDivRef = useRef(null);
	const galleryDivRef = useRef(null);
	const [workDetailsId, setworkDetailsId] = useState(null)
	const [isGalleryVisible, setisGalleryVisible] = useState(true)

	const [foundCart, setFoundCart] = useState(false)
	const [currentLikeDisLike, setCurrentLikeDisLike] = useState(null)
	const [modalArtWorkId, setmodalArtWorkId] = useState(null)
	const [artworkModalVisible, setArtworkModalVisible] = useState(false)


	const handleMouseEnter = useCallback((iconName) => {
		if (iconName == 'likeIcon') {
			setLikeHoverIcon(true);
		} else if (iconName == 'dislikeIcon') {
			setDisLikeHoverIcon(true);
		} else {
			setCartHoverIcon(true);
		}
	}, []);

	const handleMouseLeave = useCallback((iconName, workId) => {
		if (iconName == 'likeIcon') {
			setLikeHoverIcon(false);
			updateLikeDisLikeStatus();
			//checkLikeDislike(workId);
		} else if (iconName == 'dislikeIcon') {
			setDisLikeHoverIcon(false);
			//checkLikeDislike(workId);
			updateLikeDisLikeStatus();
		} else {
			setCartHoverIcon(false);
		}
	}, []);

	const updateLikeDisLikeStatus = () => {
		var current_like_dislike = sessionStorage.getItem('current_like_dislike');
		if (current_like_dislike != -2) {
			if (current_like_dislike == 1) {
				setLikeHoverIcon(true);
				setDisLikeHoverIcon(false);
			} else {
				setLikeHoverIcon(false);
				setDisLikeHoverIcon(true);
			}
		} else {
			setLikeHoverIcon(false);
			setDisLikeHoverIcon(false);
		}
	}



	const handleScrollToDdetails = (id) => {
		setworkDetailsId(id)
		setTimeout(() => {
			targetDivRef.current.scrollIntoView({ behavior: 'smooth' });
		}, 200);
		setTimeout(() => {
			setisGalleryVisible(false);
		}, 1500);

	};
	const handleScrollToGallery = () => {
		checkArtWorkStatus(workDetailsId);
		setisGalleryVisible(true);
		setTimeout(() => {
			galleryDivRef.current.scrollIntoView({ behavior: 'smooth' });
			setworkDetailsId(null)
		}, 1000);

	};



	const appLikeStyles = {
		color: `${likeratingColour}`,
		background: `${likeBgColour}`,
		padding: '5px',
		height: '35px',
		width: '35px',
		borderRadius: '20px',
		border: "1px solid #c0c0c0f2",

	}

	const appDislikeStyles = {
		color: `${dislikeratingColour}`,
		background: `${dislikeBgColour}`,
		padding: '5px',
		height: '35px',
		width: '35px',
		borderRadius: '20px',
		border: "1px solid #c0c0c0f2",

	}

	const appSkipStyles = {
		fontSize: 16,
		color: 'black',
		fontWeight: `${skipratingColour}`
	}

	const base64Image = (url) => {
		base64Img.requestBase64(url, function (err, res, body) {
			if (err) {
				setBase64(url)
				return err;
			}
			setBase64(body)
		});
	}


	const { isLoading, error, isFbAuthenticate } = useSelector(state => {
		return {
			error: state.firebaseAuth.error,
			isLoading: state.firebaseAuth.loading,
			isFbAuthenticate: state.fb.auth.uid,
		};
	});
	// const [show, setShow] = useState(false);
	const [allArtWorkData, setAllArtWorkData] = useState([])

	const works = useSelector(state => {
		return state.works.data;
	})



	const tasteQuizWorks = useSelector(state => {
		return state.tasteQuizWorkReducer.data;

	})

	const UID = useSelector((state) => {
		return state.fb.auth.uid;
	});


	/*const { profile } = useSelector(state => {
		return {
			profile: state.Profile.data,
		}
	});*/



	useEffect(() => {
		//return false ;
		dispatch(getImages(UID));
		dispatch(getTasteQuizImages());
		// dispatch(getUserLogData(UID));
		dispatch(count(UID));
	}, [dispatch]);


	const [ratingData, setRatingData] = useState([])
	//console.log("ratingData", ratingData);
	const [artWorkAffinityScore, setArtWorkAffinityScore] = useState([])

	//this api updated
	useEffect(() => {
		(async () => {
			const result = await axios.get(`${config.apiUrl}/get-rating/${UID}`);
			if (result && result.data.length > 0) {
				setRatingData(JSON.parse(result.data[0].user_choices))
				setArtWorkAffinityScore(JSON.parse(result.data[0].affinity_score))
			}

		})();
	}, []);


	const [newLikeArrayData, setNewLikeArrayData] = useState([]);
	const [seconds, setSeconds] = useState(180);

	/* start Onpage load Get All Rated and Unrated Art Works */
	var result = works.filter(function (o1) {
		return !newLikeArrayData.some(function (o2) {
			if (o1.id === o2.id) {
				return o1.id === o2.id
			}
		});
	});

	var getUnratedArtWorks = (ratingData && ratingData.length > 0) ? ratingData.filter((item) => (item.like == -2)) : []
	var getUnratedAllArtWorks = []
	if (getUnratedArtWorks) {

		getUnratedAllArtWorks = works.filter(function (o1) {
			return getUnratedArtWorks.some(function (o2) {
				if (o1.id === o2.id) {
					return o1.id === o2.id
				}
			});
		});
		//alert(getUnratedAllArtWorks.length);
	} else {
		getUnratedAllArtWorks = []
	}


	if (artWorkAffinityScore) {
		var sortArtWorkData = artWorkAffinityScore.sort((a, b) => parseFloat(b.finalAffinityScore) - parseFloat(a.finalAffinityScore))

		if (sortArtWorkData) {
			var sotingArtWorkData = sortArtWorkData

			var firstArr = []

			for (var i = 0; i < sotingArtWorkData.length; i++) {
				var secondArr = [...firstArr]
				var id = sotingArtWorkData[i].id
				for (var j = 0; j < getUnratedAllArtWorks.length; j++) {
					if (id == getUnratedAllArtWorks[j].id) {
						secondArr.push(getUnratedAllArtWorks[j])
					}
				}
				firstArr = secondArr

			}


		}
	}

	var getRatedAllArtWorks = works.filter(function (o1) {
		return ratingData.some(function (o2) {
			if (o1.id === o2.id) {
				return o1.id === o2.id && o2.like != -2
			}
		});
	});

	/* end Onpage load Get All Rated and Unrated Art Works */


	if (isFbAuthenticate) {
		//let result = works.filter(o1 => !newLikeArrayData.some(o2 => o1.id === o2.id));

		result.map((itm) => {
			return (
				newLikeArrayData.push({ id: itm.id, like: -2 })
			)
		})

	}


	const [form] = Form.useForm();
	const [state, setState] = useState({
		checked: null,
		signInVisible: false,
		showArtistsTab: true,
		showCollectorsTab: false,
		helpModalVisible: false,
	});
	const { helpModalVisible } = state;

	const onCancelHelpModal = () => {
		setState({
			...state,
			helpModalVisible: false,
		});
	};

	const refreshQueue = () => {
		location.reload();
	};

	const showHelpModal = () => {
		setState({
			...state,
			helpModalVisible: true,
		});
	};


	const images = [
		{ url: Image1 },
		{ url: Image2 },
	];

	const handleFbLogin = useCallback(() => {
		dispatch(login());
		history.push('/admin');
	}, [dispatch, history]);

	useEffect(() => {
		if (isFbAuthenticate) {
			// handleFbLogin();

		}
		// }, [isFbAuthenticate, handleFbLogin]);
	}, [isFbAuthenticate]);


	const handleSubmit = values => {
		dispatch(fbAuthLogin(values));
	};


	const { signInVisible } = state;

	const onCancel = () => {
		setState({
			...state,
			signInVisible: false,
		});
	};

	const showQuestionsModal = () => {
		setState({
			...state,
			signInVisible: true,
		});
	};

	const shareLink = () => {
		notification.success({
			message: messages['copied_to_clipboard']
		});
	};



	const scrollToElement = (id) => {
		var element = document.getElementById(id);
		if (!element) {
			return;
		}
		element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
	}


	const [allTasteTestAttributeCalculationData, setAllTasteTestAttributeCalculationData] = useState([])
	const [dbQueueCounter, setDbQueueCounter] = useState(0)

	//this api updated
	useEffect(() => {

		if (isFbAuthenticate) {

			axios.get(`${config.apiUrl}/get-user-rating-average-data/${UID}`)
				.then(function (response) {
					setNewLikeArrayData(JSON.parse(response.data[0].user_choices));
					setAllTasteTestAttributeCalculationData(JSON.parse(response.data[0].taste_test_global_data))
					setDbQueueCounter(response.data[0].counter)
					sessionStorage.setItem('taste_test_status', response.data[0].taste_test_status)
					if (response.data[0].taste_test_status === 0) {
						//sessionStorage.setItem('taste_view_visible', true)
					}
				})
				.catch(function (error) {
					console.log(error);
				});

			if (sessionStorage.getItem("show_gallery") != null) {
				handleCurrent();
			}
			/*
			const only_gallery = sessionStorage.getItem('only_gallery');
			if(only_gallery==1)
			{
			  
			  sessionStorage.setItem('taste_view_visible',false);				  
			  sessionStorage.removeItem('only_gallery');
			}
			*/
		}

	}, []);


	const [userLogo, setUserLogo] = useState([])

	const imageDetailsGet = (workId, userID) => {
		dispatch(getWorkDetails(workId));
		/*
		axios.get(`${config.apiUrl}/get-user-logo/${userID}`)
			.then((response) => {
				// console.log("response", response)
				setUserLogo(JSON.parse(response.data[0].logo))
			}).catch((error) => {
				console.log("Error", error)
			})
		*/
		setLoading(false);
	}



	const singleWorkDetails = useSelector((state) => {
		return state.singleWork.data;
	})


	if (singleWorkDetails.length > 0) {
		var allImages = JSON.parse(singleWorkDetails[0].image_urls)

		var { appliedCategoriesAttributes } = ContentCategoryData(singleWorkDetails[0].categories)

		var { appliedMaterialsXAttributes } = MaterialOneCategoryData(singleWorkDetails[0].materials1)

		var { appliedMaterialsYAttributes } = MaterialTwoCategoryData(singleWorkDetails[0].materials2)
	}


	const showImageModal = (current_index) => {
		setCurrentIndex(current_index);
		//setIsModalVisible(true);
		setLoading(true);
		setVisible(true);
	};

	const showWorkDetailsModal = (current_index, workid) => {
		setmodalArtWorkId(workid);
		setCurrentIndex(current_index);
		//setIsModalVisible(true);
		setLoading(true);
		setVisible(true);
		setArtworkModalVisible(true);
	};

	const showFilterModal = () => {
		alert("Filter is under progress")
		// setVisibleFilter(true);
	};

	const ImagehandleOk = () => {
		setIsModalVisible(false);
	};

	const ImagehandleCancel = () => {
		setIsModalVisible(false);

	};


	const close = () => {
		//console.log('Notification was closed. Either the close button was clicked or duration time elapsed.',);
	};
	// Taste Quiz notification msg function
	const updateNotificationSuccess = () => {
		const key = `open${Date.now()}`;
		const btn = (
			<Button type="primary" size="small" onClick={() => setIsTasteQuizVisible(true)}>
				Take The Taste Test
			</Button>
		);
		notification.open({
			message: 'Please take a taste test first',
			// description: 'Please take a taste quiz first',
			icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
			btn,
			key,
			onClose: close,
		});
	};

	// Taste Quiz warning notification msg function
	const tasteQuizWarningNotification = () => {
		const key = `open${Date.now()}`;
		notification.open({
			message: `It looks like you have not finished your Taste Test, please click on the Taste Test 
			in the menu above so we can develop an accurate Taste Profile for you.`,
			// description: 'Please take a taste quiz first',
			// btn,
			icon: <InfoCircleOutlined style={{ color: '#e1db2f' }} />,
			key,
			onClose: close,
		});
	};

	// New Taste Profile Generated notification msg function

	const showNewTasteProfileNotification = () => {
		Modal.success({
			content: 'Taste Profile Recalculated',
		});
	};




	/* Start Like,Dislike,Skip Operation and Calculation of Average,Affinity Score */

	const clickhandle = (imgId, likeDislikeShipValue, purchase, parsedColorArr, parsedformalCategoriesArr, parsedContentArr, parsedMaterialArr, artWorkPosition) => {
		setState({
			flagImageLoaded: false,
		});
		var userRateValue
		var colorNotSelectedValue

		if (likeDislikeShipValue == 1) {
			userRateValue = 1 - 0.1
			colorNotSelectedValue = 0 - 0.1

		} else if (likeDislikeShipValue == 2) {
			userRateValue = 1 - (-0.2)
			colorNotSelectedValue = 0 - (-0.2)
		} else {
			userRateValue = 1 - 0
			colorNotSelectedValue = 0
		}

		if (newLikeArrayData.length > 0) {
			const selectdata = {
				like: newLikeArrayData,
				UID: UID,
				colorCalculation: []
			}

			dispatch(sliderImages(selectdata))
				.then((res) => {
					var sessionStorageData = allTasteTestAttributeCalculationData

					// console.log("allTasteTestAttributeCalculationData", allTasteTestAttributeCalculationData)
					const getTasteProfile = sessionStorageData[sessionStorageData.length - 1]
					const CalculationFormalCategoriesObject = getTasteProfile.formalCategoriesTasteProfile
					// console.log("CalculationFormalCategoriesObject", CalculationFormalCategoriesObject)

					for (var a = 0; a < works.length; a++) {
						if (imgId == works[a].id) {
							/*--------color average calculation code here start ---------*/
							const { colorTasteProfile } = ColorAverage(parsedColorArr, purchase == 1 ? 10 : userRateValue, colorNotSelectedValue)

							/*--------color average calculation code here end ---------*/


							/*--------content or category average calculation code here start ---------*/
							const { appliedContentToArtWork } = ContentAverage(parsedContentArr, purchase == 1 ? 10 : userRateValue)
							/*--------content or category average calculation code here end ---------*/


							/*--------materials average calculation code here start ---------*/
							const { materialTasteProfile } = MaterialsAverage(parsedMaterialArr, purchase == 1 ? 10 : userRateValue, colorNotSelectedValue)

							/*--------materials average calculation code here end ---------*/

							/*--------formal category average calculation code here start ---------*/
							var userRatingArray = []

							parsedformalCategoriesArr.map((itm) => {
								userRatingArray.push({
									id: itm.id,
									name: itm.name,
									value: purchase == 1 ? 10 : likeDislikeShipValue == 1 ? itm.value : likeDislikeShipValue == 2 ? 2 * (1 - itm.value) : 0,
									adasRating: itm.value

								}
								)
							})

							// console.log("userRatingArray", userRatingArray)
							/*--------formal category average calculation code here end ---------*/

							setDbQueueCounter(dbQueueCounter + 1)
							/* Persist all category attribute average into database code here start */
							let latestCounter = dbQueueCounter + 1
							// console.log("latestCounter", latestCounter)

							let allCategoryAttributeData = [{ colorTasteProfile, contentTasteProfile: appliedContentToArtWork, materialTasteProfile, formalCategoriesTasteProfile: userRatingArray, id: imgId }]

							let calculationData = {
								attributes: allCategoryAttributeData,
								counter: latestCounter,
								UID: UID
							}
							// console.log("latestCounter",latestCounter)
							/*if queue complete then calculate new tasteprofile code here */

							//this api updated
							if (latestCounter == 5) {

								// console.log("queue completed and taste pofile recalculated")
								axios.get(`${config.apiUrl}/get-average-taste-profile-data/${UID}`)
									.then((response) => {

										let queueData = JSON.parse(response.data[0].queue_data)
										// console.log("queueData",queueData)
										// console.log("allCategoryAttributeData",allCategoryAttributeData)
										let contentArray = Categories;
										let materialArray = TasteQuizMaterial;

										/*color new tasteprofile calculation code here start*/
										const { color_Array_Data } = ColorTasteProfile(queueData)

										let previousColorTasteProfile = JSON.parse(response.data[0].color_taste_profile)
										let previousColorTasteProfileLenght = previousColorTasteProfile.length
										let newColorTasteProfile = color_Array_Data

										let newColorTasteProfileGenerates = []
										for (var a = 0; a < previousColorTasteProfileLenght; a++) {

											var oldname = previousColorTasteProfile[a].name
											var newname = newColorTasteProfile[a].name

											if (oldname == newname) {
												newColorTasteProfileGenerates.push({
													id: newColorTasteProfile[a].id,
													name: newColorTasteProfile[a].name,
													tpScore: previousColorTasteProfile[a].tpScore + newColorTasteProfile[a].tpScore
												})
											}

										}
										/*color new tasteprofile calculation code here end*/

										/*content new tasteprofile calculation code here start*/
										const { content_Array_Data } = ContentTasteProfile(queueData)

										var previousContentTasteProfile = JSON.parse(response.data[0].content_taste_profile)
										var previousContentTasteProfileLenght = previousContentTasteProfile.length
										var newContentTasteProfile = content_Array_Data

										var newContentTasteProfileGenerates = []

										for (var a = 0; a < newContentTasteProfile.length; a++) {
											for (var b = 0; b < previousContentTasteProfileLenght; b++) {
												if (newContentTasteProfile[a]) {
													var oldname = previousContentTasteProfile[b].name
													var newname = newContentTasteProfile[a].name
													if (oldname == newname) {
														newContentTasteProfileGenerates.push({
															id: newContentTasteProfile[a].id,
															name: newContentTasteProfile[a].name,
															tpScore: previousContentTasteProfile[b].tpScore + newContentTasteProfile[a].tpScore
														})
													}
												}
											}

										}
										/*content new tasteprofile calculation code here end*/

										/*materials new tasteprofile calculation code here start*/
										const { material_Array_Data } = MaterialsTasteProfile(queueData)

										var previousMaterialsTasteProfile = JSON.parse(response.data[0].material_taste_profile)
										var previousMaterialsTasteProfileLenght = previousMaterialsTasteProfile.length
										var newMaterialsTasteProfile = material_Array_Data

										var newMaterialsTasteProfileGenerates = []
										for (var a = 0; a < previousMaterialsTasteProfileLenght; a++) {

											var oldname = previousMaterialsTasteProfile[a].name
											var newname = newMaterialsTasteProfile[a].name

											if (oldname == newname) {
												newMaterialsTasteProfileGenerates.push({
													id: newMaterialsTasteProfile[a].id,
													name: newMaterialsTasteProfile[a].name,
													tpScore: previousMaterialsTasteProfile[a].tpScore + newMaterialsTasteProfile[a].tpScore
												})
											}
										}
										/*materials new tasteprofile calculation code here end*/



										if (newColorTasteProfileGenerates && newContentTasteProfileGenerates && newMaterialsTasteProfileGenerates) {
											axios.get(`${config.apiUrl}/get-unrated-artwork-images/${UID}`)
												.then((response) => {
													
													let artWorkDataLength = response.data.length
													let artWorkData = response.data


													let oldUnrated_ArtWorkColorData = []
													let oldUnrated_ArtWorkCategoryData = []
													let oldUnrated_ArtWorkMaterialsData = []
													let oldUnrated_ArtWorkFormalCategoriesData = []

													if (artWorkData.length > 0) {
														for (var a = 0; a < artWorkDataLength; a++) {

															let unrated_ArtWorkColorData = [...oldUnrated_ArtWorkColorData]
															let unrated_ArtWorkCategoryData = [...oldUnrated_ArtWorkCategoryData]
															let unrated_ArtWorkMaterialsData = [...oldUnrated_ArtWorkMaterialsData]
															let unrated_ArtWorkFormalCategoriesData = [...oldUnrated_ArtWorkFormalCategoriesData]

															/*get unrated artworks code here start */

															/*color start */
															var unRated_appliedColorToArtWork = []
															var unRated_parsedColorArr = JSON.parse(artWorkData[a].color_taste);
															for (var i = 0; i < colorArray.length; i++) {
																for (var j = 0; j < unRated_parsedColorArr && unRated_parsedColorArr.length; j++) {
																	if (colorArray[i].value == unRated_parsedColorArr[j]) {
																		unRated_appliedColorToArtWork.push(
																			{
																				name: colorArray[i].label,
																				userRating: 1
																			}
																		);

																	}
																}
															}

															var unRated_ColorProfile = [...unRated_appliedColorToArtWork]

															var unRated_Colorresult = colorArray.filter(function (o1) {
																return !unRated_appliedColorToArtWork.some(function (o2) {
																	if (o1.label === o2.name) {
																		return o1.id === o2.id
																	}
																});
															});


															unRated_Colorresult.map((itm) => {
																unRated_ColorProfile.push({
																	name: itm.label,
																	userRating: 0
																})
															})
															/*color end*/

															/*Content start*/
															var unRated_appliedCategoriesToArtWork = []
															var unRated_parsedCategoriesArr = JSON.parse(artWorkData[a].categories);
															for (var i = 0; i < categoriesArray.length; i++) {
																for (var j = 0; j < unRated_parsedCategoriesArr.length; j++) {
																	if (categoriesArray[i].value == unRated_parsedCategoriesArr[j]) {
																		unRated_appliedCategoriesToArtWork.push(
																			{
																				name: categoriesArray[i].label,
																				userRating: 1
																			}
																		);

																	}
																}
															}
															/*Content end */

															/*materials start */

															var unRated_appliedMaterialsToArtWork = []

															var materialX = artWorkData[a].materials1 != null ? JSON.parse(artWorkData[a].materials1) : []
															var materialY = artWorkData[a].materials2 != null ? JSON.parse(artWorkData[a].materials2) : []

															var parsedMaterialXArr = (materialX ? materialX : []).concat(materialY ? materialY : []);

															for (var i = 0; i < TasteQuizMaterial.length; i++) {
																for (var j = 0; j < parsedMaterialXArr.length; j++) {
																	if (TasteQuizMaterial[i].value == parsedMaterialXArr[j]) {
																		unRated_appliedMaterialsToArtWork.push(
																			{
																				name: TasteQuizMaterial[i].label,
																				userRating: 1
																			}
																		);

																	}
																}
															}

															var materialXTasteProfile = [...unRated_appliedMaterialsToArtWork]

															var materialsResult = TasteQuizMaterial.filter(function (o1) {
																return !unRated_appliedMaterialsToArtWork.some(function (o2) {
																	if (o1.label === o2.name) {
																		return o1.id === o2.id
																	}
																});
															});


															materialsResult.map((itm) => {
																materialXTasteProfile.push({
																	name: itm.label,
																	userRating: 0
																})
															})
															/*materials end */

															/*formal categories start*/
															var unrated_FormalCategoriesToArtWork = []
															var unRated_parsedFormalCategoriesArr = JSON.parse(artWorkData[a].formal_categories_json_data)

															for (var i = 0; i < CalculationFormalCategoriesObject.length; i++) {
																for (var j = 0; j < unRated_parsedFormalCategoriesArr.length; j++) {
																	if (CalculationFormalCategoriesObject[i].id == unRated_parsedFormalCategoriesArr[j].id) {

																		unrated_FormalCategoriesToArtWork.push({
																			id: CalculationFormalCategoriesObject[i].id,
																			name: CalculationFormalCategoriesObject[i].name,
																			tasteProfile: CalculationFormalCategoriesObject[i].tpScore,
																			adasRating: unRated_parsedFormalCategoriesArr[j].value,
																			affinitystep1: Math.abs(CalculationFormalCategoriesObject[i].tpScore - unRated_parsedFormalCategoriesArr[j].value)

																		})
																	}
																}
															}
															/*formal categories end */


															unrated_ArtWorkColorData.push({ ...unRated_ColorProfile, id: artWorkData[a].id })
															unrated_ArtWorkCategoryData.push({ ...unRated_appliedCategoriesToArtWork, id: artWorkData[a].id })
															unrated_ArtWorkMaterialsData.push({ ...materialXTasteProfile, id: artWorkData[a].id })
															unrated_ArtWorkFormalCategoriesData.push({ ...unrated_FormalCategoriesToArtWork, id: artWorkData[a].id })

															oldUnrated_ArtWorkColorData = unrated_ArtWorkColorData
															oldUnrated_ArtWorkCategoryData = unrated_ArtWorkCategoryData
															oldUnrated_ArtWorkMaterialsData = unrated_ArtWorkMaterialsData
															oldUnrated_ArtWorkFormalCategoriesData = unrated_ArtWorkFormalCategoriesData


															/*get unrated artworks code here end */

														}


														if (artWorkDataLength == oldUnrated_ArtWorkColorData.length) {
															// console.log("reached to length")
															if (newColorTasteProfileGenerates && newContentTasteProfileGenerates && newMaterialsTasteProfileGenerates) {
																var localStorage_ColorTasteProfile = newColorTasteProfileGenerates
																var localStorage_MaterialsTasteProfile = newContentTasteProfileGenerates
																var localStorage_CategoryProfile = newMaterialsTasteProfileGenerates

																var localColorData = oldUnrated_ArtWorkColorData
																if (localColorData) {
																	var getAllColorArr = localColorData
																	var color_affinity_score = []
																	var sumOfColorTasteProfileObject = 0
																	var Affinity = 0

																	for (var b = 0; b < localColorData.length; b++) {
																		var step1 = localColorData[b]
																		for (var j = 0; j < Object.keys(step1).length; j++) {
																			if (step1[j]) {
																				const isAvailable = localStorage_ColorTasteProfile.find(o1 => o1.name == step1[j].name)

																				if (isAvailable) {
																					var cal = step1[j].userRating
																					step1[j].userRating = (isAvailable.tpScore - cal - cal)
																				}
																			}
																		}

																		const result = Object.values(step1);
																		result.map((itm) => {

																			if (itm.name) {
																				var absolute_val = Math.abs(itm.userRating)
																				sumOfColorTasteProfileObject += absolute_val
																			}

																		})
																		// color affinity step2 calculation

																		var singleArrayColorLenght = Object.keys(step1).length - 1

																		Affinity = (sumOfColorTasteProfileObject) / (2 * (singleArrayColorLenght))

																		color_affinity_score.push({ ...getAllColorArr[b], Affinity })

																		sumOfColorTasteProfileObject = 0
																		Affinity = 0



																	}
																}



																var localCategoryData = oldUnrated_ArtWorkCategoryData
																if (localCategoryData) {

																	var categories_affinity_cal_step1 = localCategoryData
																	var categories_affinity_data = []
																	var categories_final_affinity = 0
																	var Affinity = 0

																	for (var i = 0; i < localCategoryData.length; i++) {

																		var step1 = localCategoryData[i]

																		for (var j = 0; j < Object.keys(step1).length; j++) {
																			if (step1[j]) {

																				const isCategoriesAvailable = localStorage_CategoryProfile.find(o1 => o1.name == step1[j].name)

																				if (isCategoriesAvailable) {

																					var cal = step1[j].userRating

																					step1[j].userRating = (isCategoriesAvailable.tpScore - cal - cal)


																				}
																			}
																		}

																		const categoriesResult = Object.values(step1);
																		categoriesResult.map((itm) => {

																			if (itm.name) {
																				var absolute_val = Math.abs(itm.userRating)
																				//console.log("absolute_val",absolute_val)
																				categories_final_affinity += absolute_val
																			}

																		})



																		var singleArrayCategoriesLenght = Object.keys(step1).length - 1

																		Affinity = (categories_final_affinity) / (2 * (singleArrayCategoriesLenght))

																		categories_affinity_data.push({ ...categories_affinity_cal_step1[i], Affinity })

																		categories_final_affinity = 0
																		Affinity = 0
																	}



																}

																var localMaterialsData = oldUnrated_ArtWorkMaterialsData
																if (localMaterialsData) {
																	var materials_affinity_cal_step1 = localMaterialsData
																	var materials_affinity_data = []
																	var materials_final_affinity = 0
																	var Affinity = 0

																	for (var i = 0; i < localMaterialsData.length; i++) {
																		var step1 = localMaterialsData[i]

																		for (var j = 0; j < Object.keys(step1).length; j++) {
																			if (step1[j]) {

																				const isMaterialXAvailable = localStorage_MaterialsTasteProfile.find(o1 => o1.name == step1[j].name)

																				if (isMaterialXAvailable) {

																					var cal = step1[j].userRating

																					step1[j].userRating = (isMaterialXAvailable.tpScore - cal - cal)


																				}
																			}
																		}

																		const materialXResult = Object.values(step1);
																		materialXResult.map((itm) => {

																			if (itm.name) {
																				var absolute_val = Math.abs(itm.userRating)
																				materials_final_affinity += absolute_val
																			}

																		})

																		var singleArrayMaterialXLenght = Object.keys(step1).length - 1

																		Affinity = (materials_final_affinity) / (2 * (singleArrayMaterialXLenght))

																		materials_affinity_data.push({ ...materials_affinity_cal_step1[i], Affinity })

																		materials_final_affinity = 0
																		Affinity = 0


																	}


																}


																var localFormalCategoriesData = oldUnrated_ArtWorkFormalCategoriesData
																if (localFormalCategoriesData) {
																	var FormalCategoriesData = localFormalCategoriesData
																	var sumOfFormalCategoriesValue = 0
																	var Affinity = 0
																	var finalFormalCategoriesAffinity = []

																	for (var i = 0; i < localFormalCategoriesData.length; i++) {
																		step1 = localFormalCategoriesData[i]

																		const aaaa = Object.values(step1);

																		aaaa.map((itm) => {
																			// console.log("itm",itm)
																			if (itm.name) {
																				var absolute_val = Math.abs(itm.affinitystep1)
																				sumOfFormalCategoriesValue += absolute_val
																			}
																		})


																		var test_singleArrayMaterialXLenght = Object.keys(step1).length - 1

																		Affinity = (sumOfFormalCategoriesValue) / (test_singleArrayMaterialXLenght)

																		finalFormalCategoriesAffinity.push({ ...FormalCategoriesData[i], Affinity })

																		sumOfFormalCategoriesValue = 0
																		Affinity = 0
																	}
																}


																let newArray = []
																if (color_affinity_score.length > 0) {

																	for (var i = 0; i < color_affinity_score.length; i++) {
																		let colorAffinity = color_affinity_score[i].Affinity
																		let materialsAffinity = materials_affinity_data[i].Affinity
																		let formalCategoriesAffinity = finalFormalCategoriesAffinity[i].Affinity
																		let categoriesAffinity = categories_affinity_data[i].Affinity

																		let finalAffinityScore = ((colorAffinity + (2 * materialsAffinity) + (3 * formalCategoriesAffinity) + (2 * categoriesAffinity))) / 8

																		newArray.push(
																			{ finalAffinityScore, id: color_affinity_score[i].id }
																		)
																	}

																}
																// console.log("newArray", newArray)
																// let removeDuplicateData = newArray.filter((ele, ind) => ind === newArray.findIndex(elem => elem.id === ele.id))
																let concatData = (queueData).concat(allCategoryAttributeData)
																// console.log("concatData",concatData)
																let newGeneratedAffinityScore = {
																	colorTasteProfile: newColorTasteProfileGenerates,
																	contentTasteProfile: newContentTasteProfileGenerates,
																	materialTasteProfile: newMaterialsTasteProfileGenerates,
																	affinityScore: newArray,
																	UID: UID,
																	counter: 0,
																	oldQueueData: concatData
																}




																if (color_affinity_score.length == artWorkDataLength) {
																	//console.log("length to reached")
																	axios.post(`${config.apiUrl}/update-new-data`, newGeneratedAffinityScore)
																		.then((response) => {
																			if (response.status == 200) {
																				//console.log('response', response)
																				//console.log("new counter set and queue will be resorted...!")
																				setDbQueueCounter(0)
																				history.push('/')
																				dispatch(getImages(UID));
																			}
																		})
																		.catch((error) => {
																			console.log("Error :", error)
																		})
																} else {
																	//console.log("something went wrong...!")
																}

															} else {
																//console.log("data not found")
															}

														} else {
															//console.log("something went wrong...!")
														}
													}


												})
												.catch((error) => {
													console.log("Error", error)
												})
										} else {
											console.log('new taste profile not generated...!')
										}


									})
									.catch((error) => {
										console.log("Error : ", error)
									})

							} else {
								axios.post(`${config.apiUrl}/update-average`, calculationData)
									.then(function (response) {
										setArtWorkIndex(artWorkPosition + 1)
										setLikeBgColour("#fff")
										setDislikeBgColour("#fff")
										setSkipBgColour("#000")
										setLikeRatingColour("#000")
										setDislikeRatingColour("#000")
										setSkipratingColour("#100")
										console.log(response);
									})
									.catch(function (error) {
										console.log(error);
									});

							}
							/* Persist all category attribute average into database code here end */
						} else {
							console.log("unrated artworks")
						}
					}
				})
				.catch((error) => {
					console.log("Error", error)
				})
		}
	}

	const handleLike = (imgId, colorData, categoriesData, materialData, material2Data, formal_categories_json_data, purchase, artWorkPosition) => {

		const taste_test_status = sessionStorage.getItem('taste_test_status')
		setBase64("");

		if (taste_test_status) {

			if (taste_test_status == 1) {

				newLikeArrayData.map((item) => {
					if (item.id === imgId) {
						item.like = 1
					}
				})
				var likeDislikeShipValue = 1;
				var purchase = purchase
				var parsedColorArr = JSON.parse(colorData);
				var parsedformalCategoriesArr = JSON.parse(formal_categories_json_data)
				var parsedContentArr = JSON.parse(categoriesData)

				var materialX = materialData != null ? JSON.parse(materialData) : []
				var materialY = material2Data != null ? JSON.parse(material2Data) : []

				var parsedMaterialArr = (materialX ? materialX : []).concat(materialY ? materialY : []);

				// console.log("parsedMaterialArr", parsedMaterialArr)
				let temp_index = artWorkPosition + 1;
				if (unRatedSortingData.length > 0 && unRatedSortingData[temp_index].id) {
					checkArtWorkStatus(unRatedSortingData[temp_index].id)
				}
				clickhandle(imgId, likeDislikeShipValue, purchase, parsedColorArr, parsedformalCategoriesArr, parsedContentArr, parsedMaterialArr, artWorkPosition);

			} else {
				
				//  link above so we can develop an accurate Taste Profile for you.")

				tasteQuizWarningNotification()
			}
		} else {
			updateNotificationSuccess()
		}

	}

	const handleDisLike = (imgId, colorData, categoriesData, materialData, material2Data, formal_categories_json_data, purchase, artWorkPosition) => {

		const taste_test_status = sessionStorage.getItem('taste_test_status')
		setBase64("");

		if (taste_test_status) {
			if (taste_test_status == 1) {
				newLikeArrayData.map((item) => {
					if (item.id === imgId) {
						item.like = -1
					}
				})

				var likeDislikeShipValue = 2;
				var parsedColorArr = JSON.parse(colorData);
				var parsedformalCategoriesArr = JSON.parse(formal_categories_json_data)
				var parsedContentArr = JSON.parse(categoriesData)

				var materialX = materialData != null ? JSON.parse(materialData) : []
				var materialY = material2Data != null ? JSON.parse(material2Data) : []

				var parsedMaterialArr = (materialX ? materialX : []).concat(materialY ? materialY : []);

				// console.log("parsedMaterialArr", parsedMaterialArr)
				let temp_index = artWorkPosition - 1;
				if (unRatedSortingData.length > 0 && unRatedSortingData[temp_index].id) {
					checkArtWorkStatus(unRatedSortingData[temp_index].id)
				}

				clickhandle(imgId, likeDislikeShipValue, purchase, parsedColorArr, parsedformalCategoriesArr, parsedContentArr, parsedMaterialArr, artWorkPosition);

			} else {

				tasteQuizWarningNotification()
			}
		}
		else {
			updateNotificationSuccess()
		}


	}

	const handleskip = (imgId, colorData, categoriesData, materialData, material2Data, formal_categories_json_data, purchase, artWorkPosition) => {

		const taste_test_status = sessionStorage.getItem('taste_test_status')



		if (taste_test_status) {
			if (taste_test_status == 1) {
				newLikeArrayData.map((item) => {
					if (item.id === imgId) {
						item.like = 0
					}
				})

				var likeDislikeShipValue = 0;
				var parsedColorArr = JSON.parse(colorData);
				var parsedformalCategoriesArr = JSON.parse(formal_categories_json_data)
				var parsedContentArr = JSON.parse(categoriesData)

				var materialX = materialData != null ? JSON.parse(materialData) : []
				var materialY = material2Data != null ? JSON.parse(material2Data) : []

				var parsedMaterialArr = (materialX ? materialX : []).concat(materialY ? materialY : []);

				// console.log("parsedMaterialArr", parsedMaterialArr)

				clickhandle(imgId, likeDislikeShipValue, purchase, parsedColorArr, parsedformalCategoriesArr, parsedContentArr, parsedMaterialArr, artWorkPosition);

			} else {

				tasteQuizWarningNotification()
			}
		}
		else {
			updateNotificationSuccess()
		}

	}

	/* End Like,Dislike,Skip Operation and Calculation of Average,Affinity Score */


	const [visible, setVisible] = React.useState(false);

	const [currentindex, setCurrentIndex] = React.useState(null);

	const [visibleFilter, setVisibleFilter] = React.useState(false);
	const [confirmLoading, setConfirmLoading] = React.useState(false);

	const handleCancel = () => {
		setVisible(false);
		setDynamicImageChange(false);
	};
	const handleModalCancel = () => {
		setmodalArtWorkId(null);
		setArtworkModalVisible(false);
	}

	const handleFilterCancel = () => {
		setVisibleFilter(false);

	};

	//item add to cart and get count of items
	const addCart = (workId) => {
		const workData = {
			UID: UID,
			workId: workId,
			gallery_index: 0
		}

		//dispatch(addDetailsIntoCart(workData));	
		const data = workService.addCart(workData).then(res => {
			dispatch(count(UID));
			setFoundCart(true)
			//console.log("res",res)
		});
		//setVisible(false);
	}

	const delectCartItem = async (ID) => {
		var clickedId = [{
			id: ID
		}];
		dispatch(removeCartItem(ID, UID));
		setFoundCart(false);
		setTimeout(() => {
			//alert(1);
			dispatch(count(UID));
		}, 1000);

	}



	// onclick to Image change in model

	const [dynamicImageChange, setDynamicImageChange] = useState('')
	//console.log("url",dynamicImageChange)
	const getimgurl = (url) => {
		setDynamicImageChange(url)
	}

	const widthscreen = window.innerWidth;

	const [color, setColor] = useState({
		color: ""
	});

	//console.log("categories", categories.categories)


	const handleInputs = (value, name) => {
		// console.log('handleDropdownChange')
		// console.log(name)
		// console.log(value)
		// return;
		setColor({
			...color,
			[name]: value,
		});
	}

	const [minPrice, setMinPrice] = useState('');
	const [maxPrice, setMaxPrice] = useState('');
	const [minWidth, setMinWidth] = useState('');
	const [maxWidth, setMaxWidth] = useState('');
	const [minHeight, setMinHeight] = useState('');
	const [maxHeight, setMaxHeight] = useState('');





	const filterPriceObject = {
		minprice: minPrice,
		maxprice: maxPrice,
		minwidth: minWidth,
		maxwidth: maxWidth,
		minheight: minHeight,
		maxheight: maxHeight,
		color: color.color,
		color_lenght: color.color.length
	}

	window.onbeforeunload = function (e) {
		//sessionStorage.clear();
		sessionStorage.removeItem('filter');
	};

	var filter_status = JSON.parse(sessionStorage.getItem('filter'))
	const handlePriceFilter = () => {

		// console.log("filterPriceObject", filterPriceObject)

		dispatch(getWorkFilterDataList(filterPriceObject));
		handleFilterCancel();
		sessionStorage.setItem('filter', true)

	}

	const filterdata = useSelector((state) => {
		return state.WorkFilterReducer.data
	})

	const handleClearFilter = () => {

		sessionStorage.removeItem('filter')
		dispatch(getImages(UID));
		handleFilterCancel();

	}


	const contentData = useSelector((state) => {
		return state.contentData
	})

	useEffect(() => {

		//console.log("contentData", contentData)
		setState({
			showArtistsTab: contentData.showArtistsTab,
			showCollectorsTab: contentData.showCollectorsTab,
		})
		scrollToElement(contentData.scrollToElement ? contentData.scrollToElement : 'box2')

	}, [contentData])


	const [isSignUpModalVisible, setIsSignUpModalVisible] = useState(false);

	const [isTasteQuizVisible, setIsTasteQuizVisible] = useState(false);
	const [loading, setLoading] = useState(false);

	const showSignUpModal = () => {
		setIsSignUpModalVisible(true);

	};

	const showTasteQuizModal = () => {
		setIsSignUpModalVisible(false)
		setIsTasteQuizVisible(true);
		sessionStorage.removeItem('signup_modal');
		localStorage.clear();
		sessionStorage.clear();
		sessionStorage.setItem('taste_view_visible', true);
		location.reload();
	};


	const handleSignUpOk = () => {
		setIsSignUpModalVisible(false);
	};

	const handleTasteQuizOk = () => {
		setIsTasteQuizVisible(false);

	};

	const handleSignUpCancel = () => {
		setIsSignUpModalVisible(false);
		sessionStorage.removeItem('signup_modal');
		localStorage.clear();
		sessionStorage.clear();
		history.push('/gallery')
	};
	const goToAddArtworks = () => {
		setIsSignUpModalVisible(false);
		sessionStorage.removeItem('signup_modal');
		localStorage.clear();
		sessionStorage.clear();
		history.push('/my-works/grid')
	};

	const handleTasteQuizCancel = () => {
		setIsTasteQuizVisible(false);
		showTasteQuizSuccessModal()

	};


	const getSessionData = JSON.parse(sessionStorage.getItem('signup_modal'))

	useEffect(() => {
		if (getSessionData) {
			setIsSignUpModalVisible(getSessionData.showmodal)
		}
		//sessionStorage.removeItem('signup_modal')
	}, [])

	// const [toggle, setToggle] = useState(false)

	const [ratedToggle, setRatedToggle] = useState(false)

	//console.log(`switch to ${ratedToggle}`);

	/* Taste Quiz Success modal here */
	const [isTasteQuizSuccessModalVisible, setIsTasteQuizSuccessModalVisible] = useState(false);

	const showTasteQuizSuccessModal = () => {
		setIsTasteQuizSuccessModalVisible(true);
	};

	const handleTasteQuizSuccessOk = () => {
		setIsTasteQuizSuccessModalVisible(false);
		history.push('/gallery')
	};

	const handleTasteQuizSuccessCancel = () => {
		setIsTasteQuizSuccessModalVisible(false);
		history.push('/gallery')
	};


	const [isShow, setIsShow] = React.useState(false);

	const handleClickAffinity = () => {
		// setIsShow(!isShow);
	};

	var emptyQueueImage = [
		{
			"id": 0,
			"user_id": UID,
			"work_title": "Art Work 0",
			"work_description": "The Upper Peninsula is a forested region in Michigan bordering 3 of the Great Lakes and extending outward from Wisconsin. It’s connected to Michigan’s Lower Peninsula by the roughly 5-miles-long Mackinac Bridge, which spans the Straits of Mackinac. Sandwiched between the 2 peninsulas is Mackinac Island, a car-free vacation destination with the iconic 1887 Grand Hotel and the",
			"work_start_date": "2021-09-15T18:30:00.000Z",
			"work_end_date": "2021-09-16T18:30:00.000Z",
			"categories": "[49, 51, 61, 73]",
			"width": 400,
			"height": 400,
			"materials1": "[8,9]",
			"materials2": "[20,21,22]",
			"color_taste": "[14,9,11,5]",
			"formal_categories": "[4,6,7,13]",
			"formal_categories_json_data": "[{\"id\":1,\"name\":\"Representational - Abstract\",\"value\":0},{\"id\":2,\"name\":\"Monochromatic - Polychromatic\",\"value\":0},{\"id\":3,\"name\":\"Flatness - Depth\",\"value\":0},{\"id\":4,\"name\":\"Digital  - Analog\",\"value\":0},{\"id\":5,\"name\":\"Dynamic - Static\",\"value\":0},{\"id\":6,\"name\":\"Human Form - Not human form\",\"value\":0},{\"id\":7,\"name\":\"Textured - Smooth\",\"value\":0},{\"id\":8,\"name\":\"Dark - Light\",\"value\":0},{\"id\":9,\"name\":\"Cultural Signifiers - Lack of cultural signifiers\",\"value\":0},{\"id\":10,\"name\":\"Symmetric - Asymmetric\",\"value\":0},{\"id\":11,\"name\":\"Kiki - Bouba\",\"value\":0},{\"id\":12,\"name\":\"Patterned - Not patterned\",\"value\":0},{\"id\":13,\"name\":\"High Contrast - Low Contrast\",\"value\":0},{\"id\":14,\"name\":\"Single Object - Multiple Objects\",\"value\":0}]",
			"price": 300,
			"part_of_series": 0,
			"unique_copy": 0,
			"year_created": "2022",
			"image_urls": "[{\"url\":\"https://stoodio-dev-images.s3.amazonaws.com/Images/EmptyQueue.png\"}]",
			"status": "1",
			"sold": "0",
			"rating": "[{\"categories\":[{\"Fashion\":1},{\"Femininity\":1},{\"Haze\":1},{\"Kawaii\":1}],\"total_categories\":4,\"material\":[{\"Graphite\":1},{\"Digital Print\":1},{\"Scratchboard\":1},{\"Vellum\":1},{\"Wood\":1}],\"total_material\":5,\"color\":[{\"Navy\":1},{\"Grey\":1},{\"Lime\":1},{\"Fuchsia\":1}],\"total_color\":4,\"formal_categories\":[{\"Digital  - Analog\":1},{\"Human Form - Not human form\":1},{\"Textured - Smooth\":1},{\"High Contrast - Low Contrast\":1}],\"total_formal_categories\":4}]",
			"updated_at": null,
			"created_at": "2022-01-21T12:00:46.000Z"
		}]


	var unRatedSortingData = firstArr ? firstArr.length > 0 ? firstArr.concat(emptyQueueImage) : getUnratedAllArtWorks.concat(emptyQueueImage) : getUnratedAllArtWorks.concat(emptyQueueImage)
	unrated_queue_length = unRatedSortingData.length;



	const delay = 1;
	const [show, setShow] = useState(false);
	useEffect(
		() => {
			let timer1 = setTimeout(() => setShow(true), delay * 1000);
			return () => {
				clearTimeout(timer1);
			};
		}, []);



	var get_taste_view_visible = sessionStorage.getItem('taste_view_visible')

	var tasteQuizVisibleAndInvisible = get_taste_view_visible == 'true' ? 'true' : false


	const [showProof, setShowProof] = useState('');

	const [isProofModalVisible, setIsProofModalVisible] = useState(false);

	const [isProofPassword, setIsProofPassword] = useState(false);

	const showProofModal = () => {
		setIsProofModalVisible(true);
	};

	const handleProofOk = () => {
		setIsProofModalVisible(false);
	};

	const handleProofCancel = () => {
		setIsProofModalVisible(false);
	};

	const handleAlertClose = () => {
		setIsProofPassword(!isProofPassword)
	};

	const handleProof = () => {
		var proofPassword = process.env.REACT_APP_PROOF_PASSWORD
		if (proofPassword == showProof) {
			setIsShow(!isShow);
			handleProofCancel();
		} else {
			setIsProofPassword(true)
		}
	}

	const [artWorkIndex, setArtWorkIndex] = useState(0);

	/*component reload every 3 minutes code here start*/

	var tasteTest = sessionStorage.getItem('taste_test_status')
	useEffect(() => {

		(seconds > 0) && setTimeout(() => setSeconds(seconds - 1), 1000);
		if (seconds == 0) {
			console.log("reset time")
			history.push('/')
			dispatch(getImages(UID));
			setArtWorkIndex(0)
			setLikeBgColour("#fff")
			setDislikeBgColour("#fff")
			setSkipBgColour("#000")
			setLikeRatingColour("#000")
			setDislikeRatingColour("#000")
			setSkipratingColour("#100")
			setSeconds(180)
		}

	}, [tasteTest != 0 ? seconds : ""])



	const defaultActiveTabKey = useSelector((state) => {
		return state.tabKeyValue.defaultActiveTabKey
	})

	// console.log("seconds", seconds)





	/*component reload every 3 minutes code here end*/

	/*goto previous artwork code here start*/
	const handleGoBack = (work_id) => {
		let temp_index = artWorkIndex - 1;
		setBase64("");
		setArtWorkIndex(artWorkIndex - 1)
		setDbQueueCounter(dbQueueCounter - 1)
		setLikeBgColour("#fff")
		setDislikeBgColour("#fff")
		setSkipBgColour("#000")
		setLikeRatingColour("#000")
		setDislikeRatingColour("#000")
		setSkipratingColour("#100")
		sessionStorage.setItem("gallery_index", (artWorkIndex - 1));

		if (unRatedSortingData[temp_index].id) {
			checkArtWorkStatus(unRatedSortingData[temp_index].id);
		}
	}

	const handleCurrent = () => {

		setTimeout(() => {

			let current = parseInt(sessionStorage.getItem("gallery_index"));
			if (current < unrated_queue_length) {
				//console.log("Current :",unrated_queue_length);
				setBase64("");
				setArtWorkIndex(current)
				setDbQueueCounter(current)
				setLikeBgColour("#fff")
				setDislikeBgColour("#fff")
				setSkipBgColour("#000")
				setLikeRatingColour("#000")
				setDislikeRatingColour("#000")
				setSkipratingColour("#100")
				sessionStorage.removeItem("show_gallery");
				sessionStorage.removeItem("gallery_index");
			}
		}, 1000)

	}

	const handleNext = (work_id) => {
		let temp_index = artWorkIndex + 1;
		setBase64("");
		setArtWorkIndex(artWorkIndex + 1)
		setDbQueueCounter(dbQueueCounter + 1)
		sessionStorage.setItem("gallery_index", (artWorkIndex + 1));
		setLikeBgColour("#fff")
		setDislikeBgColour("#fff")
		setSkipBgColour("#000")
		setLikeRatingColour("#000")
		setDislikeRatingColour("#000")
		setSkipratingColour("#100")

		if (unRatedSortingData[temp_index].id) {
			checkArtWorkStatus(unRatedSortingData[temp_index].id)
		}
	}

	const checkArtWorkStatus = (workId) => {

		if (workId !== 0) {
			let post_data = { id: workId, uid: UID };
			axios.post(`${config.apiUrl}/check-artwork-status`, post_data)
				.then((response) => {
					if (response.status == 200) {
						if (response.data) {
							//alert(workId + JSON.stringify(response.data));
							setCartHoverIcon(false);
							sessionStorage.setItem('current_like_dislike', response.data.is_like);
							setCurrentLikeDisLike(response.data.is_like);
							setFoundCart(false);
							if (response.data.is_cart == 1) {
								setFoundCart(true);
							}
							if (response.data.is_like != -2) {
								if (response.data.is_like == 1) {
									setLikeHoverIcon(true);
									setDisLikeHoverIcon(false);
								} else {
									setLikeHoverIcon(false);
									setDisLikeHoverIcon(true);
								}

							} else {
								setLikeHoverIcon(false);
								setDisLikeHoverIcon(false);
							}
						}
					}
				})
				.catch((error) => {
					console.log("Error :", error)
				})
		}

	}

	/*goto previous artwork code here end*/
	/*---------------------------------------- */

	//const defaultActiveTabKey = sessionStorage.getItem("defaultActiveTabKey")
	let tabKey = "1"

	// if(defaultActiveTabKey == "1"){
	// 	tabValue = "1"
	// }
	// else{
	// 	tabValue = "2"
	// }
	useEffect(() => {
		handledefaultActiveTabKey(2)
	}, [])


	const handledefaultActiveTabKey = (tabValue) => {
		if (tabValue == "1") {
			// sessionStorage.setItem("defaultActiveTabKey", "2")
			dispatch(getTabKey.getTabKeySuccess({
				defaultActiveTabKey: "2"
			}));
		}
		else {
			// sessionStorage.setItem("defaultActiveTabKey", "1")
			dispatch(getTabKey.getTabKeySuccess({
				defaultActiveTabKey: "1"
			}));
		}
	}
	return (
		<>

			<div id="box2"></div>

			{!isFbAuthenticate &&
				<div style={{ width: '100%' }}>
					<HomePageContent />
				</div>
			}



			{isFbAuthenticate &&
				<>
					<div className='home-page-height' style={
						Object.assign({}
							, {
								marginBottom: 0,
								flexGrow: 1,
								backgroundColor: '#fff'
							}
						)}
					>
						{/* Artwork Details Modal Start */}
						<Modal
							className='custom-modal'
							visible={artworkModalVisible}
							// onOk={handleOk}
							//confirmLoading={confirmLoading}
							onCancel={handleModalCancel}
							// width={1250}
							// bodyStyle={{ height: 720 }}
							footer={null}
						>

							{modalArtWorkId && <ArtWorkDetailsModal is_disable_cart={foundCart} work_id={modalArtWorkId} />}
						</Modal>
						{/* Artwork Details Modal Start */}

						{/* Filter Modal Start */}
						<Modal
							visible={visibleFilter}
							onCancel={handleFilterCancel}
							width={650}
							footer={null}
							title="Filter"
						>

							<Row>
								<Form style={{ marginTop: 10 }}>
									<Form.Item>
										<Form.Item
											name="min_price"
											label="Min Price"
											style={{ display: 'inline-block', width: 'calc(20% - 8px)' }}
										>
											<Input placeholder="Min Price" style={{ height: '38px' }} name="min_price" onChange={(e) => { setMinPrice(e.target.value) }} />
										</Form.Item>
										<Form.Item
											style={{ display: 'inline-block', width: 'calc(5% - 8px)' }}
										></Form.Item>
										<Form.Item
											name="max_price"
											label="Max Price"
											style={{ display: 'inline-block', width: 'calc(20% - 8px)', margin: '0 8px' }}
										>
											<Input placeholder="Max Price" style={{ height: '38px' }} name="max_price" onChange={(e) => { setMaxPrice(e.target.value) }} />
										</Form.Item>
										<Form.Item
											style={{ display: 'inline-block', width: 'calc(5% - 8px)' }}
										></Form.Item><br />

										<Form.Item
											name="min_width"
											label="Min Width"
											style={{ display: 'inline-block', width: 'calc(20% - 8px)' }}
										>
											<Input placeholder="Min Width" style={{ height: '38px' }} name="min_width" onChange={(e) => { setMinWidth(e.target.value) }} />
										</Form.Item>
										<Form.Item
											style={{ display: 'inline-block', width: 'calc(5% - 8px)' }}
										></Form.Item>
										<Form.Item
											name="max_width"
											label="Max Width"
											style={{ display: 'inline-block', width: 'calc(20% - 8px)', margin: '0 8px' }}
										>
											<Input placeholder="Max Width" style={{ height: '38px' }} name="max_width" onChange={(e) => { setMaxWidth(e.target.value) }} />
										</Form.Item>
										<Form.Item
											style={{ display: 'inline-block', width: 'calc(5% - 8px)' }}
										></Form.Item>

										<Form.Item
											name="min_height"
											label="Min Height"
											style={{ display: 'inline-block', width: 'calc(20% - 8px)' }}
										>
											<Input placeholder="Min Height" style={{ height: '38px' }} name="min_height" onChange={(e) => { setMinHeight(e.target.value) }} />
										</Form.Item>
										<Form.Item
											style={{ display: 'inline-block', width: 'calc(5% - 8px)' }}
										></Form.Item>
										<Form.Item
											name="max_height"
											label="Max Height"
											style={{ display: 'inline-block', width: 'calc(20% - 8px)', margin: '0 8px' }}
										>
											<Input placeholder="Max Height" name="max_height" style={{ height: '38px' }} value={maxHeight} onChange={(e) => { setMaxHeight(e.target.value) }} />
										</Form.Item>
										<Form.Item
											style={{ display: 'inline-block', width: 'calc(5% - 8px)' }}
										></Form.Item>


										{/* <Form.Item
											name="categories"
											label="Categories"
											style={{ display: 'inline-block', width: 'calc(73% - 8px)' }}
										>
											<Select name="categories" mode="multiple" placeholder="Select from dropdown" onChange={(value) => handleInputs(value, 'categories')}>
												{
													Categories.map((category) =>
														<Option key={category.value} value={category.value} >{category.label}</Option>
													)
												}
											</Select>

										</Form.Item> */}

										<Form.Item
											name="color"
											label="Color"
											style={{ display: 'inline-block', width: 'calc(92% - 8px)' }}
										>
											<Select name="color" mode="multiple" placeholder="Select from dropdown" onChange={(value) => handleInputs(value, 'color')}>
												{
													Color_Taste.map((color) =>
														<Option key={color.value} value={color.value} >{color.label}</Option>
													)
												}
											</Select>

										</Form.Item>

										<Form.Item>

											<Button type="dark" size='small' onClick={handlePriceFilter}>
												Submit
											</Button>
											{' '}
											<Button type="dark" size='small' onClick={handleClearFilter}>
												Clear
											</Button>
										</Form.Item>
									</Form.Item>
								</Form>

							</Row>

						</Modal>
						{/* Filter Modal End */}

						{/* Proof Modal Start */}
						<Modal
							visible={isProofModalVisible}
							onCancel={handleProofCancel}
							width={350}
							footer={null}
							title="Proof"
						>

							<Row>
								{
									isProofPassword &&
									<Alert message="Please enter correct password" type="error" showIcon closable afterClose={() => { handleAlertClose() }} />
								}
								<Form >
									<Form.Item>


										<Form.Item
											name="proof_password"
											label="Password"
											style={{ display: 'inline-block' }}
										>
											<Input.Password placeholder="Enter Password" style={{ height: '38px' }} name="password" onChange={(e) => { setShowProof(e.target.value) }} />
										</Form.Item>



										<Form.Item>

											<Button type="dark" size='small' onClick={() => { handleProof() }}>
												Submit
											</Button>
											{' '}
											<Button type="dark" size='small' onClick={handleProofCancel}>
												Close
											</Button>
										</Form.Item>
									</Form.Item>
								</Form>

							</Row>

						</Modal>
						{/* Proof Modal End */}
						{/*Modal for Artist Signup welcome message */}
						<Modal
							type={state.modalType}
							visible={getSessionData &&
								getSessionData.role === 'Artist'}
							footer={null}
							title="Welcome to Stoodio!!!!"
							onCancel={goToAddArtworks}
						>
							<p style={{ padding: 5, fontFamily: 'Poppins, sans-serif', marginTop: 5 }} dangerouslySetInnerHTML={{ __html: artistWelcomeSignupMessageSnippetData != "" ? artistWelcomeSignupMessageSnippetData.astistWelcomeSignupMessageContent : "" }} />
							{/* <p style={{ padding: 5, fontFamily: 'Poppins, sans-serif', marginTop: 5 }}>
								Please take the time to add artworks you would like to list on STOODIO. We recommend you enter at least 5 works. You will be notified and paid as soon as one of your artworks is sold.
								<br/>
								Stoodio wants to create a taste profile for you, so please take the taste quiz when directed. Thanks, it will help us give you a better experience.
							</p> */}
							<Row>

								<Button type="primary" onClick={goToAddArtworks} style={{ marginTop: 10 }}>
									Add Artworks
								</Button>
								{/* <Button type="primary" onClick={showTasteQuizModal} style={{ marginLeft: 15, marginTop: 10 }}>
									Skip For Now
								</Button> */}
							</Row>
						</Modal>

						<div style={
							Object.assign({}
								, {
									marginTop: 0,
									height: '100%',
								}
							)}
						>
							{
								isSignUpModalVisible
									?
									<div className="site-card-wrapper">
										<Row gutter={16}>
											<Col xs={1} sm={1} md={6} lg={6}>
											</Col>
											<Col xs={22} sm={22} md={12} lg={12}>
												{
													getSessionData &&
													getSessionData.role === 'Collector' &&
													<Card title="Welcome to Stoodio!" bordered={false}>
														{
															getSessionData &&
															getSessionData.role === 'Collector' &&
															<p dangerouslySetInnerHTML={{ __html: collectorWelcomeSignupMessageSnippetData != "" ? collectorWelcomeSignupMessageSnippetData.collectorWelcomeSignupMessageContent : "" }} />
															// <p>Welcome! Stoodio is here to help you discover artworks by leading emerging artists that are right for you.
															// 	Our aim is to offer you, a collector and an art lover, a reliable and effective way of discovering and
															// 	purchasing artwork without the need to navigate the gallery world.</p>
														}
														{
															getSessionData &&
															getSessionData.role === 'Collector' &&
															<Row>

																<Button type="primary" onClick={showTasteQuizModal} style={{ marginTop: 10 }}>
																	Take The Taste Test
																</Button>
																{/* <Button type="primary" onClick={handleSignUpCancel} style={{ marginLeft: 15, marginTop: 10 }}>
																	Skip For Now
																</Button> */}
															</Row>

														}
														{/* {getSessionData &&
														getSessionData.role === 'Artist' &&
														<p>After signing up, you will be able to create your artist’s profile and upload artworks you wish to sell.
															You will be notified and paid as soon as one of your artworks is sold.</p>
													} */}


													</Card>
												}

											</Col>
											<Col xs={1} sm={1} md={6} lg={6}>
											</Col>
										</Row>
									</div>

									:

									tasteQuizVisibleAndInvisible == 'true'
										?
										<TasteQuiz />
										:
										isTasteQuizVisible
											?
											<TasteQuiz />
											:

											filter_status === true
												?
												filterdata.length === 0 ?
													<>


														{/* <div className='test' style={
															Object.assign({}
																, {
																	height: '100%',
																}
															)}
														> */}

														{/* <div className='no-works-image' style={{ backgroundImage: `url(${filter_image})`, width: '100%' }}>
																<h4 style={{ textAlign: 'center' }}>

																	<img src={Filter} alt="Filter Icon" style={{ height: 35, width: 'auto' }} onClick={() => { showFilterModal() }} />
																</h4>
															</div> */}
														<div className='gallery-img-container' style={{ display: 'grid' }}>
															<Row>
																<Col xs={24} sm={24} md={24} lg={24} style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}>
																	<img src={Filter} alt="Filter Icon" style={{ height: 35, width: 'auto' }} onClick={() => { showFilterModal() }} />
																</Col>
															</Row>
															<br />
															<div className="image2" style={{ width: '70%', display: 'flex' }}>
																<img
																	src={filter_image}
																	alt="Filter" className="taste_quiz_slider_images fade-in-image" style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '-5px', WebkitFlexShrink: 0, maxWidth: '90%', maxHeight: '100%', flexShrink: 0, objectFit: 'cover' }} />
															</div>

														</div>
														{/* </div> */}

													</>
													:

													filterdata.length > 0 &&
													<>

														<div className='test' style={
															Object.assign({}
																, {
																	height: 'calc(100% - 72px)',
																}
															)}
														>

															<>
																<Slider ref={(c) => (slider.current = c)} {...settings}
																	lazyLoad='progressive'
																>

																	{

																		filterdata.length > 0 && filterdata.map((data, Index) => {
																			//console.log("data", data)

																			if (Index == artWorkIndex) {
																				if (data.image_urls) {

																					const imagesURLs = JSON.parse(data.image_urls);


																					var orientation

																					if (data.width > data.height) {
																						orientation = "horizontal"
																					} else if (data.width == data.height) {
																						orientation = "square"
																					} else {
																						orientation = "vertical"
																					}

																					const { appliedCategoriesAttributes } = ContentCategoryData(data.categories)

																					const { appliedMaterialsXAttributes } = MaterialOneCategoryData(data.materials1)

																					const { appliedMaterialsYAttributes } = MaterialTwoCategoryData(data.materials2)

																					if (imagesURLs.length > 0) {
																						const imagesURL = imagesURLs[0].url;
																						//let imagesURL=imagesURLs[0].url;
																						if (imagesURL != 'https://stoodio-dev-images.s3.amazonaws.com/Images/EmptyQueue.png') {
																							//base64Image(imagesURLs[0].url)
																							//imagesURL = base64;
																						}
																						const flagImageLoaded = state.flagImageLoaded;

																						return (
																							<>
																								<div key={data.id} style={
																									Object.assign({}
																										, {
																										}
																									)}
																								>
																									{
																										imagesURL &&
																										<>
																											<div /*className='slider-banner-width' style={{ marginLeft: 'auto', marginRight: 'auto' }}*/ >




																												<div>

																													<div className="gallery-slider-banner" style={data.id == 0 ? { marginTop: 20, marginBottom: 20 } : { marginTop: 0, marginBottom: 0 }}>


																														<Row style={{ marginTop: 10 }}>
																															<Col span={8} /*span={data.id == 0 ? 12 : 8} */>
																																{/* <button onClick={previous} id='test1' class="slick-prev pull-left" style={{ marginTop: '-5px', marginLeft: 25 }}> Prev Button </button> */}
																																<span onClick={() => { handleGoBack(data.id) }} className='mobile-arrows' style={{ marginLeft: 15, marginTop: 10 }}>
																																	{
																																		data.id == 0 ? "" :
																																			<FontAwesomeIcon icon={faAngleLeft} size="2x" style={{ pointerEvents: artWorkIndex == 0 ? "none" : "", color: artWorkIndex == 0 ? "white" : "black", marginRight: 10 }} />
																																	}
																																</span>
																															</Col>
																															<Col span={8} /*span={data.id == 0 ? 0 : 8}*/>


																																<Space style={{ justifyContent: 'center', width: "100%" }}>
																																	<table cellPadding='5'>
																																		<thead>
																																			<tr>
																																				<td>{
																																					(newLikeArrayData.map((item) => {
																																						if (!flagImageLoaded) {
																																							return null;
																																						}
																																						if (data.id === item.id) {
																																							if (item.like === 1) {
																																								return (
																																									<>
																																										<Space style={{ justifyContent: 'center', width: '100%' }}>
																																											<FeatherIcon icon="thumbs-up" style={item.like === 1 && likeStyle} onClick={() => { handleLike(data.id, data.color_taste, data.categories, data.materials1, data.materials2, data.formal_categories_json_data, data.sold, Index) }} />
																																										</Space>


																																									</>
																																								)
																																							} else {
																																								return (
																																									<>
																																										<Space style={{ justifyContent: 'center', width: '100%' }}>
																																											<FeatherIcon style={defaultStyle} icon="thumbs-up" onClick={() => { handleLike(data.id, data.color_taste, data.categories, data.materials1, data.materials2, data.formal_categories_json_data, data.sold, Index) }} />
																																										</Space>
																																									</>
																																								)
																																							}
																																						}
																																					}))
																																				}
																																				</td>
																																				<td>
																																					{
																																						(newLikeArrayData.map((item) => {
																																							if (!flagImageLoaded) {
																																								return null;
																																							}
																																							if (data.id === item.id) {
																																								if (item.like === -1) {
																																									return (
																																										<>
																																											<Space style={{ justifyContent: 'center', width: '100%' }}>
																																												<FeatherIcon icon="thumbs-down" style={item.like === -1 && disLikeStyle} onClick={() => { handleDisLike(data.id, data.color_taste, data.categories, data.materials1, data.materials2, data.formal_categories_json_data, data.sold, Index) }} />
																																											</Space>
																																										</>
																																									)
																																								} else {
																																									return (
																																										<>
																																											<Space style={{ justifyContent: 'center', width: '100%' }}>
																																												<FeatherIcon style={defaultStyle} icon="thumbs-down" onClick={() => { handleDisLike(data.id, data.color_taste, data.categories, data.materials1, data.materials2, data.formal_categories_json_data, data.sold, Index) }} />
																																											</Space>
																																										</>
																																									)
																																								}
																																							}


																																						}))
																																					}
																																				</td>
																																				<td style={{ paddingLeft: 15 }}>
																																					{
																																						data.id == 0 || !flagImageLoaded ? "" :
																																							<Badge status="success" style={{ height: 12, width: 12 }}>
																																								<img src={Filter}
																																									alt="Filter Icon"
																																									style={{ height: 35, width: 'auto', marginTop: 7 }}
																																									onClick={() => { showFilterModal() }} />
																																							</Badge>

																																						// <img src={Filter} alt="Filter Icon" style={{ height: 35, width: 'auto', marginRight: 10, marginTop: 7 }} onClick={() => { showFilterModal() }} />
																																					}

																																				</td>
																																				{/* <td>{
																																								(newLikeArrayData.map((item) => {
																																									if (!flagImageLoaded) {
																																										return null;
																																									}
																																									if (data.id === item.id) {
																																										if (item.like === 0) {
																																											return (
																																												<>
																																													<div>
																																														<p style={item.like === 0 && skipStyle}
																																															onClick={() => { handleskip(data.id, data.color_taste, data.categories, data.materials1, data.materials2, data.formal_categories_json_data, data.sold, Index) }}
																																														>
																																															SKIP
																																														</p>
																																													</div>
																																												</>
																																											)
																																										} else {
																																											return (
																																												<>
																																													<div>
																																														<p style={skipDefaultStyle}
																																															onClick={() => { handleskip(data.id, data.color_taste, data.categories, data.materials1, data.materials2, data.formal_categories_json_data, data.sold, Index) }}
																																														>
																																															SKIP
																																														</p>
																																													</div>
																																												</>
																																											)
																																										}
																																									}
																																								}))
																																							}</td> */}
																																			</tr>
																																		</thead>

																																	</table>

																																</Space>

																															</Col>
																															<Col span={8} /*span={data.id == 0 ? 12 : 8} */>

																																<Space style={orientation == "horizontal"
																																	?
																																	{ width: '100%', justifyContent: 'flex-end' }
																																	:
																																	orientation == "square"
																																		?
																																		{ width: '100%', justifyContent: 'flex-end' }
																																		:
																																		{ width: '100%', justifyContent: 'flex-end' }}>
																																	{/* <button onClick={next} id='test2' class="slick-next pull-right" style={{ marginTop: '-5px', marginRight: 43 }}> Next Button </button> */}
																																	{/* <img src={Filter} alt="Filter Icon" style={{ height: 35, width: 'auto',marginRight:10,marginTop:7 }} onClick={() => { showFilterModal() }} /> */}
																																	<span onClick={() => { handleNext(data.id) }} className='mobile-arrows' style={{ marginRight: 15, marginTop: 10 }}>
																																		{
																																			data.id == 0 ? "" :
																																				<FontAwesomeIcon icon={faAngleRight} size="2x" style={{ pointerEvents: unRatedSortingData.length - 1 == artWorkIndex + 1 ? "none" : "", color: unRatedSortingData.length - 1 == artWorkIndex + 1 ? "white" : "black" }} />
																																		}
																																	</span>
																																</Space>

																															</Col>
																														</Row>

																													</div>
																													{/* {
																																			data.id == 0 || !flagImageLoaded ? "" :
																																				<img className={`filter-icon-hide ${orientation == 'vertical' ? "adjust-filter-for-virtcle" : ""}`} src={Filter} alt="Filter Icon" style={{ marginLeft: orientation == 'vertical' ? '-90px' : orientation == 'square' ? '-40px' : '-10px', height: 35, width: 'auto', marginBottom: 10, marginTop: 7 }} onClick={() => { showFilterModal() }} />

																																		} */}
																													<div className='gallery-img-container' style={{ display: 'grid' }}>
																														<Row style={{ marginLeft: 10, marginRight: 10, marginTop: 10, marginBottom: 8 }}>
																															<Col xs={0} sm={0} md={1} lg={1}>

																															</Col>
																															<Col xs={24} sm={24} md={22} lg={22} >
																																<Row
																																	className='taste_quiz_slider_images'
																																	style={
																																		// orientation == "horizontal"
																																		// 	?
																																		// 	{ width: '95%', marginLeft: "auto", marginRight: "auto" }
																																		// 	:
																																		// 	orientation == "square"
																																		// 		?
																																		// 		{ width: '80%', marginLeft: "auto", marginRight: "auto" }
																																		// 		:
																																		// 		{ width: '70%', marginLeft: "auto", marginRight: "auto" }
																																		{ width: '95%', marginLeft: "auto", marginRight: "auto" }

																																	}
																																>


																																	<Col md={24} xs={24} sm={24} lg={24}>
																																		{
																																			data.id == 0 || !flagImageLoaded ? "" :
																																				<span className='filter-icon-hide1'>

																																					<Badge status="success" style={{ height: 12, width: 12 }}>
																																						<img src={Filter}
																																							alt="Filter Icon"
																																							style={{ height: 35, width: 'auto', marginTop: 7 }}
																																							onClick={() => { showFilterModal() }} />
																																					</Badge>

																																					{/* <img src={Filter} alt="Filter Icon" style={{ height: 35, width: 'auto', marginTop: 10, float: 'right' }} onClick={() => { showFilterModal() }} /> */}
																																				</span>

																																		}
																																	</Col>
																																</Row>
																															</Col>
																															<Col xs={0} sm={0} md={1} lg={1}>

																															</Col>
																														</Row>

																														<Row>
																															<Col xs={0} sm={0} md={1} lg={1}>

																															</Col>
																															<Col xs={24} sm={24} md={22} lg={22}>
																																{/* {
																																			data.id == 0 || !flagImageLoaded ? "" :
																																				<div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 12, marginBottom: '-10px' }}>
																																					<span className='filter-icon-hide1'>

																																						<img src={Filter} alt="Filter Icon" style={{ height: 35, width: 'auto' }} onClick={() => { showFilterModal() }} />
																																					</span>
																																				</div>
																																		} */}


																																<>
																																	<div style={{ display: flagImageLoaded ? 'none' : 'flex', alignItems: 'center', justifyContent: 'center', margin: 10 }}>
																																		<i className="fa fa-spinner fa-spin" style={{ fontSize: 48, color: '#00CDFF' }} />
																																	</div>
																																	<div style={{ display: flagImageLoaded ? "flex" : 'none', alignItems: 'center', justifyContent: 'center', margin: 10 }}>

																																		<span onClick={() => { handleGoBack(data.id) }} className='desktop-arrows'>
																																			{/* {
																																						data.id == 0 ? "" : */}
																																			<FontAwesomeIcon icon={faAngleLeft} size="2x" style={{ pointerEvents: artWorkIndex == 0 ? "none" : "", color: artWorkIndex == 0 ? "white" : "black", marginRight: 10 }} />
																																			{/* } */}
																																		</span>

																																		<div className={data.sold === '1' ? 'product' : ''} >
																																			<div className={data.sold === '1' ? 'ribbon-wrapper' : ''}>
																																				<div className={data.sold === '1' ? 'ribbon' : ''} >
																																					{data.sold === '1' ? 'SOLD' : ''}
																																				</div>
																																			</div>


																																			<div className="image2" style={{ width: '70%' }}>
																																				<img
																																					src={imagesURL}
																																					onLoad={() => {
																																						setState({
																																							flagImageLoaded: true,
																																						});
																																					}}
																																					onLoadStart={() => {
																																						setState({
																																							flagImageLoaded: false,
																																						});
																																					}}
																																					alt="image" className="taste_quiz_slider_images fade-in-image" style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '-5px', WebkitFlexShrink: 0, maxWidth: '90%', maxHeight: '70%', flexShrink: 0, objectFit: 'cover' }} />
																																			</div>

																																			{/* <div class="container">

																																									
																																									<div class="sub-container">
																																									<img src={imagesURL}
																																									onLoad={() => {
																																										setState({
																																											flagImageLoaded: true,
																																										});
																																									}}
																																									onLoadStart={() => {
																																										setState({
																																											flagImageLoaded: false,
																																										});
																																									}}
																																									style={{width:'100%',height:'100%',objectFit:'cover'}} 
																																									/>
																																									</div>
																																									
																																									</div> */}

																																			{/* <img
																																						src={imagesURL}
																																						onLoad={() => {
																																							setState({
																																								flagImageLoaded: true,
																																							});
																																						}}
																																						onLoadStart={() => {
																																							setState({
																																								flagImageLoaded: false,
																																							});
																																						}}
																																						alt="image"
																																						className='taste_quiz_slider_images fade-in-image'
																																						onClick={() => { showImageModal(), imageDetailsGet(data.id) }}
																																						style={
																																							data.id == 0 ?
																																								{ marginLeft: 'auto', marginRight: 'auto', marginBottom: 80, marginTop: -5, width: '70%' }
																																								:
																																								{ width: '100%', marginLeft: "auto", marginRight: "auto", marginTop: -5 }

																																						}
																																					/> */}
																																		</div>
																																		<span onClick={() => { handleNext(data.id) }} className='desktop-arrows'>
																																			{
																																				data.id == 0 ? "" :
																																					// <FontAwesomeIcon icon={faAngleRight} size="2x" style={{ pointerEvents: unRatedSortingData.length - 1 == artWorkIndex + 1 ? "none" : "", color: unRatedSortingData.length - 1 == artWorkIndex + 1 ? "white" : "black", marginLeft: 10 }} />
																																					<FontAwesomeIcon icon={faAngleRight} size="2x" style={{ pointerEvents: unRatedSortingData.length - 1 == artWorkIndex + 1 ? "none" : "", marginLeft: 10 }} />

																																			}
																																		</span>
																																	</div>




																																</>

																															</Col>
																															<Col xs={0} sm={0} md={1} lg={1} style={{ display: 'flex', alignItems: 'flex-end' }}>


																																{/* {
																																			data.id == 0 || !flagImageLoaded ? "" :
																																				<img className={`filter-icon-hide ${orientation == 'vertical' ? "adjust-filter-for-virtcle" : ""}`} src={Filter} alt="Filter Icon" style={{ marginLeft: orientation == 'vertical' ? '-90px' : orientation == 'square' ? '-40px' : '-10px', height: 35, width: 'auto', marginBottom: 10, marginTop: 7 }} onClick={() => { showFilterModal() }} />

																																		} */}


																															</Col>
																														</Row>



																														<Row style={{ marginLeft: 10, marginRight: 10 }}>

																															<Col xs={0} sm={0} md={1} lg={1}>

																															</Col>
																															<Col style={{ marginTop: -10 }} xs={24} sm={24} md={22} lg={22} >


																																<br />
																																{
																																	data.id == 0 || !flagImageLoaded ? ""
																																		:

																																		// <Row>
																																		<Row
																																			className='taste_quiz_slider_images'
																																			style={
																																				// orientation == "horizontal"
																																				// 	?
																																				// 	{ width: '95%', marginLeft: "auto", marginRight: "auto" }
																																				// 	:
																																				// 	orientation == "square"
																																				// 		?
																																				// 		{ width: '80%', marginLeft: "auto", marginRight: "auto" }
																																				// 		:
																																				// 		{ width: '70%', marginLeft: "auto", marginRight: "auto" }
																																				{ width: '95%', marginLeft: "auto", marginRight: "auto" }
																																			}
																																		>


																																			<Col md={8} xs={12} sm={12}>

																																				<h2 className='work-title' style={{
																																					fontWeight: modalStyle.fontWeight,
																																					fontFamily: modalStyle.heading_font_style,
																																					// marginBottom: '-1px',
																																					cursor: 'pointer',
																																					color: '#000',
																																					fontSize: 14,
																																					marginBottom: 10
																																				}}>
																																					{data.name ? data.name : ''}
																																				</h2>

																																				<h2 className='work-title' style={{
																																					// fontWeight: modalStyle.fontWeight,
																																					fontFamily: modalStyle.heading_font_style,
																																					// marginBottom: '-1px',
																																					cursor: 'pointer',
																																					color: '#000',
																																					fontSize: 12,
																																					marginBottom: 2
																																				}} onClick={() => { showImageModal(gallery_index), imageDetailsGet(data.id, data.user_id) }}>
																																					<i>{data.work_title}</i>&nbsp;&nbsp;({data.year_created})

																																				</h2>

																																				<div>
																																					<p>
																																						<span className='material-info' >
																																							{appliedMaterialsXAttributes && <span style={{ fontFamily: modalStyle.p_tag_font_style, fontSize: 12, color: 'black' }}><p>{appliedMaterialsXAttributes.join(', ')}</p></span>}
																																							<p style={{ fontFamily: modalStyle.p_tag_font_style, fontSize: 12, color: 'black' }}>&nbsp; on &nbsp;</p>
																																							{appliedMaterialsYAttributes && <span style={{ fontFamily: modalStyle.p_tag_font_style, fontSize: 12, color: 'black', textTransform: 'lowercase' }}><p>{appliedMaterialsYAttributes.join(', ')}</p></span>}
																																							<br />
																																							<span className='work-price' style={{ color: 'black', fontSize: 15 }}>
																																								<p>${parseFloat(data.price).toFixed(2)}</p>
																																							</span>

																																						</span>
																																					</p>
																																				</div>
																																			</Col>

																																			<Col md={8} xs={0} sm={0}>
																																				<div className="slider-banner1">
																																					{
																																						(newLikeArrayData.map((item) => {
																																							if (data.id === item.id) {
																																								if (item.like === 1) {
																																									return (
																																										<>
																																											<Space style={{ justifyContent: 'center', width: '100%' }}>
																																												<table cellPadding="8">
																																													<tbody>
																																														<tr>
																																															<td>
																																																<FeatherIcon icon="thumbs-up" style={item.like === 1 ? likeStyle : defaultStyle} onClick={() => { handleLike(data.id, data.color_taste, data.categories, data.materials1, data.materials2, data.formal_categories_json_data, data.sold, Index) }} />
																																															</td>
																																															<td>
																																																<FeatherIcon icon="thumbs-down" style={item.like === -1 ? disLikeStyle : defaultStyle} onClick={() => { handleDisLike(data.id, data.color_taste, data.categories, data.materials1, data.materials2, data.formal_categories_json_data, data.sold, Index) }} />
																																															</td>
																																															{/* <td>
																																																		<div>
																																																			<p style={item.like === 0 ? skipStyle : skipDefaultStyle}
																																																				onClick={() => { handleskip(data.id, data.color_taste, data.categories, data.materials1, data.materials2, data.formal_categories_json_data, data.sold, Index) }}
																																																			>
																																																				SKIP
																																																			</p>
																																																		</div>
																																																	</td> */}
																																														</tr>
																																													</tbody>
																																												</table>
																																											</Space>


																																										</>
																																									)
																																								} else if (item.like === -1) {
																																									return (
																																										<>
																																											<Space style={{ justifyContent: 'center', width: '100%' }}>
																																												<table cellPadding="8">
																																													<tbody>
																																														<tr>
																																															<td>
																																																<FeatherIcon icon="thumbs-up" style={item.like === 1 ? likeStyle : defaultStyle} onClick={() => { handleLike(data.id, data.color_taste, data.categories, data.materials1, data.materials2, data.formal_categories_json_data, data.sold, Index) }} />
																																															</td>
																																															<td>
																																																<FeatherIcon icon="thumbs-down" style={item.like === -1 ? disLikeStyle : defaultStyle} onClick={() => { handleDisLike(data.id, data.color_taste, data.categories, data.materials1, data.materials2, data.formal_categories_json_data, data.sold, Index) }} />
																																															</td>

																																														</tr>
																																													</tbody>
																																												</table>
																																											</Space>
																																										</>
																																									)
																																								} else if (item.like === 0) {
																																									return (
																																										<>
																																											<Space style={{ justifyContent: 'center', width: '100%' }}>
																																												<table cellPadding="8">
																																													<tbody>
																																														<tr>
																																															<td>
																																																<FeatherIcon icon="thumbs-up" style={item.like === 1 ? likeStyle : defaultStyle} onClick={() => { handleLike(data.id, data.color_taste, data.categories, data.materials1, data.materials2, data.formal_categories_json_data, data.sold, Index) }} />

																																															</td>
																																															<td>
																																																<FeatherIcon icon="thumbs-down" style={item.like === -1 ? disLikeStyle : defaultStyle} onClick={() => { handleDisLike(data.id, data.color_taste, data.categories, data.materials1, data.materials2, data.formal_categories_json_data, data.sold, Index) }} />

																																															</td>
																																															{/* <td>
																																																		<div>
																																																			<p style={item.like === 0 ? skipStyle : skipDefaultStyle}
																																																				onClick={() => { handleskip(data.id, data.color_taste, data.categories, data.materials1, data.materials2, data.formal_categories_json_data, data.sold, Index) }}
																																																			>
																																																				SKIP
																																																			</p>
																																																		</div>
																																																	</td> */}
																																														</tr>
																																													</tbody>
																																												</table>
																																											</Space>
																																										</>
																																									)
																																								} else {


																																									return (
																																										<>
																																											<Space style={{ justifyContent: 'center', width: '100%' }}>
																																												<table cellPadding="8">
																																													<tbody>
																																														<tr>
																																															<td>
																																																<FeatherIcon icon="thumbs-up" onMouseEnter={() => { setLikeBgColour("#ddedf3"), setLikeRatingColour('black') }} onMouseLeave={() => { setLikeBgColour("white"), setLikeRatingColour('black') }} style={item.like === 1 ? likeStyle : defaultStyle, appLikeStyles} onClick={() => { handleLike(data.id, data.color_taste, data.categories, data.materials1, data.materials2, data.formal_categories_json_data, data.sold, Index) }} />
																																															</td>

																																															<td>
																																																<FeatherIcon icon="thumbs-down" onMouseEnter={() => { setDislikeBgColour("rgb(92, 223, 255) "), setDislikeRatingColour('black') }} onMouseLeave={() => { setDislikeBgColour("white"), setDislikeRatingColour('black') }} style={item.like === -1 ? disLikeStyle : defaultStyle, appDislikeStyles} onClick={() => { handleDisLike(data.id, data.color_taste, data.categories, data.materials1, data.materials2, data.formal_categories_json_data, data.sold, Index) }} />
																																															</td>

																																															{/* <td>
																																																		<div>
																																																			<p onMouseEnter={() => { setSkipratingColour("1000") }}
																																																				onMouseLeave={() => { setSkipratingColour("100") }}
																																																				style={item.like === 0 ? skipStyle : skipDefaultStyle, appSkipStyles}
																																																				onClick={() => { handleskip(data.id, data.color_taste, data.categories, data.materials1, data.materials2, data.formal_categories_json_data, data.sold, Index) }}
																																																			>
																																																				SKIP
																																																			</p>
																																																		</div>
																																																	</td> */}
																																														</tr>
																																													</tbody>
																																												</table>
																																											</Space>
																																										</>
																																									)
																																								}
																																							}
																																						}))
																																					}
																																				</div>

																																			</Col>



																																			<Col md={8} xs={12} sm={12}>

																																				<Space style={{ justifyContent: 'flex-end', width: "100%", whiteSpace: 'nowrap' }}>
																																					{/* <img src={Filter} alt="Filter Icon" style={{ height: 35, width: 'auto' }} onClick={() => { showFilterModal() }} /> */}
																																					<Tooltip placement="bottomRight" title="more info">
																																						<InfoCircleOutlined onClick={() => { showImageModal(gallery_index), imageDetailsGet(data.id, data.user_id) }} style={{ fontSize: 35 }} />
																																					</Tooltip>
																																					{/* <NavLink to="#"  onClick={() => { showHelpModal() }}><QuestionCircleOutlined  style={{ color: '#000',fontSize: 35 }} /></NavLink> */}

																																					{/* <Button type="primary" style={{ padding: 15 }} onClick={() => { showImageModal(), imageDetailsGet(data.id) }}>LEARN MORE</Button> */}
																																				</Space>
																																				{/* <Space style={{ justifyContent: 'flex-end', width: "100%" }}>
																																							<Switch checkedChildren="Rated" unCheckedChildren="Unrated" onClick={() => { setRatedToggle(!ratedToggle) }} />


																																						</Space> */}

																																			</Col>


																																		</Row>
																																}

																																{
																																	data.id == 0 || !flagImageLoaded ?
																																		""
																																		:


																																		<Row className='taste_quiz_slider_images'
																																			style={{ width: '95%', marginLeft: "auto", marginRight: "auto" }}
																																		>
																																			<Col md={24} xs={24} sm={24}>
																																				<>
																																					{/* <Button type="primary" onClick={handleClickAffinity} style={{ padding: 15, marginBottom: 20 }}>Go Back</Button> {' '} */}
																																					{UID === process.env.REACT_APP_ADMIN_UID && (
																																						<Button type="primary" style={{ padding: 15, marginBottom: 20 }} onClick={() => { handleClickAffinity(), showProofModal() }}>Proof</Button>)}
																																				</>
																																			</Col>
																																		</Row>
																																}
																															</Col>
																															<Col xs={0} sm={0} md={1} lg={1}></Col>
																														</Row>
																													</div>
																												</div>
																											</div>
																										</>

																									}

																								</div>
																							</>
																						)
																					}
																				}
																			}



																		})


																	}



																</Slider>



															</>

														</div>



													</>

												:

												<>
													{/* <LoginGallery /> */}
												</>

							}

						</div>
					</div>


				</>

			}

		</>
	);
};


export default Home;