import React from 'react'

const MaterialTasteProfile = (previousMaterialsTasteProfile,previousMaterialsTasteProfileLenght,newMaterialsTasteProfile) => {
    var newMaterialsTasteProfileGenerates = []
    for (var a = 0; a < previousMaterialsTasteProfileLenght; a++) {

        var oldname = previousMaterialsTasteProfile[a].name
        var newname = newMaterialsTasteProfile[a].name

        if (oldname == newname) {
            newMaterialsTasteProfileGenerates.push({
                id: newMaterialsTasteProfile[a].id,
                name: newMaterialsTasteProfile[a].name,
                tpScore: previousMaterialsTasteProfile[a].tpScore + newMaterialsTasteProfile[a].tpScore
            })
        }
    }

    return { newMaterialsTasteProfileGenerates }
}

export default MaterialTasteProfile