import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Select, Col, Row, DatePicker, Upload, Avatar, notification, Button, TimePicker } from 'antd';
import propTypes from 'prop-types';
import { Modal } from '../../components/modals/antd-modals';
import { FileImageOutlined } from '@ant-design/icons';
import { BasicFormWrapper } from '../styled';
import config from '../../config/config';
import moment from 'moment';
//import Editor from 'ckeditor5-custom-build/build/ckeditor';
//import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
//import { CKEditor } from '@ckeditor/ckeditor5-react';
import messages from '../../config/constants/messages';
//import Draggable from "react-draggable";
//import JoditReactEditor from '../../profile/settings/overview/JoditReactEditor';
import axios from 'axios';

import UploadCropImage from '../profile/settings/overview/UploadCropImage';
import JoditReactEditor from '../profile/settings/overview/JoditReactEditor';

const { Option } = Select;

const dateFormat = 'YYYY/MM/DD';

const { Dragger } = Upload;
const { TextArea } = Input;

const EditEducation = ({ visible, onCancel, Id, oldImage }) => {

  const dispatch = useDispatch();

  const isFbAuthenticate = useSelector(state => {

    return {
      isFbAuthenticate: state.fb.auth.uid,
    }
  });


  const [form] = Form.useForm();
  const formData = new FormData();
  //const [is_framed, setIsFramed] = useState(false);

  const [state, setState] = useState({
    visible,
    modalType: 'primary',
    checked: [],
    education_name: '',
    education_description: '',
    education_date: '',
    education_time: '',
    education_date_range:'',
    url: '',
    meta_title: '',
    images: [],
    meta_description: '',
    meta_keywords: '',
    status: '',
    isLoading: false
  });


  const [description_data, SetDescription] = useState("")
  const [imageModalVisiable, setImageModalVisiable] = useState(false);
  const [userLogo, setUserLogo] = useState([]);

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      setState({
        visible,
      });
    }
    return () => {
      unmounted = true;
    };
  }, [visible]);

  useEffect(() => {

    sessionStorage.removeItem('tab-index');

    const getAllOrders = async () => {

      const response = await fetch(`${config.apiUrl}/get-all-education/0/${Id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      });

      const data = await response.json();
      
      if (data[0]) {
        setEditData(data[0])
        setUserLogo(JSON.parse(data[0].image_url));
        SetEditorData(data[0].education_description)
      }
      setShowForm(true);
    }
    getAllOrders();

  }, [])


  

  const handleDropdownChange = (value, name) => {
    // return;
    setState({
      ...state,
      [name]: value,
    });
  }

  const handleDropdownChange2 = value => {
    setState({
      ...state,
      unique_copy: value,
    });
  }

  const [preview, setPreview] = useState([]);

  const [images, setImages] = useState([]);
  const [document, setDocument] = useState('');
  const [buttonDisable, setButton] = useState(false);
  const [loading, setLoading] = useState(false);


  const [titlelength, setTitleLength] = useState(0);
  const [descriptionlength, SetDescriptionLength] = useState(0);


  const [ArtistList, setArtistList] = useState([]);
  const [ArtworkList, setArtworkList] = useState([]);
  const [editData, setEditData] = useState(null);
  const [ShowForm, setShowForm] = useState(false);



  const [t1, setT1] = useState(null);
  const [t2, setT2] = useState(null);

  const fileobj = [];

  const handleImageChange = (e) => {
    console.log("e.target.files :", e.target.files);
    if (e.target.files.length) {
      for (var i = 0; i < e.target.files.length; i++) {
        if (!e.target.files[i].name.match(/.(jpg|jpeg|png|bmp)$/i)) {
          alert(messages['invalid_image_format_error'])
          return false;
        }
      }
      setState({
        ...state,
        [e.target.name]: e.target.files,
      });
    } else {
      setState({
        ...state,
        [e.target.name]: [],
      });
    }
  }
  const showModal = () => {
    setImageModalVisiable(true);
  }
  const onProfileCancel = () => {
    setImageModalVisiable(false);
  }



  const handleOk = (values) => {

    var temp_data = removeHtmlTags(description_data);


    if (temp_data == "" || temp_data == undefined) {
      notification.warning({
        message: 'Please input exhibition description'
      });
      return false;
    }


    let post_data = {
      education_id: Id,
      //exhibition_date: state.exhibition_date != null ? moment(state.exhibition_date).format('YYYY-MM-DD') : editData.exhibition_date,
      //exhibition_end_date: state.exhibition_end_date != "" ? moment(state.exhibition_end_date).format('YYYY-MM-DD') : editData.exhibition_end_date,
      //exhibition_time: t1 != null ? t1 : editData.exhibition_time,
      //exhibition_end_time: t2 != null ? t2 : editData.exhibition_end_time,
      ...values,
      old_images: oldImage,
      education_description: description_data
    };
    //alert(JSON.stringify(post_data));
    //return false;


    // console.log(state)
    setButton(true);
    setLoading(true);

    setState({
      ...state,
      isLoading: true,
    });

    if (state.images) {
      for (let i = 0; i < state.images.length; i++) {
        formData.append('file', state.images[i])
      }
    }

    formData.append('data', JSON.stringify(post_data))

    axios.post(`${config.apiUrl}/update-education`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then((response) => {
      if (response.data.affectedRows == 1) {
        alert("The education updated successfully.");
        location.reload();
      } else {
        setButton(false);
        setLoading(false);
        alert("Uanble to create the education");
      }
    })
      .catch((error) => {
        console.log("Error :", error)
      })
  };




  const handleCancel = () => {
    onCancel();
  };

  function removeHtmlTags(str) {

    if (str) {
      str = str && str.replace(/<[^>]*>/g, '');
      return str.replace(/&nbsp;/g, '');
    }
  }

  const SetEditorData = value => {
    const cleanedString = removeHtmlTags(value);
    SetDescriptionLength(cleanedString.length)
    SetDescription(value);
  }

  return (

    <>

      <Modal
        type={state.modalType}
        title="Edit education"
        visible={state.visible}
        width={1000}
        backdrop="static"
        footer={[null]}
        onCancel={handleCancel}
      >
        <div className="project-modal">
          {ShowForm == true &&
            <>
              <BasicFormWrapper>
                <Form id="createWorkForm" form={form} name="createProject" onFinish={handleOk} encType="multipart/form-data">
                  <Row gutter={15}>
                    <Col md={18} className="mb-25">

                      <Form.Item name="education_name" label="Name"
                        rules={[{ required: true, message: 'Please input name' }]}
                        initialValue={editData ? editData.education_name : ''}
                      >
                        <Input.TextArea rows={2} placeholder="" name="education_name" />
                      </Form.Item>

                    </Col>
                    <Col md={6} className="mb-25" style={{ textAlign: 'center' }}>
                      {
                        userLogo ?
                          userLogo.map((data) => {
                            let profileImage = data.url
                            return (
                              <>
                                <UploadCropImage onCancel={() => { onProfileCancel() }} visible={imageModalVisiable} UID={null} OldImage={profileImage} EducationId={Id} Ratio={3 / 2} />
                                <img src={profileImage} alt='user-profile' style={{ width: 120, borderRadius: 8 }} />
                              </>
                            )
                          })
                          :
                          <>
                            <Avatar shape="square" size={120} icon={<FileImageOutlined />} />
                            <UploadCropImage onCancel={() => { onProfileCancel() }} visible={imageModalVisiable} UID={null} OldImage={null} EducationId={Id} Ratio={3 / 2} />
                          </>
                      }
                      <br />
                      <Button size="default" type="primary" style={{ marginTop: 10 }} onClick={showModal}>Change Image</Button>
                    </Col>
                  </Row>

                  <Form.Item name="education_description" label="Description"
                    initialValue={editData ? editData.education_description : ''}
                  >
                    {/* <TextArea rows={3} type='text' name='description' /> */}
                    <JoditReactEditor
                      SetEditorData={SetEditorData}
                      initialValue={editData ? editData.education_description : ''}
                    />
                  </Form.Item>
                  {/* <div style={{ marginTop: '-25px', float: 'right' }} className={5000 < descriptionlength ? 'danger-text' : ''}>{descriptionlength}/5000 char used</div> */}

                  <Form.Item name="education_date_range" label="Date Range"
                    rules={[{ required: true, message: 'Please input  date Range' }]}
                    initialValue={editData ? editData.education_date_range : ''}>
                    <Input placeholder="" name="education_date_range" />
                  </Form.Item>


                  <Form.Item name="url" label="URL"
                    rules={[{ required: true, message: 'Please input url' }]}
                    initialValue={editData ? editData.url : ''}
                  >

                    <Input addonBefore="https://stoodio.art/education/" placeholder="url" name="url" />
                  </Form.Item>

                  <Form.Item name="status" label="Status"
                    rules={[{ required: true, message: 'Please input status' }]}
                    initialValue={Number(JSON.parse(editData.status))}
                  >
                    <Select size="large" className="sDash_fullwidth-select" name="status" placeholder="Select from dropdown"
                      onChange={(value) => handleDropdownChange(value, 'status')}
                    >
                      <Option key={1} value={1}>Active</Option>
                      <Option key={0} value={0}>Inactive</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item name="meta_title" label="Meta title"
                    initialValue={editData ? editData.meta_title : ''}
                  >
                    <TextArea rows={1} type='text' name='meta_title' />
                  </Form.Item>

                  <Form.Item name="meta_description" label="Meta Description"
                    initialValue={editData ? editData.meta_description : ''}
                  >
                    <TextArea rows={2} type='text' name='meta_description'
                    />
                  </Form.Item>

                  <Form.Item name="meta_keywords" label="Meta Keywords"
                    initialValue={editData ? editData.meta_keywords : ''}
                  >
                    <TextArea rows={1} type='text' name='meta_keywords'
                    />
                  </Form.Item>

                  <Form.Item>

                    <Button type="primary" htmlType="submit" disabled={buttonDisable}>
                      Submit {loading && (<i className='fa fa-spinner fa-spin' style={{ fontSize: 18, marginLeft: 5 }} />)}
                    </Button>
                    {' '}
                    <Button size="default" type="white" key="back" outlined onClick={handleCancel}>
                      Cancel
                    </Button>
                  </Form.Item>
                </Form>
              </BasicFormWrapper>
            </>}

        </div>
      </Modal>
    </>
  );
};

EditEducation.propTypes = {
  visible: propTypes.bool.isRequired,
  onCancel: propTypes.func.isRequired,
};

export default EditEducation;
