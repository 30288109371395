import TasteQuizContentJson from '../../../../../config/constants/materials/tastequizcontent.json'

const ContentAverage = (parsedContentArr,userRateValue) => {

    let contentArray = TasteQuizContentJson;

    var appliedContentToArtWork = [];

    for (var i = 0; i < contentArray.length; i++) {
        for (var j = 0; j < parsedContentArr.length; j++) {
            if (contentArray[i].value == parsedContentArr[j]) {
                appliedContentToArtWork.push({
                    name: contentArray[i].label,
                    userRating: userRateValue
                });
            }
        }
    }

    return {appliedContentToArtWork}
}

export default ContentAverage