import { Button, Col, Row, Modal, Tooltip, notification } from 'antd'

import './css/style.css'
import { Link, NavLink, useHistory, useParams } from 'react-router-dom';
import CommonFooter from "../CommonFooter";
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from '../../../../../config/config';
import { useDispatch, useSelector } from 'react-redux';
import ArtWorkDetailsModal from './ArtWorkDetailsModal';
import ArtistProfile from '../ArtistProfile';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import notes_icon from '../../../../../static/img/leading-icon.svg';
import messages from '../../../../../config/constants/messages';

const CustomShare = () => {
    const role = "Home"

    const history = useHistory();
    const [artistData, setArtistData] = useState([]);
    const [artWorkData, setArtWorkData] = useState([]);
    const [exhibitionImage, setexhibitionImage] = useState('');
    const [exhibitionName, setexhibitionName] = useState('');
    const [exhibitionDesc, setexhibitionDesc] = useState('');

    const [isExhibitionActive, setisExhibitionActive] = useState(false);

    const { SearchValues } = useParams();

    const [ArtistId, setDetailsArtistId] = useState(null);
    const [ArtistBio, setArtistBio] = useState(null);
    const [ArtistName, setArtistName] = useState(null);
    const [ArtistLogo, setArtistLogo] = useState(null);
    const [ArtistExhibitionHistory, setArtistExhibitionHistory] = useState(null);
    const [ArtistEducation, setArtistEducation] = useState(null);

    const [artworkDetailsModalVisible, setArtworkDetailsModalVisible] = useState(false);
    const [ArtworkDetailsWorkId, setArtworkDetailsWorkId] = useState(null);

    const [artistDetailsModalVisible, setArtistDetailsModalVisible] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {

        if (SearchValues != undefined) {
            
            axios.get(`${config.apiUrl}/get-select-artworks/${SearchValues}`)
                .then(function (response) {                    
                    if (response.status == 200) 
                    {
                        setexhibitionName(response.data.title)
                        setexhibitionDesc(response.data.description);
                        setArtWorkData(response.data.artwork_ids);
                        setisExhibitionActive(true);
                    } else {
                        alert("An error occurred while processing your request.")
                        setisExhibitionActive(false)
                    }

                })
                .catch(function (error) {
                    if (error.response.status === 500) {
                        // alert("website to inform users that a link they've clicked on or followed has expired");
                        history.push('/data-not-found')
                      }
                    console.log(error);
                    history.push('/data-not-found')
                })

        }
    }, [])

    const [visible, setVisible] = React.useState(false);
    const [confirmLoading, setConfirmLoading] = React.useState(false);
    const showArtworkDetailsModal = (workId) => {
        setArtworkDetailsWorkId(workId)
        setArtworkDetailsModalVisible(true)
    };
    const handleArtworkDetailsCancel = () => {
        setArtworkDetailsModalVisible(false)
        setArtworkDetailsWorkId(null)
    };


    const shareLink = () => {
        notification.success({
            message: messages['copied_to_clipboard']
        });
    };

    const decryptData = (encryptedData) => {
        if (encryptedData) {
            try {
                const decryptedString = atob(encryptedData);
                const decryptedJson = decryptedString.replace(process.env.REACT_APP_FILTER_ENCRYPTION_KEY, '');
                const decryptedObject = JSON.parse(decryptedJson);
                return decryptedObject;
            } catch (error) {
                console.error('Error decrypting data:', error);
            }
        }
    };

    return (
        <div style={{
            width: '100%',
        }}>
            <div>
                <div style={{ marginTop: 140, paddingBottom: 60 }}>
                    <Row style={{ marginRight: 10, marginLeft: 10 }} className='set-homepage-content'>
                        <Col xs={0} sm={0} md={2} lg={2}></Col>
                        <Col xs={24} sm={24} md={20} lg={20}>
                            {
                                isExhibitionActive == 1 ?
                                    <>
                                        <Row style={{ justifyContent: 'center' }}>
                                            <p style={{ fontFamily: "Melodrama", fontSize: 45, marginBottom: 0 }} align="center">{exhibitionName}</p>
                                        </Row>
                                        <Row style={{ justifyContent: 'center' }}>
                                            <Tooltip placement="bottomRight" title="Click to share">
                                                <CopyToClipboard text={`https://stoodio.art/artworks/` + SearchValues}>
                                                    <img src={notes_icon} onClick={() => { shareLink() }} style={{ width: '40px', height: '40px', margin: '10px' }} />
                                                </CopyToClipboard>

                                            </Tooltip>
                                        </Row><br /><br />
                                        <Row style={{ justifyContent: 'center' }}>
                                            <p style={{ fontFamily: "Arial", fontSize: 15, textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: exhibitionDesc != "" ? exhibitionDesc : "" }}></p>
                                        </Row>
                                        <br /><hr style={{ width: '80%' }} /><br />

                                        {
                                            [...artWorkData].length > 0 &&
                                            <div>


                                                <Row>
                                                    {
                                                        [...artWorkData].map((item) => {
                                                            if (item) {
                                                                if (item.image_urls) {
                                                                    const imagesURLs = JSON.parse(item.image_urls);
                                                                    const imagesURL = imagesURLs[0].url;
                                                                    return (
                                                                        imagesURL &&
                                                                        <Col xs={24} sm={24} md={8} lg={6} xl={6} style={{ textAlign: 'center', padding: 2 }}>
                                                                            <>
                                                                                <div style={{ background: 'white', justifyContent: 'center', height: 300, display: 'flex', alignItems: 'center' }} >

                                                                                    <img
                                                                                        src={imagesURL}
                                                                                        alt="Art-Work"
                                                                                        style={{
                                                                                            borderRadius: 8,
                                                                                            // cursor: 'pointer',
                                                                                            maxWidth: '210px',
                                                                                            maxHeight: '260px',
                                                                                        }}
                                                                                        onClick={() => { showArtworkDetailsModal(item.id) }}
                                                                                    />

                                                                                </div>
                                                                                <div style={{ textAlign: 'center' }} className='gallery-details-div'>
                                                                                    <a
                                                                                        style={{
                                                                                            fontFamily: '"Maison Neue Demi", Arial, sans-serif',
                                                                                            fontSize: '1.2em',
                                                                                            lineHeight: '1.2em',
                                                                                            fontWeight: 'bolder',
                                                                                            color: '#272B41',
                                                                                            // cursor: 'pointer'
                                                                                        }}
                                                                                        onClick={() => { showArtworkDetailsModal(item.id) }}
                                                                                    >{item.work_title.substring(0, 50)}</a>
                                                                                </div>
                                                                            </>
                                                                        </Col>
                                                                    )
                                                                } else {
                                                                    const imagesURL = 'https://stoodio.art/static/media/stoodiologo.175eeac4.svg';
                                                                    return (
                                                                        imagesURL &&
                                                                        <Col xs={24} sm={24} md={8} lg={6} xl={6} style={{ textAlign: 'center', padding: 2 }}>
                                                                            <>
                                                                                <div style={{ background: 'white', justifyContent: 'center', height: 300, display: 'flex', alignItems: 'center' }} >

                                                                                    <img
                                                                                        src={imagesURL}
                                                                                        alt="No Image Available"
                                                                                        style={{
                                                                                            // cursor: 'pointer',
                                                                                            maxWidth: '210px',
                                                                                            maxHeight: '260px',
                                                                                        }}
                                                                                        onClick={() => { showArtworkDetailsModal(item.id) }}
                                                                                    />

                                                                                </div>
                                                                                <div style={{ textAlign: 'center' }} className='gallery-details-div'>
                                                                                    <a
                                                                                        style={{
                                                                                            fontFamily: '"Maison Neue Demi", Arial, sans-serif',
                                                                                            fontSize: '1.2em',
                                                                                            lineHeight: '1.2em',
                                                                                            fontWeight: 'bolder',
                                                                                            color: '#272B41',
                                                                                            cursor: 'pointer'
                                                                                        }}
                                                                                        onClick={() => { showArtworkDetailsModal(item.id) }}
                                                                                    >{item.work_title.substring(0, 50)}</a>
                                                                                </div>
                                                                            </>
                                                                        </Col>
                                                                    )
                                                                }

                                                            }
                                                        })
                                                    }
                                                </Row>
                                            </div>
                                        }
                                    </>
                                    :
                                    <Row style={{ justifyContent: 'center' }}>
                                        {/* <p style={{ fontFamily: "Melodrama", fontSize: 45 }} align="center">Not Found</p> */}
                                    </Row>
                            }

                        </Col>
                        <Col xs={0} sm={0} md={2} lg={2}></Col>
                    </Row>
                </div>
                <div className="adjust-home-page-footer" style={
                    Object.assign({}
                        , {
                            width: '-webkit-fill-available',
                            position: 'fixed',
                            left: 0,
                            bottom: 0,
                            right: 0,
                            background: '#E7F6FA'
                        }
                    )}
                >
                    <CommonFooter />
                </div>
                {
                    ArtworkDetailsWorkId && <Modal
                        className='custom-modal'
                        visible={artworkDetailsModalVisible}
                        // onOk={handleOk}
                        //confirmLoading={confirmLoading}
                        onCancel={handleArtworkDetailsCancel}
                        // width={1250}
                        // bodyStyle={{ height: 720 }}
                        footer={null}
                    >
                        {ArtworkDetailsWorkId != null && <ArtWorkDetailsModal is_disable_cart={true} work_id={ArtworkDetailsWorkId} />}
                    </Modal>
                }
            </div>
        </div >
    )
}

export default CustomShare