import { combineReducers } from 'redux';
import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';
import authReducer from './authentication/reducers';
import ChangeLayoutMode from './themeLayout/reducers';
import Profile from './profile/reducers';
import UsersReducer from './Users/reducers'
import WorkFilterReducer from './filterWorkData/reducers'
import singleWork from './SingleWork/reducers';
import CountReducer from './count/reducers';
import Base64Reducer from './TempImage/reducers';
import contentData from './contentChange/reducers';
import tabKeyValue from './TabKeyChange/reducers'
import RatingDataReducer from './RatingData/actionCreator'
import adminSingleArtWork from './AdminSingleArtWork/reducers'

import firebaseAuth from './firebase/auth/reducers';
import {workReducer, singleWorkReducer} from './work/reducers';
import {tasteQuizWorkReducer} from './TasteQuizWork/reducers'

const rootReducers = combineReducers({
  fb: firebaseReducer,
  fs: firestoreReducer,
  auth: authReducer,
  ChangeLayoutMode,
  Profile,
  UsersReducer,
  contentData,
  tabKeyValue,
  RatingDataReducer,
  WorkFilterReducer,
  CountReducer,
  Base64Reducer,
  adminSingleArtWork,
  singleWork,
  firebaseAuth,
  tasteQuizWorkReducer:tasteQuizWorkReducer,
  works: workReducer,
  work: singleWorkReducer
});

export default rootReducers;
