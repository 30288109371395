import actions from './actions';
import workService from '../../services/workService';

const {
	getRatingWorksBegin,
	getRatingWorksSuccess,
	getRatingWorksErr,
	
} = actions;


const getRatingWorkData = (UID) => {
	return async dispatch => {
		try {
			dispatch(getRatingWorksBegin());
			//console.log("getWork")
			const data = await workService.getRatingWorkData(UID);
			//console.log("Images data",data)
			dispatch(getRatingWorksSuccess(data));

			
			
		} catch (err) {
			console.log(err)
			dispatch(getRatingWorksErr(err));
		}
	};
};

export default getRatingWorkData