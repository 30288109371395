const actions = {

	GET_TAB_KEY_SUCCESS: 'GET_TAB_KEY_SUCCESS',
	

	getTabKeySuccess: data => {
		return {
			type: actions.GET_TAB_KEY_SUCCESS,
			payload:data,
		};
	},

};

export default actions;