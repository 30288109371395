import React, { useEffect, useState } from 'react';
import { Menu } from 'antd';
import { NavLink, useRouteMatch } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import propTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { fbGetUserInfo } from '../redux/profile/actionCreator';
import config from "../config/config";
import axios from "axios";
import PackingInstructions from "../container/profile/authentication/overview/PackingInstructions";
// import { fbGetUserInfo } from '../redux/profile/actions';



const MenuItems = ({ darkMode, toggleCollapsed, topMenu, events }) => {
  const { path } = useRouteMatch();

  const pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath.split('/');

  const [openKeys, setOpenKeys] = React.useState(
    !topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : [],
  );

  const [invite_count, setInviteCount] = React.useState(0);
  const [profile_i_am, setProfileIAm] = useState('')



  const onOpenChange = keys => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
  };

  const onClick = item => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };


  const [state, setState] = useState({
    PackingModalVisible: false,
  });

  const { PackingModalVisible } = state;

  const isFbAuthenticate = useSelector(state => {
    return {
      isFbAuthenticate: state.fb.auth.uid,
    }
  });



  const dispatch = useDispatch();

  useEffect(() => {

    //dispatch(fbGetUserInfo(isFbAuthenticate.isFbAuthenticate));

    axios.post(`${config.apiUrl}/get-invite-count`, { uid: isFbAuthenticate.isFbAuthenticate })
      .then(function (response) {
        setInviteCount(response.data[0].invite_count);
        setProfileIAm(response.data[0].i_am);
      })
      .catch(function (error) {

      });

  }, [dispatch]);

  const onCancelPackingModal = () => {
    setState({
      ...state,
      PackingModalVisible: false,
    });
  };

  const showPackingModal = () => {
    setState({
      ...state,
      PackingModalVisible: true,
    });
  };



  return (
    <Menu className='adjust-width'
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      theme={darkMode && 'dark'}
      // // eslint-disable-next-line no-nested-ternary
      defaultSelectedKeys={
        !topMenu
          ? [
            `${mainPathSplit.length === 1 ? 'home' : mainPathSplit.length === 2 ? mainPathSplit[1] : mainPathSplit[2]
            }`,
          ]
          : []
      }
      defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : []}
      overflowedIndicator={<FeatherIcon icon="more-vertical" />}
      openKeys={openKeys}
    >

      {(
        profile_i_am === 'Artist'
          ?
          <>
            <Menu.Item key="Instructions">
              <NavLink to="#" onClick={() => { showPackingModal() }} style={{ color: 'red' }} className="PackingInstructions">
                Packing Instructions
              </NavLink>
            </Menu.Item>
            <PackingInstructions onCancel={() => { onCancelPackingModal() }} visible={PackingModalVisible} />
          </>
          :
          ""
      )
      }

      <Menu.Item key="dashboard">
        {/* <NavLink onClick={toggleCollapsed} to={`${path}/home`}> */}
        <NavLink onClick={() => {
          //sessionStorage.setItem("profle-to-gallery",true);
          sessionStorage.setItem('taste_view_visible', false);
        }} to={`/gallery`}>
          Gallery
        </NavLink>
      </Menu.Item>

      <Menu.Item key="settings">
        {/* <NavLink onClick={toggleCollapsed} to={`${path}/settings`}> */}
        <NavLink onClick={toggleCollapsed} to={`/profile`}>
          Profile
        </NavLink>
      </Menu.Item>
      {
        isFbAuthenticate.isFbAuthenticate === process.env.REACT_APP_ADMIN_UID ? (
          <>
            <Menu.Item key="invite">
              <NavLink onClick={toggleCollapsed} to={`/invite`}>
                Invite
              </NavLink>
            </Menu.Item>

            <Menu.Item key="users">
              <NavLink onClick={toggleCollapsed} to={`/users`}>
                Users
              </NavLink>
            </Menu.Item>
            <Menu.Item key="art-works">
              <NavLink onClick={toggleCollapsed} to={`/art-works`}>
                Art Works
              </NavLink>
            </Menu.Item>

            <Menu.Item key="taste-quiz">
              <NavLink onClick={toggleCollapsed} to={`/admin-taste-test`}>
                Taste Test
              </NavLink>
            </Menu.Item>

            {/* <Menu.Item key="Admin Account">
              <NavLink onClick={toggleCollapsed} to={`/admin-account`}>
                Admin Account
              </NavLink>
            </Menu.Item> */}

            <Menu.Item key="Stoodio Snippets">
              <NavLink onClick={toggleCollapsed} to={`/stoodio-snippet`}>
                Stoodio Snippets
              </NavLink>
            </Menu.Item>

            <Menu.Item key="Manage Early Access">
              <NavLink onClick={toggleCollapsed} to={`/manage-early-access`}>
                Manage Early Access
              </NavLink>
            </Menu.Item>


            {/* <Menu.Item key="For City Snippet">
              <NavLink onClick={toggleCollapsed} to={`/city-snippet`}>
                For City Snippet
              </NavLink>
            </Menu.Item>

            <Menu.Item key="For Queue Empty Snippet">
              <NavLink onClick={toggleCollapsed} to={`/queue-snippet`}>
                For Queue Empty Snippet
              </NavLink>
            </Menu.Item>


            <Menu.Item key="Checkout Snippet">
              <NavLink onClick={toggleCollapsed} to={`/checkOut-snippet`}>
                For Checkout Snippet
              </NavLink>
            </Menu.Item>

            <Menu.Item key="For Artist Snippet">
              <NavLink onClick={toggleCollapsed} to={`/artist-snippet`}>
                For Artist Snippet
              </NavLink>
            </Menu.Item>

            <Menu.Item key="For Collector Snippet">
              <NavLink onClick={toggleCollapsed} to={`/collector-snippet`}>
                For Collector Snippet
              </NavLink>
            </Menu.Item> */}

          </>
        ) :
          (
            profile_i_am === 'Artist' && invite_count === 0
              ?

              <>
                <Menu.Item key="invite">
                  <NavLink onClick={toggleCollapsed} to={`/invite`}>
                    Invite
                  </NavLink>
                </Menu.Item>
              </>
              :
              ""
          )

      }
      {(
        profile_i_am === 'Artist'
          ?
          <>

            <Menu.Item key="view">
              {/* <NavLink onClick={toggleCollapsed} to={`${path}/project/view/grid`}> */}
              <NavLink onClick={toggleCollapsed} to={`/my-works/grid`}>
                My Works
              </NavLink>
            </Menu.Item>
          </>
          :
          ""
      )
      }
      {
        isFbAuthenticate.isFbAuthenticate === process.env.REACT_APP_ADMIN_UID ? (
          <>
            <Menu.Item key="manage-seo-page">
              <NavLink onClick={toggleCollapsed} to={`/manage-seo-page`}>
                Manage SEO Page
              </NavLink>
            </Menu.Item>

            <Menu.Item key="manage-exhibition">
              <NavLink onClick={toggleCollapsed} to={`/manage-exhibition`}>
                Manage Exhibitions
              </NavLink>
            </Menu.Item>

            <Menu.Item key="manage-blogs">
              <NavLink onClick={toggleCollapsed} to={`/manage-blogs`}>
                Manage Blogs
              </NavLink>
            </Menu.Item>

            <Menu.Item key="manage-education">
              <NavLink onClick={toggleCollapsed} to={`/manage-education`}>
                Manage Education
              </NavLink>
            </Menu.Item>
            <Menu.Item key="manage-custom-url">
              <NavLink onClick={toggleCollapsed} to={`/manage-custom-url`}>
                Manage Shared Links
              </NavLink>
            </Menu.Item>

            <Menu.Item key="hold-orders">
              <NavLink onClick={toggleCollapsed} to={`/hold-orders`}>
                Hold Orders
              </NavLink>
            </Menu.Item>

            <Menu.Item key="orders">
              <NavLink onClick={toggleCollapsed} to={`/my-purchases`}>
                Artwork Sales
              </NavLink>
            </Menu.Item>
          </>
        ) :
          (

            <>
              <Menu.Item key="orders">
                <NavLink onClick={toggleCollapsed} to={`/my-purchases`}>
                  My Purchases
                </NavLink>
              </Menu.Item>
            </>

          )}





      <Menu.Item key="ratings">
        <NavLink onClick={toggleCollapsed} to={`/my-ratings`}>
          My Ratings
        </NavLink>
      </Menu.Item>
      <Menu.Item key="list_event">
        <NavLink onClick={toggleCollapsed} to={`/manage-news-events`}>
          List An Event
        </NavLink>
      </Menu.Item>




    </Menu>
  );
};

MenuItems.propTypes = {
  darkMode: propTypes.bool,
  topMenu: propTypes.bool,
  toggleCollapsed: propTypes.func,
  events: propTypes.object,
};

export default MenuItems;
