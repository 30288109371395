import React, { useState, useEffect, useCallback,useRef } from 'react';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { Form, Input, Button, notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import { FacebookOutlined, TwitterOutlined, GoogleOutlined } from '@ant-design/icons';
import { AuthWrapper } from './style';
import { login } from '../../../../redux/authentication/actionCreator';
import {
  fbAuthLogin,
  fbOTPLogin
} from '../../../../redux/firebase/auth/actionCreator';
import { Checkbox } from '../../../../components/checkbox/checkbox';
import Heading from '../../../../components/heading/heading';
import { Modal } from '../../../../components/modals/antd-modals';
import FbSignup from './FbSignup';
import { Alert } from 'antd';
import ReCAPTCHA from "react-google-recaptcha";
import config from '../../../../config/config';
import axios from 'axios';
import messages from '../../../../config/constants/messages';

const FbSignInWithOTP = ({ visible, onCancel }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const recaptchaRef = useRef(null);


  const { isLoading, error, isFbAuthenticate } = useSelector(state => {
    // console.log(0)
    return {
      error: state.firebaseAuth.error,
      isLoading: state.firebaseAuth.loading,
      isFbAuthenticate: state.fb.auth.uid,
    };
  });
  const [form] = Form.useForm();
  const [state, setState] = useState({
    checked: null,
    visible,
    signupVisible: false,
    OtpVisiable: false
  });

  const { signupVisible } = state;

  const showSignupModal = () => {
    handleCancel()
    setState({
      ...state,
      signupVisible: true
    });
  };

  const resetReCAPTCHA = () => {
    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
    }
  };

  const handleFbLogin = useCallback(() => {
    dispatch(login());
    // history.push('/admin');
    history.push('/gallery');  // redirect to home page after login
  }, [dispatch, history]);

  useEffect(() => {
    if (isFbAuthenticate) {
      handleFbLogin();
    }

    let unmounted = false;
    if (!unmounted) {

      setState({
        visible,
        signupVisible
      });
    }
    return () => {
      unmounted = true;
    };

  }, [isFbAuthenticate, handleFbLogin, visible, signupVisible]);

  const handleVerify = values => {
    let post_data = { ...values, email: email };

    axios.post(`${config.apiUrl}/verify-otp`, post_data)
      .then(function (response) {
        if (response.data) {
          if (response.data.status == true) {
            dispatch(fbAuthLogin(response.data));
          } else {            
            notification.warning({
              message: response.data.message
            });
          }
        }
        else {
          notification.warning({
            message: "something went wrong"
          });
          
        }
      })
      .catch(function (error) {
        console.log("error", error.message);
        setvisiableOTP(false);
      });

  }
  const handleSubmit = values => {

    let verify_token = { token: recaptcha };
    axios.post(`${config.apiUrl}/send-recaptcha-token`, verify_token)
      .then(function (response) {
        if (response.data.google_response.success == true) {
          //dispatch(fbAuthLogin(values));
          sendOTP(values.email)
          setRecaptcha('');
          window.grecaptcha.reset();
        } else {
          setRecaptcha('');
          setVerified(false);
          window.grecaptcha.reset();
          alert(messages['recaptcha_failed_error'])
          return false;
        }
      })
      .catch(function (error) {
        window.grecaptcha.reset()
        console.log(error);
      });
  };

  const [email, setEmail] = useState(null);
  const [visiableOTP, setvisiableOTP] = useState(false);

  const onChange = checked => {
    setState({ ...state, checked });
  };

  const handleCancel = () => {
    setvisiableOTP(false);
    onCancel();
  };

  const sendOTP = values => {

    let post_data = { email: values };
    axios.post(`${config.apiUrl}/send-otp`, post_data)
      .then(function (response) {
        if (response.data) {
          if (response.data.status == true) {
            notification.success({
              message: response.data.message
            });
            setvisiableOTP(true);
          } else {
            notification.warning({
              message: response.data.message
            });
            resetReCAPTCHA();
            setvisiableOTP(false);
            //window.grecaptcha.reset();
          }
        }
        else {
          resetReCAPTCHA();
          notification.danger({
            message: "something went wrong"
          });
          setvisiableOTP(false);
        }
      })
      .catch(function (error) {
        console.log("error", error.message);
        setvisiableOTP(false);
      });
  }


  const [recaptcha, setRecaptcha] = useState('')
  const [verified, setVerified] = useState(false);
  const [message, setMessage] = useState(false);

  function onChange1(value) {

    setRecaptcha(value);
    setVerified(true);
  }



  return (
    <React.Fragment>
      <Modal
        type={state.modalType}
        title="Login without password"
        visible={state.visible}
        footer={[]}
        onCancel={handleCancel}
      >
        <AuthWrapper>
          <div className="auth-contents">
            {visiableOTP != true ?
              <Form name="login" form={form} onFinish={handleSubmit} layout="vertical">
                <Heading as="h3">
                  Login without password
                </Heading>
                <Form.Item
                  name="email"
                  rules={[{ message: 'Please input your Email!', required: true },
                  { type: 'email', message: 'Please validate your email address' },]}
                  label="Email Address"
                >
                  <Input onChange={(e) => setEmail(e.target.value)} />
                </Form.Item>

                <Form.Item>
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                    onChange={onChange1}
                  />
                </Form.Item>
                <Form.Item>
                  <Button className="btn-signin" htmlType="submit" type="primary" size="large" disabled={!verified}>
                    {isLoading ? 'Loading...' : 'Send'}
                  </Button>
                </Form.Item>
              </Form>
              :
              <>

                <Form name="login" form={form} onFinish={handleVerify} layout="vertical">
                  <Heading as="h3">
                    Authentication required
                  </Heading>
                  <Form.Item
                    name="otp"
                    rules={[{ message: 'Please input your otp', required: true },
                    { min: 6, message: "Please enter OTP of length 6" },
                    { max: 6, message: "Please enter OTP of length 6" },]}
                    label="Enter OTP"
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item>
                    <Button className="btn-signin" htmlType="submit" type="primary" size="large">
                      {isLoading ? 'Loading...' : 'Verify'}
                    </Button>
                    <Link to="#" onClick={(e) => sendOTP(email)} style={{ marginLeft: 20 }}>Resend OTP</Link>
                  </Form.Item>
                </Form>

              </>
            }
          </div>
        </AuthWrapper>
      </Modal>
    </React.Fragment>
  );
};

export default FbSignInWithOTP;
