const actions = {
    USER_LIST_BEGIN: 'USER_LIST_BEGIN',
    USER_LIST_SUCCESS: 'USER_LIST_SUCCESS',
    USER_LIST_ERR: 'USER_LIST_ERR',

    GET_LOG_USER_DATA_BEGIN: 'GET_LOG_USER_DATA_BEGIN',
	GET_LOG_USER_DATA_SUCCESS: 'GET_LOG_USER_DATA_SUCCESS',
	GET_LOG_USER_DATA_WORK_ERR: 'GET_LOG_USER_DATA_WORK_ERR',

  
    usersListBegin: () => {
      return {
        type: actions.USER_LIST_BEGIN,
      };
    },
  
    usersListSuccess: data => {
      return {
        type: actions.USER_LIST_SUCCESS,
        data,
      };
    },
  
    usersListErr: err => {
      return {
        type: actions.USER_LIST_ERR,
        err,
      };
    },

    getLogUserDataBegin: () => {
      return {
        type: actions.GET_LOG_USER_DATA_BEGIN,
      };
    },
  
    getLogUserDataSuccess: data => {
      //console.log('in actions')
      // console.log(data)
      return {
        type: actions.GET_LOG_USER_DATA_SUCCESS,
        data,
      };
    },
  
    getLogUserDataWorkErr: err => {
      return {
        type: actions.GET_LOG_USER_DATA_WORK_ERR,
        err,
      };
    },
    
  };
  
  export default actions;