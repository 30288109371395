import { Button, Col, Row, Form, Input, Modal } from 'antd'
import './css/style.css'
import {  useHistory, useParams } from 'react-router-dom';
import CommonFooter from "../CommonFooter";
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import config from '../../../../../config/config';
import { fbAuthLogin } from '../../../../../redux/firebase/auth/actionCreator';

const ChangeRole = (match) => {


    const history = useHistory();
    const dispatch = useDispatch();
    const { token } = useParams();

    const [linkValid, setLinkvalid] = React.useState(false);

    const [form] = Form.useForm();

    const [password, setPassword] = useState('')
    const [email, setEmail] = useState('');

    useEffect(() => {
        const headers = {
            "Content-Type": "application/json",
            'Authorization': "Bearer " + token
        }
        let post_data = {};
        axios.post(`${config.apiUrl}/check-user-role`, post_data, {
            headers: headers
        })
            .then(function (response) {

                if (response.data.status) {
                    setLinkvalid(response.data.status);
                    setEmail(response.data.email);
                } else {
                    setLinkvalid(false);
                }
            })
            .catch(function (error) {
                console.log("error", error.message);
                //setLinkvalid(true);
                setLinkvalid(false);
            });
    }, [])


    const handleSubmit = async ev => {
        let post_data = { email:email,password: password };
        

        //return false;
        /*
        Modal.confirm({
            title: 'Congrats!',
            content: 'You have upgraded your account. Would you like to take a virtual tour for adding artworks?',
            okText: 'OK',
            onOk: () => {
                dispatch(fbAuthLogin(post_data));
                history.push('/project/view/grid');
                // code to be implemented
                //location.reload();
            },
            onCancel() {
                location.reload();
            }
        });
        return false;
        */
        

        const headers = {
            "Content-Type": "application/json",
            'Authorization': "Bearer " + token
        }
        axios.post(`${config.apiUrl}/update-user-role`, post_data, {
            headers: headers
        })
            .then(function (response) {
                var result = response.data;
                if (result[0]) {
                    if (result[0].status == true) {
                        //alert(result[0].message);
                        sessionStorage.setItem('invite_artist_flag',1);
                        dispatch(fbAuthLogin(post_data));

                    } else {
                        alert(result[0].message);
                    }

                } else {
                    alert("Something went to wrong");
                }
            })
            .catch(function (error) {
                console.log("error", error.message);
            });

    };


    return (
        <div style={{
            width: '100%',
        }}>
            <div>
                <div style={{ marginTop: 140, paddingBottom: 60 }}>
                    <Row className='set-homepage-content'>
                        <Col xs={0} sm={0} md={0} lg={3}></Col>
                        <Col xs={24} sm={24} md={24} lg={12}>

                            {
                                linkValid != true ?
                                    <>
                                        <h2>Please attempt to change your role again.</h2>
                                        {linkValid != true && (<div class="ant-form-item-explain ant-form-item-explain-error">
                                            <div role="alert">Your request to change your role has expired, or the link has already been used.</div></div>)}
                                    </>
                                    :
                                    <>
                                        <Form
                                            form={form}
                                            layout="vertical"
                                            id="payment-form"
                                            style={{ margin: '50px' }}
                                        >
                                            <h3 style={{ marginBottom: 30 }}>Please enter your password below to confirm your artist account of email <b>{email}</b>.</h3>

                                            <Form.Item
                                                label="Password"
                                                name="password"
                                                rules={[{ required: true, message: 'Please input your password!' }, { min: 6, message: 'password must be minimum six characters.' }]}
                                            >
                                                <Input.Password placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
                                            </Form.Item>

                                            <Button className="gallery-btn" htmlType="submit" style={{ marginLeft: 0 }} type="primary" size="default" onClick={handleSubmit}>
                                                <p style={{ margin: 0, padding: 10, fontSize: 14 }}>Upgrade</p>
                                            </Button>

                                        </Form>
                                    </>
                            }



                        </Col>
                        <Col xs={0} sm={0} md={2} lg={3}></Col>
                    </Row>
                </div>
                <div className="adjust-home-page-footer" style={
                    Object.assign({}
                        , {
                            width: '-webkit-fill-available',
                            position: 'fixed',
                            left: 0,
                            bottom: 0,
                            right: 0,
                        }
                    )}
                >
                    <CommonFooter />
                </div>
            </div>
        </div>
    )
}

export default ChangeRole