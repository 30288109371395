import { Button, Col, Row } from 'antd'
import GetSnippetsData from '../Snippets/GetSnippetsData'
import './css/style.css'
import { Link, NavLink, useHistory } from 'react-router-dom';
import CommonFooter from "../CommonFooter";
import React, { useEffect } from 'react';
import getdata from '../../../../../redux/contentChange/actions'
import { useDispatch, useSelector } from 'react-redux';

const SucessfulPurchase = () => {
    const role = "Home"
    
    
    
    const snippetData = GetSnippetsData(role)
    const history = useHistory();

    

    const dispatch = useDispatch();

    const handleCartCount = (count) => {
        dispatch(getdata.getContentDetailsSuccess({
            count
        }));
    }

    useEffect(() => {
        sessionStorage.removeItem('cartItemCount')
        handleCartCount(0)

        /*if(sessionStorage.getItem('payment_success') != 1)
        {
            sessionStorage.removeItem('payment_success')
            history.push('/')
        }else{
            sessionStorage.removeItem('payment_success')
        }*/
    }, [])

    

    const [visible, setVisible] = React.useState(false);
    const [confirmLoading, setConfirmLoading] = React.useState(false);

    const handleModalCancel = () => {
        setVisible(false);
    };

    

    const showModal = () => {
        sessionStorage.setItem('show_purchase_taste_modal',1);
        history.push('/gallery')
    };


    return (
        <div style={{
            width: '100%',
        }}>
            <div>
                
                <div style={{ marginTop: 140, paddingBottom: 60 }}>
                    <Row className='set-homepage-content'>
                        <Col xs={0} sm={0} md={2} lg={3}></Col>
                        <Col xs={24} sm={24} md={12} lg={12}>

                            <p className='home-content-heading' >
                            Thank you for your purchase!
                            </p>
                            <p className='home-content-title' style={{fontSize:15}}>
                            We will be reaching out to you regarding your order to coordinate a shipping date and time that works for you! If you have any questions, please contact us at {process.env.REACT_APP_EMAIL_ADDRESS}, or call us at +1 (312)-900-7323.
                            </p>
                            <p>
                                <Button className="gallery-btn" htmlType="submit" type="primary" size="default" onClick={showModal}>
                                    <p style={{ margin: 0, padding: 10, fontSize: 14 }}> Go To Gallery</p>
                                </Button>
                            </p>

                        </Col>
                        <Col xs={0} sm={0} md={2} lg={3}></Col>
                    </Row>
                </div>
                <div className="adjust-home-page-footer" style={
                    Object.assign({}
                        , {
                            width: '-webkit-fill-available',
                            position: 'fixed',
                            left:0,
                            bottom:0,
                            right:0,
                        }
                    )}
                >
                    <CommonFooter />
                </div>
            </div>
        </div>
    )
}

export default SucessfulPurchase