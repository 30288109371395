import actions from './actions';
import workService from '../../services/workService';

const {

	getTestQuizImagesWorkBegin,
	getTestQuizImagesWorkSuccess,
	getTestQuizImagesWorkErr,

	
} = actions;


const getTasteQuizImages = () => {
	return async dispatch => {
		try {
			dispatch(getTestQuizImagesWorkBegin());
			//console.log("getWork")
			const data = await workService.getTasteQuizImages();
			//console.log("Images data",data)
			dispatch(getTestQuizImagesWorkSuccess(data));

			
			
		} catch (err) {
			console.log(err)
			dispatch(getTestQuizImagesWorkErr(err));
		}
	};
};










export { getTasteQuizImages };