const likeStyle = {
	backgroundColor: '#ddedf3',
	color: 'black',
	borderRadius: 20,
	padding: 5,
	height: 35,
	width: 35
}

const disLikeStyle = {
	backgroundColor: 'rgb(92, 223, 255) ',
	color: 'black',
	borderRadius: 20,
	padding: 5,
	height: 35,
	width: 35
}

const skipStyle = {
	fontSize: 16,
	color: 'black',
	fontWeight: 1000
}

const defaultStyle = {
	color: 'black',
	border: "1px solid #c0c0c0f2",
	borderRadius: 20,
	padding: 5,
	height: 35,
	width: 35
}

const skipDefaultStyle = {
	fontSize: 16,
	color: 'black'
}

const modalStyle = {
	// heading_font_style: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Poppins, sans-serif,Ubuntu,sans-serif',
	heading_font_style: 'Poppins, sans-serif',
	p_tag_font_style: 'Poppins, sans-serif',
	fontWeight: '600',
	fontSize: '25px'
};

export {likeStyle,disLikeStyle,skipStyle,defaultStyle,skipDefaultStyle,modalStyle}