import actions from './actions';

const {
  

  GET_TASTE_QUIZ_IMAGES_WORK_BEGIN,
  GET_TASTE_QUIZ_IMAGES_WORK_SUCCESS,
  GET_TASTE_QUIZ_IMAGES_WORK_ERR,

} = actions;

const initialState = {
  data: [],
  loading: false,
  error: null,
};


const tasteQuizWorkReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    
    case GET_TASTE_QUIZ_IMAGES_WORK_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_TASTE_QUIZ_IMAGES_WORK_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case GET_TASTE_QUIZ_IMAGES_WORK_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};


export { tasteQuizWorkReducer };