const MaterialsAffinity = (materialAvgData,unratedMaterial_X_Y_Data) => {
    var materialX_Y_affinity_score = unratedMaterial_X_Y_Data
    var materialX_Y_affinity_data = []
    var materialX_Y_final_affinity = 0
    var Affinity = 0

    for (var i = 0; i < unratedMaterial_X_Y_Data.length; i++) {
        var step1 = unratedMaterial_X_Y_Data[i]

        for (var j = 0; j < Object.keys(step1).length; j++) {
            if (step1[j]) {

                const isMaterialXAvailable = materialAvgData.find(o1 => o1.name == step1[j].name)

                if (isMaterialXAvailable) {

                    var cal = step1[j].userRating

                    step1[j].userRating = (isMaterialXAvailable.tpScore - cal - cal)


                }
            }
        }

        const materialXResult = Object.values(step1);
        materialXResult.map((itm) => {

            if (itm.name) {
                var absolute_val = Math.abs(itm.userRating)
                //console.log("absolute_val",absolute_val)
                materialX_Y_final_affinity += absolute_val
            }

        })


        // categories affinity step2 calculation

        var singleArrayMaterialXLenght = Object.keys(step1).length - 1

        Affinity = (materialX_Y_final_affinity) / (2 * (singleArrayMaterialXLenght))

        materialX_Y_affinity_data.push({ ...materialX_Y_affinity_score[i], Affinity })

        materialX_Y_final_affinity = 0
        Affinity = 0


    }
    return {materialX_Y_affinity_data}
}

export default MaterialsAffinity