const actions = {
  FB_LOGIN_BEGIN: 'FB_LOGIN_BEGIN',
  FB_LOGIN_SUCCESS: 'FB_LOGIN_SUCCESS',
  FB_LOGIN_ERR: 'FB_LOGIN_ERR',

  FB_LOGOUT_BEGIN: 'FB_LOGOUT_BEGIN',
  FB_LOGOUT_SUCCESS: 'FB_LOGOUT_SUCCESS',
  FB_LOGOUT_ERR: 'FB_LOGOUT_ERR',

  FB_SIGNUP_BEGIN: 'FB_SIGNUP_BEGIN',
  FB_SIGNUP_SUCCESS: 'FB_SIGNUP_SUCCESS',
  FB_SIGNUP_ERR: 'FB_SIGNUP_ERR',

  FB_DELETE_BEGIN: 'FB_DELETE_BEGIN',
  FB_DELETE_SUCCESS: 'FB_DELETE_SUCCESS',
  FB_DELETE_ERR: 'FB_DELETE_ERR',

  FB_OTP_BEGIN: 'FB_OTP_BEGIN',
  FB_OTP_SUCCESS: 'FB_OTP_SUCCESS',
  FB_OTP_ERR: 'FB_OTP_ERR',

  fbLoginBegin: () => {
    return {
      type: actions.FB_LOGIN_BEGIN,
    };
  },

  fbLoginSuccess: data => {
    return {
      type: actions.FB_LOGIN_SUCCESS,
      data,
    };
  },

  fbLoginErr: err => {
    return {
      type: actions.FB_LOGIN_ERR,
      err,
    };
  },

  fbLogOutBegin: () => {
    return {
      type: actions.FB_LOGOUT_BEGIN,
    };
  },

  fbLogOutSuccess: data => {
    return {
      type: actions.FB_LOGOUT_SUCCESS,
      data,
    };
  },

  fbLogOutErr: err => {
    return {
      type: actions.FB_LOGOUT_ERR,
      err,
    };
  },

  fbSignUpBegin: () => {
    return {
      type: actions.FB_SIGNUP_BEGIN,
    };
  },

  fbSignUpSuccess: data => {
    return {
      type: actions.FB_SIGNUP_SUCCESS,
      data,
    };
  },

  fbSignUpErr: err => {
    return {
      type: actions.FB_SIGNUP_ERR,
      err,
    };
  },

  fbResetPasswordBegin: () => {
    return {
      type: actions.FB_SIGNUP_BEGIN,
    };
  },

  fbResetPasswordSuccess: data => {
    return {
      type: actions.FB_SIGNUP_SUCCESS,
      data,
    };
  },

  fbResetPasswordErr: err => {
    return {
      type: actions.FB_SIGNUP_ERR,
      err,
    };
  },

  fbDeleteBegin: () => {
    return {
      type: actions.FB_DELETE_BEGIN,
    };
  },

  fbDeleteSuccess: data => {
    return {
      type: actions.FB_DELETE_SUCCESS,
      data,
    };
  },

  fbDeleteErr: err => {
    return {
      type: actions.FB_DELETE_ERR,
      err,
    };
  },

  fbOTPBegin: () => {
    return {
      type: actions.FB_OTP_BEGIN,
    };
  },

  fbOTPSuccess: data => {
    return {
      type: actions.FB_OTP_SUCCESS,
      data,
    };
  },

  fbOTPErr: err => {
    return {
      type: actions.FB_OTP_ERR,
      err,
    };
  },
  

};

export default actions;
