import actions from './actions';

const {

  GET_SINGLE_WORK_DETAILS_BEGIN,
  GET_SINGLE_WORK_DETAILS_SUCCESS,
  GET_SINGLE_WORK_DETAILS_ERR,


} = actions;

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const testRecord = {
  categories: [1, 3, 4],
  created_at: null,
  height: 50,
  id: 1,
  image_urls: null,
  length: 50,
  materials: null,
  part_of_series: 1,
  price: 500,
  unique_copy: 0,
  updated_at: null,
  user_id: null,
  width: 50,
  work_description: "This is hardcoded data in grid",
  work_end_date: "2021-05-30T18:30:00.000Z",
  work_start_date: "2021-05-17T18:30:00.000Z",
  work_title: "My test work add",
}


const initialStateSingle = {
  data: [],
  loading: false,
  error: null,
};

const adminSingleArtWork = (state = initialStateSingle, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_SINGLE_WORK_DETAILS_BEGIN:
      return {
        ...initialStateSingle,
        loading: true,
      };
    case GET_SINGLE_WORK_DETAILS_SUCCESS:
      return {
        ...initialStateSingle,
        data,
        loading: false,
      };
    case GET_SINGLE_WORK_DETAILS_ERR:
      return {
        ...initialStateSingle,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};

export default adminSingleArtWork;