
const FormalCategoriesAffinity = (unratedFormalCategoriesData) => {
    var FormalCategoriesData = unratedFormalCategoriesData
    var sumOfFormalCategoriesValue = 0
    var Affinity = 0
    var finalFormalCategoriesAffinity = []

    for (var i = 0; i < unratedFormalCategoriesData.length; i++) {
        var step1 = unratedFormalCategoriesData[i]

        const aaaa = Object.values(step1);

        aaaa.map((itm) => {
            if (itm.name) {
                var absolute_val = Math.abs(itm.affinitystep1)
                sumOfFormalCategoriesValue += absolute_val
            }
        })


        var totalLenghtOfFormalCategories = Object.keys(step1).length - 1

        Affinity = (sumOfFormalCategoriesValue) / (totalLenghtOfFormalCategories)

        finalFormalCategoriesAffinity.push({ ...FormalCategoriesData[i], Affinity })

        sumOfFormalCategoriesValue = 0
        Affinity = 0
    }
    return {finalFormalCategoriesAffinity}
}

export default FormalCategoriesAffinity