import actions from './actions';

const initialState = {
    data: null,
    loading: false,
    error: null,
  };

  const {
    Base64_BEGIN,
    Base64_SUCCESS,
    Base64_ERR
  } = actions;  
 
  const Base64Reducer = (state = initialState , action) =>{

    const { type, data, err } = action;
    switch (type) {
        case Base64_BEGIN:
        return {
            ...initialState,
            loading: true,
        };

        case Base64_SUCCESS:
        return {
            ...initialState,
            data,
            error: false,
            loading: false,
        };

        case Base64_ERR:
        return {
            ...initialState,
            error: err,
            loading: false,
        };

        default:
      return state;
    }

  }

  export default Base64Reducer;