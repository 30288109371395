const actions = {
	ADD_WORK_BEGIN: 'ADD_WORK_BEGIN',
	ADD_WORK_SUCCESS: 'ADD_WORK_SUCCESS',
	ADD_WORK_ERR: 'ADD_WORK_ERR',

	ADD_TEST_QUIZ_WORK_BEGIN: 'ADD_TEST_QUIZ_WORK_BEGIN',
	ADD_TEST_QUIZ_WORK_SUCCESS: 'ADD_TEST_QUIZ_WORK_SUCCESS',
	ADD_TEST_QUIZ_WORK_ERR: 'ADD_TEST_QUIZ_WORK_ERR',

	GET_WORK_BEGIN: 'GET_WORK_BEGIN',
	GET_WORK_SUCCESS: 'GET_WORK_SUCCESS',
	GET_WORK_ERR: 'GET_WORK_ERR',

	GET_SINGLE_WORK_DETAILS_BEGIN: 'GET_SINGLE_WORK_DETAILS_BEGIN',
	GET_SINGLE_WORK_DETAILS_SUCCESS: 'GET_SINGLE_WORK_DETAILS_SUCCESS',
	GET_SINGLE_WORK_DETAILS_ERR: 'GET_SINGLE_WORK_DETAILS_ERR',

	UPDATE_WORK_BEGIN: 'UPDATE_WORK_BEGIN',
	UPDATE_WORK_SUCCESS: 'UPDATE_WORK_SUCCESS',
	UPDATE_WORK_ERR: 'UPDATE_WORK_ERR',

	ADMIN_UPDATE_WORK_BEGIN: 'ADMIN_UPDATE_WORK_BEGIN',
	ADMIN_UPDATE_WORK_SUCCESS: 'ADMIN_UPDATE_WORK_SUCCESS',
	ADMIN_UPDATE_WORK_ERR: 'ADMIN_UPDATE_WORK_ERR',

	DELETE_WORK_BEGIN: 'DELETE_WORK_BEGIN',
	DELETE_WORK_SUCCESS: 'DELETE_WORK_SUCCESS',
	DELETE_WORK_ERR: 'DELETE_WORK_ERR',

	GET_IMAGES_WORK_BEGIN: 'GET_IMAGES_WORK_BEGIN',
	GET_IMAGES_WORK_SUCCESS: 'GET_IMAGES_WORK_SUCCESS',
	GET_IMAGES_WORK_ERR: 'GET_IMAGES_WORK_ERR',

	GET_WORK_DETAILS_BEGIN: 'GET_WORK_DETAILS_BEGIN',
	GET_WORK_DETAILS_SUCCESS: 'GET_WORK_DETAILS_SUCCESS',
	GET_WORK_DETAILS_ERR: 'GET_WORK_DETAILS_ERR',

	ADD_WORK_DETAILS_INTO_CART_BEGIN: 'ADD_WORK_DETAILS_INTO_CART_BEGIN',
	ADD_WORK_DETAILS_INTO_CART_SUCCESS: 'ADD_WORK_DETAILS_INTO_CART_SUCCESS',
	ADD_WORK_DETAILS_INTO_CART_ERR: 'ADD_WORK_DETAILS_INTO_CART_ERR',


	GET_CART_DETAILS_BEGIN: 'GET_CART_DETAILS_BEGIN',
	GET_CART_DETAILS_SUCCESS: 'GET_CART_DETAILS_SUCCESS',
	GET_CART_DETAILS_ERR: 'GET_CART_DETAILS_ERR',

	REMOVE_CART_ITEM_BEGIN: 'REMOVE_CART_ITEM_BEGIN',
	REMOVE_CART_ITEM_SUCCESS: 'REMOVE_CART_ITEM_SUCCESS',
	REMOVE_CART_ITEM_ERR: 'REMOVE_CART_ITEM_ERR',

	GET_ALL_ART_WORKS_BEGIN: 'GET_ALL_ART_WORKS_BEGIN',
	GET_ALL_ART_WORKS_SUCCESS: 'GET_ALL_ART_WORKS_SUCCESS',
	GET_ALL_ART_WORKS_ERR: 'GET_ALL_ART_WORKS_ERR',

	
	addWorkBegin: () => {
		return {
			type: actions.ADD_WORK_BEGIN,
		};
	},

	addWorkSuccess: data => {
		// console.log('in actions')
		// console.log(data)
		return {
			type: actions.ADD_WORK_SUCCESS,
			data,
		};
	},

	addWorkErr: err => {
		return {
			type: actions.ADD_WORK_ERR,
			err,
		};
	},

	addTestQuizWorkBegin: () => {
		return {
			type: actions.ADD_TEST_QUIZ_WORK_BEGIN,
		};
	},

	addTestQuizWorkSuccess: data => {
		// console.log('in actions')
		// console.log(data)
		return {
			type: actions.ADD_TEST_QUIZ_WORK_SUCCESS,
			data,
		};
	},

	addTestQuizWorkErr: err => {
		return {
			type: actions.ADD_TEST_QUIZ_WORK_ERR,
			err,
		};
	},

	getWorkBegin: () => {
		return {
			type: actions.GET_WORK_BEGIN,
		};
	},

	getWorkSuccess: data => {
		//console.log('in actions')
		// console.log(data)
		return {
			type: actions.GET_WORK_SUCCESS,
			data,
		};
	},

	getWorkErr: err => {
		return {
			type: actions.GET_WORK_ERR,
			err,
		};
	},

	getSingleWorkDetailsBegin: () => {
		return {
			type: actions.GET_SINGLE_WORK_DETAILS_BEGIN,
		};
	},

	getSingleWorkDetailsSuccess: data => {
		//console.log('in getSingleWorkDetailsSuccess actions')
		//console.log(data)
		return {
			type: actions.GET_SINGLE_WORK_DETAILS_SUCCESS,
			data,
		};
	},

	getSingleWorkDetailsErr: err => {
		return {
			type: actions.GET_SINGLE_WORK_DETAILS_ERR,
			err,
		};
	},

	updateWorkBegin: () => {
		return {
			type: actions.UPDATE_WORK_BEGIN,
		};
	},

	updateWorkSuccess: data => {
		//console.log('in actions')
		console.log(data)
		return {
			type: actions.UPDATE_WORK_SUCCESS,
			data,
		};
	},

	updateWorkErr: err => {
		return {
			type: actions.UPDATE_WORK_ERR,
			err,
		};
	},

	adminUpdateWorkBegin: () => {
		return {
			type: actions.ADMIN_UPDATE_WORK_BEGIN,
		};
	},

	adminUpdateWorkSuccess: data => {
		//console.log('in actions')
		console.log(data)
		return {
			type: actions.ADMIN_UPDATE_WORK_SUCCESS,
			data,
		};
	},

	adminUpdateWorkErr: err => {
		return {
			type: actions.ADMIN_UPDATE_WORK_ERR,
			err,
		};
	},

	deleteWorkBegin: () => {
		return {
			type: actions.DELETE_WORK_BEGIN,
		};
	},

	deleteWorkSuccess: workId => {
		return {
			type: actions.DELETE_WORK_SUCCESS,
			data: workId,
		};
	},

	deleteWorkErr: err => {
		return {
			type: actions.DELETE_WORK_ERR,
			err,
		};
	},


	getImagesWorkBegin: () => {
		return {
			type: actions.GET_IMAGES_WORK_BEGIN,
		};
	},

	getImagesWorkSuccess: data => {
		//console.log('in actions')
		// console.log(data)
		return {
			type: actions.GET_IMAGES_WORK_SUCCESS,
			data,
		};
	},

	getImagesWorkErr: err => {
		return {
			type: actions.GET_IMAGES_WORK_ERR,
			err,
		};
	},


	getWorkDetailsBegin: () => {
		return {
			type: actions.GET_WORK_DETAILS_BEGIN,
		};
	},

	getWorkDetailsSuccess: data => {
		return {
			type: actions.GET_WORK_DETAILS_SUCCESS,
			data,
		};
	},

	getWorkDetailsErr: err => {
		return {
			type: actions.GET_WORK_DETAILS_ERR,
			err,
		};
	},


	addDetailsIntoCartBegin: () => {
		return {
			type: actions.ADD_WORK_DETAILS_INTO_CART_BEGIN,
		};
	},

	addDetailsIntoCartSuccess: data => {
		// console.log('in actions')
		// console.log(data)
		return {
			type: actions.ADD_WORK_DETAILS_INTO_CART_SUCCESS,
			data,
		};
	},

	addDetailsIntoCartErr: err => {
		return {
			type: actions.ADD_WORK_DETAILS_INTO_CART_ERR,
			err,
		};
	},



	getCartDetailsBegin: () => {
		return {
			type: actions.GET_CART_DETAILS_BEGIN,
		};
	},

	getCartDetailsSuccess: data => {
		//console.log('in actions')
		// console.log(data)
		return {
			type: actions.GET_CART_DETAILS_SUCCESS,
			data,
		};
	},

	getCartDetailsErr: err => {
		return {
			type: actions.GET_CART_DETAILS_ERR,
			err,
		};
	},


	removeCartItemBegin: () => {
		return {
			type: actions.REMOVE_CART_ITEM_BEGIN,
		};
	},

	removeCartItemSuccess: data => {
		return {
			type: actions.REMOVE_CART_ITEM_SUCCESS,
			data,
		};
	},

	removeCartItemErr: err => {
		return {
			type: actions.REMOVE_CART_ITEM_ERR,
			err,
		};
	},

	getAllArtWorksBegin: () => {
		return {
			type: actions.GET_ALL_ART_WORKS_BEGIN,
		};
	},

	getAllArtWorksSuccess: data => {
		return {
			type: actions.GET_ALL_ART_WORKS_SUCCESS,
			data,
		};
	},

	getAllArtWorksWorkErr: err => {
		return {
			type: actions.GET_ALL_ART_WORKS_ERR,
			err,
		};
	},



};

export default actions;