import actions from './actions';

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const {
  FB_GET_USER_INFO_BEGIN,
  FB_GET_USER_INFO_SUCCESS,
  FB_GET_USER_INFO_ERR,

  FB_UPDATE_USER_INFO_BEGIN,
  FB_UPDATE_USER_INFO_SUCCESS,
  FB_UPDATE_USER_INFO_ERR,
} = actions;

const ProfileReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case FB_GET_USER_INFO_BEGIN:
      return {
        ...initialState,
        loading: true,
      };

    case FB_GET_USER_INFO_SUCCESS:
      return {
        ...initialState,
        data,
        error: false,
        loading: false,
      };

    case FB_GET_USER_INFO_ERR:
      return {
        ...initialState,
        error: err,
        loading: false,
      };
    
    case FB_UPDATE_USER_INFO_BEGIN:
      return {
        ...initialState,
        loading: true,
      };

    case FB_UPDATE_USER_INFO_SUCCESS:
      console.log('FB_UPDATE_USER_INFO_SUCCESS')
      console.log(data)
      return {
        ...initialState,
        data,
        error: false,
        loading: false,
      };

    case FB_UPDATE_USER_INFO_ERR:
      return {
        ...initialState,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export default ProfileReducer;
