import { theme, darkTheme } from './theme/themeVariables';

const config = {
  darkMode: false,
  topMenu: false,
  rtl: false,
  theme,
  darkTheme,
  apiUrl: process.env.REACT_APP_API_URL,
  shareUrl: process.env.REACT_APP_SHARE_URL,
  googleUrl:process.env.REACT_APP_RECAPTCHA_API_URL,
};

export default config;
