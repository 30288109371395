import actions from './actions';

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const {
    GET_WORK_FILTER_DATA_BEGIN,
    GET_WORK_FILTER_DATA_SUCCESS,
    GET_WORK_FILTER_DATA_ERR

} = actions;

const WorkFilterReducer = (state = initialState, action) => {

  const { type, data, err } = action;
  switch (type) {
    case GET_WORK_FILTER_DATA_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case GET_WORK_FILTER_DATA_SUCCESS:
      // console.log('USER_LIST_SUCCESS',data)
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case GET_WORK_FILTER_DATA_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

      default:
        return state;
  }

}

export default WorkFilterReducer;