import axios from 'axios'
import { useEffect, useState } from 'react'
import config from '../../../../../config/config'

const GetSnippetsData = (role) => {
  const [snippetData, setSnippetData] = useState("")

  useEffect(() => {

    (async () => {
      const result = await axios.get(`${config.apiUrl}/get-snippets/${role}`);
      if (result.data.length > 0) {
        if (role == "EarlyWork") {
          let snitppets_data = JSON.parse(result.data[0].snitppets_data);
          console.log("snitppets_data :", snitppets_data);
          if (result.data && result.data[0] && result.data[0].image1) {
            //console.log("result.data[0] :",result.data[0].image1);
            snitppets_data = { ...snitppets_data, image1: result.data[0].image1 };
          }
          if (result.data && result.data[0] && result.data[0].image2) {
            //console.log("result.data[0] :",result.data[0].image2);
            //snitppets_data.push({image2:result.data[0].image2});
            snitppets_data = { ...snitppets_data, image2: result.data[0].image2 };
          }

          setSnippetData(snitppets_data)
        } else {
          setSnippetData(JSON.parse(result.data[0].snitppets_data))
        }

      }

    })();
  }, [role])


  return snippetData
}

export default GetSnippetsData