const actions = {
	

	GET_TASTE_QUIZ_IMAGES_WORK_BEGIN: 'GET_TASTE_QUIZ_IMAGES_WORK_BEGIN',
	GET_TASTE_QUIZ_IMAGES_WORK_SUCCESS: 'GET_TASTE_QUIZ_IMAGES_WORK_SUCCESS',
	GET_TASTE_QUIZ_IMAGES_WORK_ERR: 'GET_TASTE_QUIZ_IMAGES_WORK_ERR',




	getTestQuizImagesWorkBegin: () => {
		return {
			type: actions.GET_TASTE_QUIZ_IMAGES_WORK_BEGIN,
		};
	},

	getTestQuizImagesWorkSuccess: data => {
		//console.log('in actions')
		// console.log(data)
		return {
			type: actions.GET_TASTE_QUIZ_IMAGES_WORK_SUCCESS,
			data,
		};
	},

	getTestQuizImagesWorkErr: err => {
		return {
			type: actions.GET_TASTE_QUIZ_IMAGES_WORK_ERR,
			err,
		};
	},





};

export default actions;