import React, { useEffect, useRef, useState, useCallback } from 'react'
import { useHistory, NavLink, Link } from 'react-router-dom';
import { Form, Col, Row, Space, Modal, Skeleton, Popover } from 'antd';
import { Button } from '../../../../components/buttons/buttons';
import { useDispatch, useSelector } from 'react-redux';
import EmptyQueue from '../../../../static/img/EmptyQueue.png'
import FeatherIcon from 'feather-icons-react';
import Slider from "react-slick";
import config from '../../../../config/config';
import axios from 'axios';
import { getImages } from '../../../../redux/work/actionCreator';
import TasteQuizContentJson from '../../../../config/constants/materials/tastequizcontent.json'
import Color_Taste from '../../../../config/constants/materials/color_taste';
import TasteQuizMaterialJson from '../../../../config/constants/materials/tastequizmaterials';
import Materials1 from '../../../../config/constants/materials/materials1.json';
import Materials2 from '../../../../config/constants/materials/materials2.json';
import TasteQuizCategoriesJson from '../../../../config/constants/materials/tastequizcategories';
import TasteQuizMaterial from '../../../../config/constants/materials/tastequizmaterials.json'
import { getWorkDetails } from '../../../../redux/SingleWork/actionCreator';
import count from '../../../../redux/count/actionCreator'
import './Payment.css'
import './Ribbon.css'
import ColorAverage from './AttributeAverageCalculation/ColorAverage';
import ContentAverage from './AttributeAverageCalculation/ContentAverage';
import MaterialsAverage from './AttributeAverageCalculation/MaterialsAverage';
import FormalCategoriesAverage from './AttributeAverageCalculation/FormalCategoriesAverage';
import ColorTasteProfile from './TasteProfileCalculation/ColorTasteProfile';
import ContentTasteProfile from './TasteProfileCalculation/ContentTasteProfile';
import MaterialsTasteProfile from './TasteProfileCalculation/MaterialsTasteProfile';
import FormalCategoriesTasteProfile from './TasteProfileCalculation/FormalCategoriesTasteProfile';
import FormalCategories from './AttributeAverageCalculation/FormalCategories';
import './SliderComponent/css/arrows.css'
import CommonFooter from "./CommonFooter";
import ColorAffinity from './AffinityCalculation/ColorAffinity';
import ContentAffinity from './AffinityCalculation/ContentAffinity';
import MaterialsAffinity from './AffinityCalculation/MaterialsAffinity';
import FormalCategoriesAffinity from './AffinityCalculation/FormalCategoriesAffinity';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import leftArrowCircle from '../../../../static/img/icon/arrow-left-circle.svg'
import rightArrowCircle from '../../../../static/img/icon/arrow-right-circle.svg'

import GetSnippetsData from '../../authentication/overview/Snippets/GetSnippetsData';
import { likeStyle, disLikeStyle, skipStyle, defaultStyle, skipDefaultStyle, modalStyle } from '../../../../container/profile/authentication/overview/GalleryComponents/css/RatingStyle'

import like_icon from '../../../../static/img/icon/like-button.svg';
import like_icon_hover from '../../../../static/img/icon/like-hover-button.svg';
import dislike_icon from '../../../../static/img/icon/dislike-button.svg';
import dislike_icon_hover from '../../../../static/img/icon/dislike-hover-button.svg';
var event = 'go_back';

var base64Img = require('base64-img');



const settings = {
	dots: false,
	infinite: false,
	speed: 500,
	slidesToShow: 1,
	slidesToScroll: 1,
	adaptiveHeight: true,
	arrows: false,
};


const colorArray = Color_Taste;
const categoriesArray = TasteQuizContentJson

const TasteQuiz = () => {


	const slider = useRef();

	const next = () => {
		slider.current.slickNext();
	};
	const previous = () => {
		slider.current.slickPrev();
	};
	const history = useHistory();
	const dispatch = useDispatch();
	const { isLoading, error, isFbAuthenticate } = useSelector(state => {
		return {
			error: state.firebaseAuth.error,
			isLoading: state.firebaseAuth.loading,
			isFbAuthenticate: state.fb.auth.uid,
		};
	});

	useEffect(() => {
		if (isFbAuthenticate) {
			// handleFbLogin();
		}
		else {
			history.push('/');
		}
		// }, [isFbAuthenticate, handleFbLogin]);
	}, [isFbAuthenticate]);

	const handleMouseEnter = useCallback((iconName) => {
		if (iconName == 'likeIcon') {
			setLikeHoverIcon(true);
		} else if (iconName == 'dislikeIcon') {
			setDisLikeHoverIcon(true);
		} else {
			setCartHoverIcon(true);
		}
	}, []);

	const handleMouseLeave = useCallback((iconName, workId) => {

		if (iconName == 'likeIcon') {
			setLikeHoverIcon(false);
			//checkLikeDislike(workId);
		} else if (iconName == 'dislikeIcon') {
			setDisLikeHoverIcon(false);
			//checkLikeDislike(workId);
		} else {
			setCartHoverIcon(false);
		}
	}, []);


	const [likeBgColour, setLikeBgColour] = useState("#fff")
	const [dislikeBgColour, setDislikeBgColour] = useState("#fff")

	const [likeHoverIcon, setLikeHoverIcon] = useState(false)
	const [dislikeHoverIcon, setDisLikeHoverIcon] = useState(false)


	const [likeratingColour, setLikeRatingColour] = useState("#000")
	const [dislikeratingColour, setDislikeRatingColour] = useState("#000")
	const [base64, setBase64] = useState("")

	const appLikeStyles = {
		color: `${likeratingColour}`,
		background: `${likeBgColour}`,
		padding: '5px',
		height: '35px',
		width: '35px',
		borderRadius: '20px',
		border: "1px solid #c0c0c0f2",

	}

	const appDislikeStyles = {
		color: `${dislikeratingColour}`,
		background: `${dislikeBgColour}`,
		padding: '5px',
		height: '35px',
		width: '35px',
		borderRadius: '20px',
		border: "1px solid #c0c0c0f2",

	}




	const works = useSelector(state => {
		return state.works.data;
	})

	const tasteQuizWorks = useSelector(state => {
		return state.tasteQuizWorkReducer.data;

	})

	//console.log("tasteQuizWorks", tasteQuizWorks)

	const UID = useSelector((state) => {
		return state.fb.auth.uid;
	});

	const [state, setState] = useState({
		flagImageLoaded: false,
	});


	useEffect(() => {
		dispatch(getImages(UID));
		var show_purchase_taste_modal = sessionStorage.getItem('show_purchase_taste_modal');
		if (show_purchase_taste_modal == 1) {
			setIsPurchaseSuccessModalVisible(true)
		}

		dispatch(count(UID));
	}, [dispatch]);


	const [ratingData, setRatingData] = useState([])

	useEffect(() => {
		(async () => {
			const result = await axios.get(`${config.apiUrl}/get-taste-test-rating/${UID}`);
			// console.log("result",result)
			// setData(result.data);
			// const data = await result.json();
			//console.log("home data", result.data)
			if (result.data.length > 0) {
				setRatingData(JSON.parse(result.data[0].user_choices))
			}

		})();
	}, []);



	const [newLikeArrayData, setNewLikeArrayData] = useState([]);
	const [tasteTestAverageData, setTasteTestAverageData] = useState([]);

	//this api updated
	useEffect(() => {
		axios.get(`${config.apiUrl}/get-taste-test-rating-average-data/${UID}`)
			.then(function (response) {
				setNewLikeArrayData(JSON.parse(response.data[0].taste_quiz_choices));
				setTasteTestAverageData(response.data[0].taste_test_average_data)
				sessionStorage.setItem('taste_test_status', response.data[0].taste_test_status)
			})
			.catch(function (error) {
				console.log(error);
			});
	}, []);


	var result = tasteQuizWorks.filter(function (o1) {
		return !newLikeArrayData.some(function (o2) {
			if (o1.id === o2.id) {
				return o1.id === o2.id
			}
		});
	});


	var getUnratedAllArtWorks = works.filter(function (o1) {
		return !ratingData.some(function (o2) {
			if (o1.id === o2.id) {
				return o1.id === o2.id && o2.like != -2
			}
		});
	});


	if (isFbAuthenticate) {
		//let result = works.filter(o1 => !newLikeArrayData.some(o2 => o1.id === o2.id));

		result.map((itm) => {
			return (
				newLikeArrayData.push({ id: itm.id, like: -2 })
			)
		})

	}


	const [form] = Form.useForm();



	const [tasteTestglobalData, setTasteTestglobalData] = useState([])
	const [tasteTestColorTasteProfile, setTasteTestColorTasteProfile] = useState([])
	const [tasteTestContentTasteProfile, setTasteTestContentTasteProfile] = useState([])
	const [tasteTestMaterialsTasteProfile, setTasteTestMaterialsTasteProfile] = useState([])
	const [tasteTestFormalCategoryTasteProfile, setTasteTestFormalCategoryTasteProfile] = useState([])

	const clickhandle = async (imgId, likeDislikeShipValue, parsedColorArr, parsedformalCategoriesArr, parsedContentArr, parsedMaterialArr, index, totalArtWorkLength) => {
		setState({
			flagImageLoaded: false,
		});
		event = 'go_back';

		if (newLikeArrayData.length > 0) {
			const selectdata = {
				like: newLikeArrayData,
				UID: UID
			}

			const handleClickableData = [{
				id: imgId,
				value: likeDislikeShipValue,
				data: parsedColorArr
			}]

			var userRateValue
			var colorNotSelectedValue

			if (likeDislikeShipValue == 1) {
				userRateValue = 1 - 0.1
				colorNotSelectedValue = 0 - 0.1

			} else if (likeDislikeShipValue == 2) {
				userRateValue = 1 - (-0.2)
				colorNotSelectedValue = 0 - (-0.2)
			} else {
				userRateValue = 1 - 0
				colorNotSelectedValue = 0
			}

			/*color average calculation code here start*/
			const { colorTasteProfile } = ColorAverage(parsedColorArr, userRateValue, colorNotSelectedValue)
			/*color average calculation code here end */

			/*content average calculation code here start */
			const { appliedContentToArtWork } = ContentAverage(parsedContentArr, userRateValue)
			/*content average calculation code here end */

			/*materials average calculation code here start  */
			const { materialTasteProfile } = MaterialsAverage(parsedMaterialArr, userRateValue, colorNotSelectedValue)
			/*materials average calculation code here end */


			/*formalcategories average calculation code here start  */
			const { formalCategoriesTasteProfileArray } = FormalCategoriesAverage(parsedformalCategoriesArr, likeDislikeShipValue, tasteTestAverageData)
			/*formalcategories average calculation code here end  */



			let allCategoryAttributeData = [{ colorTasteProfile, contentTasteProfile: appliedContentToArtWork, materialTasteProfile, formalCategoriesTasteProfile: formalCategoriesTasteProfileArray, id: imgId }]

			let calculationData = {
				attributes: allCategoryAttributeData,
				// counter: latestCounter,
				UID: UID
			}

			// setTasteTestAverageData

			axios.post(`${config.apiUrl}/update-taste-test-average`, calculationData)
				.then(function (response) {
					setTasteTestAverageData(response.data[0].taste_test_average_data)
					let globalData = JSON.parse(response.data[0].taste_test_average_data)

					/*color tasteprofile calculation code here start */
					const { color_Array_Data } = ColorTasteProfile(globalData)
					/*color tasteprofile calculation code here end */

					/*content tasteprofile calculation code here start */
					const { content_Array_Data } = ContentTasteProfile(globalData)
					/*content tasteprofile calculation code here end */

					/*content tasteprofile calculation code here start */
					const { material_Array_Data } = MaterialsTasteProfile(globalData)
					/*content tasteprofile calculation code here end */

					/*formalcategory tasteprofile calculation code here start */
					const { formalCategoryTasteProfile } = FormalCategoriesTasteProfile(globalData)
					/*formalcategory tasteprofile calculation code here end */

					let tasteProfileData = {
						...selectdata,
						color_taste_profile: color_Array_Data,
						content_taste_profile: content_Array_Data,
						material_taste_profile: material_Array_Data,
						formal_categories_taste_profile: formalCategoryTasteProfile,
						taste_test_global_data: globalData
					}


					axios.post(`${config.apiUrl}/taste-quiz-like-diklike-skip`, tasteProfileData)
						.then(function (response) {
							if (artWorkIndex == totalArtWorkLength) {
								// console.log("if condition executes")
								setTasteTestglobalData(JSON.parse(response.data[0].taste_test_average_data))
								setTasteTestColorTasteProfile(JSON.parse(response.data[0].color_taste_profile))
								setTasteTestContentTasteProfile(JSON.parse(response.data[0].content_taste_profile))
								setTasteTestMaterialsTasteProfile(JSON.parse(response.data[0].material_taste_profile))
								setTasteTestFormalCategoryTasteProfile(JSON.parse(response.data[0].formal_categories_taste_profile))

								setArtWorkIndex(index + 1)
								setLikeBgColour("#fff")
								setDislikeBgColour("#fff")
								setLikeRatingColour("#000")
								setDislikeRatingColour("#000")
								sessionStorage.setItem('taste_test_status', 1)
								closeModal()
							} else {
								// console.log("else condition executes")
								setArtWorkIndex(index + 1)
								setLikeBgColour("#fff")
								setDislikeBgColour("#fff")
								setLikeRatingColour("#000")
								setDislikeRatingColour("#000")
							}

						})
						.catch(function (error) {
							console.log(error);
						});


				})
				.catch(function (error) {
					console.log(error);
				});

		}
		setLikeHoverIcon(false)
		setDisLikeHoverIcon(false)
	}

	const handleLike = (imgId, colorData, formal_categories_json_data, content, materials, index, totalArtWorkLength) => {

		newLikeArrayData.map((item) => {
			if (item.id === imgId) {
				item.like = 1
			}
		})
		setBase64("")

		var likeDislikeShipValue = 1;
		var parsedColorArr = JSON.parse(colorData);
		var parsedformalCategoriesArr = JSON.parse(formal_categories_json_data)
		var parsedContentArr = JSON.parse(content)
		var parsedMaterialArr = JSON.parse(materials)

		clickhandle(imgId, likeDislikeShipValue, parsedColorArr, parsedformalCategoriesArr, parsedContentArr, parsedMaterialArr, index, totalArtWorkLength);
		// console.log("liked Array", newLikeArrayData);
	}
	const handleDisLike = (imgId, colorData, formal_categories_json_data, content, materials, index, totalArtWorkLength) => {


		newLikeArrayData.map((item) => {
			if (item.id === imgId) {
				item.like = -1
			}
		})
		setBase64("")
		var likeDislikeShipValue = 2;
		var parsedColorArr = JSON.parse(colorData);
		var parsedformalCategoriesArr = JSON.parse(formal_categories_json_data)
		var parsedContentArr = JSON.parse(content)
		var parsedMaterialArr = JSON.parse(materials)

		clickhandle(imgId, likeDislikeShipValue, parsedColorArr, parsedformalCategoriesArr, parsedContentArr, parsedMaterialArr, index, totalArtWorkLength);
		//console.log("Disliked Array", newLikeArrayData);


	}
	const handleskip = (imgId, colorData, formal_categories_json_data, content, materials, index, totalArtWorkLength) => {

		newLikeArrayData.map((item) => {
			if (item.id === imgId) {
				item.like = 0
			}
		})
		var likeDislikeShipValue = 0;

		var parsedColorArr = JSON.parse(colorData);
		var parsedformalCategoriesArr = JSON.parse(formal_categories_json_data)
		var parsedContentArr = JSON.parse(content)
		var parsedMaterialArr = JSON.parse(materials)

		clickhandle(imgId, likeDislikeShipValue, parsedColorArr, parsedformalCategoriesArr, parsedContentArr, parsedMaterialArr, index, totalArtWorkLength);
		//console.log("Skip Array", newLikeArrayData);


	}
	const closeModal = () => {
		setIsTasteQuizSuccessModalVisible(true);
	}

	const [isTasteQuizSuccessModalVisible, setIsTasteQuizSuccessModalVisible] = useState(false);
	const [isPurchaseSuccessModalVisible, setIsPurchaseSuccessModalVisible] = useState(false);

	const handleTasteQuizSuccessOk = () => {
		setIsTasteQuizSuccessModalVisible(false);
		sessionStorage.removeItem("visible")
		location.reload();
	};
	const handlePurchaseSuccessCancel = () => {
		setIsPurchaseSuccessModalVisible(false);
		sessionStorage.removeItem("show_purchase_taste_modal")

	};

	const [calculateColorData1, setCalculateColorData1] = useState([])

	const handleTasteQuizSuccessCancel = () => {


		setIsTasteQuizSuccessModalVisible(false);
		//history.push('/')

		sessionStorage.setItem('taste_view_visible', false);
		sessionStorage.removeItem("visible")

		// var allCategorySessionStorageData = JSON.parse(sessionStorage.getItem('tempGlobalData'))
		var allCategorySessionStorageData = tasteTestglobalData

		//console.log("allCategorySessionStorageData", allCategorySessionStorageData)
		if (allCategorySessionStorageData) {

			axios.get(`${config.apiUrl}/user-likes/${UID}`)
				.then(function (response) {

					// setNewLikeArrayData(JSON.parse(response.data[0].user_choices));
					// setCalculateColorData1(JSON.parse(response.data[0].rating));

					var unRatedArtWorkData = getUnratedAllArtWorks

					var unratedColorData = []
					var unratedCategoriesData = []
					var unratedMaterial_X_Y_Data = []
					var unratedFormalCategoriesData = []

					var sessionStorageData = allCategorySessionStorageData

					const getTasteProfile = sessionStorageData[sessionStorageData.length - 1]

					const CalculationFormalCategoriesObject = getTasteProfile.formalCategoriesTasteProfile

					unRatedArtWorkData.map((itm) => {

						var materialX = itm.materials1 != null ? JSON.parse(itm.materials1) : []
						var materialY = itm.materials2 != null ? JSON.parse(itm.materials2) : []

						var materialsX_materialsY = (materialX).concat(materialY);
						let userRateValue = 1
						let colorNotSelectedValue = 0

						/*color average calculation code here start*/
						var parsedColorArr = JSON.parse(itm.color_taste);
						const { colorTasteProfile } = ColorAverage(parsedColorArr, userRateValue, colorNotSelectedValue)
						/*color average calculation code here end */


						/*content average calculation code here start */
						var parsedContentArr = itm.categories

						if (typeof itm.categories === "string") {
							parsedContentArr = JSON.parse(itm.categories);
						}
						const { appliedContentToArtWork: appliedCategoryToArtWork } = ContentAverage(parsedContentArr, userRateValue)
						/*content average calculation code here end */


						/*materials average calculation code here start  */
						var parsedMaterialXArr = materialsX_materialsY;
						const { materialTasteProfile: materialXTasteProfile } = MaterialsAverage(parsedMaterialXArr, userRateValue, colorNotSelectedValue)
						/*materials average calculation code here end */


						/*formalcategories average calculation code here start  */
						var parsedFormalCategoriesArr = JSON.parse(itm.formal_categories_json_data)
						const { appliedFormalCategoriesToArtWork } = FormalCategories(parsedFormalCategoriesArr, CalculationFormalCategoriesObject)
						/*formalcategories average calculation code here end  */

						unratedColorData.push({ ...colorTasteProfile, id: itm.id })
						unratedCategoriesData.push({ ...appliedCategoryToArtWork, id: itm.id })
						unratedMaterial_X_Y_Data.push({ ...materialXTasteProfile, id: itm.id })
						unratedFormalCategoriesData.push({ ...appliedFormalCategoriesToArtWork, id: itm.id })

					})



					// console.log("allCategorySessionStorageData", tasteTestFormalCategoryTasteProfile)


					var colorAvgData = tasteTestColorTasteProfile
					var categoryAvgData = tasteTestContentTasteProfile
					var materialAvgData = tasteTestMaterialsTasteProfile




					/* Affinity Calculation */


					/*color affinity calculation code here start */
					const { color_affinity_data } = ColorAffinity(colorAvgData, unratedColorData)
					/*color affinity calculation code here start */

					/*content affinity calculation code here start */
					const { categories_affinity_data } = ContentAffinity(categoryAvgData, unratedCategoriesData)
					/*content affinity calculation code here start */

					/*materials affinity calculation code here start */
					const { materialX_Y_affinity_data } = MaterialsAffinity(materialAvgData, unratedMaterial_X_Y_Data)
					/*materials affinity calculation code here start */

					/*formalcategories affinity calculation code here start */
					const { finalFormalCategoriesAffinity } = FormalCategoriesAffinity(unratedFormalCategoriesData)

					// console.log("color_affinity_data", color_affinity_data)
					// console.log("categories_affinity_data", categories_affinity_data)
					// console.log("materialX_Y_affinity_data", materialX_Y_affinity_data)
					// console.log("finalFormalCategoriesAffinity", finalFormalCategoriesAffinity)


					if (color_affinity_data.length > 0) {
						let newArray = []
						for (var i = 0; i < color_affinity_data.length; i++) {

							var colorAffinity = color_affinity_data[i].Affinity
							var materialsAffinity = materialX_Y_affinity_data[i].Affinity
							var formalCategoriesAffinity = finalFormalCategoriesAffinity[i].Affinity
							var categoriesAffinity = categories_affinity_data[i].Affinity

							var finalAffinityScore = ((colorAffinity + (2 * materialsAffinity) + (3 * formalCategoriesAffinity) + (2 * categoriesAffinity))) / 8
							newArray.push({ finalAffinityScore, id: color_affinity_data[i].id })

						}

						var getFinalAffinityData = newArray
						if (getFinalAffinityData) {
							var artWorkAffinityData = {
								finalAffinityScore: getFinalAffinityData,
								unratedColorData: color_affinity_data,
								unratedCategoriesData: categories_affinity_data,
								unratedMaterial_X_Y_Data: materialX_Y_affinity_data,
								unratedFormalCategoriesData: finalFormalCategoriesAffinity,
								taste_taste_status: 1,
								UID: UID
							}

							axios.post(`${config.apiUrl}/update-artwork-affinity-score`, artWorkAffinityData)
								.then(function (response) {
									sessionStorage.setItem('taste_test_status', 1)
									window.location = '/gallery';
									//dispatch(getImages(UID));
								})
								.catch(function (error) {
									console.log(error);
								});
						}

					} else {
						var artWorkAffinityData = {
							taste_taste_status: 1,
							UID: UID
						}
						axios.post(`${config.apiUrl}/update-artwork-affinity-score`, artWorkAffinityData)
							.then(function (response) {
								//history.push('/')
								sessionStorage.setItem('taste_test_status', 1)
								//dispatch(getImages(UID));
								window.location = '/gallery';
							})
							.catch(function (error) {
								console.log(error);
							});
					}

					// console.log("success to retrive data")
				})
				.catch(function (error) {
					console.log(error);
				});
		} else {
			console.log("failed to fetch data")
		}
	};

	const tasteQuizData = JSON.parse(sessionStorage.getItem("tempGlobalData"))
	const tasteQuizAWLength = sessionStorage.getItem('tasteQuizArtWorkLength')


	const [artWorkIndex, setArtWorkIndex] = useState(0);

	const handleGoBack = () => {
		setLikeHoverIcon(false)
		setDisLikeHoverIcon(false)
		event = 'next';
		setBase64("")
		setArtWorkIndex(artWorkIndex - 1)
		setLikeBgColour("#fff")
		setDislikeBgColour("#fff")
		setLikeRatingColour("#000")
		setDislikeRatingColour("#000")
	}

	const handleNext = () => {
		setLikeHoverIcon(false)
		setDisLikeHoverIcon(false)
		event = 'go_back';

		setBase64("")
		setArtWorkIndex(artWorkIndex + 1)
		setLikeBgColour("#fff")
		setDislikeBgColour("#fff")
		setLikeRatingColour("#000")
		setDislikeRatingColour("#000")
	}
	const base64Image = (url) => {
		base64Img.requestBase64(url, function (err, res, body) {
			if (err) {
				setBase64(url)
				return err;
			}
			setBase64(body)
		});
	}

	const taste_quiz_completion_message = "Taste_Quiz_Completion_Message"
	const tasteQuizCompletedSnippetData = GetSnippetsData(taste_quiz_completion_message)

	let tasteTestStatus = sessionStorage.getItem('taste_test_status')
	return (
		<section style={{ top: '72px', position: 'fixed', overflow: 'hidden auto', height: 'calc(100% - 72px)', width: '100%' }}>

			<Modal title="Welcome to Stoodio!"
				visible={isTasteQuizSuccessModalVisible}
				onOk={handleTasteQuizSuccessOk}
				onCancel={handleTasteQuizSuccessCancel}
				footer={[
					<button size='default' class="slide-from-bottom-test-quiz slide-from-bottom add-cart-button-text" onClick={handleTasteQuizSuccessCancel} style={{ cursor: 'pointer', height: '30px !important' }}>
						<p style={{ fontFamily: 'Arial', fontSize: 13, margin: 0, fontWeight: 600, lineHeight: '24px', letterSpacing: ' 0em', textAlign: 'left' }}>Close</p>
					</button>
				]}
			>

				{
					tasteTestStatus
						?
						tasteTestStatus == 1
							?
							<>
								<p dangerouslySetInnerHTML={{ __html: tasteQuizCompletedSnippetData != "" ? tasteQuizCompletedSnippetData.tasteQuizCompletionMessageContent : "" }} />
								{/* <p>
									Thanks for taking the Taste Test! We will match works that best cater to your likes first!
									Enjoy browsing the gallery.
								</p>

								<p>
									If you made a mistake and would like to re-rate an art work, click the menu, go to your profile,
									and go to the "Ratings" section. It will allow you to reset any rating for any art work that was
									in your queue.
								</p> */}
							</>

							:
							<p>
								It looks like you have not finished your Taste Test,
								please click on the Taste Test in the menu above so we can develop an accurate Taste Profile for you.
							</p>

						:
						<p>
							If you did not rate art works in this taste test, we will create a taste profile for you
							as you rate works in our gallery.
						</p>
				}



			</Modal>
			<Modal title="Welcome to Stoodio!"
				visible={isPurchaseSuccessModalVisible}
				onOk={handlePurchaseSuccessCancel}
				onCancel={handlePurchaseSuccessCancel}
				footer={[
					<button size='default' class="slide-from-bottom-test-quiz slide-from-bottom add-cart-button-text" onClick={handlePurchaseSuccessCancel} style={{ cursor: 'pointer', height: '30px !important' }}>
						<p style={{ fontFamily: 'Arial', fontSize: 13, margin: 0, fontWeight: 600, lineHeight: '24px', letterSpacing: ' 0em', textAlign: 'left' }}>Close</p>
					</button>
				]}
			>

				<p>
					Stoodio is here to help you discover artworks by leading emerging artists that are right for you. Our aim is to offer you, a collector and an art lover, a reliable and effective way of discovering and purchasing artwork without the need to navigate the gallery world. Taking our taste test will allow us to better serve you, showing you artworks you are most likely to enjoy first. Thanks for taking the taste test, and enjoy Stoodio. For any questions or comments, please contact us using the contact link in the footer.
				</p>



			</Modal>

			<div className='test' style={
				Object.assign({}
					, {
						height: 'calc(100% - 60px)',
						overflowY: 'scroll',

					}
				)}
			>
				{


					<div style={
						Object.assign({}
							, {
								marginBottom: 0,
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
							}
						)}
					>
						<Slider ref={(c) => (slider.current = c)} {...settings} lazyLoad='progressive'>

							{

								tasteQuizWorks && tasteQuizWorks.map((data, index) => {

									var totalArtWorkLength = tasteQuizWorks.length - 1

									if (index == artWorkIndex) {
										if (data.image_urls) {

											const imageInParse = JSON.parse(data.image_urls);

											let contentsArray = TasteQuizContentJson;

											var orientation

											if (data.width > data.height) {
												orientation = "horizontal"
											} else if (data.width == data.height) {
												orientation = "square"
											} else {
												orientation = "vertical"
											}


											var selectedContent = [];
											if (data.content) {
												var parsedContentArr = JSON.parse(data.content);
												for (var i = 0; i < contentsArray.length; i++) {
													for (var j = 0; j < parsedContentArr.length; j++) {
														if (contentsArray[i].value == parsedContentArr[j]) {
															selectedContent.push(contentsArray[i].label);
														}
													}
												}
											}

											// let categoriesArray = TasteQuizCategoriesJson;

											// var selectedCategories = [];
											// if (data.formal_categories) {
											// 	var parsedCategoriesArr = JSON.parse(data.formal_categories);
											// 	for (var i = 0; i < categoriesArray.length; i++) {
											// 		for (var j = 0; j < parsedCategoriesArr.length; j++) {
											// 			if (categoriesArray[i].value == parsedCategoriesArr[j]) {
											// 				selectedCategories.push(categoriesArray[i].label);
											// 			}
											// 		}
											// 	}
											// }

											// let colorArray = Color_Taste;

											// var appliedColorToArtWork = [];
											// if (data.colors) {
											// 	var parsedColorArr = JSON.parse(data.colors);
											// 	for (var i = 0; i < colorArray.length; i++) {
											// 		for (var j = 0; j < parsedColorArr.length; j++) {
											// 			if (colorArray[i].value == parsedColorArr[j]) {
											// 				appliedColorToArtWork.push(colorArray[i].label);
											// 			}
											// 		}
											// 	}
											// }




											let materials1Array = Materials1;

											var selectedMaterials1 = [];
											if (data.materials) {
												var parsedMaterialsArr = JSON.parse(data.materials);
												for (var i = 0; i < materials1Array.length; i++) {
													for (var j = 0; j < parsedMaterialsArr.length; j++) {
														if (materials1Array[i].value == parsedMaterialsArr[j]) {
															selectedMaterials1.push(materials1Array[i].label);
														}
													}
												}
											}
											var like_dislike_flag = 0;
											newLikeArrayData.map((item) => {

												if (item.id == data.id) {
													like_dislike_flag = item.like
												}
											})


											let materials2Array = Materials2;

											var selectedMaterials2 = [];
											if (data.materials) {
												var parsedMaterialsArr = JSON.parse(data.materials);
												for (var i = 0; i < materials2Array.length; i++) {
													for (var j = 0; j < parsedMaterialsArr.length; j++) {
														if (materials2Array[i].value == parsedMaterialsArr[j]) {
															selectedMaterials2.push(materials2Array[i].label);
														}
													}
												}
											}

											//console.log("imagesURLs",imagesURLs.length)
											if (imageInParse.length > 0) {
												//console.log("imageInParse", imageInParse[0].url)
												//base64Image(imageInParse[0].url)
												const imagesURL = imageInParse[0].url;
												//const imagesURL = base64;

												const flagImageLoaded = state.flagImageLoaded;
												var worklenght = tasteQuizWorks.length - 1
												var workIndexValue = index
												// console.log('worklenght', worklenght)
												// console.log("index", workIndexValue)
												sessionStorage.setItem('tasteQuizArtWorkLength', tasteQuizWorks.length)

												return (
													<>
														<div style={{ height: '100%' }} key={data.id}>
															{
																imagesURL &&
																<>
																	<div className='taste-test-img-container' style={{ display: 'grid', height: '100%' }}>
																		<Row style={{ display: 'grid', height: '100%' }}>
																			<Col xs={24} sm={24} md={24} lg={24}>
																				<>
																					<div style={{ display: flagImageLoaded ? 'none' : 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '22%' }}>
																						<i className="fa fa-spinner fa-spin" style={{ fontSize: 48, color: '#00CDFF' }} />
																					</div>
																					<div style={{ display: flagImageLoaded ? "flex" : 'none', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
																						<div style={{ display: "block" }}>
																							<div className="slider-banner" style={data.id == 0 ? { marginTop: 20, marginBottom: 20 } : {}}>
																								{
																									data.id == 0 ?
																										<Row>
																											<Col span={3}>
																												<Space style={{ justifyContent: 'center', width: "100%" }}>
																													<img src={leftArrowCircle} alt="left arrow" className='slider-left-arrows arrows-on-hv' onClick={() => { handleEmptyQwe() }} style={{ cursor: artWorkIndex == 0 ? "none" : "pointer", marginRight: 10, display: artWorkIndex == 0 ? "none" : "block", height: 40 }} />
																												</Space>
																											</Col>
																										</Row>
																										:
																										<>
																											<Row style={{ padding: '0px 10px' }} >
																												<Col span={3}>
																													<Space style={{ justifyContent: 'center', width: "100%" }}>
																														<img src={leftArrowCircle} alt="left arrow" className='slider-left-arrows arrows-on-hv' onClick={() => { handleGoBack() }} style={{ cursor: data.id == 0 || artWorkIndex == 0 ? "none" : "pointer", marginRight: 10, display: data.id == 0 || artWorkIndex == 0 ? "none" : "block", height: 40 }} />
																													</Space>
																												</Col>
																												<Col span={18} className='set-conetnt-mobile-gallery'></Col>

																												<Col span={3}>
																													<Space style={{ justifyContent: 'center', width: "100%" }}>
																														{/* <img src={rightArrowCircle} alt="right arrow" className='slider-left-arrows arrows-on-hv' onClick={() => { handleNext() }} style={{ cursor: data.id == 0 || totalArtWorkLength + 1 == artWorkIndex + 1 ? "none" : "pointer", marginLeft: 10, display: data.id == 0 || totalArtWorkLength + 1 == artWorkIndex + 1 ? "none" : "block", height: 40 }} /> */}
																														<Popover content='Please like or dislike this artwork before moving on' title="" trigger="hover">
																															<img src={rightArrowCircle} alt="right arrow" className={"immediate-display slider-left-arrows arrows-on-hv " + (like_dislike_flag == -2 ? "not-allowed" : "")} onClick={like_dislike_flag == -2 ? '' : () => { handleNext() }} style={{ cursor: data.id == 0 || totalArtWorkLength + 1 == artWorkIndex + 1 ? "none" : "pointer", marginLeft: 10, display: data.id == 0 || totalArtWorkLength + 1 == artWorkIndex + 1 ? "none" : "block", height: 40 }} />
																														</Popover>
																													</Space>
																												</Col>
																											</Row>

																											<Row >
																												<Col span={24} className='set-conetnt-mobile-gallery'>

																													<p style={{ marginBottom: '5px', marginTop: 20 }}>
																														<span /*className='material-info'*/ style={{ fontFamily: modalStyle.p_tag_font_style, color: 'black', marginBottom: 40 }}>
																															<h2 style={{ fontSize: 25 }} className='gallery-title'>
																																{data.work_title ? data.work_title : ''}
																															</h2>
																															<p className='gallery-material-line'>
																																{selectedMaterials1.slice(0, 3).join(', ').toLowerCase()}
																																{
																																	selectedMaterials2.length > 0 &&
																																	<span> on {selectedMaterials2.slice(0, 3).join(', ').toLowerCase()}</span>
																																}
																																{selectedMaterials2.length > 3 && <span> ...</span>}

																															</p>
																														</span>
																													</p>
																												</Col>
																											</Row>
																										</>

																								}

																							</div>
																							<div style={{ width: "100%", display: 'block' }} class="row">
																								<div >
																									<div className={orientation == 'vertical' ? 'fit-gallery-container' : 'fit-gallery-container'} style={{ display: flagImageLoaded ? "flex" : 'none', alignItems: 'center', justifyContent: 'center', margin: 0 }}>
																										<Col span={24} className='set-display-flex' style={{ width: "100%", height: '100%' }}>
																											{
																												data.id == 0 ?
																													<Col className='make-width-zero' style={{ width: "10%", position: 'static', alignItems: 'center', display: 'flex', justifyContent: 'left' }} span={2}>
																														<img src={leftArrowCircle} alt="left arrow" className='desktop-arrows arrows-on-hv  gallery-arrow-btn' onClick={() => { handleEmptyQwe() }} style={{ cursor: data.id == 0 ? "pointer" : "none", marginRight: 10, display: data.id == 0 ? "block" : "none", float: 'left', height: 56 }} />
																													</Col>
																													:

																													<Col className='make-width-zero' style={{ width: "10%", position: 'static', alignItems: 'center', display: 'flex', justifyContent: 'left' }} span={2}>
																														<img src={leftArrowCircle} alt="left arrow" className='desktop-arrows arrows-on-hv gallery-arrow-btn' onClick={() => { handleGoBack() }} style={{ cursor: artWorkIndex == 0 ? "none" : "pointer", marginRight: 10, display: artWorkIndex == 0 ? "none" : "block", float: 'left', height: 56 }} />
																													</Col>

																											}
																											<Col className='image-container-new-gallery' style={{ width: "350px", margin: '0 auto', alignItems: 'center', maxHeight: '800px', display: 'flex', overflow: 'hidden', height: '350px', position: 'static', textAlign: 'center' }} xxl={20} lg={20} md={18} xs={22}>
																												<div>
																													<div className={event == 'go_back' ? 'image2 adjust-image-height-mobile slide-left' : 'image2 adjust-image-height-mobile slide-right'} style={{ display: 'flex', justifyContent: 'center' }}>
																														{imagesURL == 'https://stoodio-dev-images.s3.amazonaws.com/Images/EmptyQueue.png' &&
																															<>
																																<div>
																																	<h1 className='home-content-heading' dangerouslySetInnerHTML={{ __html: snippetData != "" ? snippetData.QueueContent.split("<br>")[0] : "Looks like your queue is empty!" }}></h1>
																																	<h1 style={{ fontSize: '1.5em',fontFamily:'Arial' }} dangerouslySetInnerHTML={{ __html: snippetData != "" ? snippetData.QueueContent.split("<br>")[1] : "Come back soon to explore more art on STOODIO." }}></h1>
																																	<b className="auth-notice">
																																		Save your ratings by creating an account.<br />
																																		<b >
																																			<Link to="#" onClick={showSignupModal}>Sign Up Now</Link><br />
																																			<Link to="#" onClick={refreshQueue} style={{ lineHeight: '2.4' }}>Refresh The Queue</Link>
																																		</b>
																																	</b>

																																</div>
																															</>
																														}

																														{imagesURL != 'https://stoodio-dev-images.s3.amazonaws.com/Images/EmptyQueue.png' &&
																															<>
																																<img
																																	src={imagesURL}
																																	alt="image"
																																	onLoad={() => {
																																		setState({
																																			flagImageLoaded: true,
																																		});
																																	}}
																																	onLoadStart={() => {
																																		setState({
																																			flagImageLoaded: false,
																																		});
																																	}}
																																	//onClick={() => { handleScrollToDdetails(data.id) }}
																																	className="gallery-image-max-height"
																																	style={{ cursor: 'pointer', maxWidth: '100%' }}
																																//objectFit: 'cover', marginLeft: 'auto', marginRight: 'auto', WebkitFlexShrink: 0, maxWidth: '100%', maxHeight: '100%', flexShrink: 0,

																																/>
																															</>
																														}
																													</div>
																													<Row className='taste_quiz_slider_images show-mobile-gallery-icon hide-mobile-gallery-icon'
																														style={{ width: '100%', marginLeft: "auto", marginRight: "auto", marginTop: 0 }
																														}
																													>
																														<Col md={24} xs={24} sm={24} lg={24} xl={24}>
																															<div>
																																<>
																																	<Space style={{ justifyContent: 'center', width: '100%' }}>
																																		<table cellPadding="8">
																																			<tbody>
																																				<tr>
																																					<td>
																																						<div style={{ display: 'inline-flex' }} className='like-dislike-icon-box'>
																																							{
																																								(newLikeArrayData.map((item) => {
																																									if (!flagImageLoaded) {
																																										return null;
																																									}
																																									if (data.id === item.id) {
																																										if (item.like === 1) {
																																											return (<>
																																												<div onMouseEnter={() => handleMouseEnter('likeIcon')} onMouseLeave={() => handleMouseLeave('likeIcon', data.id)}>
																																													<img className="like-dislike" src={like_icon_hover} onClick={() => { handleLike(data.id, data.colors, data.formal_categories_json_data, data.content, data.materials, index, totalArtWorkLength) }} style={{ width: '20px', height: '20px', margin: '8px' }} />

																																												</div>
																																												<span style={{ marginTop: 10 }}>&nbsp;|&nbsp;</span>
																																												<div onMouseEnter={() => handleMouseEnter('dislikeIcon')} onMouseLeave={() => handleMouseLeave('dislikeIcon', data.id)}>
																																													{
																																														!dislikeHoverIcon && <img className="like-dislike" src={dislike_icon} onClick={() => { handleDisLike(data.id, data.colors, data.formal_categories_json_data, data.content, data.materials, index, totalArtWorkLength) }} style={{ width: '20px', height: '20px', margin: '8px' }} />
																																													}
																																													{
																																														dislikeHoverIcon && <img className="like-dislike" src={dislike_icon_hover} onClick={() => { handleDisLike(data.id, data.colors, data.formal_categories_json_data, data.content, data.materials, index, totalArtWorkLength) }} style={{ width: '20px', height: '20px', margin: '8px' }} />
																																													}
																																												</div>
																																											</>)
																																										} else if (item.like === -1) {
																																											return (<>
																																												<div onMouseEnter={() => handleMouseEnter('likeIcon')} onMouseLeave={() => handleMouseLeave('likeIcon', data.id)}>
																																													{
																																														!likeHoverIcon && <img className="like-dislike" src={like_icon} onClick={() => { handleLike(data.id, data.colors, data.formal_categories_json_data, data.content, data.materials, index, totalArtWorkLength) }} style={{ width: '24px', height: '24px', margin: '10px' }} />
																																													}
																																													{
																																														likeHoverIcon && <img className="like-dislike" src={like_icon_hover} onClick={() => { handleLike(data.id, data.colors, data.formal_categories_json_data, data.content, data.materials, index, totalArtWorkLength) }} style={{ width: '24px', height: '24px', margin: '10px' }} />
																																													}

																																												</div>
																																												<span style={{ marginTop: 10 }}>&nbsp;|&nbsp;</span>
																																												<div onMouseEnter={() => handleMouseEnter('dislikeIcon')} onMouseLeave={() => handleMouseLeave('dislikeIcon', data.id)}>
																																													<img className="like-dislike" src={dislike_icon_hover} onClick={() => { handleDisLike(data.id, data.colors, data.formal_categories_json_data, data.content, data.materials, index, totalArtWorkLength) }} style={{ width: '24px', height: '24px', margin: '10px' }} />
																																												</div>
																																											</>)
																																										} else {
																																											return (<>
																																												<div onMouseEnter={() => handleMouseEnter('likeIcon')} onMouseLeave={() => handleMouseLeave('likeIcon', data.id)}>
																																													{
																																														!likeHoverIcon && <img className="like-dislike" src={like_icon} onClick={() => { handleLike(data.id, data.colors, data.formal_categories_json_data, data.content, data.materials, index, totalArtWorkLength) }} style={{ width: '24px', height: '24px', margin: '10px' }} />
																																													}
																																													{
																																														likeHoverIcon && <img className="like-dislike" src={like_icon_hover} onClick={() => { handleLike(data.id, data.colors, data.formal_categories_json_data, data.content, data.materials, index, totalArtWorkLength) }} style={{ width: '24px', height: '24px', margin: '10px' }} />
																																													}

																																												</div>
																																												<span style={{ marginTop: 10 }}>&nbsp;|&nbsp;</span>

																																												<div onMouseEnter={() => handleMouseEnter('dislikeIcon')} onMouseLeave={() => handleMouseLeave('dislikeIcon', data.id)}>

																																													{
																																														!dislikeHoverIcon && <img className="like-dislike" src={dislike_icon} onClick={() => { handleDisLike(data.id, data.colors, data.formal_categories_json_data, data.content, data.materials, index, totalArtWorkLength) }} style={{ width: '24px', height: '24px', margin: '10px' }} />
																																													}
																																													{
																																														dislikeHoverIcon && <img className="like-dislike" src={dislike_icon_hover} onClick={() => { handleDisLike(data.id, data.colors, data.formal_categories_json_data, data.content, data.materials, index, totalArtWorkLength) }} style={{ width: '24px', height: '24px', margin: '10px' }} />
																																													}
																																												</div>
																																											</>)
																																										}
																																									}
																																								})
																																								)
																																							}
																																						</div>
																																					</td>
																																				</tr>
																																			</tbody>
																																		</table>
																																	</Space>
																																</>
																															</div>
																														</Col>
																													</Row>
																												</div>
																											</Col>

																											<Col style={{ width: "10%", display: 'flex', alignItems: 'center', position: 'static', justifyContent: 'right' }} span={2}>
																												<Popover content='Please like or dislike this artwork before moving on' title="" trigger="hover">
																													<img src={rightArrowCircle} alt="right arrow" className={"immediate-display desktop-arrows arrows-on-hv " + (like_dislike_flag == -2 ? "not-allowed" : "")} onClick={like_dislike_flag == -2 ? '' : () => { handleNext() }} style={{ textBrand: '#006C94', cursor: data.id == 0 || totalArtWorkLength + 1 == artWorkIndex + 1 ? "none" : "pointer", marginLeft: 10, display: data.id == 0 || totalArtWorkLength + 1 == artWorkIndex + 1 ? "none" : "block", height: 56, float: "right" }} />
																												</Popover>
																												{
																													data.sold == '1' && <Tooltip placement="bottomRight" title="Artwork sold">
																														<div className="sold-icon-gallery"></div>
																													</Tooltip>
																												}
																												{
																													data.sold == '2' && <Tooltip placement="bottomRight" title="Artwork put on hold by collector for purchase">
																														<div className="hold-icon-gallery"></div>
																													</Tooltip>
																												}
																											</Col>
																										</Col>

																									</div>
																									{
																										data.id == 0 ?
																											""
																											:
																											<>
																												<Row style={{ display: 'block', textAlign: 'center', marginTop: 10 }} className="art-info">
																													<h2 style={{ fontSize: 25 }} className='gallery-title'>
																														{data.work_title ? data.work_title : ''}
																													</h2>
																													<p className='gallery-material-line'>
																														{selectedMaterials1.slice(0, 3).join(', ').toLowerCase()}
																														{
																															selectedMaterials2.length > 0 &&
																															<span> on {selectedMaterials2.slice(0, 3).join(', ').toLowerCase()}</span>
																														}
																														{selectedMaterials2.length > 3 && <span> ...</span>}

																													</p>

																												</Row>
																												<Row className='taste_quiz_slider_images show-desktop-gallery-icon hide-desktop-gallery-icon'
																													style={{ width: '100%', marginLeft: "auto", marginRight: "auto", marginTop: 0 }
																													}
																												>
																													<Col md={24} xs={24} sm={24} lg={24} xl={24}>
																														<div>
																															<>
																																<Space style={{ justifyContent: 'center', width: '100%' }}>
																																	<table cellPadding="8">
																																		<tbody>
																																			<tr>
																																				<td>
																																					<div style={{ display: 'inline-flex' }} className='like-dislike-icon-box'>
																																						{
																																							(newLikeArrayData.map((item) => {
																																								if (!flagImageLoaded) {
																																									return null;
																																								}
																																								if (data.id === item.id) {
																																									if (item.like === 1) {
																																										return (<>
																																											<div onMouseEnter={() => handleMouseEnter('likeIcon')} onMouseLeave={() => handleMouseLeave('likeIcon', data.id)}>
																																												<img className="like-dislike" src={like_icon_hover} onClick={() => { handleLike(data.id, data.colors, data.formal_categories_json_data, data.content, data.materials, index, totalArtWorkLength) }} style={{ width: '20px', height: '20px', margin: '8px' }} />

																																											</div>
																																											<span style={{ marginTop: 10 }}>&nbsp;|&nbsp;</span>
																																											<div onMouseEnter={() => handleMouseEnter('dislikeIcon')} onMouseLeave={() => handleMouseLeave('dislikeIcon', data.id)}>
																																												{
																																													!dislikeHoverIcon && <img className="like-dislike" src={dislike_icon} onClick={() => { handleDisLike(data.id, data.colors, data.formal_categories_json_data, data.content, data.materials, index, totalArtWorkLength) }} style={{ width: '20px', height: '20px', margin: '8px' }} />
																																												}
																																												{
																																													dislikeHoverIcon && <img className="like-dislike" src={dislike_icon_hover} onClick={() => { handleDisLike(data.id, data.colors, data.formal_categories_json_data, data.content, data.materials, index, totalArtWorkLength) }} style={{ width: '20px', height: '20px', margin: '8px' }} />
																																												}
																																											</div>
																																										</>)
																																									} else if (item.like === -1) {
																																										return (<>
																																											<div onMouseEnter={() => handleMouseEnter('likeIcon')} onMouseLeave={() => handleMouseLeave('likeIcon', data.id)}>
																																												{
																																													!likeHoverIcon && <img className="like-dislike" src={like_icon} onClick={() => { handleLike(data.id, data.colors, data.formal_categories_json_data, data.content, data.materials, index, totalArtWorkLength) }} style={{ width: '24px', height: '24px', margin: '10px' }} />
																																												}
																																												{
																																													likeHoverIcon && <img className="like-dislike" src={like_icon_hover} onClick={() => { handleLike(data.id, data.colors, data.formal_categories_json_data, data.content, data.materials, index, totalArtWorkLength) }} style={{ width: '24px', height: '24px', margin: '10px' }} />
																																												}

																																											</div>
																																											<span style={{ marginTop: 10 }}>&nbsp;|&nbsp;</span>
																																											<div onMouseEnter={() => handleMouseEnter('dislikeIcon')} onMouseLeave={() => handleMouseLeave('dislikeIcon', data.id)}>
																																												<img className="like-dislike" src={dislike_icon_hover} onClick={() => { handleDisLike(data.id, data.colors, data.formal_categories_json_data, data.content, data.materials, index, totalArtWorkLength) }} style={{ width: '24px', height: '24px', margin: '10px' }} />
																																											</div>
																																										</>)
																																									} else {
																																										return (<>
																																											<div onMouseEnter={() => handleMouseEnter('likeIcon')} onMouseLeave={() => handleMouseLeave('likeIcon', data.id)}>
																																												{
																																													!likeHoverIcon && <img className="like-dislike" src={like_icon} onClick={() => { handleLike(data.id, data.colors, data.formal_categories_json_data, data.content, data.materials, index, totalArtWorkLength) }} style={{ width: '24px', height: '24px', margin: '10px' }} />
																																												}
																																												{
																																													likeHoverIcon && <img className="like-dislike" src={like_icon_hover} onClick={() => { handleLike(data.id, data.colors, data.formal_categories_json_data, data.content, data.materials, index, totalArtWorkLength) }} style={{ width: '24px', height: '24px', margin: '10px' }} />
																																												}

																																											</div>
																																											<span style={{ marginTop: 10 }}>&nbsp;|&nbsp;</span>

																																											<div onMouseEnter={() => handleMouseEnter('dislikeIcon')} onMouseLeave={() => handleMouseLeave('dislikeIcon', data.id)}>

																																												{
																																													!dislikeHoverIcon && <img className="like-dislike" src={dislike_icon} onClick={() => { handleDisLike(data.id, data.colors, data.formal_categories_json_data, data.content, data.materials, index, totalArtWorkLength) }} style={{ width: '24px', height: '24px', margin: '10px' }} />
																																												}
																																												{
																																													dislikeHoverIcon && <img className="like-dislike" src={dislike_icon_hover} onClick={() => { handleDisLike(data.id, data.colors, data.formal_categories_json_data, data.content, data.materials, index, totalArtWorkLength) }} style={{ width: '24px', height: '24px', margin: '10px' }} />
																																												}
																																											</div>
																																										</>)
																																									}
																																								}
																																							})
																																							)
																																						}
																																					</div>
																																				</td>
																																			</tr>
																																		</tbody>
																																	</table>
																																</Space>
																															</>
																														</div>
																													</Col>
																												</Row>

																											</>
																									}

																								</div>


																							</div>
																						</div>
																					</div>
																				</>
																			</Col>
																		</Row>
																	</div>
																</>
															}
														</div>
													</>
												)
											}
										}
									}



								})


							}



						</Slider>

						{
							tasteQuizWorks.length === 0 &&
							<>
								<div className='slider-banner-width' style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: 20 }}>
									<div style={{ justifyContent: 'center', display: 'flex' }} >
										<img src={EmptyQueue} alt="image"  /*className='slider-img' */ style={{ width: '100%', maxHeight: 600, maxWidth: 600 }} />
									</div>

								</div>
							</>
						}
					</div>



				}

			</div>
			<div style={
				Object.assign({}
					, {
						width: '-webkit-fill-available',
						position: 'fixed',
						left: 0,
						bottom: 0,
						right: 0,
					}
					// , isFbAuthenticate && isSignUpModalVisible && {
					// 	marginTop: 380,
					// }
					// , !isFbAuthenticate && !isSignUpModalVisible && {
					// 	// marginTop: '-50px',
					// 	// position: 'absolute',
					// 	// bottom: 0
					// 	marginTop: 380,
					// }
				)}
			>
				<CommonFooter />
			</div>
		</section>
	);
};

export default TasteQuiz;