import React, { useState, useEffect } from 'react';
import { Avatar } from 'antd';
import { Link, useRouteMatch, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import FeatherIcon from 'feather-icons-react';
import { InfoWraper, NavAuth, UserDropDwon } from './auth-info-style';
import Message from './message';
import Notification from './notification';
import Settings from './settings';
import Support from './support';
import { Popover } from '../../popup/popup';
import { Dropdown } from '../../dropdown/dropdown';
import { logOut } from '../../../redux/authentication/actionCreator';
import { fbAuthLogout } from '../../../redux/firebase/auth/actionCreator';
import Heading from '../../heading/heading';
import { fbGetUserInfo } from '../../../redux/profile/actionCreator';
import '../../../container/profile/authentication/overview/Navbar.css'
import axios from 'axios';
import config from '../../../config/config';
import { UserOutlined } from '@ant-design/icons';

const AuthInfo = () => {
  const dispatch = useDispatch();
  const { path } = useRouteMatch();
  const history = useHistory();

  const [profile_image, setProfileImage] = useState('')
  const [profile_name, setProfileName] = useState('')
  const [profile_i_am, setProfileIAm] = useState('')

  const isFbAuthenticate = useSelector(state => {
    return {
      isFbAuthenticate: state.fb.auth.uid,
    }
  });

  const { isLogout } = useSelector(state => {
    return {
      isLogout: state.fb.auth.uid,
    }
  });

  useEffect(() => {
    let invite_artist_flag = sessionStorage.getItem('invite_artist_flag');
		if(invite_artist_flag!=null){
			sessionStorage.removeItem('invite_artist_flag')
      sessionStorage.setItem('invite_artist_confirm',1);
			history.push('/profile');
		}

    dispatch(fbGetUserInfo(isFbAuthenticate.isFbAuthenticate));
  }, [dispatch]);

  useEffect(() => {
    axios.get(`${config.apiUrl}/get-user-logo/${isFbAuthenticate.isFbAuthenticate}`)
      .then((response) => {
        
        if (response.data[0]) {

          setProfileName(response.data[0].name);
          setProfileIAm(response.data[0].i_am);
          sessionStorage.setItem('name', response.data[0].name);
          let taste_test_status = response.data[0].taste_test_status==1 ? true:false;
          sessionStorage.setItem('taste_test_status', taste_test_status);
          sessionStorage.setItem('taste_test_status_visible', response.data[0].taste_test_status);
          sessionStorage.setItem('user_address', response.data[0].address);
          if (response.data[0].logo != null) {
            var image = JSON.parse(response.data[0].logo);
            setProfileImage(image[0].url);
          }
        }
      }).catch((error) => {
        console.log("Error", error)
      })
  }, []);


  var name = '';
  var fInitials = '';
  var lInitials = '';


  if (profile_name) {
    name = profile_name.split(" ");
    fInitials = name[0].charAt(0).toUpperCase();
    if (name[1])
      lInitials = name[1].charAt(0).toUpperCase();
  }


  const [state, setState] = useState({
    flag: 'english',
  });
  const { flag } = state;



  

  const SignOut = async (e) => {
    e.preventDefault();
    
    // Disable the button to prevent multiple clicks
    e.target.disabled = true;
  
    // Perform the logout process
    try {
      await dispatch(fbAuthLogout());
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = '/';
    } catch (error) {
      console.error("Logout error:", error);
      // Handle any errors that occur during logout
    } finally {
      // Re-enable the button
      e.target.disabled = false;
    }
  };
  

  if (!isLogout) dispatch(logOut());


  const userContent = (
    <UserDropDwon>
      <div className="user-dropdwon">
        <figure className="user-dropdwon__info">
          {
            profile_image ? <img src={profile_image} alt="" style={{ width: 45, height: 45 }} /> : <Avatar size="medium" style={{ marginRight: 7 }} icon={<UserOutlined />} />
          }
          <figcaption>
            <Heading as="h5">{profile_name ? profile_name : ''}</Heading>
            {/* <p>UI Expert</p> */}
          </figcaption>
        </figure>
        <ul className="user-dropdwon__links">
          <li>
            <Link to={`/gallery`}>
              <FeatherIcon icon="camera" /> Gallery
            </Link>
          </li>
          <li>

            <Link to={`/profile`}>
              <FeatherIcon icon="user" /> Profile
            </Link>
          </li>
          {
            profile_i_am === "Artist" &&
            (
              <>
                <li>
                  <Link to={`/invite`}>
                    <FeatherIcon icon="user-check" /> Invite
                  </Link>
                </li>
                <li>
                  <Link to={`/my-works/grid`}>
                    <FeatherIcon icon="settings" /> My Works
                  </Link>
                </li>

              </>
            )

          }
          <li>
            <Link to={`/my-purchases`}>
              <FeatherIcon icon="shopping-bag" /> My Purchases
            </Link>
          </li>

          <li>
            <Link to={`/my-ratings`}>
              <FeatherIcon icon="star" /> My Ratings
            </Link>
          </li>

          <li>
            <Link to={`/all-artworks`}>
              <FeatherIcon icon="camera" /> All Artworks
            </Link>
          </li>


        </ul>
        <Link className="user-dropdwon__bottomAction" onClick={SignOut} to="#">
          <FeatherIcon icon="log-out" /> Sign Out
        </Link>
      </div>
    </UserDropDwon>
  );

  const onFlagChangeHandle = value => {
    setState({
      ...state,
      flag: value,
    });
  };

  const country = (
    <NavAuth>
      <Link onClick={() => onFlagChangeHandle('english')} to="#">
        <img src={require('../../../static/img/flag/english.png')} alt="" />
        <span>English</span>
      </Link>
      <Link onClick={() => onFlagChangeHandle('germany')} to="#">
        <img src={require('../../../static/img/flag/germany.png')} alt="" />
        <span>Germany</span>
      </Link>
      <Link onClick={() => onFlagChangeHandle('spain')} to="#">
        <img src={require('../../../static/img/flag/spain.png')} alt="" />
        <span>Spain</span>
      </Link>
      <Link onClick={() => onFlagChangeHandle('turky')} to="#">
        <img src={require('../../../static/img/flag/turky.png')} alt="" />
        <span>Turky</span>
      </Link>
    </NavAuth>
  );

  return (
    <InfoWraper>
      {/* <Message />
      <Notification />

      <Settings /> */}
      {/* <Support /> */}
      {/* <div className="nav-author">
        <Dropdown placement="bottomRight" content={country} trigger="click">
          <Link to="#" className="head-example">
            <img src={require(`../../../static/img/flag/${flag}.png`)} alt="" />
          </Link>
        </Dropdown>
      </div> */}

      <div className="nav-author">
        <Popover placement="bottomRight" content={userContent} action="click">
          <Link to="#" className="head-example">
            {/* <Avatar src="https://cdn0.iconfinder.com/data/icons/user-pictures/100/matureman1-512.png" /> */}
            <div className='menu-profile' style={{
              //padding: 6,
              //paddingTop: 8,
              padding: '10px 10px 7px',
              // height: 35,
              // background: '#000',
              borderRadius: 20,
              fontWeight: 700,
              fontSize:'1rem'
              // color: '#fff',
            }}>
              {
                fInitials + lInitials
              }
            </div>
          </Link>
        </Popover>
      </div>
    </InfoWraper>
  );
};

export default AuthInfo;
