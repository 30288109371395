const actions = {

	GET_SINGLE_WORK_DETAILS_BEGIN: 'GET_SINGLE_WORK_DETAILS_BEGIN',
	GET_SINGLE_WORK_DETAILS_SUCCESS: 'GET_SINGLE_WORK_DETAILS_SUCCESS',
	GET_SINGLE_WORK_DETAILS_ERR: 'GET_SINGLE_WORK_DETAILS_ERR',

   

	getSingleWorkDetailsBegin: () => {
		return {
			type: actions.GET_SINGLE_WORK_DETAILS_BEGIN,
		};
	},

	getSingleWorkDetailsSuccess: data => {
		//console.log('in getSingleWorkDetailsSuccess actions')
		//console.log(data)
		return {
			type: actions.GET_SINGLE_WORK_DETAILS_SUCCESS,
			data,
		};
	},

	getSingleWorkDetailsErr: err => {
		return {
			type: actions.GET_SINGLE_WORK_DETAILS_ERR,
			err,
		};
	},

	



};

export default actions;