import { Form, Modal, Button, Input, Select } from 'antd';
import React, { useEffect, useState } from 'react'
import propTypes from 'prop-types';
import axios from 'axios';
import 'react-phone-input-2/lib/style.css'

const Help = ({ visible, onCancel, artWorkId }) => {

    const [state, setState] = useState({
        visible,
        modalType: 'primary',
    });

    const handleCancel = () => {
        onCancel();
    };

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            setState({
                visible,
            });
        }
        return () => {
            unmounted = true;
        };
    }, [visible]);

    return (
        <div>
            <Modal
                type={state.modalType}
                visible={state.visible}
                footer={null}
                onCancel={handleCancel}
            >
                  <p className='header-menu-font' style={{ padding: 5, marginTop: 15 ,fontWeight:600}}>
        Stoodio requests you to like or dislike an art work so that we can show you art works you are most likely to enjoy. If you do not feel comfortable rating the art works, you may advance by clicking the forward arrow on either the side of the art work, or the top of the art work, based on your device used. Please reach out if you need additional help, or have suggestions at
        +1 (312)-900-7323.
      </p>
            </Modal>
        </div>
    )
}


Help.propTypes = {
    visible: propTypes.bool.isRequired,
    onCancel: propTypes.func.isRequired,
};

export default Help