import actions from './actions';
import workService from '../../services/workService';

const {
	addWorkBegin,
	addWorkSuccess,
	addWorkErr,

	addTestQuizWorkBegin,
	addTestQuizWorkSuccess,
	addTestQuizWorkErr,

	getWorkBegin,
	getWorkSuccess,
	getWorkErr,

	getSingleWorkDetailsBegin,
	getSingleWorkDetailsSuccess,
	getSingleWorkDetailsErr,

	updateWorkBegin,
	updateWorkSuccess,
	updateWorkErr,

	adminUpdateWorkBegin,
	adminUpdateWorkSuccess,
	adminUpdateWorkErr,

	deleteWorkBegin,
	deleteWorkSuccess,
	deleteWorkErr,

	getImagesWorkBegin,
	getImagesWorkSuccess,
	getImagesWorkErr,

	getWorkDetailsBegin,
	getWorkDetailsSuccess,
	getWorkDetailsErr,

	addDetailsIntoCartBegin,
	addDetailsIntoCartSuccess,
	addDetailsIntoCartErr,

	getCartDetailsBegin,
	getCartDetailsSuccess,
	getCartDetailsErr,

	removeCartItemBegin,
	removeCartItemSuccess,
	removeCartItemErr,

	getAllArtWorksBegin,
	getAllArtWorksSuccess,
	getAllArtWorksWorkErr,
	
} = actions;

const addWork = inputs => {
	return async dispatch => {
		try {
			await dispatch(addWorkBegin());
			// console.log("inputs")
			// console.log(inputs)
			const data = await workService.addWork(inputs);

			console.log("in addWork success")
			console.log(data)
			console.log("before success")
			await dispatch(addWorkSuccess(data));
		} catch (err) {
			console.log(err)
			dispatch(addWorkErr(err));
		}
	};
};


const addTestQuizWork = inputs => {
	return async dispatch => {
		try {
			await dispatch(addTestQuizWorkBegin());
			const data = await workService.addTestQuizWork(inputs);

			console.log("in addTestQuizWork success")
			console.log(data)
			console.log("before success")
			await dispatch(addTestQuizWorkSuccess(data));
		} catch (err) {
			console.log(err)
			dispatch(addTestQuizWorkErr(err));
		}
	};
};

const getWork = (uid,current_state) => {
	return async dispatch => {
		try {
			dispatch(getWorkBegin());
			//console.log("getWork")
			const data = await workService.getWork(uid,current_state);

			// console.log(data)
			// console.log("before getWork success")
			dispatch(getWorkSuccess(data));
		} catch (err) {
			console.log(err)
			dispatch(getWorkErr(err));
		}
	};
};

const getSingleWorkDetails = id => {
	console.log("this is id " + id)
	return async dispatch => {
		try {
			await dispatch(getSingleWorkDetailsBegin());
			const data = await workService.getSingleWorkDetails(id);

			await dispatch(getSingleWorkDetailsSuccess(data));
		} catch (err) {
			console.log(err)
			dispatch(getSingleWorkDetailsErr(err));
		}
	}
}

const updateWork = inputs => {
	return async dispatch => {
		try {
			await dispatch(updateWorkBegin());
			console.log("in editWork inputs")
			console.log(inputs)

			const data = await workService.updateWork(inputs);

			console.log("in editWork success")
			console.log(data)
			console.log("before success")
			await dispatch(updateWorkSuccess(data));
		} catch (err) {
			console.log(err)
			dispatch(updateWorkErr(err));
		}
	};
}


const adminUpdateWork = inputs => {
	return async dispatch => {
		try {
			await dispatch(adminUpdateWorkBegin());
			console.log("in admin editWork inputs")
			console.log(inputs)

			const data = await workService.adminUpdateWork(inputs);

			console.log("in admin editWork success")
			console.log(data)
			console.log("admin before success")
			await dispatch(adminUpdateWorkSuccess(data));
		} catch (err) {
			console.log(err)
			dispatch(adminUpdateWorkErr(err));
		}
	};
} 

const deleteWork = (uid, id, image_urls) => {
	return async dispatch => {
		try {
			await dispatch(deleteWorkBegin());

			const data = await workService.deleteWork(uid, id, image_urls);

			await dispatch(deleteWorkSuccess(id));
		} catch (err) {
			console.log(err)
			dispatch(deleteWorkErr(err));
		}
	};
}

const getImages = (UID) => {
	return async dispatch => {
		try {
			dispatch(getImagesWorkBegin());
			//console.log("getWork")
			const data = await workService.getImages(UID);
			//console.log("Images data",data)
			dispatch(getImagesWorkSuccess(data));

			
			
		} catch (err) {
			console.log(err)
			dispatch(getImagesWorkErr(err));
		}
	};
};

const getWorkDetails = (workId) => {
	return async dispatch => {
		try {
			dispatch(getWorkDetailsBegin());
			//console.log("getWork")
			const data = await workService.getSingleWorkDetails(workId);
			console.log("Works data",data)
			dispatch(getWorkDetailsSuccess(data));

			
			
		} catch (err) {
			console.log(err)
			dispatch(getWorkDetailsErr(err));
		}
	};
};

const addDetailsIntoCart = (workData) => {
	
	return async dispatch => {
		try {
			await dispatch(addDetailsIntoCartBegin());
			const data = await workService.addCart(workData);
			await dispatch(addDetailsIntoCartSuccess(data));
		} catch (err) {
			console.log(err)
			dispatch(addDetailsIntoCartErr(err));
		}
	};
	
};



const getCartItems = (UID) => {
	return async dispatch => {
		try {
			dispatch(getCartDetailsBegin());
			//console.log("getWork")
			const data = await workService.getCartItems(UID);
			//console.log("Images data",data)
			dispatch(getCartDetailsSuccess(data));

			
			
		} catch (err) {
			console.log(err)
			dispatch(getCartDetailsErr(err));
		}
	};
};

const removeCartItem = (ID,UID) =>{
	return async dispatch => {
		try {
			await dispatch(removeCartItemBegin());

			const data = await workService.removeCartItem(ID,UID);

			await dispatch(removeCartItemSuccess(data));
		} catch (err) {
			console.log(err)
			//dispatch(removeCartItemErr(err));
		}
	};
}

const getAllArtWorks = () => {

	return async dispatch => {
		try {
			console.log("get this data")
			dispatch(getAllArtWorksBegin());
			const data = await workService.getAllArtWorks();
			
			dispatch(getAllArtWorksSuccess(data));

			
			
		} catch (err) {
			console.log(err)
			dispatch(getAllArtWorksWorkErr(err));
		}
	};
};








export { addWork, addTestQuizWork, getWork, getSingleWorkDetails, updateWork, deleteWork, getImages, getWorkDetails,addDetailsIntoCart,getCartItems,removeCartItem,getAllArtWorks,adminUpdateWork };