import React, { lazy, Suspense } from 'react';
import { Spin } from 'antd';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Dashboard from './dashboard';
import withAdminLayout from '../../layout/withAdminLayout';
import AboutUs from '../../container/profile/authentication/overview/GalleryComponents/AboutUs';

const Projects = lazy(() => import('./projects'));
const Settings = lazy(() => import('../../container/profile/settings/Settings'));
const Invite = lazy(() => import('../../container/profile/settings/overview/Invite'));
const UserList = lazy(() => import('../../container/profile/settings/overview/UsersList'));
const ArtWorks = lazy(() => import('../../container/profile/settings/overview/ArtWorks'));
const ArtWorksActions = lazy(() => import('../../container/profile/settings/overview/WorkActions'));
const Home = lazy(() => import('../../container/profile/authentication/overview/Home'));
const Cart = lazy(() => import('../../container/profile/authentication/overview/Cart'));
const Checkout = lazy(() => import('../../container/profile/authentication/overview/Checkout'));
const ForArtist = lazy(() => import('../../container/profile/authentication/overview/ForArtist'));
const ForCollector = lazy(() => import('../../container/profile/authentication/overview/ForCollector'));

const ForArtistSnippet = lazy(() => import('../../container/profile/settings/overview/ForArtistSnippet'));
const ForCollectorSnippet = lazy(() => import('../../container/profile/settings/overview/ForCollectorSnippet'));
const Orders = lazy(() => import('../../container/profile/settings/overview/Orders'));
const HoldOrders = lazy(() => import('../../container/profile/settings/overview/HoldOrders'));

const Admin = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        <Route path={`${path}/home`} component={Home} />
        <Route path={`${path}/checkout`} component={Checkout} />
        <Route path={`${path}/for-artists`} component={ForArtist} />
        <Route path={`${path}/for-collectors`} component={ForCollector} />
        <Route path={`${path}/about-us`} component={AboutUs} />        
        {/* <Route path={`${path}/all-artworks`} component={ForCollector} /> */}
        {/* <Route path={path} component={Dashboard} /> */}
        <Route path={`${path}/project`} component={Projects} />
        <Route path={`${path}/settings`} component={Settings} />
        <Route path={`${path}/invite`} component={Invite} />
        <Route path={`${path}/users`} component={UserList} />
        <Route path={`${path}/cart`} component={Cart} />
        <Route path={`${path}/art-works`} component={ArtWorks} />
        <Route path={`${path}/admin-art-works`} component={ArtWorks} />
        <Route path={`${path}/art-works-actions`} component={ArtWorksActions} />        
        <Route exact path={`/artist-snippet`} component={ForArtistSnippet} />
        <Route exact path={`/collector-snippet`} component={ForCollectorSnippet} />
        <Route path={`${path}/orders`} component={Orders} />
        <Route path={`${path}/hold-orders`} component={HoldOrders} />
        
        {/* <Route path={`${path}`} component={Settings} /> */}
      </Suspense>
    </Switch>
  );
};

export default withAdminLayout(Admin);
