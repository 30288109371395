import { notification } from 'antd';
import actions from './actions';
import axios from 'axios';
import config from '../../config/config';
import messages from '../../config/constants/messages';


const {
  fbGetUserInfoBegin,
  fbGetUserInfoSuccess,
  fbGetUserInfoErr,
  fbUpdateUserInfoBegin,
  fbUpdateUserInfoSuccess,
  fbUpdateUserInfoErr,
  fbUpdatePasswordUserInfoBegin,
  fbUpdatePasswordUserInfoSuccess,
  fbUpdatePasswordUserInfoErr
} = actions;

/*
Ramesh Comment code on sep 15 2022 remove firebase code for store user details
const fbGetUserInfo = uid => {
  
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    
    const db = getFirestore();
    // console.log('fbGetUserInfo')
    // console.log(data)
    // // const query = await db.collection('users').get();
    // const query = await db
    //     .collection('users')
    //     // .where('uid', '==', uid)
    //     .get();
    // console.log(query)
    // await query.forEach(doc => {
    //   console.log('doc')
    //   console.log(doc.data())
    // });

    // const firebase = getFirebase()

    // const output = await firebase.firestore().doc(`users/${uid}`).get().then((doc)=> { 
    //   console.log(doc)
    //   console.log(doc.data())  
    //   return  doc.data()
    // })
    // console.log('output')
    // console.log(output)
    // const firebase = getFirebase()
    // firebase
    //   .database()
    //   .ref('users/' + uid)
    //   .once('value')
    //   .then(snapshot => {
    //     console.log(snapshot.val())
    //   });
    // return
    try {
      await dispatch(fbGetUserInfoBegin());
      

      const firebase = getFirebase()

      const data = await firebase.firestore().doc(`users/${uid}`).get().then((doc) => {
        // console.log(doc)
        //
        

        return doc.data()
      })
      //alert(JSON.stringify(data));
      //alert("uid:  "+uid);
      // console.log('data')
      // console.log(data)

      // await db
      //   .collection('users')
      //   .doc(`${data.id}`)
      //   .set(data);
      await dispatch(fbGetUserInfoSuccess(data));
    } catch (err) {
      await dispatch(fbGetUserInfoErr(err));
    }
  };
};
*/

const fbGetUserInfo = uid => {
  
  return async (dispatch, getState) => {        
    try {
      await dispatch(fbGetUserInfoBegin());
      
      const response = await fetch(`${config.apiUrl}/get-user-logo/${uid}`, {
        method: "GET",
        headers: {"Content-Type": "application/json",}
      });

      const data = await response.json();
      return JSON.stringify(data[0]);
      //alert(JSON.stringify(data));
      //alert("uid:  "+uid);

      await dispatch(fbGetUserInfoSuccess(data));
    } catch (err) {
      await dispatch(fbGetUserInfoErr(err));
    }
  };
};

/*
Ramesh Comment code on sep 15 2022 remove firebase code for store user details
*/
const fbUpdateUserInfo1 = input => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    try {

      await dispatch(fbUpdateUserInfoBegin());
      
      const firebase = getFirebase()
      await firebase.firestore().doc(`users/${input.uid}`).set(input, { merge: false });
      
      const data = await firebase.firestore().doc(`users/${input.uid}`).get().then((doc) => {
        console.log('firebase data : ', doc.data())
        return doc.data()

      })


      if (input.password) {
        console.log("input.password inside")
        // [START auth_update_password]
        const user = firebase.auth().currentUser;
        const newPassword = input.password;

        user.updatePassword(newPassword).then(() => {
          // Update successful.
          console.log("Update successful.")
        }).catch((error) => {
          // An error ocurred
          console.log("Update error.", error)
          // ...
        });
        // [END auth_update_password]
      }


      console.log("fbUpdateUserInfo1")
      console.log(data)      
      await dispatch(fbUpdateUserInfoSuccess(data));
      if (input.flag == 1) {
        await updateRoleNotificationSuccess();
        
      }else{
        await updateNotificationSuccess();
      }
      

    } catch (err) {
      console.log("fbUpdateUserInfo err")
      console.log(err)
      await dispatch(fbUpdateUserInfoErr(err));
      await updateNotificationError(err);
    }
  }
}


const fbUpdateUserInfo = input => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    try {

      await dispatch(fbUpdateUserInfoBegin());
      
      const firebase = getFirebase()
      const user = firebase.auth().currentUser;
      console.log("input data",input);
      
      if (input.old_email!=input.email) 
      {
        // [START auth_update_password]
        const newEmail = input.email;
        const data = user.updateEmail(newEmail).then(() => {
          let email_data= { uid: input.uid, email: newEmail}
          axios.post(`${config.apiUrl}/update-user-email`, email_data)
            .then((response) => {
              if (response.data == true) {
                updateNotificationSuccess();
              }
            })
          .catch((error) => {
              console.log("Error :", error)
              return false;
          });
          //
        }).catch((error) => {
          var error_repons = JSON.stringify(error);
          var error_repons1 = JSON.parse(error_repons);
          notification.error({
            message: error_repons1.message,
          });
          return false;
        });
        // [END auth_update_password]
        //await dispatch(fbUpdateUserInfoSuccess(data));
      }
      
      if (input.password != input.old_password) 
      {
        
        // [START auth_update_password]
        const newPassword = input.password;
        const data = user.updatePassword(newPassword).then(() => {      
          console.log("password Update successful.")      
          let password_data= { uid: input.uid, password: newPassword}
          axios.post(`${config.apiUrl}/update-user-password`, password_data)
            .then((response) => {
              if (response.data == true) {
                updateNotificationSuccess();
              }
            })
          .catch((error) => {
              console.log("Error :", error)
              return false;
          });

        }).catch((error) => {
          var error_repons = JSON.stringify(error);
          var error_repons1 = JSON.parse(error_repons);
          notification.error({
            message: error_repons1.message,
          });
          return false;
        });
        
        // [END auth_update_password]
      }
      

    } catch (err) 
    {
      console.log("fbUpdateUserInfo err")
      console.log(err)
      await dispatch(fbUpdateUserInfoErr(err));
      await updateNotificationError(err);
    }
  }
}

const fbUpdatePasswordUserInfo = input => {
  
  return async (dispatch, getState, { getFirebase, getFirestore },url) => {
        
    try {

      //console.log("input details ",input)
      await dispatch(fbUpdateUserInfoBegin());
      const firebase = getFirebase()

      const mode = input.mode;
      // Get the one-time code from the query parameter.
      const actionCode = input.oobCode;
      //const continueUrl = 'http://3.84.163.202'
      const lang = 'en';

      // This is the minimum configuration required for the API to be used.
      const config = {
        'apiKey': input.apiKey
      };

      const auth = firebase.auth()
      const email = input.email;
      var password = input.password;
      var apiUrl=process.env.REACT_APP_API_URL;

      auth.verifyPasswordResetCode(actionCode).then((email) => {
        
        var accountEmail = email;
        
        // TODO: Show the reset screen with the user's email and ask the user for
        // the new password.
        var newPassword = password;
        // Save the new password.

        auth.confirmPasswordReset(actionCode, newPassword).then((resp) => 
        {
          let password_data= { uid: input.uid, password: newPassword}
          axios.post(`${apiUrl}/update-user-password`, password_data)
            .then(function (response) {
                console.log(response.data);
            })
            .catch(function (error) {
            });
          updatePasswordNotificationSuccess();
          
          // Password reset has been confirmed and new password updated.
        }).catch((error) => {
          var error_repons = JSON.stringify(error);
          var error_repons1 = JSON.parse(error_repons);
          notification.error({
            message: error_repons1.message,
          });
        });

      }).catch((error) => {
        var error_repons = JSON.stringify(error);
        var error_repons1 = JSON.parse(error_repons);

        notification.error({
          message: error_repons1.message,
        });
      });



    } catch (err) {
      console.log("fbUpdateUserInfo err")
      console.log(err)
      await dispatch(fbUpdatePasswordUserInfoErr(err));
      await updateNotificationError(err);
    }
  }
}



const updateNotificationSuccess = () => {
  notification.success({
    message: messages['update_profile_success'],
  });
};

const updateRoleNotificationSuccess = () => {
  notification.success({
    message: messages['change_role_success'],
  });
};

const updatePasswordNotificationSuccess = () => {
  notification.success({
    message: 'Your password has been updated',
  });
};

const updateNotificationError = err => {
  notification.error({
    message: 'Something went wrong',
  });
};

export { fbGetUserInfo, fbUpdateUserInfo, fbUpdatePasswordUserInfo };
