import { Form, Select, Table } from 'antd';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Cards } from '../../components/cards/frame/cards-frame'
import { Main } from '../styled'
import config from '../../config/config';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Modal, notification, Button } from 'antd';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { ProjectHeader } from '../project/style';
import { PageHeader } from '../../components/page-headers/page-headers';

function ManageCustomURL() {

  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [getblogData, setGetBlogata] = useState([])
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [exId, setId] = useState(null);

  const [deliverystatus, setDeliveryStatus] = useState("");
  const [olddeliverystatus, setOldDeliveryStatus] = useState("");
  const [txn_id, setTxnId] = useState("");
  const [orderId, setOrderId] = useState("");
  const [oldImageURL, setOldImageURL] = useState("");


  const UID = useSelector((state) => {
    return state.fb.auth.uid;
  });

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setId(null)
    setIsEditModalVisible(false);
    setIsModalVisible(false);
  };

  const openBlogModal = () => {
    setIsModalVisible(true);
  };

  const deleteBlog = (id, imagesURL) => {
    Modal.confirm({
      title: 'Delete Blog',
      content: (
        <div>
          <p>Do you want to delete blog</p>
        </div>
      ),
      onOk() {


        const data = { id, imagesURL };

        axios.post(`${config.apiUrl}/delete-blogs`, data)
          .then(function (response) {
            console.log(JSON.stringify(response));
            location.reload();
          })
          .catch(function (error) {
            // handle your error
            console.log(error)
            return false;
          });

      },
      onCancel() { }
    });

  }
  const showModal = (id, imagesURL) => {
    setOldImageURL(imagesURL);
    setId(id)
    setIsEditModalVisible(true);
  };



  const history = useHistory();

  useEffect(() => {

    sessionStorage.removeItem('tab-index');
    var temp_data = [];
    const getAllOrders = async () => {
      const response = await fetch(`${config.apiUrl}/get-custom-url/all`, {
        method: "GET", // or 'PUT'
        headers: {
          "Content-Type": "application/json",
        }
      });
      const data = await response.json();
      
      setGetBlogata(data)
    }
    getAllOrders();

  }, [])

  const deleteShare = (share_id) => {
    let delete_array = [share_id];
    delete_URLS(delete_array);
  }
  const delete_URLS = (delete_array) => {
    if (delete_array.length > 0) {
      Modal.confirm({
        title: 'Delete Custom URLS',
        content: (
          <div>
            <p>Do you want to delete ?</p>
          </div>
        ),
        onOk() {

          const data = { ids: delete_array };
          console.log('axios data', data)

          axios.post(`${config.apiUrl}/delete-custom-urls`, data)
            .then(function (response) {
              if (response.status === 200) {
                location.reload();
                return true;
              } else {
                return false;
              }
            })
            .catch(function (error) {
              // handle your error
              console.log(error)
              return false;
            });

        },
        onCancel() { }
      });

    }
  };


  const handleStatus = async () => {
    let BrowserData = { id: orderId, delivery_status: deliverystatus, order_id: txn_id }
    console.log(JSON.stringify(BrowserData));
    axios.post(`${config.apiUrl}/update-order-status`, BrowserData)
      .then(function (response) {
        if (response.status && response.status == 200) {
          sessionStorage.setItem("update_status", "1")
          location.reload();
        }

      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const handleInputs = (value, name) => {
    setDeliveryStatus(value);
  }

  let columns = [
    {
      title: 'URL',
      dataIndex: 'share_url',
      render: (id, row) => {
        return (
          <>
            {row['type'] == 'curate' ?
              <>
                <a href={`${process.env.REACT_APP_SHARE_URL}/artworks/${row['share_url']}`} target='_blank'>{`https://share.stoodio.art/artworks/${row['share_url']}`}</a>
              </>
              :
              <>
                <a href={`${process.env.REACT_APP_SHARE_URL}/all-artworks/${row['share_url']}`} target='_blank'>{`https://share.stoodio.art/artworks/${row['share_url']}`}</a>
              </>
            }
          </>
        )
      }
    },
    {
      title: 'create Date',
      dataIndex: 'created_at'
    },
    {
      title: 'Type',
      dataIndex: 'type',
      render: (id, row) => {
        return (
          <>
            {row['type'] == 'curate' ?
              <>
                Curate
              </>
              :
              <>
                Filter
              </>
            }
          </>
        )
      }
    },
    {
      title: 'Edit',
      dataIndex: ['share_id', 'type'],
      render: (id, row) => {
        return (
          <>
            {row['type'] == 'curate' &&
              <a href={`https://stoodio.art/all-artworks/${row['share_url']}/${row['share_id']}`}>
                <EditOutlined  />
              </a>
            }
          </>
        )
      }
    },
    {
      title: 'Delete',
      dataIndex: ['share_id'],
      render: (id, row) => {
        return (
          <DeleteOutlined onClick={() => { deleteShare(row['share_id'], '') }} />
        )
      }
    }
  ];

  return (
    <>

      <ProjectHeader >
        <PageHeader
          ghost
          title="Manage Shared Links"
          buttons={[
            // <Button onClick={openBlogModal} type="danger" size="large">Delete Selected</Button>
          ]}
        />
      </ProjectHeader>

      <Main className='main-layout-div'>
        <Cards>
          <div className="table-responsive">
            <Table dataSource={getblogData} columns={columns} />
          </div>
        </Cards>


      </Main>
    </>
  )
}

export default ManageCustomURL
