import config from '../config/config';
import axios from 'axios';

const addWork = async (data) => {
	return await axios.post(`${config.apiUrl}/create-work`, data, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	}).then(response => {
		// console.log('addWork response')
		// console.log(response.data[0])
		return response.data[0]
	}).catch(error => {
		// handle your error
		console.log(error)
	});;
}

const addTestQuizWork = async (data) => {
	//console.log("axios data",data)
	return await axios.post(`${config.apiUrl}/create-test-quiz-work`, data, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	}).then(response => {
		// console.log('addTestQuizWork response')
		// console.log(response.data[0])
		return response.data[0]
	}).catch(error => {
		// handle your error
		console.log(error)
	});;
}


const getWork = async (uid,current_state) => {
	return await fetch(`${config.apiUrl}/get-work/${uid}/${current_state}`)
	.then(async response => {
		// console.log('get work response')
		// console.log(response)

		if (response.ok) {
			return await response.json().then(json => {
				console.log(json);
				return json;
			});
		}
	});
}

const getSingleWorkDetails = async (id) => {
	return await fetch(`${config.apiUrl}/get-single-work-details/${id}`)
	.then(async response => {
		// console.log('get getSingleWorkDetails response')
		// console.log(response)

		if (response.ok) {
			return await response.json().then(json => {
				//console.log(json);
				return json;
			});
		}
	});
}

const updateWork = async (data) => {
	// console.log('updateWork service')
	// console.log(data)
	// return;
	return await axios.post(`${config.apiUrl}/update-work`, data, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	}).then(response => {
		// console.log('updateWork response')
		// console.log(response.data[0])
		return response.data[0]
	}).catch(error => {
		// handle your error
		console.log(error)
	});
}

const adminUpdateWork = async (data) => {
	//console.log('admin updateWork service')
	//console.log(data)
	// return;
	return await axios.post(`${config.apiUrl}/admin-update-work`, data, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	}).then(response => {
		//console.log('admin updateWork response')
		//console.log(response.data[0])
		return response.data[0]
	}).catch(error => {
		// handle your error
		console.log(error)
	});
}

const adminTasteQuizUpdateWork = async (data) => {
	// console.log('admin updateWork service')
	// console.log(data)
	// return;
	return await axios.post(`${config.apiUrl}/admin-taste-quiz-update-work`, data, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	}).then(response => {
		// console.log('admin updateWork response')
		// console.log(response.data[0])
		return response.data[0]
	}).catch(error => {
		// handle your error
		console.log(error)
	});
}



const deleteWork = async (uid, id, image_urls) => {
	// console.log('deleteWork service')

	const data = { uid, id, image_urls };
	// console.log(data)

	return await axios.post(`${config.apiUrl}/delete-work`, data).then(response => {
		// console.log('deleteWork response')
		// console.log(response)
		if(response.status === 200) {
			return true;
		} else {
			return false;
		}
	}).catch(error => {
		// handle your error
		console.log(error)
		return false;
	});
}

const getImages = async (UID) => {	

	const response = await fetch(`${config.apiUrl}/get-work-images/${UID}`, {
        method: "GET", // or 'PUT'
        headers: {
            "Content-Type": "application/json",
        }
    });

    const data = await response.json();
    //console.log("data",data)
    return data;

}

const getTasteQuizImages = async () => {

	const response = await fetch(`${config.apiUrl}/get-test-quiz-works`, {
        method: "GET", // or 'PUT'
        headers: {
            "Content-Type": "application/json",
        }
    });

    const data = await response.json();
    //console.log("data",data)
    return data;


}



const addCart = async (data) => {
	//console.log('axios',data)
	// return await axios.post(`${config.apiUrl}/create-cart`, data)
	// .then(response => {
	// 	console.log(response.data[0])
	// 	return response.data[0]
	// }).catch(error => {
	// 	// handle your error
	// 	console.log(error)
	// });

	return await axios.post(`${config.apiUrl}/create-cart`, data)
	.then(async response => {

		 if(response.status === 200) {
			return true;
		} else {
			return false;
		}
	});

}


const getCartItems = async (UID) => {


	const response = await fetch(`${config.apiUrl}/get-cart-details/${UID}`, {
        method: "GET", // or 'PUT'
        headers: {
            "Content-Type": "application/json",
        }
    });

    const data = await response.json();
    //console.log("data",data)
    return data;

}

const removeCartItem = async (ID,UID) => {


	const response = await fetch(`${config.apiUrl}/remove-cart-item`, {
        method: "POST", // or 'PUT'
        headers: {
            "Content-Type": "application/json",
        },
		body:JSON.stringify({
			work_id:ID,
			uid:UID
		})
    });

	

	if(response.status === 200) {
		const data = await response.json();
		return data;
	} else {
		return false;
	}

    // const data = await response.json();
    // console.log("data",data)
    // return data;

}


const getCartCount = async (UID) => {


	const response = await fetch(`${config.apiUrl}/get-cart-details/${UID}`, {
        method: "GET", // or 'PUT'
        headers: {
            "Content-Type": "application/json",
        }
    });

    const data = await response.json();
    //console.log("data",data)
    return data;
}


const getAllArtWorks = async ()=>{
	const response = await fetch(`${config.apiUrl}/get-art-works`, {
        method: "GET", // or 'PUT'
        headers: {
            "Content-Type": "application/json",
        }
    });

    const data = await response.json();
    console.log("Get All Works Data",data)
    return data;

}


const updateArtWorkStatus = async (status_data) => {
	console.log('axios',status_data)

	return await axios.post(`${config.apiUrl}/update-art-work-status`, status_data)
	.then(async response => {

		 if(response.status === 200) {
			return true;
		} else {
			return false;
		}
	});

}

const getRatingWorkData = async (UID) => {

	const response = await fetch(`${config.apiUrl}/get-rating-works/${UID}`, {
        method: "GET", // or 'PUT'
        headers: {
            "Content-Type": "application/json",
        }
    });

    const data = await response.json();
    console.log("rating data",data)
    return data;

}


const workService = {
	addWork,
	addTestQuizWork,
	getWork,
	getSingleWorkDetails,
	updateWork,
	deleteWork,
	getImages,
	addCart,
	getCartItems,
	removeCartItem,
	getCartCount,
	getAllArtWorks,
	updateArtWorkStatus,
	adminUpdateWork,
	getRatingWorkData,
	getTasteQuizImages,
	adminTasteQuizUpdateWork
};
  
export default workService;