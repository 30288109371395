import config from '../config/config';
import axios from 'axios';

const sliderImages = async (data) => {
	//console.log("axios data : ", data);


	// return await axios.post(`${config.apiUrl}/like-diklike-skip`, data)
	//   .then(function (response) {
	// 	console.log("response",response);
	//   })
	//   .catch(function (error) {
	// 	  // handle your error
	// 	console.log(error);
	//   });

	return await axios.post(`${config.apiUrl}/like-diklike-skip`, data)
		.then(async response => {

			if (response.status === 200) {
				return true;
			} else {
				return false;
			}
		});



}

const sliderImages1 = async (data) => {
	// console.log("axios colorTasteProfile : ", data);

	return await axios.post(`${config.apiUrl}/taste-quiz-like-diklike-skip`, data)
		.then(async response => {

			if (response.status === 200) {
				return response;
			} else {
				return false;
			}
		});



}



const updateCartItems = async (data) => {

	return await axios.post(`${config.apiUrl}/update-cart-item`, data)
		.then(async response => {

			if (response.status === 200) {
				console.log("updated data")
				return true;
			} else {
				return false;
			}
		});
}


const likeDislikeSkipService = {
	sliderImages,updateCartItems,sliderImages1
};

export default likeDislikeSkipService;