import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Select, Col, Row, DatePicker, Avatar, Upload, TimePicker, notification, Button } from 'antd';
import propTypes from 'prop-types';
import { Modal } from '../../components/modals/antd-modals';
import { BasicFormWrapper } from '../styled';
import { FileImageOutlined } from '@ant-design/icons';
import config from '../../config/config';
import moment from 'moment';
import messages from '../../config/constants/messages';
import axios from 'axios';

import JoditReactEditor from '../profile/settings/overview/JoditReactEditor';
import CreateCropImage from '../profile/settings/overview/CreateCropImage';


const { Option } = Select;
const dateFormat = 'MM/DD/YYYY';
const { Dragger } = Upload;
const { TextArea } = Input;

const CreateEducation = ({ visible, onCancel }) => {
  const dispatch = useDispatch();

  const UID = useSelector((state) => {
    return state.fb.auth.uid;
  });


  const Base64Data = useSelector((state) => {
    return state.Base64Reducer.data
  })  


  const [form] = Form.useForm();
  const formData = new FormData();

  //const [is_framed, setIsFramed] = useState(false);

  const [state, setState] = useState({
    visible,
    modalType: 'primary',
    checked: [],
    education_name: '',
    education_description: '',
    education_date: '',
    education_time: '',
    education_date_range:'',
    url: '',
    meta_title: '',
    meta_description: '',
    meta_keywords: '',
    status: '',
    images: [],
    isLoading: false
  });


  const [description_data, SetDescription] = useState("")

  const [preview, setPreview] = useState([]);

  const [images, setImages] = useState([]);
  const [document, setDocument] = useState('');
  const [buttonDisable, setButton] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imagesModalVisiable, setImagesModalVisiable] = useState(false);



  const [titlelength, setTitleLength] = useState(0);
  const [descriptionlength, SetDescriptionLength] = useState(0);


  const [ArtistList, setArtistList] = useState([]);
  const [ArtworkList, setArtworkList] = useState([]);

  const [t1, setT1] = useState('');
  const [t2, setT2] = useState('');


  useEffect(() => {
    sessionStorage.removeItem('tab-index');
    let unmounted = false;
    if (!unmounted) {
      setState({
        visible,
      });
    }
    return () => {
      unmounted = true;
    };
  }, [visible]);


  const handleStartDateChange = (date, dateString) => {
    setState({
      ...state,
      blog_date: moment(date).format('YYYY-MM-DD'),
    });
  }

  const handleEndDateChange = (date, dateString) => {
    setState({
      ...state,
      blog_end_date: moment(date).format('YYYY-MM-DD'),
    });
  }

  const handleDropdownChange = (value, name) => {
    // return;
    setState({
      ...state,
      [name]: value,
    });
  }

  const handleDropdownChange2 = value => {
    setState({
      ...state,
      unique_copy: value,
    });
  }

  const fileobj = [];
  function removeHtmlTags(str) {
    if (str) {
      str = str && str.replace(/<[^>]*>/g, '');
      return str.replace(/&nbsp;/g, '');
    }
  }


  const handleOk = (values) => {

    var temp_data = removeHtmlTags(description_data);

    if (temp_data == "") {
      notification.warning({
        message: 'Please input education description'
      });
      return false;
    }
    let post_data = { ...values, image_Base64: Base64Data, education_description: description_data,uid:UID };

    // console.log(state)
    setButton(true);
    setLoading(true);

    setState({
      ...state,
      isLoading: true,
    });

    if (state.images) {
      for (let i = 0; i < state.images.length; i++) {
        formData.append('file', state.images[i])
      }
    }

    formData.append('data', JSON.stringify(post_data))

    axios.post(`${config.apiUrl}/create-education`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then((response) => {
      if (response.data.affectedRows == 1) {
        alert("The education created successfully.");
        location.reload();
      } else {
        setButton(false);
        setLoading(false);
        alert("Uanble to create the education");
      }
    })
      .catch((error) => {
        console.log("Error :", error)
      })

  };

  const handleImageChange = (e) => {
    console.log("e.target.files :", e.target.files);
    if (e.target.files.length) {
      for (var i = 0; i < e.target.files.length; i++) {
        if (!e.target.files[i].name.match(/.(jpg|jpeg|png|bmp)$/i)) {
          alert(messages['invalid_image_format_error'])
          return false;
        }
      }
      setState({
        ...state,
        [e.target.name]: e.target.files,
      });
    } else {
      setState({
        ...state,
        [e.target.name]: [],
      });
    }
  }




  const handleCancel = () => {
    onCancel();
  };

  const showModal = () => {
    setImagesModalVisiable(true);
  }
  const onImageCancel = () => {
    setImagesModalVisiable(false);
  }


  const SetEditorData = value => {
    //alert(value);
    //const cleanedString = removeHtmlTags(value);
    //SetDescriptionLength(cleanedString.length)
    SetDescription(value);
  }

  return (

    <>

      <Modal
        type={state.modalType}
        title="Create education"
        visible={state.visible}
        width={1000}
        backdrop="static"
        footer={[null]}
        onCancel={handleCancel}
      >
        <div className="project-modal">
          <BasicFormWrapper>
            <Form id="createWorkForm" form={form} name="createProject" onFinish={handleOk} encType="multipart/form-data">
              <Row gutter={15}>
                <Col md={18} className="mb-25">
                  <Form.Item name="education_name" label="Name"
                    rules={[{ required: true, message: 'Please input blog name' }]}
                  >
                    <Input.TextArea rows={2} name="education_name" />
                  </Form.Item>
                  {/* <div style={{ marginTop: '-25px', float: 'right' }} className={200 < titlelength ? 'danger-text danger-text-margin' : ''}>{titlelength}/200 char used</div> */}
                </Col>
                <Col md={6} className="mb-25" style={{ textAlign: 'center' }}>
                  {
                    Base64Data ?
                      <img src={Base64Data} alt='user-profile' style={{ height: 90, borderRadius: 8 }} />
                      :
                      <>
                        <Avatar shape="square" size={100} icon={<FileImageOutlined />} />
                      </>
                  }
                  <CreateCropImage onCancel={() => { onImageCancel() }} visible={imagesModalVisiable} Ratio={3 / 2} />
                  <Button size="default" type="primary" onClick={showModal} style={{ marginTop: 10 }}>Upload Image</Button>
                </Col>
              </Row>

              <Form.Item name="education_description" label="Description">
                {/* <TextArea rows={3} type='text' name='description' /> */}
                <JoditReactEditor
                  SetEditorData={SetEditorData}
                />
              </Form.Item>
              {/* <div style={{ marginTop: '-25px', float: 'right' }} className={5000 < descriptionlength ? 'danger-text' : ''}>{descriptionlength}/5000 char used</div> */}


              {/* <Row gutter={15}>
                <Col md={12} className="mb-25">
                  <Form.Item name="blog_date" label="Created Date"
                    rules={[{ required: true, message: 'Please input blog date' }]}
                  >
                    <DatePicker placeholder="mm/dd/yyyy" format={dateFormat} name="blog_date" value={state.startDate} onChange={handleStartDateChange} />
                  </Form.Item>
                </Col>

                <Col md={12}>
                  <Form.Item name="blog_time" label="Created Time"
                    rules={[{ required: true, message: 'Please input blog time' }]}
                  >
                    <TimePicker use12Hours name='blog_time' format="h:mm a"
                      onChange={(time, timeString) => {
                        setT1(time != null ? timeString : "")
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row> */}


              <Form.Item name="education_date_range" label="Date"
                rules={[{ required: true, message: 'Please input date' }]}
              >
                <Input placeholder="" name="education_date_range" />
              </Form.Item>

              <Form.Item name="url" label="URL"
                rules={[{ required: true, message: 'Please input url' }]}
              >
                <Input addonBefore="https://stoodio.art/education/" placeholder="url" name="url" />
              </Form.Item>

              <Form.Item name="status" label="Status"
                rules={[{ required: true, message: 'Please input status' }]}
              >
                <Select size="large" className="sDash_fullwidth-select" name="status" placeholder="Select from dropdown"
                  onChange={(value) => handleDropdownChange(value, 'status')}
                >
                  <Option value="1">Active</Option>
                  <Option value="0">Inactive</Option>
                </Select>
              </Form.Item>

              <Form.Item name="meta_title" label="Meta Title">
                <TextArea rows={1} type='text' name='meta_title' />
              </Form.Item>

              <Form.Item name="meta_description" label="Meta Description">
                <TextArea rows={2} type='text' name='meta_description'
                />
              </Form.Item>

              <Form.Item name="meta_keywords" label="Meta Keywords">
                <TextArea rows={1} type='text' name='meta_keywords'
                />
              </Form.Item>

              <Form.Item>

                <Button type="primary" htmlType="submit" disabled={buttonDisable}>
                  Submit {loading && (<i className='fa fa-spinner fa-spin' style={{ fontSize: 18, marginLeft: 5 }} />)}
                </Button>
                {' '}
                <Button size="default" type="white" key="back" outlined onClick={handleCancel}>
                  Cancel
                </Button>
              </Form.Item>
            </Form>
          </BasicFormWrapper>
        </div>
      </Modal>
    </>
  );
};

CreateEducation.propTypes = {
  visible: propTypes.bool.isRequired,
  onCancel: propTypes.func.isRequired,
};

export default CreateEducation;
