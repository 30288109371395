import actions from './actions';

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const {
  USER_LIST_BEGIN,
  USER_LIST_SUCCESS,
  USER_LIST_ERR,

  GET_LOG_USER_DATA_BEGIN,
  GET_LOG_USER_DATA_SUCCESS,
  GET_LOG_USER_DATA_WORK_ERR,

} = actions;

const UsersReducer = (state = initialState, action) => {

  const { type, data, err } = action;
  switch (type) {
    case USER_LIST_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case USER_LIST_SUCCESS:
      // console.log('USER_LIST_SUCCESS',data)
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case USER_LIST_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case GET_LOG_USER_DATA_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_LOG_USER_DATA_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case GET_LOG_USER_DATA_WORK_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }

}

export default UsersReducer;