import actions from './actions';

const {

  GET_TAB_KEY_SUCCESS,

} = actions;


const initialStateSingle = {
  defaultActiveTabKey: "1"
};

const tabKeyValue = (state = initialStateSingle, action) => {
  const { type, data, err } = action;
  switch (type) {

    case GET_TAB_KEY_SUCCESS:
      return action.payload


    default:
      return state;
  }
};

export default tabKeyValue;