import { Button, Col, Row, Modal, Tooltip, Spin, notification } from 'antd'

import '../../container/profile/authentication/overview/GalleryComponents/css/style.css';
import { Link, NavLink, useHistory, useParams } from 'react-router-dom';

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from '../../config/config';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import notes_icon from '../../static/img/leading-icon.svg';
import messages from '../../config/constants/messages';
import CommonFooter from '../profile/authentication/overview/CommonFooter';
import leftArrow from '../../static/img/icon/left-arrow.svg';
import GetSnippetsData from '../profile/authentication/overview/Snippets/GetSnippetsData';


const ConsultationDetails = () => {


    const role = "ScheduleArtConsultation"
    const snippetData = GetSnippetsData(role)

    const history = useHistory();


    const dispatch = useDispatch();

    const [visible, setVisible] = React.useState(false);
    const [confirmLoading, setConfirmLoading] = React.useState(false);


    const gotoback = () => {
        //history.push('/');
    }



    return (
        <div style={{
            width: '100%',
        }}>
            {
                snippetData === "" === "" ?
                    <>
                        <div className="spin">
                            <Spin />
                        </div>
                    </>
                    :
                    <>
                        <div>

                            <div style={{ marginTop: 140, paddingBottom: 60 }}>
                                <Row style={{ marginRight: 10, marginLeft: 10 }} className='set-homepage-content'>
                                    {/* <Col xs={24} sm={24} md={2} lg={2}>
                                        <Button style={{ cursor: 'pointer', border: '1px solid #006C94', color: '#006C94', background: 'rgb(255, 255, 255)', borderRadius: 4 }} size='default' onClick={gotoback}>
                                            <img className="like-dislike" src={leftArrow} style={{ width: '14px', height: '14px' }} /> Go back
                                        </Button>
                                    </Col> */}
                                    <Col xs={24} sm={24} md={20} lg={20}>
                                        {

                                            <>
                                                <Row style={{ justifyContent: 'center' }}>
                                                    <p style={{ fontFamily: "Melodrama", fontSize: 45, marginBottom: 18 }} align="center" dangerouslySetInnerHTML={{ __html: snippetData !== "" ? snippetData.buttonTitle : "Schedule an Art Consultation" }}></p>
                                                </Row>


                                                <Row style={{ justifyContent: 'center' }}>
                                                    <p style={{ fontFamily: "Arial", fontSize: 15, textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: snippetData !== "" ? snippetData.ScheduleArtContent : "" }}></p>
                                                </Row>
                                                <Row style={{ justifyContent: 'center' }}>
                                                    <p style={{ fontFamily: "Arial", fontSize: 15, textAlign: 'left' }}>
                                                        <a href='https://calendly.com/teamstoodio/30min' target='_blank'>
                                                            <Button style={{ cursor: 'pointer', border: '1px solid #006C94', color: '#006C94', background: 'rgb(255, 255, 255)', borderRadius: 4 }} size='default'>
                                                            <p dangerouslySetInnerHTML={{ __html: snippetData !== "" ? snippetData.buttonTitle : "Schedule an Art Consultation" }}></p>
                                                            </Button>
                                                        </a>
                                                    </p>
                                                </Row>

                                            </>
                                        }


                                    </Col>
                                    <Col xs={0} sm={0} md={2} lg={2}></Col>
                                </Row>
                            </div>
                            <div className="adjust-home-page-footer" style={
                                Object.assign({}
                                    , {
                                        width: '-webkit-fill-available',
                                        position: 'fixed',
                                        left: 0,
                                        bottom: 0,
                                        right: 0,
                                        background: '#E7F6FA'
                                    }
                                )}
                            >
                                <CommonFooter />
                            </div>

                        </div>
                    </>
            }

        </div >
    )
}

export default ConsultationDetails