const actions = {
  FB_GET_USER_INFO_BEGIN: 'FB_GET_USER_INFO_BEGIN',
  FB_GET_USER_INFO_SUCCESS: 'FB_GET_USER_INFO_SUCCESS',
  FB_GET_USER_INFO_ERR: 'FB_GET_USER_INFO_ERR',

  FB_UPDATE_USER_INFO_BEGIN: 'FB_UPDATE_USER_INFO_BEGIN',
  FB_UPDATE_USER_INFO_SUCCESS: 'FB_UPDATE_USER_INFO_SUCCESS',
  FB_UPDATE_USER_INFO_ERR: 'FB_UPDATE_USER_INFO_ERR',

  FB_UPDATE_PASSWORD_USER_INFO_BEGIN:'FB_UPDATE_PASSWORD_USER_INFO_BEGIN',
  FB_UPDATE_PASSWORD_USER_INFO_SUCCESS:'FB_UPDATE_PASSWORD_USER_INFO_SUCCESS',
  FB_UPDATE_PASSWORD_USER_INFO_ERR:'FB_UPDATE_PASSWORD_USER_INFO_ERR',

  fbGetUserInfoBegin: () => {
    return {
      type: actions.FB_GET_USER_INFO_BEGIN,
    };
  },

  fbGetUserInfoSuccess: data => {
    return {
      type: actions.FB_GET_USER_INFO_SUCCESS,
      data,
    };
  },

  fbGetUserInfoErr: err => {
    return {
      type: actions.FB_GET_USER_INFO_ERR,
      err,
    };
  },

  fbUpdateUserInfoBegin: () => {
    return {
      type: actions.FB_UPDATE_USER_INFO_BEGIN,
    }
  },

  fbUpdateUserInfoSuccess: data => {
    return {
      type: actions.FB_UPDATE_USER_INFO_SUCCESS,
      data,
    }
  },

  fbUpdateUserInfoErr: err => {
    return {
      type: actions.FB_UPDATE_USER_INFO_ERR,
      err,
    }
  },


  fbUpdatePasswordUserInfoBegin: () => {
    return {
      type: actions.FB_UPDATE_PASSWORD_USER_INFO_BEGIN,
    }
  },

  fbUpdatePasswordUserInfoSuccess: data => {
    return {
      type: actions.FB_UPDATE_PASSWORD_USER_INFO_SUCCESS,
      data,
    }
  },

  fbUpdatePasswordUserInfoErr: err => {
    return {
      type: actions.FB_UPDATE_PASSWORD_USER_INFO_ERR,
      err,
    }
  }
};

export default actions;