import React from 'react'

const ColorTasteProfile = (previousColorTasteProfile,previousColorTasteProfileLenght,newColorTasteProfile) => {

    let newColorTasteProfileGenerates = []
    for (var a = 0; a < previousColorTasteProfileLenght; a++) {

        var oldname = previousColorTasteProfile[a].name
        var newname = newColorTasteProfile[a].name

        if (oldname == newname) {
            newColorTasteProfileGenerates.push({
                id: newColorTasteProfile[a].id,
                name: newColorTasteProfile[a].name,
                tpScore: previousColorTasteProfile[a].tpScore + newColorTasteProfile[a].tpScore
            })
        }

    }

    return {newColorTasteProfileGenerates}
}

export default ColorTasteProfile