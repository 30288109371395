import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Select, Col, Row, DatePicker, Upload, Avatar, notification, Button, TimePicker } from 'antd';
import propTypes from 'prop-types';
import { Modal } from '../../../components/modals/antd-modals';
import { FileImageOutlined } from '@ant-design/icons';
import { BasicFormWrapper } from '../../styled';
import config from '../../../config/config';
import moment from 'moment';
//import Editor from 'ckeditor5-custom-build/build/ckeditor';
//import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
//import { CKEditor } from '@ckeditor/ckeditor5-react';
import messages from '../../../config/constants/messages';
//import Draggable from "react-draggable";
//import JoditReactEditor from '../../profile/settings/overview/JoditReactEditor';
import axios from 'axios';
import UploadCropImage from '../../profile/settings/overview/UploadCropImage';
import JoditReactEditor from '../../profile/settings/overview/JoditReactEditor';


const { Option } = Select;

const dateFormat = 'YYYY/MM/DD';

const { Dragger } = Upload;
const { TextArea } = Input;

const EditEarlyAccess = ({ visible, onCancel, Id }) => {

    const dispatch = useDispatch();

    const isFbAuthenticate = useSelector(state => {

        return {
            isFbAuthenticate: state.fb.auth.uid,
        }
    });


    const [form] = Form.useForm();
    const formData = new FormData();
    //const [is_framed, setIsFramed] = useState(false);

    const [state, setState] = useState({
        visible,
        modalType: 'primary',
        checked: [],
        title: '',
        description: '',
        artist_ids: '',
        artwork_ids: '',
        early_date: '',
        status: '',
        isLoading: false
    });


    const [description_data, SetDescription] = useState("")
    const [imageModalVisiable, setImageModalVisiable] = useState(false);
    const [userLogo, setUserLogo] = useState([]);

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            setState({
                visible,
            });
        }
        return () => {
            unmounted = true;
        };
    }, [visible]);

    useEffect(() => {

        sessionStorage.removeItem('tab-index');
        const getAllOrders = async () => {

            const response = await fetch(`${config.apiUrl}/edit-early-access/${Id}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                }
            });

            const data = await response.json();

            if (data && data.status == true) {
                setArtistList(data.ArtistList);
                setArtworkList(data.ArtworkList);
                if (data.EditEarly[0]) {
                    setEditData(data.EditEarly[0])
                    SetEditorData(data.EditEarly[0].description)
                }
                setShowForm(true);
            }
            //setGetExhibitionData(data)
        }
        getAllOrders();

    }, [])


    const handleStartDateChange = (date, dateString) => {
        setState({
            ...state,
            exhibition_date: moment(date).format('YYYY-MM-DD'),
        });
    }

    const handleEndDateChange = (date, dateString) => {
        setState({
            ...state,
            exhibition_end_date: moment(date).format('YYYY-MM-DD'),
        });
    }

    const handleDropdownChange = async (value, name) => {
        console.log(`Dropdown changed: ${name}, Value:`, value); // Added for debugging

        if (name === "artist_ids") {
            form.setFieldsValue({ artwork_ids: [] });
            setArtworkList([]);
            await fetchArtworks(value);
        }

        setState({
            ...state,
            [name]: value,
        });
    };


    const fetchArtworks = async (artistIds) => {
        console.log("Fetching artworks for artists:", artistIds); // Added for debugging

        try {
            let data = { artist_ids: artistIds };
            axios.post(`${config.apiUrl}/get-artworks-by-select-artists`, data)
                .then(function (response) {
                    //alert(JSON.stringify(response.data));
                    setArtworkList(response.data);
                })
                .catch(function (error) {
                    // handle your error
                    console.log("select artist :", error)
                    return false;
                });

        } catch (error) {
            console.error("Error fetching artworks:", error);
        }
    };



    const handleDropdownChange2 = value => {
        setState({
            ...state,
            unique_copy: value,
        });
    }

    const [preview, setPreview] = useState([]);

    const [images, setImages] = useState([]);
    const [document, setDocument] = useState('');
    const [buttonDisable, setButton] = useState(false);
    const [loading, setLoading] = useState(false);


    const [titlelength, setTitleLength] = useState(0);
    const [descriptionlength, SetDescriptionLength] = useState(0);


    const [ArtistList, setArtistList] = useState([]);
    const [ArtworkList, setArtworkList] = useState([]);
    const [editData, setEditData] = useState(null);
    const [ShowForm, setShowForm] = useState(false);



    const [t1, setT1] = useState(null);
    const [t2, setT2] = useState(null);

    const fileobj = [];

    const handleImageChange = (e) => {
        console.log("e.target.files :", e.target.files);
        if (e.target.files.length) {
            for (var i = 0; i < e.target.files.length; i++) {
                if (!e.target.files[i].name.match(/.(jpg|jpeg|png|bmp)$/i)) {
                    alert(messages['invalid_image_format_error'])
                    return false;
                }
            }
            setState({
                ...state,
                [e.target.name]: e.target.files,
            });
        } else {
            setState({
                ...state,
                [e.target.name]: [],
            });
        }
    }
    const showModal = () => {
        setImageModalVisiable(true);
    }
    const onProfileCancel = () => {
        setImageModalVisiable(false);
    }

    const validateDiscount = (_, value) => {
        if (value < 0) {
            return Promise.reject(new Error('Discount cannot be negative'));
        }
        if (value > 100) {
            return Promise.reject(new Error('Discount cannot be more than 100'));
        }
        return Promise.resolve();
    };



    const handleOk = (values) => {
        //alert(values.early_date);return false;
        var temp_data = removeHtmlTags(description_data);

        if (temp_data == "") {
            notification.warning({
                message: 'Please input early access description'
            });
            return false;
        }



        let post_data = {
            early_access_id: editData.early_access_id,
            ...values,
            //early_date: state.early_date,
            early_date: values.early_date != null ? moment(values.early_date).format('YYYY-MM-DD') : null,
            description: description_data
        };
        //alert(post_data.early_date);return;


        // console.log(state)
        setButton(true);
        setLoading(true);

        setState({
            ...state,
            isLoading: true,
        });

        if (state.images) {
            for (let i = 0; i < state.images.length; i++) {
                formData.append('file', state.images[i])
            }
        }

        formData.append('data', JSON.stringify(post_data))

        axios.post(`${config.apiUrl}/update-early-access`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((response) => {
            if (response.data.affectedRows == 1) {
                alert("The early access update successfully.");
                location.reload();
            } else {
                setButton(false);
                setLoading(false);
                alert("Uanble to update the early access");
            }
        })
            .catch((error) => {
                console.log("Error :", error)
            })
    };




    const handleCancel = () => {
        onCancel();
    };

    function removeHtmlTags(str) {

        if (str) {
            str = str && str.replace(/<[^>]*>/g, '');
            return str.replace(/&nbsp;/g, '');
        }
    }

    const SetEditorData = value => {
        const cleanedString = removeHtmlTags(value);
        SetDescriptionLength(cleanedString.length)
        SetDescription(value);
    }

    return (

        <>

            <Modal
                type={state.modalType}
                title="Edit exhibition"
                visible={state.visible}
                width={1000}
                backdrop="static"
                footer={[null]}
                onCancel={handleCancel}
            >
                <div className="project-modal">
                    {ShowForm == true &&
                        <>
                            <BasicFormWrapper>
                                <Form id="createWorkForm" form={form} name="createProject" onFinish={handleOk} encType="multipart/form-data">

                                    <Form.Item name="title" label="Early Access Title"
                                        rules={[{ required: true, message: 'Please input early access title' }]}
                                        initialValue={editData ? editData.title : ''}
                                    >
                                        <Input name="title" />
                                    </Form.Item>


                                    <Form.Item name="description" label="Early Access Description"
                                        initialValue={editData ? editData.description : ''}
                                    >
                                        {/* <TextArea rows={3} type='text' name='description' /> */}
                                        <JoditReactEditor
                                            SetEditorData={SetEditorData}
                                            initialValue={editData ? editData.description : ''}
                                        />
                                    </Form.Item>


                                    <Form.Item name="early_date" label="Date to show on site"
                                        //rules={[{ required: true, message: 'Please input early work date' }]}
                                        initialValue={editData ? moment(editData.early_date, dateFormat) : null}
                                    >
                                        <DatePicker placeholder="mm/dd/yyyy" format={dateFormat} name="early_date" onChange={handleStartDateChange}
                                            value={editData ? moment(editData.early_date, dateFormat) : null}
                                            defaultValue={editData ? moment(editData.early_date, dateFormat) : null}
                                        />
                                    </Form.Item>


                                    <Form.Item name="artist_ids" label="Select Artist"
                                        rules={[{ required: true, message: 'Please select artists' }]}
                                        initialValue={editData ? editData.artist_ids : []}
                                    >
                                        <Select size="large" name="artist_ids" className="sDash_fullwidth-select" placeholder="Select from dropdown"
                                            onChange={(value) => handleDropdownChange(value, 'artist_ids')}
                                            optionFilterProp='children'
                                        >
                                            {
                                                ArtistList.map((data) =>
                                                    <Option key={data.uid} value={data.uid}>{data.name}</Option>
                                                )
                                            }
                                        </Select>
                                    </Form.Item>

                                    <Form.Item name="artwork_ids" label="Select Artwork"
                                        rules={[{ required: true, message: 'Please select artworks' }]}
                                        initialValue={editData ? JSON.parse(editData.artwork_ids) : []}
                                    >
                                        <Select size="large" mode="multiple" name="artwork_ids" className="sDash_fullwidth-select" placeholder="Select from dropdown"
                                            onChange={(value) => handleDropdownChange(value, 'artwork_ids')}
                                            optionFilterProp='children'
                                        >
                                            {
                                                [...ArtworkList].map((data) =>
                                                    <Option key={data.id} value={data.id}>{data.work_title}</Option>
                                                )
                                            }
                                        </Select>
                                    </Form.Item>

                                    <Form.Item name="discount" label="Early Work Discount (%)"
                                        rules={[
                                            { required: true, message: 'Please enter a discount value' },
                                            { validator: validateDiscount },
                                        ]}
                                        initialValue={Number(JSON.parse(editData.discount))}
                                    >
                                        <Input name="discount" />
                                    </Form.Item>

                                    <Form.Item name="status" label="Status"
                                        rules={[{ required: true, message: 'Please input status' }]}
                                        initialValue={Number(JSON.parse(editData.status))}
                                    >
                                        <Select size="large" className="sDash_fullwidth-select" name="status" placeholder="Select from dropdown"
                                            onChange={(value) => handleDropdownChange(value, 'status')}
                                        >
                                            <Option key={1} value={1}>Active</Option>
                                            <Option key={0} value={0}>Inactive</Option>
                                        </Select>
                                    </Form.Item>

                                    <Form.Item>

                                        <Button type="primary" htmlType="submit" disabled={buttonDisable}>
                                            Submit {loading && (<i className='fa fa-spinner fa-spin' style={{ fontSize: 18, marginLeft: 5 }} />)}
                                        </Button>
                                        {' '}
                                        <Button size="default" type="white" key="back" outlined onClick={handleCancel}>
                                            Cancel
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </BasicFormWrapper>
                        </>}

                </div>
            </Modal>
        </>
    );
};

EditEarlyAccess.propTypes = {
    visible: propTypes.bool.isRequired,
    onCancel: propTypes.func.isRequired,
};

export default EditEarlyAccess;
