import config from '../config/config';
import axios from 'axios';

const UsersList = async () => {
    // return await axios.get(`${config.apiUrl}/userslist`)
	//   .then(function (response) {
    //       if(response){
    //         let res = response.json();
    //         console.log("res",res)
    //       }
		
	//   })
	//   .catch(function (error) {
	// 	  // handle your error
	// 	console.log(error);
	//   });

    
    const response = await fetch(`${config.apiUrl}/userslist/0`, {
        method: "GET", // or 'PUT'
        headers: {
            "Content-Type": "application/json",
        }
    });

    const data = await response.json();
    // console.log("data",data)
    return data;
       

	
}

const WorkFilterList = async (workData) => {

    // console.log("workData axios data",workData)
    
    // const response = await fetch(`${config.apiUrl}/get-filter-data`, {
    //     method: "POST", // or 'PUT'
    //     headers: {
    //         "Content-Type": "application/json",
    //     },
    //     body:JSON.stringify({
    //         workData
    //     })
    // });

    // console.log("Work Filter Response",response)
    // const data = await response.json();
    // console.log("data",data)
    // return data;

    // return await axios.post(`${config.apiUrl}/get-filter-data`, workData, {
	// 	headers: {
	// 		'Content-Type': 'multipart/form-data'
	// 	}
	// }).then(response => {
	// 	console.log('addWork response')
	// 	console.log(response)
	// 	return response
	// }).catch(error => {
	// 	// handle your error
	// 	console.log(error)
	// });;
       

    return await axios.post(`${config.apiUrl}/get-filter-data`, workData)
	  .then(function (response) {
		//console.log(response);
        return response.data
        
	  })
	  .catch(function (error) {
		  // handle your error
		console.log(error);
	  });

	
}

const getUserLogData = async (UID) => {


	const response = await fetch(`${config.apiUrl}/get-user-log-data/${UID}`, {
        method: "GET", // or 'PUT'
        headers: {
            "Content-Type": "application/json",
        }
    });

    const data = await response.json();
    //console.log("data",data)
    return data;

}





const userListServices = {
	UsersList,WorkFilterList,
    getUserLogData
};
  
export default userListServices;