import Materials1 from '../../../../../config/constants/materials/materials1.json';

const MaterialOne = (material) => {

    var appliedMaterialsXAttributes = []
    if (material) {
        const parsedMaterialsXArr = JSON.parse(material)
        for (var i = 0; i < Materials1.length; i++) {
            for (var j = 0; j < parsedMaterialsXArr.length; j++) {
                if (Materials1[i].value == parsedMaterialsXArr[j]) {
                    var label=Materials1[i].label;
                    //appliedMaterialsXAttributes.push(label);
                    if(j == 0)
                    {
                        const text=label.charAt(0).toUpperCase() + label.slice(1);
                        appliedMaterialsXAttributes.push(text);
                    }else{
                        appliedMaterialsXAttributes.push(label.toLowerCase());
                    }
                }
            }
        }
    }
    return { appliedMaterialsXAttributes }
}

export default MaterialOne

/*
var label=Materials1[i].label;
                    if(i == 0)
                    {
                        appliedMaterialsXAttributes.push(label.toUpperCase());
                        console.log("index",i)
                    }else{
                        appliedMaterialsXAttributes.push(label.toLowerCase());
                    }
*/                    