import React, { useState } from "react";
import FileInput from "./FileInput";
import ImageCropper from "./ImageCropper";
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { notification } from 'antd';
import config from '../../../../../../src/config/config';
import storeBase64Image from '../../../../../redux/TempImage/actionCreator'

function TempUpload({ Ratio,handleCancel}) {

    const dispatch = useDispatch();

    const [image, setImage] = useState("");
    const [currentPage, setCurrentPage] = useState("choose-img");
    const [imgAfterCrop, setImgAfterCrop] = useState("");

    // Invoked when new image file is selected
    const onImageSelected = (selectedImg) => {
        setImage(selectedImg);
        setCurrentPage("crop-img");
    };

    // Generating Cropped Image When Done Button Clicked
    const onCropDone = (imgCroppedArea) => {

        const canvasEle = document.createElement("canvas");
        canvasEle.width = imgCroppedArea.width;
        canvasEle.height = imgCroppedArea.height;

        const context = canvasEle.getContext("2d");

        let imageObj1 = new Image();
        imageObj1.src = image;
        imageObj1.onload = function () {
            context.drawImage(
                imageObj1,
                imgCroppedArea.x,
                imgCroppedArea.y,
                imgCroppedArea.width,
                imgCroppedArea.height,
                0,
                0,
                imgCroppedArea.width,
                imgCroppedArea.height
            );

            const dataURL = canvasEle.toDataURL("image/jpeg");            

            console.log("dataURL :", dataURL);
            /*
            let post_data = { UID: UID, OldImage: OldImage, new_image: dataURL,ExhibitionId:ExhibitionId };

            axios.post(`${config.apiUrl}/update-profile-image`, post_data)
                .then((response) => {
                    //alert(JSON.stringify(response));
                    if (response.data && response.data.status == true) {
                        alert("Profile Image uploaded successfully");
                        location.reload();
                    } else {
                        notification.error({
                            message: "unable to upload Image",
                        });
                        setCurrentPage("choose-img");
                        setImage("");
                    }
                })
                .catch((error) => {
                    console.log("Error :", error)
                })
            */
                dispatch(storeBase64Image(dataURL));
                handleCancel();

            //setImgAfterCrop(dataURL);
            //setCurrentPage("img-cropped");
        };

    };

    // Handle Cancel Button Click
    const onCropCancel = () => {
        setCurrentPage("choose-img");
        setImage("");
    };

    return (
        <div className="container 1234">
            {currentPage === "choose-img" ? (
                <FileInput setImage={setImage} onImageSelected={onImageSelected} />
            ) : currentPage === "crop-img" ? (
                <ImageCropper
                    image={image}
                    onCropDone={onCropDone}
                    onCropCancel={onCropCancel}
                    ExhibitionId={null}
                    Ratio={Ratio}
                />
            ) : (
                <div style={{ display: "none" }}>
                    <div >
                        {/* <img src={imgAfterCrop} className="cropped-img" /> */}
                    </div>

                    <button
                        onClick={() => {
                            setCurrentPage("crop-img");
                        }}
                        className="btn"
                    >
                        Crop
                    </button>

                    <button
                        onClick={() => {
                            setCurrentPage("choose-img");
                            setImage("");
                        }}
                        className="btn"
                    >
                        New Image
                    </button>
                </div>
            )}
        </div>
    );
}

export default TempUpload;
