import { Form, Modal, Button, Input, Select } from 'antd';
import React, { useEffect, useState } from 'react'
import propTypes from 'prop-types';
import axios from 'axios';
import 'react-phone-input-2/lib/style.css'

const PackingInstructions = ({ visible, onCancel }) => {

    const [state, setState] = useState({
        visible,
        modalType: 'primary',
    });

    const handleCancel = () => {
        onCancel();
    };

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            setState({
                visible,
            });
        }
        return () => {
            unmounted = true;
        };
    }, [visible]);





    return (
        <div>
            <Modal
                type={state.modalType}
                visible={state.visible}
                footer={null}
                // title=""
                title="Packing Instructions"
                onCancel={handleCancel}
            >
                <p style={{ padding: 5, fontFamily: 'Poppins, sans-serif', marginTop: 5 }}>
                    <p>Before you ship or transport your artwork to its agreed upon destination, it’s important to pack it securely so that it does not get damaged during transport. Even though shipping companies exercise caution, it’s important to pack your art as safely as possible. Please follow the following guidelines when packing your artwork:<br /><br />
                        <p><a href="https://www.youtube.com/watch?v=GGRkygKnsbs&ab_channel=Agora" target='_blank' >How to Safely Pack Paintings for Shipping Video</a></p>
                        <br/><br/>
                        <b>How to Pack Framed Paintings</b>
                        <br/><br/>
                        1. Find a box or build a crate that can fit the piece, with 2-3 inches of space available on all sides of the frame.<br /><br/>
                        2. Wrap your artwork with acid-free tissue paper. We recommend Glassine.<br /><br/>
                        3. Wrap the piece in bubble wrap, ensuring adequate coverage on the corners since they are most susceptible to damage during transport.<br /><br/>
                        4. Place a layer of foam or your packaging material of choice in the box, creating a layer to rest the frame on.<br /><br/>
                        5. Pack all open areas surrounding the piece with packing material. The more snug the fit, the less the potential for damage.<br /><br/>
                        6. Mark the package as fragile.
                        <br /><br/><br/>
                        <b>How to Pack Unframed Paintings</b>
                        <br/><br/>
                        1. Find a box or build a crate that can fit the piece, with 2-3 inches of space available on all sides of the frame.<br/><br/>
                        2. If possible, try not to directly touch the artwork. Use nitrile gloves, tissue paper, or white cotton gloves.<br/><br/>
                        3. Wrap the piece in acid-free tissue paper. We recommend Glassine.<br/><br/>
                        4. Fold four pieces of acid-free tissue paper into triangles with one side open, then place the triangles on each corner of the work. These help secure the piece to a strong backing material.<br/><br/>
                        5. Taping only on the paper corners you just made, mount your wrapped piece with tape to sturdy cardboard.<br/><br/>
                        6. Place a few layers of cardboard on both sides of the newly secured piece. Tape these pieces together.<br/><br/>
                        7. Place a layer of foam or your packaging material of choice in the box, creating a layer to rest the frame on.<br/><br/>
                        8. Pack all open areas surrounding the piece with packing material. The more snug the fit, the less the potential for damage.<br/><br/>
                        9. Mark the package as fragile.<br/>
                    </p>                                    
                </p>
            </Modal>
        </div>
    )
}


PackingInstructions.propTypes = {
    visible: propTypes.bool.isRequired,
    onCancel: propTypes.func.isRequired,
};

export default PackingInstructions