import Color_Taste from '../../../../../config/constants/materials/color_taste';

const ColorTasteProfile = (globalData) => {

    let colorArray = Color_Taste;
    var color_Array_Data = []
    //var counterData = 0
    var tempDataColorSum = 0
    var tempDataColorCount = 0
    var colorName = ''
    var step2 = []
    for (var i = 0; i < colorArray.length; i++) {

        colorName = colorArray[i].label
        var id = colorArray[i].value

        for (var j = 0; j < globalData.length; j++) {
            step2 = globalData[j].colorTasteProfile

            for (let k = 0; k < step2.length; k++) {
                if (step2[k].name == colorName) {
                    tempDataColorCount++
                    tempDataColorSum += step2[k].userRating

                }
            }

        }

        if (tempDataColorCount > 0) {
            color_Array_Data.push({
                id: id,
                name: colorName,
                // cUserRating:tempDataColorSum,
                // Avg: tempDataColorCount == 0 ? tempDataColorCount : (tempDataColorSum) / (tempDataColorCount)
                tpScore: tempDataColorCount == 0 ? tempDataColorCount : (tempDataColorSum) / (tempDataColorCount)
            })
        }

        tempDataColorCount = 0
        tempDataColorSum = 0

    }
    return {color_Array_Data}
}

export default ColorTasteProfile