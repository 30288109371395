const actions = {

	// GET_CONTENT_DETAILS_BEGIN: 'GET_CONTENT_DETAILS_BEGIN',
	GET_CONTENT_DETAILS_SUCCESS: 'GET_CONTENT_DETAILS_SUCCESS',
	// GET_CONTENT_DETAILS_ERR: 'GET_CONTENT_DETAILS_ERR',
   

	// getContentDetailsBegin: () => {
	// 	return {
	// 		type: actions.GET_CONTENT_DETAILS_BEGIN,
	// 	};
	// },

	getContentDetailsSuccess: data => {
		//console.log('in getSingleWorkDetailsSuccess actions')
		//console.log(data)
		return {
			type: actions.GET_CONTENT_DETAILS_SUCCESS,
			payload:data,
		};
	},

	// getContentDetailsErr: err => {
	// 	return {
	// 		type: actions.GET_CONTENT_DETAILS_ERR,
	// 		err,
	// 	};
	// },



};

export default actions;

// export default function Getdata(data) {
// 	return {
// 	  type: actions.GET_CONTENT_DETAILS_SUCCESS,
// 	  payload: data,
// 	};
//   }