import React from 'react'

const FormalCategoriesTasteProfile = (globalData) => {

    let formalCategoryData = globalData[globalData.length - 1];

    let formalCategoryTasteProfile = formalCategoryData.formalCategoriesTasteProfile

  return {formalCategoryTasteProfile}
}

export default FormalCategoriesTasteProfile