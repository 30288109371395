import { Form, Modal, Button, Input, Select, Row } from 'antd';
import React, { useEffect, useState, useRef } from 'react'
import propTypes from 'prop-types';
import axios from 'axios';
import config from '../../../../config/config';
import Upload from '../../authentication/overview/UploadImage/Upload';



const UploadCropImage = ({ visible, onCancel,UID,OldImage,ExhibitionId,BlogId,EducationId,NewsId,Ratio }) => {    
    const [state, setState] = useState({
        visible,
        modalType: 'primary',
    });

    const handleCancel = () => {
        onCancel();
    };


    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            setState({
                visible,
            });
        }
        return () => {
            unmounted = true;
        };
    }, [visible]);    


    return (
        <div>
            <Modal
                type={state.modalType}
                visible={state.visible}
                footer={null}
                width={600}
                height={600}
                title="Upload Image"
                onCancel={handleCancel}
            >
                <div className="project-modal">
                    <Row gutter={15}>
                        <Upload UID={UID} OldImage={OldImage} ExhibitionId={ExhibitionId} BlogId={BlogId} EducationId={EducationId} NewsId={NewsId} Ratio={Ratio}/>
                    </Row>
                </div>
            </Modal>
        </div>
    )
}


UploadCropImage.propTypes = {
    visible: propTypes.bool.isRequired,
    onCancel: propTypes.func.isRequired,
};


export default UploadCropImage;
