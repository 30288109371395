import TasteQuizContentJson from '../../../../../config/constants/materials/tastequizcontent.json'

const ContentTasteProfile = (globalData) => {

    let contentArray = TasteQuizContentJson;

    var content_Array_Data = []
    var tempDataContentSum = 0
    var tempDataContentCount = 0
    var contentName = ''
    var contentStep2 = []

    for (var i = 0; i < contentArray.length; i++) {
        contentName = contentArray[i].label
        var id = contentArray[i].value
        // counterData++
        for (var j = 0; j < globalData.length; j++) {

            contentStep2 = globalData[j].contentTasteProfile
            for (let k = 0; k < contentStep2.length; k++) {
                if (contentStep2[k].name == contentName) {
                    tempDataContentCount++
                    tempDataContentSum += contentStep2[k].userRating

                }
            }

        }

        if (tempDataContentCount > 0) {
            content_Array_Data.push({
                id: contentArray[i].value,
                name: contentName,
                // cUserRating:tempDataContentSum,
                tpScore: tempDataContentCount == 0 ? tempDataContentCount : (tempDataContentSum) / (tempDataContentCount)
            })
        }


        tempDataContentCount = 0
        tempDataContentSum = 0
    }
    return {content_Array_Data}
}

export default ContentTasteProfile