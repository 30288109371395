import actions from './actions';

const {

    // GET_CONTENT_DETAILS_BEGIN,
    GET_CONTENT_DETAILS_SUCCESS,
    // GET_CONTENT_DETAILS_ERR,

} = actions;

const initialState = {
  data: [],
  loading: false,
  error: null,
};


const initialStateSingle = {
  count:0
};

const contentData = (state = initialStateSingle, action) => {
  const { type, data, err } = action;
  switch (type) {
    // case GET_CONTENT_DETAILS_BEGIN:
    //   return {
    //     ...initialStateSingle,
    //     loading: true,
    //   };
    case GET_CONTENT_DETAILS_SUCCESS:
      return action.payload
    // case GET_CONTENT_DETAILS_ERR:
    //   return {
    //     ...initialStateSingle,
    //     error: err,
    //     loading: false,
    //   };
    default:
      return state;
  }
};

export default contentData;