import actions from './actions'
import workService from '../../services/workService';

const {
    countBegin,
    countSuccess,
    countSendErr
  } = actions;


  const count = (inputs) => {
	return async dispatch => {
		try {
			await dispatch(countBegin());
			// console.log("inputs")
			// console.log(inputs)
			const data = await workService.getCartCount(inputs);
			
			await dispatch(countSuccess(data));
		} catch (err) {
			console.log("err",err)
			dispatch(countSendErr(err));
		}
	};
};


export default count