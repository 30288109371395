const actions = {
	LIKE_DISLIKE_SkIP_BEGIN: 'LIKE_DISLIKE_SkIP_BEGIN',
	LIKE_DISLIKE_SkIP_SUCCESS: 'LIKE_DISLIKE_SkIP_SUCCESS',
	LIKE_DISLIKE_SkIP_ERR: 'LIKE_DISLIKE_SkIP_ERR',

    UPDATE_CART_ITEMS_BEGIN: 'UPDATE_CART_ITEMS_BEGIN',
	UPDATE_CART_ITEMS_SUCCESS: 'UPDATE_CART_ITEMS_SUCCESS',
	UPDATE_CART_ITEMS_ERR: 'UPDATE_CART_ITEMS_ERR',


 
likeDislikeSkipBegin: () => {
    return {
        type: actions.LIKE_DISLIKE_SkIP_BEGIN,
    };
},

likeDislikeSkipSuccess: data => {
    return {
        type: actions.LIKE_DISLIKE_SkIP_SUCCESS,
        data,
    };
},

likeDislikeSkipErr: err => {
    return {
        type: actions.LIKE_DISLIKE_SkIP_ERR,
        err,
    };
},

updateCartItemsBegin: () => {
    return {
        type: actions.UPDATE_CART_ITEMS_BEGIN,
    };
},

updateCartItemsSuccess: data => {
    return {
        type: actions.UPDATE_CART_ITEMS_SUCCESS,
        data,
    };
},

updateCartItemsErr: err => {
    return {
        type: actions.UPDATE_CART_ITEMS_ERR,
        err,
    };
}

};



  
export default actions;  