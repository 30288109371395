import { Form, Select, Table } from 'antd';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Cards } from '../../components/cards/frame/cards-frame'
import { Main } from '../styled'
import config from '../../config/config';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Modal, notification, Button } from 'antd';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { ProjectHeader } from '../project/style';
import { PageHeader } from '../../components/page-headers/page-headers';
import CreateEducation from './CreateEducation';
import EditEducation from './EditEducation';

function ManageEducation() {

  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [getEducationData, setEducationata] = useState([])
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [exId, setId] = useState(null);

  const [deliverystatus, setDeliveryStatus] = useState("");
  const [olddeliverystatus, setOldDeliveryStatus] = useState("");
  const [txn_id, setTxnId] = useState("");
  const [orderId, setOrderId] = useState("");
  const [oldImageURL, setOldImageURL] = useState("");


  const UID = useSelector((state) => {
    return state.fb.auth.uid;
  });

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setId(null)
    setIsEditModalVisible(false);
    setIsModalVisible(false);
  };

  const openModal = () => {
    setIsModalVisible(true);
  };

  const deleteEducation = (id, imagesURL) => {
    Modal.confirm({
      title: 'Delete Blog',
      content: (
        <div>
          <p>Do you want to delete education</p>
        </div>
      ),
      onOk() {


        const data = { id, imagesURL };

        axios.post(`${config.apiUrl}/delete-education`, data)
          .then(function (response) {
            console.log(JSON.stringify(response));
            location.reload();
          })
          .catch(function (error) {
            // handle your error
            console.log(error)
            return false;
          });

      },
      onCancel() { }
    });

  }
  const openEducationModal = (id, imagesURL) => {
    setOldImageURL(imagesURL);
    setId(id)
    setIsEditModalVisible(true);
  };



  const history = useHistory();

  useEffect(() => {

    sessionStorage.removeItem('tab-index');
    var temp_data = [];
    const getAllOrders = async () => {
      const response = await fetch(`${config.apiUrl}/get-all-education/0/null`, {
        method: "GET", // or 'PUT'
        headers: {
          "Content-Type": "application/json",
        }
      });
      const data = await response.json();
      setEducationata(data)
    }
    getAllOrders();

  }, [])

  const handleStatus = async () => {
    let BrowserData = { id: orderId, delivery_status: deliverystatus, order_id: txn_id }
    console.log(JSON.stringify(BrowserData));
    axios.post(`${config.apiUrl}/update-order-status`, BrowserData)
      .then(function (response) {
        if (response.status && response.status == 200) {
          sessionStorage.setItem("update_status", "1")
          location.reload();
        }

      })
      .catch(function (error) {
        console.log(error);
      });

  }





  const handleInputs = (value, name) => {
    setDeliveryStatus(value);
  }

  let columns = [
    {
      title: 'Thumbnail Image',
      dataIndex: 'image_url',
      key: 'index',
      align: 'center',
      render: (tags) => {
        if (tags != null && tags != '') {
          const test = JSON.parse(tags)
          console.log("tags******", test)
          if (test.length > 0) {
            const imagesURL = test[0].url;
            return (
              <img src={imagesURL} style={{ height: 50, width: 50, borderRadius: 8 }} />
            )

          }
        }
      }
    },
    {
      title: 'Name',
      dataIndex: 'education_name',
    },
    {
      title: 'Date',
      dataIndex: 'education_date_range'
    },    
    {
      title: 'Status',
      dataIndex: 'status',
      align: 'center',
      render: (status) => {
        if (status == '1') {
          return <label><b style={{ color: '#006C94' }}>Active</b></label>
        } else {
          return <label><b style={{ color: '#ff4d4f' }}>Inactive</b></label>
        }
      }
    },
    {
      title: 'Edit',
      dataIndex: ['education_id', 'image_url'],
      //render: (id, row) => <span onClick={() => { showImageModal(), imageDetailsGet(row['id']) }} style={{ cursor: 'pointer', wordBreak: 'break-word' }}>{row['work_title'] != null && row['work_title'] != "" && row['work_title'].slice(0, 70)} {row['work_title'] != null && row['work_title'] != "" && row['work_title'].length > 70 && "..."}</span>
      render: (id, row) => {
        if (row['image_url'] != null) {
          var image_url = JSON.parse(row['image_url']);
          if (image_url.length > 0) {
            const imagesURL = image_url[0].url;
            return (
              <EditOutlined onClick={() => { openEducationModal(row['education_id'], imagesURL) }} />
            )
          } else {
            return (
              <EditOutlined onClick={() => { openEducationModal(row['education_id'], '') }} />
            )
          }
        }
        else {
          return (
            <EditOutlined onClick={() => { openEducationModal(row['education_id'], '') }} />
          )
        }


      }
    },
    {
      title: 'Delete',
      dataIndex: ['education_id', 'image_url'],
      render: (id, row) => {
        if (row['image_url'] != null) {
          var image_url = JSON.parse(row['image_url']);
          if (image_url.length > 0) {
            const imagesURL = image_url[0].url;
            return (
              <DeleteOutlined onClick={() => { deleteEducation(row['education_id'], imagesURL) }} />
            )
          } else {
            return (
              <DeleteOutlined onClick={() => { deleteEducation(row['education_id'], '') }} />
            )
          }
        }
        else {
          return (
            <DeleteOutlined onClick={() => { deleteEducation(row['education_id'], '') }} />
          )
        }

      }
    }
  ];

  return (
    <>

      <ProjectHeader >
        <PageHeader
          ghost
          title="Manage Education"
          buttons={[
            <Button onClick={openModal} type="primary" size="large">Create education</Button>
          ]}
        />
      </ProjectHeader>

      <Main className='main-layout-div'>
        <Cards>
          <div className="table-responsive">
            <Table dataSource={getEducationData} columns={columns} />
          </div>
        </Cards>

         <CreateEducation onCancel={handleCancel} visible={isModalVisible} />
        {exId != null && <EditEducation onCancel={handleCancel} visible={isEditModalVisible} Id={exId} oldImage={oldImageURL} />}
      </Main>
    </>
  )
}

export default ManageEducation
