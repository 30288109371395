import React, { useState, useEffect, useCallback } from 'react';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { FacebookOutlined, TwitterOutlined } from '@ant-design/icons';
import { Form, Input, Button, Select, Row, notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { AuthWrapper } from '../style';
import { Checkbox } from '../../../../../components/checkbox/checkbox';
import Heading from '../../../../../components/heading/heading';
import {
  fbAuthSignUp,
  fbAuthLogin,
  fbAuthLoginWithGoogle,
  fbAuthLoginWithFacebook,
} from '../../../../../redux/firebase/auth/actionCreator';
import { login } from '../../../../../redux/authentication/actionCreator';
import { Modal } from '../../../../../components/modals/antd-modals';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios';
import config from '../../../../../config/config';
import messages from '../../../../../config/constants/messages';
import userServices from '../../../../../services/userServices';
import GetSnippetsData from '../../overview/Snippets/GetSnippetsData'


const AdminAccount = ({ visible, confirmLoading, onCancel }) => {

  const { isSignUpError, isSignUpLoading, isFbAuthenticate } = useSelector(state => {
    return {
      isSignUpError: state.firebaseAuth.isSignUpError,
      isSignUpLoading: state.firebaseAuth.isSignUpLoading,
      isFbAuthenticate: state.fb.auth.uid,
    };
  });
  const history = useHistory();
  const dispatch = useDispatch();
  const [state, setState] = useState({
    values: null,
    checked: null,
    visible
  });

  const [recaptcha, setRecaptcha] = useState('')
  const [namelength, setNamelength] = useState(0)
  const [verified, setVerified] = useState(false);

  function onChange1(value) {
    setRecaptcha(value);
    setVerified(true);
  }

  const handleFbLogin = useCallback(() => {
    dispatch(login());
    history.push('/gallery');

  }, [dispatch, history]);

  useEffect(() => {
    if (isFbAuthenticate) {
      handleFbLogin();
    }

    let unmounted = false;
    if (!unmounted) {
      setState({
        visible,
      });
    }
    return () => {
      unmounted = true;
    };
  }, [isFbAuthenticate, handleFbLogin, visible]);


  const [phone, setPhone] = useState('')
  const handleCountryCode = (value, data, event, formattedValue) => {
    // console.log("value",value)
    // console.log("data",data)
    // console.log("event",event)
    // console.log("formattedValue",formattedValue)
    setPhone(formattedValue)
  }


  const handleSubmit = values => {

    const promise = new Promise((resolve, reject) => {
      let verify_token = { token: recaptcha };
      axios.post(`${config.apiUrl}/send-recaptcha-token`, verify_token)
        .then(function (response) {

          if (response.data.google_response.success == true) {
            resolve(true);
            setRecaptcha('');
          } else {
            window.grecaptcha.reset();
            setRecaptcha('');
            setVerified(false);
            alert(messages['recaptcha_failed_error'])
            return false;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    })
    promise.then((d) => {
      let post_data = { ...values, phone: phone, terms: state.checked, bio: '' };
      //dispatch(fbAuthSignUp({ ...values, phone: phone, terms: state.checked }));
      const data = userServices.SignUp(post_data).then(response => {
        if (response.data.status == true) {
          sessionStorage.setItem('signup_modal', JSON.stringify({
            showmodal: true,
            role: 'Collector'
          }))
          //   notification.success({
          //     message: response.data.message
          // });
          dispatch(fbAuthLogin(post_data));
        } else {
          notification.warning({
            message: response.data.message
          });
        }
      }).
        catch(error => {
          if (error.response && error.response.status === 400) {
            console.log("Error 400: Bad Request");
          } else {
            console.error("An error occurred:", error);
          }
        });
      window.grecaptcha.reset();
    })

  };

  const onChange = checked => {
    setState({ ...state, checked });
  };
  //reducers
  const handleCancel = () => {
    onCancel();
  };

  const cancelModal = () => {
    onCancel()
  }


  const [tasteTestVisible, setTasteTestVisible] = React.useState(false);

  const tasteTestModalCancel = () => {
    setTasteTestVisible(false)
  }



  const handleClick = () => {
    onCancel()
    // history.push('/home')
    setTasteTestVisible(true)
  }

  const showTasteQuizModal = () => {
    setTasteTestVisible(false)
    sessionStorage.setItem('temp_taste_test', true)
    history.push('/tastequiz')
  }

  const skipTasteTest = () => {
    setTasteTestVisible(false)
    sessionStorage.setItem('temp_taste_test', false)
    history.push('/view-gallery')
  }
  const collector_Welcome_Signup_Message = "Collector_Welcome_Signup_Message"
  const collectorWelcomeSignupMessageSnippetData = GetSnippetsData(collector_Welcome_Signup_Message)

  return (

    <React.Fragment>
      <Modal
        visible={tasteTestVisible}
        onCancel={tasteTestModalCancel}
        footer={null}
      >
        <p style={{ marginTop: 25 }} dangerouslySetInnerHTML={{ __html: collectorWelcomeSignupMessageSnippetData != "" ? collectorWelcomeSignupMessageSnippetData.collectorWelcomeSignupMessageContent : "" }} />
        {/* <p style={{ marginTop: 25 }}>Welcome! Stoodio is here to help you discover artworks by leading emerging artists that are right for you.
          Our aim is to offer you, a collector and an art lover, a reliable and effective way of discovering and
          purchasing artwork without the need to navigate the gallery world.</p> */}
        <Row>


          <button  size='default' class="slide-from-bottom-test-quiz slide-from-bottom add-cart-button-text " onClick={showTasteQuizModal} style={{ cursor: 'pointer', marginTop: 20,marginRight:25,height:'30px !important' }}>
            <p style={{ fontFamily: 'Arial', fontSize: 13, margin: 0, fontWeight: 600, lineHeight: '24px', letterSpacing: ' 0em', textAlign: 'left' }}>Take The Taste Test</p>
          </button>

          <button  size='default' class="slide-from-bottom-test-quiz slide-from-bottom add-cart-button-text" onClick={skipTasteTest} style={{ cursor: 'pointer', marginTop: 20,height:'30px !important' }}>
            <p style={{ fontFamily: 'Arial', fontSize: 13, margin: 0, fontWeight: 600, lineHeight: '24px', letterSpacing: ' 0em', textAlign: 'left' }}>Skip For Now</p>
          </button>

        </Row>

      </Modal>

      <Modal
        visible={visible}
        confirmLoading={confirmLoading}
        onCancel={cancelModal}
        footer={null}

      >
        <AuthWrapper>

          <div className="auth-contents">
            <Form name="register" onFinish={handleSubmit} layout="vertical">
              <p style={{ fontSize: 18, fontWeight: 600 }}>
                Don't lose your data! Build up your taste profile and save your ratings by creating an account in seconds!
              </p>
              <p style={{ fontSize: 14 }}>Your ratings and taste profile will be saved by entering the information below. </p>

              <Link className="btn-create" htmlType="submit" type="primary" size="large" onClick={handleClick} style={{ margin: '10px 0' }}>
                No thanks, take me to the gallery.
              </Link>
              <Form.Item name="user_type" initialValue="user" style={{ height: 0, display: 'none' }}>
                <Input placeholder="User Type" type="hidden" />
              </Form.Item>

              <Form.Item name="i_am" initialValue="Collector" style={{ height: 0 }}>

                <Input readOnly type='hidden'
                />
              </Form.Item>


              <Form.Item label="Name" name="name"
                rules={[{ required: true, message: 'Please input your full name' },
                { max: 100, message: "The maximum length for the full name is 100 characters." }]}
              >

                <Input placeholder="Full name"
                  onChange={(e) => {
                    var temp = e.target.value;
                    setNamelength(temp.length);
                  }
                  }
                />
              </Form.Item>
              <div style={{ marginTop: '-25px', float: 'right' }} className={100 < namelength ? 'danger-text' : ''}>{namelength}/100 char used</div>


              <Form.Item
                name="email"
                label="Email Address"
                rules={[{ required: true, message: 'Please input your email', type: 'email' }]}
              >
                <Input placeholder="name@example.com" />
              </Form.Item>

              <Form.Item name="phone" label="Phone" rules={[{ required: true, message: 'Please input your phone' }]}>
                {/* <Input placeholder="Phone" /> */}
                <PhoneInput
                  country={'us'}
                  inputProps={{
                    name: 'phone'
                  }}
                  inputStyle={{
                    height: '48px',
                    width: '100%'
                  }}
                  enableSearch={true}
                  copyNumbersOnly={false}
                  searchPlaceholder='search'
                  searchNotFound='No entries to show'
                  countryCodeEditable={false}
                  onChange={handleCountryCode}
                />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                rules={[{ required: true, message: 'Please input your password' }]}
              >
                <Input.Password placeholder="Password" />
              </Form.Item>

              <Form.Item
                name="confirm"
                label="Confirm Password"
                dependencies={['password']}
                rules={[
                  {
                    required: true,
                    message: 'Please confirm your password',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('The two passwords that you entered do not match'));
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item
                name="checkbox"
                rules={[
                  //{ required: true, message: 'Please select TOS and Privacy Policy' },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('Please agree to TOS and Privacy Policy'));
                    },
                  }),
                ]}
              >
                <Checkbox checked={state.checked} onChange={onChange}>
                  I agree to the <NavLink to={`/terms`} target='_blank'>Terms of Service</NavLink> and <NavLink to={`/privacy-policy`} target='_blank'>Privacy Policy</NavLink>
                </Checkbox>
              </Form.Item>

              <Form.Item>
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                  onChange={onChange1}
                />
              </Form.Item>

              {isSignUpError ? <p>{isSignUpError.message}</p> : null}
              <Form.Item>
                <Button className="btn-create" htmlType="submit" type="primary" size="large" disabled={!verified}>
                  {isSignUpLoading ? 'Loading...' : 'Create Account'}
                </Button>
              </Form.Item>

            </Form>
          </div>
        </AuthWrapper>
      </Modal>
    </React.Fragment>


  )
}

export default AdminAccount