import React, { useRef } from "react";
import { Form, Modal, Button, Input } from 'antd';

function FileInput({ onImageSelected }) {
    const inputRef = useRef();

    const handleOnChange = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            const reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = function (e) {
                onImageSelected(reader.result);
            };
        }
    };

    const onChooseImg = () => {
        inputRef.current.click();
    };

    return (
        <>
            <input
                type="file"
                accept="image/*"
                ref={inputRef}
                onChange={handleOnChange}
                style={{ display: "none" }}
            />

            <Form.Item style={{textAlign:'center'}}>
                <Button onClick={onChooseImg} type="primary" size="large">
                Choose Image
                </Button>
            </Form.Item>            
        </>
    );
}

export default FileInput;
