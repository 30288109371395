import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input,  notification, Button } from 'antd';
import propTypes from 'prop-types';
import { Modal } from '../../components/modals/antd-modals';
import { BasicFormWrapper } from '../styled';
import config from '../../config/config';
import messages from '../../config/constants/messages';
import axios from 'axios';


const { TextArea } = Input;

const EditMeta = ({ visible, onCancel, Id, editData }) => {

  const dispatch = useDispatch();

  const isFbAuthenticate = useSelector(state => {

    return {
      isFbAuthenticate: state.fb.auth.uid,
    }
  });


  const [form] = Form.useForm();
  const formData = new FormData();
  //const [is_framed, setIsFramed] = useState(false);

  const [state, setState] = useState({
    visible,
    modalType: 'primary',
    checked: [],
    page_name: '',
    page_url: '',
    page_meta_title: '',
    page_meta_description: '',
    page_meta_robot:'',    
    images: [],
    isLoading: false
  });

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      setState({
        visible,
      });
    }
    return () => {
      unmounted = true;
    };
  }, [visible]);

  useEffect(() => {

    sessionStorage.removeItem('tab-index');

    setShowForm(true);

  }, [])


  const [buttonDisable, setButton] = useState(false);
  const [loading, setLoading] = useState(false);


  
  const [ShowForm, setShowForm] = useState(false);
  



  const handleOk = (values) => {

    let post_data = {
      seo_id: Id,
      ...values
    };    


    // console.log(state)
    setButton(true);
    setLoading(true);

    setState({
      ...state,
      isLoading: true,
    });

    if (state.images) {
      for (let i = 0; i < state.images.length; i++) {
        formData.append('file', state.images[i])
      }
    }

    formData.append('data', JSON.stringify(post_data))

    axios.post(`${config.apiUrl}/update-seo`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then((response) => {
      if (response.data.affectedRows == 1) {
        alert("The seo updated successfully.");
        location.reload();
      } else {
        setButton(false);
        setLoading(false);
        alert("Uanble to update the seo");
      }
    })
      .catch((error) => {
        console.log("Error :", error)
      })
  };




  const handleCancel = () => {
    onCancel();
  };

  
  

  return (

    <>

      <Modal
        type={state.modalType}
        title="Edit SEO"
        visible={state.visible}
        width={1000}
        backdrop="static"
        footer={[null]}
        onCancel={handleCancel}
      >
        <div className="project-modal">
          {ShowForm == true &&
            <>
              <BasicFormWrapper>
                <Form id="createWorkForm" form={form} name="createProject" onFinish={handleOk} encType="multipart/form-data">

                  <Form.Item name="page_name" label="Page Name"
                    initialValue={editData ? editData.page_name : ''}
                    rules={[{ required: true, message: 'Please input page name' }]}
                  >
                    <Input placeholder="" name="page_name" />
                  </Form.Item>

                  <Form.Item name="page_url" label="Page URL"
                    initialValue={editData ? editData.page_url : ''}
                    rules={[{ required: true, message: 'Please input page url' }]}
                  >
                    <Input addonBefore="https://stoodio.art/" placeholder="url" name="url" />
                  </Form.Item>

                  <Form.Item name="page_meta_title" label="Meta title"
                    initialValue={editData ? editData.page_meta_title : ''}
                    rules={[{ required: true, message: 'Please input meta title' }]}
                  >
                    <TextArea rows={1} type='text' name='page_meta_title' />
                  </Form.Item>

                  <Form.Item name="page_meta_description" label="Meta Description"
                    initialValue={editData ? editData.page_meta_description : ''}
                    rules={[{ required: true, message: 'Please input meta description' }]}
                  >
                    <TextArea rows={2} type='text' name='page_meta_description'
                    />
                  </Form.Item>

                  <Form.Item name="page_meta_robot" label="Meta Robots"
                    rules={[{ required: true, message: 'Please input meta robot' }]}
                    initialValue={editData ? editData.page_meta_robot : ''}
                  >
                    <TextArea rows={1} type='text' name='page_meta_robot'
                    />
                  </Form.Item>

                  <Form.Item>

                    <Button type="primary" htmlType="submit" disabled={buttonDisable}>
                      Submit {loading && (<i className='fa fa-spinner fa-spin' style={{ fontSize: 18, marginLeft: 5 }} />)}
                    </Button>
                    {' '}
                    <Button size="default" type="white" key="back" outlined onClick={handleCancel}>
                      Cancel
                    </Button>
                  </Form.Item>
                </Form>
              </BasicFormWrapper>
            </>}

        </div>
      </Modal>
    </>
  );
};

EditMeta.propTypes = {
  visible: propTypes.bool.isRequired,
  onCancel: propTypes.func.isRequired,
};

export default EditMeta;
