import Categories from '../../../../../config/constants/materials/tastequizcontent';

const Content = (categories) => {

    var appliedCategoriesAttributes = []
    if (categories) {
        let parsedCategoriesArr;
        if (typeof categories === "string") {
            parsedCategoriesArr = JSON.parse(categories);
        } else {
            parsedCategoriesArr = categories

        }
        for (var i = 0; i < Categories.length; i++) {
            for (var j = 0; j < parsedCategoriesArr.length; j++) {
                if (Categories[i].value == parsedCategoriesArr[j]) {
                    appliedCategoriesAttributes.push(Categories[i].label);
                }
            }
        }
    }

    return { appliedCategoriesAttributes }
}

export default Content