import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Select, Col, Row, DatePicker, Avatar, Upload, TimePicker, notification, Button } from 'antd';
import propTypes from 'prop-types';
import { Modal } from '../../components/modals/antd-modals';
import { BasicFormWrapper } from '../styled';
import config from '../../config/config';
import moment from 'moment';
import messages from '../../config/constants/messages';
import axios from 'axios';




const { Option } = Select;
const dateFormat = 'MM/DD/YYYY';
const { Dragger } = Upload;
const { TextArea } = Input;

const CreateSEO = ({ visible, onCancel }) => {
  const dispatch = useDispatch();

  const UID = useSelector((state) => {
    return state.fb.auth.uid;
  });


  const Base64Data = useSelector((state) => {
    return state.Base64Reducer.data
  })  


  const [form] = Form.useForm();
  const formData = new FormData();

  const [buttonDisable, setButton] = useState(false);
  const [loading, setLoading] = useState(false);


  const [state, setState] = useState({
    visible,
    modalType: 'primary',    
    page_name: '',
    page_url: '',
    page_meta_title: '',
    page_meta_description: '',
    page_meta_robot:'',    
    images: [],
    isLoading: false
  });



  useEffect(() => {
    sessionStorage.removeItem('tab-index');
    let unmounted = false;
    if (!unmounted) {
      setState({
        visible,
      });
    }
    return () => {
      unmounted = true;
    };
  }, [visible]);



  const handleOk = (values) => {

    let post_data = { ...values, uid:UID };

    setButton(true);
    setLoading(true);

    setState({
      ...state,
      isLoading: true,
    });

    

    formData.append('data', JSON.stringify(post_data))

    axios.post(`${config.apiUrl}/create-seo`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then((response) => {
      if (response.data.affectedRows == 1) {
        alert("The SEO created successfully.");
        location.reload();
      } else {
        setButton(false);
        setLoading(false);
        alert("Uanble to create the education");
      }
    })
      .catch((error) => {
        console.log("Error :", error)
      })

  };

  const handleImageChange = (e) => {
    console.log("e.target.files :", e.target.files);
    if (e.target.files.length) {
      for (var i = 0; i < e.target.files.length; i++) {
        if (!e.target.files[i].name.match(/.(jpg|jpeg|png|bmp)$/i)) {
          alert(messages['invalid_image_format_error'])
          return false;
        }
      }
      setState({
        ...state,
        [e.target.name]: e.target.files,
      });
    } else {
      setState({
        ...state,
        [e.target.name]: [],
      });
    }
  }




  const handleCancel = () => {
    onCancel();
  };

  const showModal = () => {
    setImagesModalVisiable(true);
  }
  const onImageCancel = () => {
    setImagesModalVisiable(false);
  }


  const SetEditorData = value => {
    //alert(value);
    //const cleanedString = removeHtmlTags(value);
    //SetDescriptionLength(cleanedString.length)
    SetDescription(value);
  }

  return (

    <>

      <Modal
        type={state.modalType}
        title="Create SEO"
        visible={state.visible}
        width={1000}
        backdrop="static"
        footer={[null]}
        onCancel={handleCancel}
      >
        <div className="project-modal">
          <BasicFormWrapper>
            <Form id="createWorkForm" form={form} name="createProject" onFinish={handleOk} encType="multipart/form-data">

              <Form.Item name="page_name" label="Page Name"
                rules={[{ required: true, message: 'Please input page name' }]}
              >
                <Input placeholder="" name="page_name" />
              </Form.Item>

              <Form.Item name="page_url" label="Page URL"
                rules={[{ required: true, message: 'Please input page url' }]}
              >
                <Input addonBefore="https://stoodio.art/" placeholder="url" name="url" />
              </Form.Item>

              <Form.Item name="page_meta_title" label="Meta Title"
              rules={[{ required: true, message: 'Please input meta title' }]}
              >
                <TextArea rows={1} type='text' name='page_meta_title' />
              </Form.Item>

              <Form.Item name="page_meta_description" label="Meta Description"
              rules={[{ required: true, message: 'Please input meta description' }]}
              >
                <TextArea rows={2} type='text' name='page_meta_description'
                />
              </Form.Item>

              <Form.Item name="page_meta_robot" label="Meta Robot"
              rules={[{ required: true, message: 'Please input meta robot' }]}
              >
                <TextArea rows={1} type='text' name='page_meta_robot'
                />
              </Form.Item>

              <Form.Item>

                <Button type="primary" htmlType="submit" disabled={buttonDisable}>
                  Submit {loading && (<i className='fa fa-spinner fa-spin' style={{ fontSize: 18, marginLeft: 5 }} />)}
                </Button>
                {' '}
                <Button size="default" type="white" key="back" outlined onClick={handleCancel}>
                  Cancel
                </Button>
              </Form.Item>
            </Form>
          </BasicFormWrapper>
        </div>
      </Modal>
    </>
  );
};

CreateSEO.propTypes = {
  visible: propTypes.bool.isRequired,
  onCancel: propTypes.func.isRequired,
};

export default CreateSEO;
