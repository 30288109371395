

const actions = {
    GET_RATING_WORKS_BEGIN: 'GET_RATING_WORKS_BEGIN',
    GET_RATING_WORKS_SUCCESS: 'GET_RATING_WORKS_SUCCESS',
    GET_RATING_WORKS_ERR: 'GET_RATING_WORKS_ERR',



    getRatingWorksBegin: () => {
        return {
            type: actions.GET_RATING_WORKS_BEGIN,
        };
    },

    getRatingWorksSuccess: data => {
        return {
            type: actions.GET_RATING_WORKS_SUCCESS,
            data,
        };
    },

    getRatingWorksErr: err => {
        return {
            type: actions.GET_RATING_WORKS_ERR,
            err,
        };
    }
};


export default actions;