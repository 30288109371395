import actions from './actions';
import workService from '../../services/workService';
import {getAllArtWorks} from '../../redux/work/actionCreator'

const {

	getSingleWorkDetailsBegin,
	getSingleWorkDetailsSuccess,
	getSingleWorkDetailsErr,

	updateArtWorkStatusBegin,
	updateArtWorkStatusSuccess,
	updateArtWorkStatusErr
	
} = actions;





const getWorkDetails = (workId) => {
	return async dispatch => {
		try {
			dispatch(getSingleWorkDetailsBegin());
			//console.log("getWork")
			const data = await workService.getSingleWorkDetails(workId);
			
			dispatch(getSingleWorkDetailsSuccess(data));

			
			
		} catch (err) {
			console.log(err)
			dispatch(getSingleWorkDetailsErr(err));
		}
	};
};


const getActivateDeactivateWork = (status_data) => {
	console.log('status_data',status_data)
	return async dispatch => {
		try {
			dispatch(updateArtWorkStatusBegin());
			//console.log("getWork")
			const data = await workService.updateArtWorkStatus(status_data);
			// console.log("Works data",data)
			dispatch(updateArtWorkStatusSuccess(data));
			dispatch(getAllArtWorks())

			
			
		} catch (err) {
			console.log(err)
			dispatch(updateArtWorkStatusErr(err));
		}
	};
};





export {getWorkDetails,getActivateDeactivateWork};