const ColorAffinity = (colorAvgData,unratedColorData) => {

    var color_affinity_score = unratedColorData
    var color_affinity_data = []
    var color_final_affinity_score = 0
    var Affinity = 0
    for (var i = 0; i < unratedColorData.length; i++) {
        var step1 = unratedColorData[i]

        for (var j = 0; j < Object.keys(step1).length; j++) {
            if (step1[j]) {

                const isAvailable = colorAvgData.find(o1 => o1.name == step1[j].name)

                if (isAvailable) {

                    var cal = step1[j].userRating

                    step1[j].userRating = (isAvailable.tpScore - cal - cal)


                }
            }
        }

        const result = Object.values(step1);
        result.map((itm) => {

            if (itm.name) {
                var absolute_val = Math.abs(itm.userRating)
                color_final_affinity_score += absolute_val
            }

        })

        // color affinity step2 calculation

        var singleArrayColorLenght = Object.keys(step1).length - 1

        Affinity = (color_final_affinity_score) / (2 * (singleArrayColorLenght))

        color_affinity_data.push({ ...color_affinity_score[i], Affinity })

        color_final_affinity_score = 0
        Affinity = 0



    }
    return {color_affinity_data}
}

export default ColorAffinity