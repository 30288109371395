
const ContentAffinity = (categoryAvgData,unratedCategoriesData) => {
    var categories_affinity_score = unratedCategoriesData
    var categories_affinity_data = []
    var categories_final_affinity = 0
    var Affinity = 0


    for (var i = 0; i < unratedCategoriesData.length; i++) {

        var step1 = unratedCategoriesData[i]

        for (var j = 0; j < Object.keys(step1).length; j++) {
            if (step1[j]) {

                const isCategoriesAvailable = categoryAvgData.find(o1 => o1.name == step1[j].name)

                if (isCategoriesAvailable) {

                    var cal = step1[j].userRating

                    step1[j].userRating = (isCategoriesAvailable.tpScore - cal - cal)


                }
            }
        }

        const categoriesResult = Object.values(step1);
        categoriesResult.map((itm) => {

            if (itm.name) {
                var absolute_val = Math.abs(itm.userRating)
                categories_final_affinity += absolute_val
            }

        })

        // categories affinity step2 calculation

        var singleArrayCategoriesLenght = Object.keys(step1).length - 1

        Affinity = (categories_final_affinity) / (2 * (singleArrayCategoriesLenght))

        categories_affinity_data.push({ ...categories_affinity_score[i], Affinity })

        categories_final_affinity = 0
        Affinity = 0
    }
    return {categories_affinity_data}
}

export default ContentAffinity