import Materials2 from '../../../../../config/constants/materials/materials2.json';

const MaterialTwo = (material) => {

    var appliedMaterialsYAttributes = []
    if (material) {
        const parsedMaterialsYArr = JSON.parse(material)
        for (var i = 0; i < Materials2.length; i++) {
            //console.log('parsedMaterialsYArr.length',parsedMaterialsYArr);
            if (parsedMaterialsYArr!=null && parsedMaterialsYArr.length > 0) {
                for (var j = 0; j < parsedMaterialsYArr.length; j++) {
                    if (Materials2[i].value == parsedMaterialsYArr[j]) {
                        appliedMaterialsYAttributes.push(Materials2[i].label);
                    }
                }
            }            
        }
    }
    return { appliedMaterialsYAttributes }
}

export default MaterialTwo