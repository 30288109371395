import { Button, Col, Row, Form, Input } from 'antd'
import './css/style.css'
import { Link, NavLink, useHistory, useParams } from 'react-router-dom';
import CommonFooter from "../CommonFooter";
import React, { useEffect, useState } from 'react';
import getdata from '../../../../../redux/contentChange/actions'
import { useDispatch, useSelector } from 'react-redux';
import { faVirusCovidSlash } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import config from '../../../../../config/config';
import { fbUpdatePasswordUserInfo } from '../../../../../redux/profile/actionCreator';


const SucessfulPurchase = ({ urlData }) => {


    const history = useHistory();
    const dispatch = useDispatch();
    const [linkValid, setLinkvalid] = React.useState(false);
    const [uid, setUID] = React.useState(false);
    const [form] = Form.useForm();
    const [emptyfields, setEmptyfields] = useState(false)
    const [password, setPassword] = useState('')
    const [confermPassword, setConfermPassword] = useState('')
    const [matchpassword, setMatchPassword] = useState(false)

    const [mode, setMode] = useState('')
    const [oobCode, setOobCode] = useState('')
    const [apiKey, setApiKey] = useState('')
    const [email, setEmail] = useState('')

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    useEffect(() => {

        setMode(urlParams.get("mode"));
        setOobCode(urlParams.get("oobCode"));
        setApiKey(urlParams.get("apiKey"));
        setEmail(urlParams.get("email"));

        let verify_token = { email: urlParams.get("email") };
        axios.post(`${config.apiUrl}/check-email`, verify_token)
            .then(function (response) {
                if (response.data[0].status == true) {
                    setUID(response.data[0].uid);
                    setLinkvalid(false);
                } else {
                    setLinkvalid(true);
                    setUID(false)
                }
            })
            .catch(function (error) {
                console.log("error", error.message);
                setLinkvalid(true);
                setUID(false)
            });
    }, [])

    const handlePasswordMatch = type => {
        setConfermPassword(type)
        if (type != password) {
            setMatchPassword(true)
        } else {
            setMatchPassword(false)
        }
    };
    const handleSubmit = async ev => {
        let password_data = { uid: uid, password: password, mode: mode, oobCode: oobCode, apiKey: apiKey, email: email };
        
        dispatch(fbUpdatePasswordUserInfo(password_data));
        /*
        history.push('/')
        if (!password || !confermPassword) {
            setEmptyfields(true);
            return false;
        }
        
        
        axios.post(`${config.apiUrl}/update-user-password`, password_data)
            .then(function (response) {
                if (response.data == true) {
                    dispatch(fbUpdatePasswordUserInfo(password_data));
                } else {
                    setUID(false)
                }
            })
            .catch(function (error) {
                console.log("error", error.message);
            });
        */

    };


    return (
        <div style={{
            width: '100%',
        }}>
            <div>
                <div style={{ marginTop: 140, paddingBottom: 60 }}>
                    <Row className='set-homepage-content'>
                        <Col xs={0} sm={0} md={0} lg={3}></Col>
                        <Col xs={24} sm={24} md={24} lg={12}>
                            {
                                linkValid ?
                                    <>
                                        <h2>Try resetting your password again</h2>
                                        {linkValid && (<div class="ant-form-item-explain ant-form-item-explain-error">
                                            <div role="alert">Your request to reset your password has expired or the link has already been used.</div></div>)}
                                    </>
                                    :
                                    <>
                                        <Form
                                            form={form}
                                            layout="vertical"
                                            id="payment-form"
                                            style={{ margin: '50px' }}
                                        >
                                            <h2 style={{ marginBottom: 30 }}>Reset your password</h2>
                                            <h4 style={{ marginBottom: 30 }}>for <b>{email}</b></h4>
                                           
                                            <Form.Item
                                                label="Password"
                                                name="password"
                                                rules={[{ required: true, message: 'Please input your password!' }, { min: 6, message: 'password must be minimum six characters.' }]}
                                            >
                                                <Input.Password placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
                                            </Form.Item>

                                            <Form.Item
                                                name="confirm"
                                                label="Confirm Password"
                                                dependencies={['password']}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please confirm your password!',
                                                    },
                                                    ({ getFieldValue }) => ({
                                                        validator(_, value) {
                                                            if (!value || getFieldValue('password') === value) {
                                                                return Promise.resolve();
                                                            }
                                                            return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                                        },
                                                    }),
                                                ]}
                                            >
                                                <Input.Password onChange={(e) => setConfermPassword(e.target.value)} />
                                            </Form.Item>

                                            <Button className="gallery-btn" htmlType="submit" style={{ marginLeft: 0 }} type="primary" size="default" onClick={handleSubmit}>
                                                <p style={{ margin: 0, padding: 10, fontSize: 14 }}>Save</p>
                                            </Button>

                                        </Form>
                                    </>
                            }



                        </Col>
                        <Col xs={0} sm={0} md={2} lg={3}></Col>
                    </Row>
                </div>
                <div className="adjust-home-page-footer" style={
                    Object.assign({}
                        , {
                            width: '-webkit-fill-available',
                            position: 'fixed',
                            left: 0,
                            bottom: 0,
                            right: 0,
                        }
                    )}
                >
                    <CommonFooter />
                </div>
            </div>
        </div>
    )
}

export default SucessfulPurchase