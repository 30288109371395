import actions from './actions'

const {
    storeBase64Begin,
    storeBase64Success,
    storeBase64SendErr
  } = actions;


  const storeBase64Image = (inputs) => {
	return async dispatch => {
		try {
			await dispatch(storeBase64Begin());
			
			//console.log("storeBase64Image: ",inputs);
			// console.log(inputs)
			//const data = await workService.getCartCount(inputs);

			await dispatch(storeBase64Success(inputs));
		} catch (err) {
			console.log("err",err)
			dispatch(storeBase64SendErr(err));
		}
	};
};


export default storeBase64Image