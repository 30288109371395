import React from 'react'


const FormalCategoriesAverage = (parsedformalCategoriesArr, likeDislikeShipValue,tasteTestAverageData) => {

    var userRatingArray = []
    parsedformalCategoriesArr.map((itm) => {
        userRatingArray.push({
            id: itm.id,
            name: itm.name,
            value: likeDislikeShipValue == 1 ? itm.value : likeDislikeShipValue == 2 ? 2 * (1 - itm.value) : 0,
            // testCumulative: likeDislikeShipValue
            adasRating: itm.value

        }
        )
    })
    
    
    var getCalculatedData = JSON.parse(tasteTestAverageData)
    
    var tmpData;
    if (getCalculatedData) {
        // console.log("if executed")
        tmpData = getCalculatedData[getCalculatedData.length - 1];

        var formalCategoriesTasteProfileArray = []

        userRatingArray.map((itm, i) => {
            formalCategoriesTasteProfileArray.push({
                id: itm.id,
                name: itm.name,
                denominator: likeDislikeShipValue + tmpData.formalCategoriesTasteProfile[i].denominator,
                userRating: parseFloat(itm.value) + parseFloat(tmpData.formalCategoriesTasteProfile[i].userRating),
                tpScore: (parseFloat(itm.value) + parseFloat(tmpData.formalCategoriesTasteProfile[i].userRating)) / (likeDislikeShipValue + tmpData.formalCategoriesTasteProfile[i].denominator)
            }
            )
        })
    } else {
        // console.log("else executed")
        var formalCategoriesTasteProfileArray = []

        userRatingArray.map((itm) => {

            formalCategoriesTasteProfileArray.push({
                id: itm.id,
                name: itm.name,
                denominator: likeDislikeShipValue,
                userRating: itm.value,
                tpScore: (itm.value) / (likeDislikeShipValue == 1 ? 1 : likeDislikeShipValue == 2 ? 2 : 0)
            }
            )
        })
    }

    return {formalCategoriesTasteProfileArray}
}

export default FormalCategoriesAverage