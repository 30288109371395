import { Modal, Row, Tabs, Col, Avatar } from 'antd'
import './css/style.css'
import { Link, NavLink, useHistory, useParams } from 'react-router-dom';

import React, { useState, useEffect } from 'react'
import Magnifier from 'react-magnifier'
import { useDispatch, useSelector } from 'react-redux'
import ContentCategoryData from '../AllCategories/Content'
import MaterialOneCategoryData from '../AllCategories/MaterialOne'
import MaterialTwoCategoryData from '../AllCategories/MaterialTwo'
import ArtistProfile from '../ArtistProfile'
import { likeStyle, disLikeStyle, skipStyle, defaultStyle, skipDefaultStyle, modalStyle } from '../../../../../container/profile/authentication/overview/GalleryComponents/css/RatingStyle'
import { UserOutlined } from '@ant-design/icons';
import Questions from '../Questions'
import { Button } from '../../../../../components/buttons/buttons';
import getdata from '../../../../../redux/contentChange/actions'
import getTabKey from '../../../../../redux/TabKeyChange/actions'
import CommonFooter from "../CommonFooter";
import axios from 'axios';
import config from '../../../../../../src/config/config';
import ArtWorkDetailsModal from './ArtWorkDetailsModal';
import count from '../../../../../redux/count/actionCreator';

const ArtistDetails = (match) => {

    const history = useHistory();
    const { artistId } = useParams();
    const dispatch = useDispatch();
    const [ArtistName, setArtistName] = useState('')
    const [artist_id, setartist_id] = useState(null)
    const [bio, setbio] = useState('')
    const [artistImage, setartistImage] = useState('')
    const [userId, setUID] = useState('')
    const [exhibition_history, setexhibition_history] = useState('')
    const [education_history, seteducation_history] = useState('')

    useEffect(() => {
        if (artistId) {
            axios.get(`${config.apiUrl}/get-user-api/${artistId.split('-').pop()}`)
            .then(function (response) {
                if (response.data.length > 0) {
                    setArtistName(response.data[0].name)
                    setartist_id(response.data[0].id)
                    setbio(response.data[0].bio)
                    setUID(response.data[0].uid)
                    setexhibition_history(response.data[0].exhibition_history)
                    seteducation_history(response.data[0].education_history)
                    if (response.data[0].logo && response.data[0].logo.length > 3) {
                        const imagesURLs = response.data[0].logo && JSON.parse(response.data[0].logo);
                        const imagesURL = imagesURLs[0].url;
                        setartistImage(imagesURL)
                    } else {
                        const imagesURL = 'https://stoodio.art/static/media/stoodiologo.175eeac4.svg';
                        setartistImage(imagesURL)
                    }
                } else {
                    alert('User Not Found')
                }
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
        }

    }, []);

    return (
        <div style={{
            width: '100%',
        }}>
            <div>
                {
                    artist_id &&
                    <Row style={{ minHeight: 900, margin: '5%', }}>
                        <Col lg={24} md={24} xs={24}>
                            {
                                userId &&
                                <ArtistProfile
                                    UID={userId && userId}
                                    artistName={ArtistName && ArtistName}
                                    bio={bio && bio}
                                    currentArtWorkId={null}
                                    artistImage={artistImage && artistImage}
                                    isModal={false}
                                    exhibition_history={exhibition_history && exhibition_history}
                                    education_history={education_history && education_history}
                                    onlyProfile={0}
                                />
                            }
                        </Col>
                    </Row>
                }
                <div className="adjust-home-page-footer" style={
                    Object.assign({}
                        , {
                            width: '-webkit-fill-available',
                            position: 'fixed',
                            left: 0,
                            bottom: 0,
                            right: 0,
                            background: '#E7F6FA'
                        }
                    )}
                >
                    <CommonFooter />
                </div>
            </div>
        </div>
    )
}

export default ArtistDetails
