import { Col, Row, Modal } from "antd";
import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Footer } from "antd/lib/layout/layout";
import Questions from "./Questions";
import Help from "./Help";
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import config from '../../../../../src/config/config';
import ChicagoBoothLogo from '../../../../../src/static/img/ChicagoBooth.svg';
import expoChicagologo from '../../../../../src/static/img/expoChicagologo.svg';
import gertiLogo from '../../../../../src/static/img/gertie_logo.gif';
import Wdot from '../../../../../src/static/img/Wdot1.png';

import count from "../../../../redux/count/actionCreator";


const CommonFooter = () => {

    const dispatch = useDispatch();

    const UID = useSelector((state) => {
        return state.fb.auth.uid;
    });

    const currentYear = new Date().getFullYear();
    const footerStyle = {
        padding: '15px 10px 15px',
        color: 'rgba(0, 0, 0, 0.65)',
        fontSize: '14px',
        background: '#43b6d921',
        width: '100%',
        boxShadow: '0 -5px 10px rgba(146,153,184, 0.05)',
    };

    const [state, setState] = useState({
        signInVisible: false,
        helpModalVisible: false,
    });

    const role = "Notification";

    useEffect(() => {
        var notification = sessionStorage.getItem('alert-notification');
        if (notification != 1) 
        {
            (async () => {
                const result = await axios.get(`${config.apiUrl}/get-snippets/${role}`);
                if (result.data.length > 0) {
                    var snippet = JSON.parse(result.data[0].snitppets_data);
                    if (snippet.status == true) {
                        showDeleteModal(snippet.NotificationContent);
                    }
                }

            })();
        }
        if (UID != undefined) {
            dispatch(count(UID))
        }


    }, [])


    const showDeleteModal = (NotificationContent) => {

        Modal.confirm({
            title: 'Site Maintenance Alert',
            content: (
                <div dangerouslySetInnerHTML={{ __html: NotificationContent }}>
                </div>
            ),
            cancelButtonProps: { style: { display: 'none' } },
            onOk() {
                sessionStorage.setItem('alert-notification', 1);
            }
        });
    }


    const { signInVisible } = state;
    const { helpModalVisible } = state;

    const onCancel = () => {
        setState({
            ...state,
            signInVisible: false,
        });
    };

    const showQuestionsModal = () => {
        setState({
            ...state,
            signInVisible: true,
        });
    };

    const onCancelHelpModal = () => {
        setState({
            ...state,
            helpModalVisible: false,
        });
    };

    const showHelpModal = () => {
        setState({
            ...state,
            helpModalVisible: true,
        });
    };

    return (
        <>
            <Questions onCancel={() => { onCancel() }} visible={signInVisible} artWorkId={0} />
            <Help onCancel={() => { onCancelHelpModal() }} visible={helpModalVisible} artWorkId={0} />
            <Footer style={footerStyle}>
                <Row>
                    <Col md={12} xs={9}>
                        <span className="mobile-footer-font" style={{ color: '#000' }}>{currentYear} ©STOODIO</span>
                    </Col>
                    <Col md={12} xs={15}>
                        <div className="float-right-footer mobile-footer-font">
                            <NavLink to={`/our-partners`} style={{ color: '#000' }} >Our Partners</NavLink>
                            <NavLink to={`/terms`} style={{ color: '#000', marginLeft: 7 }} >TOS</NavLink>
                            <NavLink to={`/privacy-policy`} style={{ color: '#000', marginLeft: 7 }} >Privacy Policy</NavLink>
                            <NavLink to="#" style={{ color: '#000', marginLeft: 7 }} onClick={() => { showQuestionsModal() }}>Contact</NavLink>
                            <NavLink to="#" style={{ color: '#000', marginLeft: 7 }} onClick={() => { showHelpModal() }}>FAQ</NavLink>
                        </div>
                    </Col>
                </Row>
            </Footer>


        </>
    )

}

export default CommonFooter