import actions from './actions';

const initialState = {
    data: [],
    loading: false,
    error: null,
  };

  const {
    COUNT_BEGIN,
    COUNT_SUCCESS,
    COUNT_ERR
  } = actions;  
 
  const CountReducer = (state = initialState , action) =>{

    const { type, data, err } = action;
    switch (type) {
        case COUNT_BEGIN:
        return {
            ...initialState,
            loading: true,
        };

        case COUNT_SUCCESS:
        return {
            ...initialState,
            data,
            error: false,
            loading: false,
        };

        case COUNT_ERR:
        return {
            ...initialState,
            error: err,
            loading: false,
        };

        default:
      return state;
    }

  }

  export default CountReducer;