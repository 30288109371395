import Color_Taste from '../../../../../config/constants/materials/color_taste';

const ColorAverage = (parsedColorArr,userRateValue,colorNotSelectedValue) => {

    let colorArray = Color_Taste;
    var appliedColorToArtWork = []
    for (var i = 0; i < colorArray.length; i++) {
        for (var j = 0; j < parsedColorArr && parsedColorArr.length; j++) {
            if (colorArray[i].value == parsedColorArr[j]) {
                appliedColorToArtWork.push(
                    {
                        name: colorArray[i].label,
                        userRating: userRateValue
                    }
                );

            }
        }
    }

    var colorTasteProfile = [...appliedColorToArtWork]

    var colorResult = colorArray.filter(function (o1) {
        return !appliedColorToArtWork.some(function (o2) {
            if (o1.label === o2.name) {
                return o1.id === o2.id
            }
        });
    });

    colorResult.map((itm) => {
        colorTasteProfile.push({
            name: itm.label,
            userRating: colorNotSelectedValue
        })
    })
    

    return {colorTasteProfile}
}

export default ColorAverage