const actions = {
    COUNT_BEGIN: 'COUNT_BEGIN',
    COUNT_SUCCESS: 'COUNT_SUCCESS',
    COUNT_ERR: 'COUNT_ERR',
  
    countBegin: () => {
      return {
        type: actions.COUNT_BEGIN,
      };
    },
  
    countSuccess: data => {
      return {
        type: actions.COUNT_SUCCESS,
        data,
      };
    },
  
    countSendErr: err => {
      return {
        type: actions.COUNT_ERR,
        err,
      };
    }
  };

  
  
  export default actions;