const actions = {
  Base64_BEGIN: 'Base64_BEGIN',
  Base64_SUCCESS: 'Base64_SUCCESS',
  Base64_ERR: 'Base64_ERR',

  storeBase64Begin: () => {
    return {
      type: actions.Base64_BEGIN,
    };
  },

  storeBase64Success: data => {
    return {
      type: actions.Base64_SUCCESS,
      data,
    };
  },

  storeBase64SendErr: err => {
    return {
      type: actions.Base64_ERR,
      err,
    };
  }
};



export default actions;