import actions from './actions'
import userListService from '../../services/userListService';

const {
    getWorkFilterDataBegin,
    getWorkFilterDataSuccess,
    getWorkFilterDataListErr
  } = actions;


  const getWorkFilterDataList = (workData) => {
	return async dispatch => {
		try {
			await dispatch(getWorkFilterDataBegin());
			const data = await userListService.WorkFilterList(workData);
			console.log("UserList success",data)
			//console.log(dispatch(usersListSuccess(data)));
			await dispatch(getWorkFilterDataSuccess(data));
		} catch (err) {
			console.log("err",err)
			dispatch(getWorkFilterDataListErr(err));
		}
	};
};

export default getWorkFilterDataList