import { Form, Select, Table } from 'antd';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Cards } from '../../../../components/cards/frame/cards-frame'
import Heading from '../../../../components/heading/heading'
import { Main } from '../../../styled'
import config from '../../../../config/config';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Modal, notification, Button } from 'antd';
import count from '../../../../redux/count/actionCreator';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import messages from '../../../../config/constants/messages';
import CreateEarlyAccess from '../../../project/overview/CreateEarlyAccess';
import EditEarlyAccess from '../../../project/overview/EditEarlyAccess';
import { ProjectHeader } from '../../../project/style';
import { PageHeader } from '../../../../components/page-headers/page-headers';

function ManageEarlyAccess() {

  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [getearlyData, setEarlyData] = useState([])
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [exId, setId] = useState(null);

  const [deliverystatus, setDeliveryStatus] = useState("");
  const [olddeliverystatus, setOldDeliveryStatus] = useState("");
  const [txn_id, setTxnId] = useState("");
  const [orderId, setOrderId] = useState("");
  const [oldImageURL, setOldImageURL] = useState("");


  const UID = useSelector((state) => {
    return state.fb.auth.uid;
  });

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setId(null)
    setIsEditModalVisible(false);
    setIsModalVisible(false);
  };

  const openEarlyModal = () => {
    setIsModalVisible(true);
  };
  const deleteEarly = (id) => {
    Modal.confirm({
      title: 'Delete Early Access',
      content: (
        <div>
          <p>Do you want to delete Early Access</p>
        </div>
      ),
      onOk() {


        const data = { id };

        axios.post(`${config.apiUrl}/delete-early-access`, data)
          .then(function (response) {
            if (response.status == 200) {
              alert("The early access deleted successfully");
              location.reload();
            } else {
              alert(response.data.message);
            }
          })
          .catch(function (error) {
            // handle your error
            console.log(error)
            return false;
          });

      },
      onCancel() { }
    });

  }
  const showModal = (id, imagesURL) => {
    setOldImageURL(imagesURL);
    setId(id)
    setIsEditModalVisible(true);
  };



  const history = useHistory();

  useEffect(() => {

    sessionStorage.removeItem('tab-index');
    var temp_data = [];
    const getAllOrders = async () => {
      const response = await fetch(`${config.apiUrl}/get-all-early-access/null`, {
        method: "GET", // or 'PUT'
        headers: {
          "Content-Type": "application/json",
        }
      });
      const data = await response.json();
      //alert(JSON.stringify(data));
      setEarlyData(data)
    }
    getAllOrders();

  }, [])

  const handleStatus = async () => {
    let BrowserData = { id: orderId, delivery_status: deliverystatus, order_id: txn_id }
    console.log(JSON.stringify(BrowserData));
    axios.post(`${config.apiUrl}/update-order-status`, BrowserData)
      .then(function (response) {
        if (response.status && response.status == 200) {
          sessionStorage.setItem("update_status", "1")
          location.reload();
        }

      })
      .catch(function (error) {
        console.log(error);
      });

  }





  const handleInputs = (value, name) => {
    setDeliveryStatus(value);
  }

  let columns = [    
    {
      title: 'Early Access Title',
      dataIndex: 'title',
    },    
    {
      title: 'Date',
      dataIndex: 'early_date'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      align: 'center',
      render: (status) => {
        if (status == '1') {
          return <label><b style={{ color: '#006C94' }}>Active</b></label>
        } else {
          return <label><b style={{ color: '#ff4d4f' }}>Inactive</b></label>
        }
      }
    },
    {
      title: 'Edit',
      dataIndex: ['early_access_id'],      
      render: (id, row) => {
          return (
            <EditOutlined onClick={() => { showModal(row['early_access_id'], '') }} />
          )
      }
    },
    {
      title: 'Delete',
      dataIndex: ['early_access_id'],
      render: (id, row) => {
        return (
          <DeleteOutlined onClick={() => { deleteEarly(row['early_access_id']) }} />
        )

      }
    }
  ];

  return (
    <>

      <ProjectHeader >
        <PageHeader
          ghost
          title="Manage Early Access"
          buttons={[
            <Button onClick={openEarlyModal} type="primary" size="large">Create Early Access</Button>
          ]}
        />
      </ProjectHeader>

      <Main className='main-layout-div'>
        <Cards>
          <div className="table-responsive">
            <Table dataSource={getearlyData} columns={columns} />
          </div>
        </Cards>
        <CreateEarlyAccess onCancel={handleCancel} visible={isModalVisible} />
        {exId != null && <EditEarlyAccess onCancel={handleCancel} visible={isEditModalVisible} Id={exId}  />}
      </Main>
    </>
  )
}

export default ManageEarlyAccess
