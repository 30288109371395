import { Button, Col, Row, Space, Spin } from 'antd'

import './css/style.css'
import { Link, NavLink, useHistory, useParams } from 'react-router-dom';
import CommonFooter from "../CommonFooter";
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from '../../../../../config/config';
import { useDispatch, useSelector } from 'react-redux';

import Newcity from '../../../../../static/img/newcity.png';
import Thevisualist from '../../../../../static/img/thevisualist.png';
import Artsclubofchicago from '../../../../../static/img/artsclubofchicago.png';
import CommonSEO from '../../../../meta/CommonSEO';

const NewsEventList = () => {

    const history = useHistory();
    const dispatch = useDispatch();

    const [newsData, setNewsData] = useState([]);
    const [loading, setLoading] = useState(true);


    useEffect(() => {

        axios.get(`${config.apiUrl}/get-all-news`)
            .then(function (response) {
                console.log("response", response)
                if (response.data.length > 0) {
                    setNewsData(response.data)
                    setLoading(false);
                }
            })
            .catch(function (error) {
                console.log(error);
            })
    }, [])

    const redirectURL = (url) => {
        history.push(url);
    }


    return (
        <div style={{
            width: '100%',
        }}>
            <CommonSEO URL="news-events"/>
            <div>
                <div style={{ marginTop: 140, paddingBottom: 60 }}>
                    <Row style={{ marginRight: 10, marginLeft: 10 }} className='set-homepage-content'>
                        <Col xs={0} sm={0} md={2} lg={4}></Col>

                        <Col xs={24} sm={24} md={20} lg={16}>
                            {loading == true ?
                                <>
                                    <Space
                                        direction="vertical"
                                        style={{
                                            width: '100%',
                                            textAlign: 'center'

                                        }}
                                    >
                                        <Spin tip="Loading..." size="large">
                                            <div className="content" />
                                        </Spin>
                                    </Space>
                                </>
                                :
                                <>
                                    <Row >
                                        <p style={{ fontFamily: "Melodrama", fontSize: 45, marginBottom: 25 }} align="center">News and Events</p>
                                    </Row>
                                    {
                                        [...newsData].length > 0 ?

                                            <Row>
                                                {
                                                    [...newsData].map((item) => {
                                                        if (item) {
                                                            let height = 25;
                                                            let url_link = '/news-events/' + item.news_url; 
                                                            let imagesURL = 'https://stoodio.art/static/media/stoodiologo.175eeac4.svg';

                                                            if (item.is_approved == 2) 
                                                            {

                                                                url_link =  item.news_url;

                                                                if (url_link.includes("artsclubchicago")) {
                                                                    imagesURL = Artsclubofchicago;
                                                                    height = 40;
                                                                } else if (url_link.includes("thevisualist")) {
                                                                    imagesURL = Thevisualist;
                                                                    height = 40;
                                                                } else if (url_link.includes("newcity")) {
                                                                    imagesURL = Newcity;
                                                                    height = 40;
                                                                } else {

                                                                }
                                                            }

                                                            return (
                                                                imagesURL &&
                                                                <Row style={{ width: '100%', marginBottom: 30 }}>
                                                                    <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: 'left' }}>
                                                                        <>
                                                                            <div class="five columns" style={{margin:'20px 10px'}}>
                                                                                <section class="entry-content">
                                                                                    <div class="entry-meta-event">
                                                                                        <img
                                                                                            src={imagesURL}
                                                                                            alt="Art-Work"
                                                                                            style={{
                                                                                                cursor: 'pointer',
                                                                                                height: height,
                                                                                                marginRight: 25
                                                                                            }}
                                                                                        />
                                                                                        by <span class="entry-author remove-underline" style={{ textDecoration: 'underline', fontWeight: 600, cursor: 'pointer', paddingRight: 10, fontSize: 15 }}>{item.name}</span>
                                                                                        •<span class="entry-date" style={{ fontSize: 13, display: 'inline-block', marginLeft: 10 }}><i>{item.created_at}</i></span>
                                                                                    </div>
                                                                                    <h1 class="underline-on-hover" style={{color:'black !important'}}>
                                                                                        <a href={url_link} rel="bookmark" target="_blank">
                                                                                            <span style={{textTransform:'uppercase'}}>{item.news_title}</span>
                                                                                        </a>
                                                                                    </h1>

                                                                                    <div class="entry-excerpt" style={{ display: 'block',textAlign:'justify' }} dangerouslySetInnerHTML={{ __html: item.news_description != "" ? item.news_description : "" }}></div>

                                                                                    <a href={url_link} target="_blank">
                                                                                        <button size='default' class="slide-from-bottom-test-quiz slide-from-bottom add-cart-button-text"
                                                                                            //onClick={() => {redirectURL(url_link)}} 
                                                                                            style={{ cursor: 'pointer', marginTop: 5, height: '40px !important',zIndex:0 }}>
                                                                                            <p style={{ fontFamily: 'Arial', fontSize: 13, margin: 0, fontWeight: 600, lineHeight: '24px', letterSpacing: ' 0em', textAlign: 'left' }}>Keep reading</p>
                                                                                        </button>
                                                                                    </a>
                                                                                </section>
                                                                            </div>
                                                                            <hr style={{ width: '90%' }} />
                                                                        </>
                                                                    </Col>
                                                                </Row>
                                                            )


                                                        }
                                                    })
                                                }
                                            </Row>

                                            :
                                            <Row style={{ justifyContent: 'center' }}>
                                                {/* <p style={{ fontFamily: "Melodrama", fontSize: 45 }} align="center">Comming Soon</p> */}
                                            </Row>

                                    }
                                </>
                            }
                        </Col>

                        <Col xs={0} sm={0} md={2} lg={4}></Col>
                    </Row>
                </div>
                <div className="adjust-home-page-footer" style={
                    Object.assign({}
                        , {
                            width: '-webkit-fill-available',
                            position: 'fixed',
                            left: 0,
                            bottom: 0,
                            right: 0,
                            background: '#E7F6FA'
                        }
                    )}
                >
                    <CommonFooter />
                </div>
            </div>
        </div>
    )
}

export default NewsEventList