import { Button, Col, Row } from 'antd'

import './css/style.css'
import { Link, NavLink, useHistory } from 'react-router-dom';
import CommonFooter from "../CommonFooter";
import React, { useEffect } from 'react';
import getdata from '../../../../../redux/contentChange/actions'
import { useDispatch, useSelector } from 'react-redux';

const DataNotFound = () => {

    const history = useHistory();
    const dispatch = useDispatch();

    const handleCartCount = (count) => {
        dispatch(getdata.getContentDetailsSuccess({
            count
        }));
    }

    useEffect(() => {
        sessionStorage.removeItem('cartItemCount')
        handleCartCount(0)
        console.log("Build No:8");
    }, [])

    return (
        <div style={{
            width: '100%',
        }}>
            <div style={{ backgroundColor: 'rgb(206 209 219)', overflowY: 'hidden', minHeight: '1000px' }}>
                <section className="content-section" style={{ paddingTop: '40px' }}>
                    <div id="dark" style={{ display: 'block', marginTop: '10%' }} className="row text-center">
                        <div className="info">
                            <img style={{ maxHeight: '50%', maxWidth: '85%' }} src="https://envato.ukie.company/404-error/villains/assets/img/404-dark.png" alt="404 error" /><br /><br />

                            <b style={{ color: '#fff' }}>The page you are looking for was moved, removed,<br />
                                might never have existed</b>
                            <div style={{ padding: '20px 0px 20px 0px' }} className="col-lg-12">


                                <Button className="gallery-btn" htmlType="submit" type="primary" size="default">
                                    <a href='/' style={{ margin: 0, padding: 10, fontSize: 14 }}> Go To Home</a>
                                </Button>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div className="adjust-home-page-footer" style={
                Object.assign({}
                    , {
                        width: '-webkit-fill-available',
                        position: 'fixed',
                        left: 0,
                        bottom: 0,
                        right: 0,
                        background: '#E7F6FA'
                    }
                )}
            >
                <CommonFooter />
            </div>

        </div >
    )
}

export default DataNotFound